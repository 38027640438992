import { TemplateType } from '../Platforms/PlatformConstants';

export interface PlatformOptionDef {
    text: string;
    value: TemplateType;
}

export const platformOptions: PlatformOptionDef[] = [
    {
        text: 'HTTP',
        value: 'http_curl_v1',
    },
    {
        text: '.NET',
        value: 'cs_net_standard_lib',
    },
    {
        text: 'Android',
        value: 'java_gradle_android_lib',
    },
    {
        text: 'Objective C',
        value: 'objc_cocoa_touch_ios_lib',
    },
    {
        text: 'Java',
        value: 'java_eclipse_jre_lib',
    },
    {
        text: 'PHP',
        value: 'php_generic_lib_v2',
    },
    {
        text: 'Python',
        value: 'python_generic_lib',
    },
    {
        text: 'Ruby',
        value: 'ruby_generic_lib',
    },
    {
        text: 'TypeScript',
        value: 'ts_generic_lib',
    },
    {
        text: 'AngularJs',
        value: 'angular_javascript_lib',
    },
    {
        text: 'Node',
        value: 'node_javascript_lib',
    },
    {
        text: 'GO',
        value: 'go_generic_lib',
    },
];
