import {
    AIChatbotStateDef,
    UPDATE_STATE,
    UPDATE_TOGGLE,
    UPDATE_WELCOME_MESSAGE,
    UPDATE_STATE_TYPE,
    UPDATE_TOGGLE_TYPE,
    UPDATE_WELCOME_MESSAGE_TYPE,
} from './AIChatbotTypes';

export const aiChatbotReducer = (
    state: AIChatbotStateDef,
    action: UPDATE_STATE_TYPE | UPDATE_TOGGLE_TYPE | UPDATE_WELCOME_MESSAGE_TYPE
): AIChatbotStateDef => {
    if (action.type === UPDATE_STATE) {
        return {
            ...state,
            ...action.payload,
        };
    }

    if (action.type === UPDATE_TOGGLE) {
        return {
            ...state,
            isAiEnabled: action.payload,
        };
    }

    if (action.type === UPDATE_WELCOME_MESSAGE) {
        return {
            ...state,
            welcomeMessage: action.payload,
        };
    }

    return state;
};
