import React, { FunctionComponent, useState } from 'react';
import './badges-style.scss';
import { BadgeDef } from './BadgeConstants';
import { Dropdown } from '../../../../design-system';
import { DropdownItemDef } from '../../../../design-system/DropDown/DropDown';
import { BadgeSelector } from '../BadgeSelector/BadgeSelector';
import { H4 } from '../../../../design-system/Headings/Headings';

interface BadgeProps {
    badge: BadgeDef;
    index: number;
    replaceBadge: (newBadge: string, oldBadge: string) => void;
    deleteBadge: (deletedBadge: string) => void;
    inactiveBadges: BadgeDef[];
}

const Badge: FunctionComponent<BadgeProps> = (props) => {
    const { badge, index, inactiveBadges, replaceBadge, deleteBadge } = props;
    const [activeScreen, setActiveScreen] = useState('screen_1');

    const replaceBadgeClicked = (activeScreen: string) => {
        setActiveScreen(activeScreen);
    };

    const onBadgeSelectorChange = (newBadge: string) => {
        setActiveScreen('screen_1');
        replaceBadge(newBadge, badge.value);
    };

    const onBadgeSelectorCancel = () => {
        setActiveScreen('screen_1');
    };

    const dropdownItems: DropdownItemDef[] = [
        {
            name: 'Replace',
            onClick: () => replaceBadgeClicked('screen_2'),
        },
        {
            name: 'Delete',
            onClick: () => deleteBadge(badge.value),
        },
    ];

    const renderBadge = () => {
        return (
            <div>
                <Dropdown
                    className="absolute close-icon"
                    name="Edit"
                    dropdownItems={dropdownItems}
                />
                <div className="flex flex-col items-center">
                    {badge.icon}
                    <H4 className="mb-3 text-center leading-normal:">{badge.title}</H4>
                    <p className="leading-relaxed text-center text-14 text-charcoal-800">
                        {badge.description}
                    </p>
                </div>
            </div>
        );
    };

    return (
        // <EuiDraggable
        //     className="relative w-2/6 px-3 py-5 mx-3 mt-6 border border-transparent border-solid hover:bg-white hover:border-goose-600 rounded-12"
        //     key={badge.value}
        //     index={index}
        //     draggableId={badge.value}
        //     customDragHandle={true}
        // >
        //     {(provided) => (

        <div className="relative w-2/6 px-3 py-5 mx-3 mt-6 border border-transparent border-solid hover:bg-white hover:border-goose-600 rounded-12">
            <div className="flex flex-col ">
                {/* <div
                        className="absolute top-0 left-0 invisible drag-icon"
                        {...provided.dragHandleProps}
                    >
                        <DragIcon />
                    </div> */}

                {activeScreen === 'screen_1' && renderBadge()}
                {activeScreen === 'screen_2' && (
                    <BadgeSelector
                        inactiveBadges={inactiveBadges}
                        onChange={onBadgeSelectorChange}
                        onCancel={onBadgeSelectorCancel}
                    />
                )}
            </div>
        </div>
        //     )}
        // </EuiDraggable>
    );
};

export default Badge;
