/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FunctionComponent, useState, ChangeEvent } from 'react';
import { FieldGroupCard, FieldBox, TextField, Button } from '../../../design-system';
import { PackageDeploymentDef } from '../Platforms/PlatformConstants';
import { PublishedPackageDef } from '../Platforms/PlatformInitialState';

interface packageDeploymentProps {
    packageDeployment: PackageDeploymentDef;
    onCancel: () => void;
    onSave: (packageInfo: PublishedPackageDef) => void;
}

const PackageDeployment: FunctionComponent<packageDeploymentProps> = (props) => {
    const {
        packageDeployment: { repositories },
        onSave,
        onCancel,
    } = props;

    const initialState = {
        activeState: 'screen_1',
        packageDeploymentVM: {
            packageRepository: '',
            packageName: '',
            version: '',
        },
    };

    const [activeScreen, setActiveScreen] = useState(initialState.activeState);
    const [packageDeploymentVM, setPackageDeploymentVM] = useState(
        initialState.packageDeploymentVM
    );

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPackageDeploymentVM({
            ...packageDeploymentVM,
            [name]: value,
        });
    };

    return (
        <FieldGroupCard>
            {activeScreen === 'screen_1' && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <a onClick={() => setActiveScreen('screen_2')} className="w-16 h-16 bg-gray-200">
                    {repositories[0].name}
                </a>
            )}

            {activeScreen === 'screen_2' && (
                <div className="flex justify-evenly">
                    <FieldBox label="Package Name">
                        <TextField
                            placeholder="Package Name"
                            value={packageDeploymentVM.packageName}
                            onChange={(e) => onInputChange(e)}
                            name="packageName"
                            aria-label=""
                        />
                    </FieldBox>
                    <FieldBox label="Package Name">
                        <TextField
                            placeholder="Package Value"
                            value={packageDeploymentVM.version}
                            onChange={(e) => onInputChange(e)}
                            name="version"
                            aria-label=""
                        />
                    </FieldBox>
                    <Button
                        fill="primary"
                        text="Publish"
                        onClick={() => onSave(packageDeploymentVM)}
                    />
                    <Button fill="transparent" text="Remove" onClick={() => onCancel()} />
                </div>
            )}
        </FieldGroupCard>
    );
};

export default PackageDeployment;
