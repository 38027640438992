import React, { FunctionComponent } from 'react';
import './badge.scss';

interface BadgeProps {
    title: string;
    type: 'default' | 'success';
    id?: string;
    className?: string;
    style?: React.CSSProperties;
}

const Badge: FunctionComponent<BadgeProps> = (props) => {
    const { title, type, className, id, style } = props;
    return (
        <div id={id} className={`badge-wrapper ${type} ${className} `} style={style}>
            <p className="badge-title">{title}</p>
        </div>
    );
};

export default Badge;
