import React, { FunctionComponent, useEffect } from 'react';
import CustomContentDefault from '../../components/api-docs/CustomContentDefault/CustomContentDefault';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { CustomContentKeyForSidenav, SideNavActiveItemStoreKey } from '../ViewConstant';

const CustomContentView: FunctionComponent = () => {
    const { updateStore } = useStore() as StoreProviderValue;
    useEffect(() => {
        updateStore(CustomContentKeyForSidenav, SideNavActiveItemStoreKey);
    }, []);

    return (
        <div>
            <CustomContentDefault />
        </div>
    );
};

export default CustomContentView;
