import React from 'react';
import './pre-release-tag.scss';

export type PreReleaseTagProps = {
    title: string;
};

export const PreReleaseTag = ({ title }: PreReleaseTagProps) => {
    return (
        <div className="flex items-center justify-center mx-2">
            <span className="beta-release-tag">{title}</span>
        </div>
    );
};
