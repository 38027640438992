/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { StoreProviderValue, useStore } from '../../../../store/storeContext';
import Logo from '../Logo/Logo';
import NavigationLinks from '../Navigation/Navigation';
import './home-page-header.scss';

const HomePageHeader: FunctionComponent = () => {
    const {
        storeState: {
            apiGroup: { apiDocsBranding },
        },
    } = useStore() as StoreProviderValue;

    return (
        <div
            className="w-full h-16 px-10 bg-white border-0 border-b-2 border-solid home-page-header-wrapper"
            style={{ borderColor: apiDocsBranding?.colors.cta }}
        >
            <div className="flex items-center justify-between h-full mx-auto my-0">
                <Logo />

                <NavigationLinks />
            </div>
        </div>
    );
};

export default HomePageHeader;
