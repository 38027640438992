import { useEffect } from 'react';

const useDragEvent = (ref: React.RefObject<HTMLElement>, callback: () => void): void => {
    const handleDragEnter = (e: DragEvent) => {
        if (ref && ref.current && e.target && !ref.current.contains(e.target as HTMLElement)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('dragenter', handleDragEnter);

        return () => {
            document.removeEventListener('dragenter', handleDragEnter);
        };
    });
};

export default useDragEvent;
