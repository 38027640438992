import React, { FunctionComponent } from 'react';
import ApiDocsPreview from '../ApiDocsPreview/ApiDocsPreview';

const CustomContentDefault: FunctionComponent = () => {
    return (
        <div>
            <ApiDocsPreview />
        </div>

        // <div className="p-10">
        //     <h3 className="mt-6 mb-4 text-xl  font-medium">Let's get started</h3>

        //     <div className="p-8">
        //     <MainIcon width="382.1" height="279"/>
        //     </div>

        //     <p className="mb-4">Add your new custom file and edit it in our new Markdown Editor.</p>

        //     <EuiButton color={'secondary'} fill={true}>
        //         Add New File
        //     </EuiButton>
        // </div>
    );
};

export default CustomContentDefault;
