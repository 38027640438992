import React, { FunctionComponent, useContext } from 'react';
import { FieldBox } from '../../../../../../design-system';
import { getFontsList } from '../TypographySectionConstant';
import { FontCategoryType, TypographySectionKeyType } from '../../TypographyConstant';
import { BrandingContext } from '../../../BrandingContext';
import SelectField from '../../../../../../design-system/SelectField/SelectField';

const TypographySection: FunctionComponent<{
    fontCategory: FontCategoryType;
    sectionKey: TypographySectionKeyType;
}> = ({ sectionKey, fontCategory }) => {
    const { apiDocsBrandingState, changeDefaultFont } = useContext(BrandingContext);

    return (
        <FieldBox label="Font Family">
            <div className="flex justify-between">
                <SelectField
                    className={'w-64 select-font-family typography-select-' + sectionKey}
                    id=""
                    name=""
                    options={getFontsList(fontCategory)}
                    value={apiDocsBrandingState.typography[sectionKey].fontFamily}
                    onChange={(e): void => changeDefaultFont(sectionKey, e.target.value)}
                />
            </div>
        </FieldBox>
    );
};

export default TypographySection;
