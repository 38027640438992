import { ReactNode } from 'react';
import { UiStateDef } from '../../types/ui-state';

export const ADD_SECTION = 'ADD_SECTION';
export const ADD_FILE = 'ADD_FILE';
export const RENAME_SECTION = 'RENAME_SECTION';
export const RENAME_FILE = 'RENAME_FILE';
export const DELETE_SECTION = 'DELETE_SECTION';
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_GENERATED_CONTENT = 'DELETE_GENERATED_CONTENT';
export const DISABLE_GENERATED_CONTENT = 'DISABLE_GENERATED_CONTENT';
export const UPDATE_TOC = 'UPDATE_TOC';
export const UPDATE_STATE = 'UPDATE_STATE';
export const RENAME_GENERATED_CONTENT = 'RENAME_GENERATED_CONTENT';
export const HANDLE_DROP_END_OF_TOC_ITEMS = 'HANDLE_DROP_END_OF_TOC_ITEMS';
export const ADD_NEW_DYNAMIC_ELEMENT = 'ADD_NEW_DYNAMIC_ELEMENT';

export type UPDATE_STATE_TYPE = {
    type: 'UPDATE_STATE';
    payload: TocType;
};

export type ADD_SECTION_TYPE = {
    type: 'ADD_SECTION';
    payload: {
        path: string[];
        group: string;
        slug: string;
    };
    resolve: (a: true) => void;
    reject: (errorMsg: string) => void;
};

export type ADD_FILE_TYPE = {
    type: 'ADD_FILE';
    payload: {
        path: string[];
        file: string;
        page: string;
        slug: string;
    };
    resolve: (file: ToCFileType) => void;
    reject: (errorMsg: string) => void;
};

export type RENAME_SECTION_TYPE = {
    type: 'RENAME_SECTION';
    payload: {
        path: string[];
        oldGroup: string;
        newGroup: string;
        slug: string;
    };
    resolve: (a: true) => void;
    reject: () => void;
};

export type RENAME_FILE_TYPE = {
    type: 'RENAME_FILE';
    payload: {
        path: string[];
        oldFile: ToCFileType;
        newFile: ToCFileType;
    };
    resolve: (file: ToCFileType) => void;
    reject: (errorMessage: string) => void;
};

export type RENAME_GENERATED_CONTENT = {
    type: 'RENAME_GENERATED_CONTENT';
    payload: {
        path: string[];
        from: string;
        newName: string;
        resolve: (errorMsg: string) => void;
    };
};
export type DELETE_SECTION_TYPE = {
    type: 'DELETE_SECTION';
    payload: {
        path: string[];
        group: string;
        generatedContentList: GeneratedContentType[];
    };
};

export type DELETE_FILE_TYPE = {
    type: 'DELETE_FILE';
    payload: {
        path: string[];
        file: string;
        resolve: Function;
    };
};

export type DELETE_GENERATED_CONTENT_TYPE = {
    type: 'DELETE_GENERATED_CONTENT';
    payload: {
        path: string[];
        content: GeneratedContentType;
    };
};

export type DISABLE_GENERATED_CONTENT_TYPE = {
    type: 'DISABLE_GENERATED_CONTENT';
    payload: {
        path: string[];
        content: GeneratedContentType;
    };
};

export type UPDATE_TOC = {
    type: 'UPDATE_TOC';
    payload: {
        toc: TocType;
    };
};

export type HANDLE_DROP_END_OF_TOC_ITEMS = {
    type: 'HANDLE_DROP_END_OF_TOC_ITEMS';
    payload: {
        isReorderOnSamelevel: boolean;
        source: { path: string[]; index: number };
        sourceObj: any;
        destination: { path: string[]; index: number };
    };
};

export type ADD_NEW_DYNAMIC_ELEMENT_TYPE = {
    type: 'ADD_NEW_DYNAMIC_ELEMENT';
    payload: {
        sourceIndex: number;
        newObj: ToCItemType;
        isReorderOnSamelevel: boolean;
        path: string[];
        index: number;
    };
};

export type ActionType =
    | UPDATE_STATE_TYPE
    | ADD_SECTION_TYPE
    | ADD_FILE_TYPE
    | RENAME_SECTION_TYPE
    | RENAME_FILE_TYPE
    | RENAME_GENERATED_CONTENT
    | HANDLE_DROP_END_OF_TOC_ITEMS
    | DELETE_SECTION_TYPE
    | DELETE_FILE_TYPE
    | DELETE_GENERATED_CONTENT_TYPE
    | DISABLE_GENERATED_CONTENT_TYPE
    | UPDATE_TOC
    | ADD_NEW_DYNAMIC_ELEMENT_TYPE;

export type GeneratedContentType = {
    generate: string;
    from: string;
    disabled?: boolean;
};

export type ToCFileType = {
    page: string;
    slug: string;
    file: string;
};

export type ToCItemType = GeneratedContentType | ToCFileType | ToCElementType;
export type ToCItemsType = Array<ToCItemType>;

export type ToCElementType = {
    group: string;
    slug: string;
    items: ToCItemsType;
};

export type TocType = Array<ToCElementType | GeneratedContentType>;
export type MarkdownFileType = {
    [key: string]:
        | {
              title: string;
              name: string;
              markdown: string;
              oldPath?: string;
              newPath?: string;
          }
        | {};
};

export type FilesToBeRenamedType = {
    file: string;
    newName: string;
    markdown: string;
};

export type ToCItemError = {
    name: string;
    slug: string;
    custom: true;
};

export type CustomContentContextValue = {
    tableOfContents: TocType;
    uiState: UiStateDef;
    isSaving: boolean;
    fileToBeRenamed: FilesToBeRenamedType[];
    fileToBeDeleted: string[];
    addSection: (path: string[], name: string, slug: string) => Promise<true>;
    addFile: (path: string[], file: string, slug: string) => Promise<ToCFileType>;
    renameSection: (
        path: string[],
        oldName: string,
        newName: string,
        slug: string
    ) => Promise<true>;
    renameFile: (
        path: string[],
        oldName: ToCFileType,
        newName: string,
        slug: string
    ) => Promise<ToCFileType>;
    renameGeneratedContent: (path: string[], from: string, newName: string) => Promise<string>;
    deleteSection: (toc: TocType, path: string[], group: string) => void;
    deleteFile: (path: string[], page: string, callback: Function) => void;
    deleteGeneratedContent: (path: string[], content: GeneratedContentType) => void;
    disableGeneratedContent: (path: string[], content: GeneratedContentType) => void;
    updateToc: (toc: TocType) => void;
    setFileToBeRenamed: (fTBR: FilesToBeRenamedType[]) => void;
    setFileToBeDeleted: (pathKeys: string[]) => void;
    handleDropEnd: (
        toc: TocType,
        source: { path: string[]; index: number },
        destination: { path: string[]; index: number }
    ) => void;
    setNewFile: (newFile: ToCFileType) => void;
};

export type BrandingProviderProps = {
    children: ReactNode;
};
