import { getHeaders, fetcher } from './ApiClient';
import { PublishedPortalStateDef } from '../components/api-docs/PublishedPortal/PublishedPortalTypes';

const getPublishedPortal = (apiGroupId: string): Promise<PublishedPortalStateDef> => {
  const url = `api-groups/${apiGroupId}/draft-portal-settings/publishing?pendingUpdates=true`;
  return fetcher<PublishedPortalStateDef>(url, 'GET', getHeaders());
};

const updatePublishedPortal = (body: PublishedPortalStateDef, apiGroupId: string) => {
  const url = `api-groups/${apiGroupId}/draft-portal-settings/publishing`;
  return fetcher<PublishedPortalStateDef>(url, 'PUT', getHeaders(), body);
};

const updatePublishingSlug = (body: { slug: string }, apiGroupId: string) => {
  const url = `api-groups/${apiGroupId}/draft-portal-settings/publishing/slug`;
  return fetcher<{ slug: string }>(url, 'PUT', getHeaders(), body);
};

export { getPublishedPortal, updatePublishedPortal, updatePublishingSlug };
