export const UPDATE_STORE = 'UPDATE_STORE';
export const SAVE_BUTTON_CLICKED = 'SAVE_BUTTON_CLICKED';
// export const SAVE_DOCUMENTATION_PLATFORMS = 'SAVE_DOCUMENTATION_PLATFORMS';
export const UPDATE_STORE_API_GROUP = 'UPDATE_STORE_API_GROUP';
export const UPDATE_STORE_API_ENTITY = 'UPDATE_STORE_API_ENTITY';
export const UPDATE_UTM_MEDIUM = 'UPDATE_UTM_MEDIUM';

export const storeReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_STORE:
            return {
                ...state,
                [action.payload.key]: action.payload.newState,
            };
            break;

        // case SAVE_DOCUMENTATION_PLATFORMS:
        //   return {
        //     ...state,
        //     saveButtonClicked: true
        //   }
        //   break;

        case UPDATE_STORE_API_GROUP:
            return {
                ...state,
                apiGroup: {
                    ...state.apiGroup,
                    [action.payload.key]: action.payload.newState,
                },
            };
            break;

        case UPDATE_STORE_API_ENTITY: {
            return {
                ...state,
                apiEntities: {
                    ...state.apiEntities,
                    [action.payload.apiEntityId]: {
                        ...state.apiEntities[action.payload.apiEntityId],
                        [action.payload.key]: action.payload.newState,
                    },
                },
            };
            break;
        }

        case UPDATE_UTM_MEDIUM: {
            return {
                ...state,
                UTMMedium: action.payload.UTMMedium,
            };
            break;
        }

        default:
            return state;
            break;
    }
};
