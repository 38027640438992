import React, { FunctionComponent, useState, useRef } from 'react';
import useCancelEvent from '../../../hooks/useCancelEvent';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { FieldBox, TextField, FieldErrorText } from '../../../design-system';
import Button from '../../../design-system/Button/Button';
import { ToCItemError } from '../CustomContentTypes';
import { getDefaultErrorMessage, sanitizeSlug } from '../CustomContentConstant';

interface CustomContentProps {
    className?: string;
    title: string;
    hideSlug?: true;
    oldName?: string;
    oldSlug?: string;
    errorMsg?: ToCItemError;
    setErrorMsg: React.Dispatch<React.SetStateAction<ToCItemError>>;
    close: (data?: { name: string; slug: string }) => void;
}

const CustomSectionInput: FunctionComponent<CustomContentProps> = ({
    title,
    oldName,
    errorMsg,
    setErrorMsg,
    close,
    className,
    oldSlug,
    hideSlug,
}) => {
    const ref = useRef(null);
    const [name, setName] = useState(oldName ? oldName : '');
    const [slug, setSlug] = useState(oldSlug ? oldSlug : '');

    const _handleDone = () => {
        const trimmedName = name.trim();
        const sanitizedSlug = sanitizeSlug(slug);

        const error: ToCItemError = {
            custom: true,
            name: trimmedName ? '' : 'Name is required',
            slug: sanitizedSlug ? '' : 'Slug is required',
        };

        if ((error.slug && !hideSlug) || error.name) {
            return setErrorMsg(error);
        }

        close({ name: trimmedName, slug: sanitizedSlug });
    };

    const _handleCancel = () => {
        close();
    };

    const _handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (slug === sanitizeSlug(name) && !oldSlug) {
            setErrorMsg(getDefaultErrorMessage());
            setSlug(sanitizeSlug(ev.target.value));
        } else {
            setErrorMsg((prev) => ({ ...prev, name: '' }));
        }

        setName(ev.target.value);
    };

    const _handleEnterKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter') {
            _handleDone();
        }
    };

    const _handleSlugChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMsg((prev) => ({ ...prev, slug: '' }));
        setSlug(ev.target.value);
    };

    const shouldDisplaySanitizedSlug = () => {
        return oldSlug !== slug && slug !== sanitizeSlug(name);
    };

    useCancelEvent(_handleCancel);

    useOutsideClick(ref, _handleCancel);

    return (
        <div
            className={`relative z-10 w-full p-4 bg-snow-500 ${className}`}
            ref={ref}
            style={{ maxWidth: '228px' }}
        >
            {typeof errorMsg === 'string' && <FieldErrorText error={errorMsg} />}
            <FieldBox label={title} className="mr-3">
                <>
                    <TextField
                        name="name"
                        placeholder=""
                        value={name}
                        onChange={_handleInputChange}
                        onKeyDown={_handleEnterKeyDown}
                        autoFocus
                        type="text"
                        className="w-full border border-gray-200"
                    />
                    {errorMsg && <FieldErrorText error={errorMsg.name} />}
                </>
            </FieldBox>
            {!hideSlug && (
                <>
                    <FieldBox label="Slug" className="relative mt-3 mr-3">
                        <>
                            <TextField
                                name="Slug"
                                placeholder=""
                                value={slug}
                                onChange={_handleSlugChange}
                                onKeyDown={_handleEnterKeyDown}
                                type="text"
                                className="w-full border border-gray-200"
                            />

                            {errorMsg && <FieldErrorText error={errorMsg.slug} />}
                        </>
                    </FieldBox>
                    {shouldDisplaySanitizedSlug() && (
                        <div className="mt-3 mr-3 text-sm break-words">
                            {/* <div>sanitized slug:</div> */}
                            <div>{sanitizeSlug(slug)}</div>
                        </div>
                    )}
                </>
            )}{' '}
            <div className="flex justify-between mt-2">
                <Button className="mr-2" text="Cancel" fill="default" onClick={_handleCancel} />
                <Button text="Done" fill="default" onClick={_handleDone} />
            </div>
        </div>
    );
};

export default CustomSectionInput;
