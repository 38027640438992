/* eslint-disable jsx-a11y/no-static-element-interactions */
import { BadgeDef } from '../Badges/BadgeConstants';
import React, { FunctionComponent } from 'react';
import { Button } from '../../../../design-system';
import './badge-selector.scss';

interface BadgeSelectorProps {
    inactiveBadges: BadgeDef[];
    onChange: (selectedValue: string) => void;
    onCancel: () => void;
}

export const BadgeSelector: FunctionComponent<BadgeSelectorProps> = (props) => {
    const { inactiveBadges, onChange, onCancel } = props;

    return (
        <div className="flex flex-col w-full overflow-hidden badge-selector-wrapper">
            <div className="flex items-center justify-between m-2">
                <p className="leading-relaxed text-14 text-charcoal-800">Replace with:</p>
                <Button
                    fill="transparent"
                    text="Cancel"
                    className="font-normal text-13"
                    onClick={onCancel}
                />
            </div>
            <div className="flex flex-col overflow-auto badges-list">
                {inactiveBadges.map(({ title, value }) => {
                    return (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <div key={value} className="flex py-1" onClick={() => onChange(value)}>
                            {/* <div className="p-1 mr-3 bg-goose-500 rounded-12">
                                <Icon width="25" height="25" />
                            </div> */}
                            <p className="m-2 leading-relaxed cursor-pointer text-14 text-charcoal-800">
                                {title}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
