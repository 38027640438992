import React, { FunctionComponent } from 'react';
import { CloseIconFilled } from '../../icons/Ui/CloseIcon';

interface PublishErrorProps {}

const PublishError: FunctionComponent<PublishErrorProps> = ({}) => {
    return (
        <div className="flex flex-col widget-body">
            <div className="px-4">
                <div className="flex items-center mb-4 font-medium text-red text-22">
                    <CloseIconFilled fill="#FF4451" className="mr-2"></CloseIconFilled>
                    <div>Error</div>
                </div>
                <div className="mb-5 text-14 text-charcoal-800">
                    Something went wrong. Please go back and try again.
                </div>
            </div>
        </div>
    );
};

export default PublishError;
