import React, { FunctionComponent, useState } from 'react';
import { Button, RadioButton } from '../../design-system';
import { getEmbeddedApiDocs } from '../../api-client/ApiDocsPublishService';
import Tooltip from '../../design-system/Tooltip/Tooltip';
import { CopyIcon } from '../../icons/Ui/CopyIcon';
import { StoreProviderValue, useStore } from '../../store/storeContext';
import PublishError from './PublishError';
import {
    usePublishWizard,
    PublishWizardProviderValue,
} from '../api-docs/PublishedPortal/PublishWizardContext';
import Spinner from '../../design-system/Spinner/spinner';
import {
    PublishedPortalProviderValue,
    usePublishedPortal,
} from '../api-docs/PublishedPortal/PublishedPortalContext';
import { InfoIcon } from '../../icons/Ui/InfoIcon';
import CallOut from '../../design-system/CallOut/CallOut';
import { CheckIconFilled } from '../../icons/Ui/CheckIconFilled';
import PublishWizardFooter from './PublishWizardFooter';
import { useNavigate } from 'react-router';
import { PublishSDKCard } from '../api-docs/PubliskSDKCard/PublishSDKCard';

const EmbedSnippet: FunctionComponent<{
    setBlankPortal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
    const { setBlankPortal } = props;
    const [activeScreen, setActiveScreen] = useState('screen_1');
    const [selectedVersions, setSelectedVersions] = useState<string>('');
    const { updateActiveScreen } = usePublishWizard() as PublishWizardProviderValue;
    const [snippetState, setSnippetState] = useState();
    const { storeState } = useStore() as StoreProviderValue;
    const [responseStatus, setResponseStatus] = useState({});

    const { publishedPortalState } = usePublishedPortal() as PublishedPortalProviderValue;

    const [uiState, setUiState] = useState({
        isLoading: false,
        isError: false,
        statusCode: 0,
        message: '',
    });

    const generateScript = () => {
        setUiState({
            ...uiState,
            isLoading: true,
        });
        const promise = getEmbeddedApiDocs(selectedVersions as string);
        promise
            .then((resp) => {
                setBlankPortal(true);
                setUiState({
                    ...uiState,
                    isLoading: false,
                });
                setSnippetState(resp);
            })
            .catch((e: Response) => {
                setResponseStatus(e);
                setBlankPortal(true);
                setUiState({
                    isLoading: false,
                    isError: true,
                    statusCode: e.status,
                    message: e.statusText,
                });
            });
    };

    const navigate = useNavigate();

    const close = () => {
        navigate(
            storeState.previousView
                ? storeState.previousView.split('#')[1]
                : './../../portal-settings/home-page'
        );
    };

    const backToProjectFooter = () => (
        <div className="flex justify-end pt-3 mt-5 widget-footer">
            <Button text="Back to Project" fill="transparent" onClick={close} />
        </div>
    );

    const renderEmbedSnippetView = () => (
        <>
            <div className="p-8 border border-solid rounded-6 border-goose-600 mb-4">
                <div className="flex flex-col widget-body">
                    <div className="px-4">
                        <div className="flex items-center mb-4 font-medium text-green text-22">
                            <CheckIconFilled fill="#28c397" className="mr-2"></CheckIconFilled>
                            <div>Success</div>
                        </div>
                        <div className="mb-5 text-14 text-charcoal-800">
                            You will shortly receive a notification in your email inbox. Copy this
                            script to embed into your existing portal. The script only needs to be
                            embedded once, as it will always fetch the latest published artefacts
                            like docs and SDKs. For any branding changes, you need to embed the
                            script again
                        </div>
                    </div>
                    <div className="relative">
                        <div className="w-full overflow-auto code-block-wrapper rounded-6 bg-snow-600 text-charcoal-800">
                            <code className="block w-full px-4 leading-relaxed code-block text-13 text-charcoal-800">
                                <pre>{snippetState}</pre>
                            </code>
                            <div className="absolute bottom-0 left-0 mb-8 ml-5">
                                {snippetState && (
                                    <Tooltip message="Copied!" event="click">
                                        <Button
                                            className="drop-shadow"
                                            text="Copy Snippet"
                                            fill="default"
                                            icon={<CopyIcon />}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    (snippetState as unknown) as string
                                                );
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {backToProjectFooter()}
            </div>
            <PublishSDKCard />
        </>
    );

    const renderVersionSelector = () => (
        <>
            <p className="mb-6 text-goose-700 text-16">
                Select the API version that you want to generate a embeddable script for
            </p>
            <div className="p-8 border border-solid rounded-6 border-goose-600">
                <CallOut type="info">
                    <div className="flex">
                        <InfoIcon
                            fill="#8E561D"
                            width="26px"
                            height="16px"
                            className="mr-2"
                        ></InfoIcon>
                        <div>
                            The script only needs to be embedded once, as it will always fetch the
                            latest published artefacts like docs and SDKs. For any branding changes,
                            you need to embed the script again
                        </div>
                    </div>
                </CallOut>
                <table className="w-full mt-8 text-left text-14 version-selector">
                    <thead className="border-b border-solid text-goose-700 border-goose-600">
                        <tr>
                            <th className="pb-2 font-medium" style={{ width: '22%' }}>
                                Version
                            </th>
                            <th className="w-full pb-2 font-medium">Status</th>
                            <th className="pb-2"></th>
                        </tr>
                    </thead>
                    <tbody className="text-charcoal-800">
                        {storeState.settings?.apiEntities.map((apiEntity) => {
                            const isPublished = publishedPortalState.status[apiEntity.id];
                            const pendingChanges =
                                publishedPortalState.pendingUpdates[apiEntity.id];

                            return (
                                <tr key={apiEntity.version}>
                                    <td>
                                        <div className="py-2">v {apiEntity.version}</div>
                                    </td>
                                    <td>
                                        <div className="flex items-center py-2 ">
                                            {isPublished && (
                                                <>
                                                    <span className="pr-5 text-green">
                                                        Published
                                                    </span>
                                                    {pendingChanges && (
                                                        <span className="flex items-center pr-1 text-orange text-10">
                                                            <InfoIcon
                                                                fill="#FF9E3D"
                                                                className="pr-2"
                                                                width="20px"
                                                            ></InfoIcon>
                                                            Pending Changes
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                            {!isPublished && 'Draft'}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="py-2">
                                            <RadioButton
                                                value={apiEntity.id}
                                                name="publishingWizardVersionSelector"
                                                onChange={(e) => {
                                                    setSelectedVersions(e.target.value);
                                                }}
                                            ></RadioButton>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <PublishWizardFooter>
                    <div className="flex">
                        <Button
                            className="mr-2 "
                            text="Back"
                            fill="default"
                            onClick={() => updateActiveScreen('screen_1')}
                        />

                        <Button
                            disabled={uiState.isLoading || !selectedVersions}
                            icon={uiState.isLoading && <Spinner size="s" />}
                            text="Generate Script"
                            fill="primary"
                            onClick={() => {
                                generateScript();
                                setActiveScreen('screen_2');
                            }}
                        />
                    </div>
                </PublishWizardFooter>
            </div>
        </>
    );

    const renderView = () => (
        <>
            {activeScreen === 'screen_1' && renderVersionSelector()}
            {activeScreen === 'screen_2' && renderEmbedSnippetView()}
        </>
    );

    const renderLoadingView = () => (
        <div className="flex items-center justify-center w-full h-full">
            <div className="text-base font-bold">
                <Spinner size="xl" />
            </div>
        </div>
    );

    const renderErrorView = () => (
        <div className="p-8 border border-solid rounded-6 border-goose-600">
            <div className="flex flex-col widget-body">
                <PublishError></PublishError>
                {backToProjectFooter()}
            </div>
        </div>
    );

    return (
        <div className="widget-content">
            {uiState.isLoading
                ? renderLoadingView()
                : uiState.isError
                ? renderErrorView()
                : renderView()}
        </div>
    );
};

export default EmbedSnippet;
