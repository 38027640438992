import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const CheckIconFilled = (props: SVGIconProps) => (
    <SVGIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
        <g transform="translate(-723 -252)">
            <rect
                fill={props.fill || DEFAULT_SVG_COLOR}
                width="22"
                height="22"
                rx="11"
                transform="translate(723 252)"
            />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M15.237,5.262a.893.893,0,0,1,0,1.262L8.095,13.666a.893.893,0,0,1-1.262,0L3.262,10.095A.893.893,0,1,1,4.524,8.832l2.94,2.94,6.511-6.511A.893.893,0,0,1,15.237,5.262Z"
                transform="translate(724.75 253.536)"
            />
        </g>
        {/* <path
            d="M0,5.871l.7-.718L2.45,6.947,6.3,3l.7.718L2.45,8.383Z"
            transform="translate(0.489 -2.499)"
            fill={props.fill || DEFAULT_SVG_COLOR}
            stroke={props.fill || DEFAULT_SVG_COLOR}
            strokeWidth="0.7px"
        /> */}
    </SVGIcon>
);
