import React, { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { FieldGroupCard, FieldBox, TextField, Button } from '../../../design-system';
import { PackageDeploymentDef } from '../Platforms/PlatformConstants';
import {
    PackageRepositoriesSettingsDef,
    PublishedPackageDef,
} from '../Platforms/PlatformInitialState';
import { FIELD_VALIDATOR_FORM_TYPE, FormValidatorDef } from '../../../types/form-validator';
import { utilityFunctions } from '../../../utilities/functions';

interface PackageProps {
    publishedPackageState: PublishedPackageDef;
    packageRepositoriesSettings: PackageRepositoriesSettingsDef;
    packageDeployment: PackageDeploymentDef;
    form: FIELD_VALIDATOR_FORM_TYPE;
    isPackagePublished: boolean;
    onCancel: () => void;
    onSave: (packageInfo: PublishedPackageDef) => void;
}

const Package: FunctionComponent<PackageProps> = (props) => {
    const {
        publishedPackageState,
        packageRepositoriesSettings,
        isPackagePublished,
        onSave,
        onCancel,
    } = props;
    const [packageState, setPackageState] = useState(publishedPackageState);

    const [formValidator, setFormValidator] = useState<FormValidatorDef>({
        isSubmitted: false,
        errors: {},
    });

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPackageState({
            ...packageState,
            packageSettings: {
                ...packageState.packageSettings,
                [name]: value,
            },
        });
    };
    useEffect(() => {
        setPackageState(publishedPackageState);
    }, [publishedPackageState]);

    useEffect(() => {
        if (isPackagePublished) {
            onSave(packageState);
        }
    }, [packageState]);

    const _handleSave = () => {
        if (
            utilityFunctions.formIsValid({
                state: formValidator,
                setState: setFormValidator,
            })
        ) {
            onSave(packageState);
        }
    };

    return (
        <FieldGroupCard className="flex flex-col p-6">
            <div className="flex flex-wrap justify-between mb-4 space-y-4">
                <div className="mt-4">
                    <FieldBox label="Repository">
                        <TextField
                            readOnly={true}
                            placeholder="Package Repository"
                            value={packageState.packageRepository}
                            name="packageRepository"
                            onChange={(e) => onInputChange(e)}
                            aria-label=""
                        />
                    </FieldBox>
                </div>

                {packageRepositoriesSettings?.repositoryFields?.map((e) => (
                    <div className="mt-4" key={publishedPackageState.packageRepository + e.Field}>
                        <FieldBox label={e.FieldLabel}>
                            <TextField
                                placeholder={e.FieldLabel}
                                value={packageState.packageSettings[e.Field]}
                                name={e.Field}
                                onChange={(e) => onInputChange(e)}
                                aria-label=""
                                validation={
                                    e.ValidationRules
                                        ? {
                                              ...e.ValidationRules,
                                              key:
                                                  publishedPackageState.packageRepository + e.Field,
                                          }
                                        : undefined
                                }
                                form={{ state: formValidator, setState: setFormValidator }}
                            />
                        </FieldBox>
                    </div>
                ))}
            </div>
            <div className="flex justify-end">
                <Button
                    className="mr-3"
                    text="Remove"
                    fill="transparent"
                    onClick={() => onCancel()}
                />

                {!isPackagePublished && (
                    <Button
                        className="mr-3"
                        type="button"
                        text="Add"
                        fill="primary"
                        onClick={() => _handleSave()}
                    />
                )}
            </div>
        </FieldGroupCard>
    );
};

export default Package;
