import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import './notification-bar.scss';
import { useNotification, NotificationProviderValue } from './NotificationBarContext';

export const NotificationBarPortal: FunctionComponent<any> = (props) => {
    // grab the new element you declared above
    const domElement = document.getElementById('notification-portal') as HTMLElement;

    return domElement && ReactDOM.createPortal(props.children, domElement);
};

const NotificationContent: FunctionComponent<any> = (props) => {
    const { notificationState } = useNotification() as NotificationProviderValue;

    return (
        <Fragment>
            {notificationState.show && (
                <div
                    className={`notification animate-open ${notificationState.type} ${notificationState.className}`}
                >
                    {notificationState.message}
                </div>
            )}
        </Fragment>
    );
};

const NotificationBar: FunctionComponent<any> = (props) => {
    return (
        <NotificationBarPortal>
            <NotificationContent showNotification={props.showNotification} />
        </NotificationBarPortal>
    );
};

export default NotificationBar;
