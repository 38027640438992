import React, { FunctionComponent, useContext, useState } from 'react';
import { Button, ToggleSwitch } from '../../design-system';
import {
    CustomContentContextValue,
    GeneratedContentType,
    ToCItemError,
} from './CustomContentTypes';
import { CustomContentContext } from './CustomContentContext';
import { Link } from 'react-router-dom';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { EndPointIcon } from '../../icons/Ui/EndPointIcon';
import { RocketIcon } from '../../icons/Ui/RocketIcon';
import { ModelsIcon } from '../../icons/Ui/ModelsIcon';
import cx from 'classnames';
import { EditIcon } from '../../icons/Ui/EditIcon';
import CustomSectionInput from './DropdownOptions/CustomSectionInput';
import { getDefaultErrorMessage } from './CustomContentConstant';

const ToCGeneratedContent: FunctionComponent<{
    path: string[];
    content: GeneratedContentType;
    disabled?: boolean;
    className?: string;
}> = ({ path, content, disabled, className }) => {
    const { storeState } = useStore() as StoreProviderValue;

    const [editing, setEditing] = useState(false);
    const [errorMsg, setErrorMsg] = useState<ToCItemError>(getDefaultErrorMessage());

    const { disableGeneratedContent, renameGeneratedContent } = useContext(
        CustomContentContext
    ) as CustomContentContextValue;

    const getPath = () => {
        return [...path];
    };

    const getLink = () => {
        return `generated-content/${content.from.toLowerCase().replace(' ', '-')}`;
    };

    const isActive = () => {
        return storeState?.activeView === 'generated-content-' + content.from;
    };

    // rename generated content
    const _handleRenameGeneratedContent = (data?: { name: string; slug: string }) => {
        if (data) {
            renameGeneratedContent(getPath(), content.from, data.name)
                .then(() => {
                    setErrorMsg(getDefaultErrorMessage());
                    setEditing(false);
                })
                .catch((e) => {
                    setErrorMsg(e);
                });
        } else {
            setErrorMsg(getDefaultErrorMessage());
            setEditing(false);
        }
    };

    const ICON =
        content.from === 'endpoints'
            ? EndPointIcon
            : content.from === 'getting-started'
            ? RocketIcon
            : content.from === 'models'
            ? ModelsIcon
            : null;

    return (
        <div className={cx('flex w-full', className)}>
            {!editing && (
                <div className="flex items-center justify-between w-full nav-item-wrapper">
                    <div
                        className={
                            'w-11/12 text-14 text-gray-800 nav-item nav-item-title' +
                            (disabled ? ' disabled' : '') 
                            // + (path.length === 0 ? ' font-medium' : '')
                        }
                    >
                        <Link
                            className={
                                'flex w-full h-6 items-center' +
                                (content.disabled ? ' text-goose-700' : isActive() ? ' active' : '')
                            }
                            key={getLink()}
                            to={getLink()}
                        >
                            {ICON && (
                                <ICON
                                    fill={
                                        content.disabled ? '#9CAAB8' : isActive() ? '#0c7ff2' : ''
                                    }
                                    width="12"
                                ></ICON>
                            )}
                            <span className="pl-2 blur-overflow lh-21">{content.generate}</span>
                        </Link>
                    </div>

                    {content.from === 'getting-started' && (
                        <>
                            {!content.disabled && (
                                <Button
                                    className="generated-content-edit-button"
                                    icon={<EditIcon width="12" height="12"></EditIcon>}
                                    text=""
                                    size="xs"
                                    fill="transparent"
                                    onClick={() => setEditing(true)}
                                ></Button>
                            )}
                            <ToggleSwitch
                                className="toggle-switch-getting-started"
                                size="sm"
                                checked={content.disabled ? false : true}
                                onChange={(e) => {
                                    disableGeneratedContent([...getPath()], {
                                        ...content,
                                        disabled: !e.target.checked,
                                    });
                                }}
                            />
                        </>
                    )}
                </div>
            )}
            {editing && (
                <CustomSectionInput
                    title="Rename Getting Started"
                    oldName={content.generate}
                    hideSlug={true}
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    close={_handleRenameGeneratedContent}
                ></CustomSectionInput>
            )}
        </div>
    );
};

export default ToCGeneratedContent;
