import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const EllipsisIcon = (props: SVGIconProps) => (
    <SVGIcon width="18" height="4" viewBox="0 0 18 4" {...props} fill="none">
        <g transform="translate(-3 -10)">
            <circle
                fill={props.fill || DEFAULT_SVG_COLOR}
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2px"
                cx="1"
                cy="1"
                r="1"
                transform="translate(11 11)"
            />
            <circle
                fill={props.fill || DEFAULT_SVG_COLOR}
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2px"
                cx="1"
                cy="1"
                r="1"
                transform="translate(18 11)"
            />
            <circle
                fill={props.fill || DEFAULT_SVG_COLOR}
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2px"
                cx="1"
                cy="1"
                r="1"
                transform="translate(4 11)"
            />
        </g>
    </SVGIcon>
);
