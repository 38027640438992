import React, { ReactChild } from 'react';
import { GlobeIcon } from '../../icons/Ui/GlobeIcon';
import { FilmIcon } from '../../icons/Ui/FilmIcon';
import { BrowserWindowIcon } from '../../icons/Ui/BrowserWindowIcon';
import { ShareIcon } from '../../icons/Ui/ShareIcon';
import { StationIcon } from '../../icons/Ui/StationIcon';
import { CodeIcon } from '../../icons/Ui/CodeIcon';
import { RobotIcon } from '../../icons/Ui/RobotIcon';
import { aiChatbotTitle } from '../api-docs/AIChatbot/AIChatbot';

export type SideNavItemsConstantType = {
    title: string;
    value: string;
    items: ReadonlyArray<SideNavItemsConstantType>;
    icon?: ReactChild;
    customItem?: 'custom-content' | 'version-settings' | 'homepage';
    itemClass?: string;
    group?: true;
};

export const sideNavItemsConstant: ReadonlyArray<SideNavItemsConstantType> = [
    {
        title: 'PORTAL SETTINGS',
        value: '',
        group: true,
        items: [
            {
                title: 'Home Page',
                value: 'portal-settings/home-page',
                icon: <BrowserWindowIcon width="15" height="12.5" fill="#455666" />,
                items: [],
                itemClass: '',
                customItem: 'homepage',
            },
            {
                title: 'Branding',
                value: 'portal-settings/branding',
                icon: <FilmIcon width="15" height="12" fill="#455666" />,
                items: [],
                itemClass: '',
            },
            {
                title: 'Publishing',
                value: 'portal-settings/published-portals',
                icon: <GlobeIcon width="16" height="16" fill="#455666" />,
                itemClass: '',
                items: [],
            },
            {
                title: aiChatbotTitle,
                value: 'portal-settings/ai-chatbot',
                icon: <RobotIcon width="15" height="15" fill="#455666" />,
                items: [],
                itemClass: '',
            },
        ],
    },

    {
        title: 'DOCUMENTATION SETTINGS',
        value: '',
        group: true,
        items: [
            {
                title: 'Version Settings',
                value: '',
                customItem: 'version-settings',
                items: [],
            },
            {
                title: 'Available Languages',
                value: 'documentation-settings/platforms',
                icon: <CodeIcon width="15" height="8.242" fill="#455666" />,
                items: [],
                itemClass: '',
            },
            {
                title: 'API Explorer',
                value: 'documentation-settings/console-calls',
                icon: <StationIcon width="15" height="15.002" fill="#455666" />,
                items: [],
                itemClass: '',
            },
            {
                title: 'API Spec Export',
                value: 'documentation-settings/api-spec-export',
                icon: <ShareIcon width="15" height="15" fill="#455666" />,
                items: [],
                itemClass: '',
            },

            {
                title: 'Documentation',
                value: 'custom-pages',
                customItem: 'custom-content',
                items: [],
                itemClass: '',
            },
        ],
    },
];
