import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const HostedIcon = (props: SVGIconProps) => (
    <SVGIcon width="28" height="21" viewBox="0 0 28 21" {...props} fill="none">
        <g transform="translate(-286 -486)">
            <g transform="translate(82 -232)">
                <g
                    fill="none"
                    stroke={props.fill || DEFAULT_SVG_COLOR}
                    strokeWidth="2px"
                    transform="translate(204 718)"
                >
                    <rect stroke="none" width="28" height="21" rx="3" />
                    <rect fill="none" x="1" y="1" width="26" height="19" rx="2" />
                </g>
                <line
                    fill="none"
                    stroke={props.fill || DEFAULT_SVG_COLOR}
                    strokeWidth="2px"
                    x2="25"
                    transform="translate(205.5 722.5)"
                />
                <line
                    fill="none"
                    stroke={props.fill || DEFAULT_SVG_COLOR}
                    strokeWidth="2px"
                    y2="17"
                    transform="translate(210.5 721.5)"
                />
                <line
                    fill="none"
                    stroke={props.fill || DEFAULT_SVG_COLOR}
                    strokeWidth="2px"
                    y2="17"
                    transform="translate(221.5 721.5)"
                />
            </g>
        </g>
    </SVGIcon>
);
