import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const SidebarIllustration1 = (props: SVGIconProps) => (
    <SVGIcon width="81" height="96.07" viewBox="0 0 81 96.07" {...props}>
        <g id="Group_25" data-name="Group 25" transform="translate(-310 -290.01)">
            <rect
                id="Rounded_Rectangle_16_copy_11"
                data-name="Rounded Rectangle 16 copy 11"
                width="81"
                height="17.04"
                rx="3"
                transform="translate(310 290.01)"
                fill="#d9dee5"
            />
            <rect
                id="Rounded_Rectangle_16_copy_12"
                data-name="Rounded Rectangle 16 copy 12"
                width="81"
                height="17.04"
                rx="3"
                transform="translate(310 316.02)"
                fill="#d9dee5"
            />
            <rect
                id="Rounded_Rectangle_16_copy_13"
                data-name="Rounded Rectangle 16 copy 13"
                width="81"
                height="17.04"
                rx="3"
                transform="translate(310 343.03)"
                fill="#d9dee5"
            />
            <rect
                id="Rounded_Rectangle_16_copy_14"
                data-name="Rounded Rectangle 16 copy 14"
                width="81"
                height="17.04"
                rx="3"
                transform="translate(310 369.04)"
                fill="#d9dee5"
            />
        </g>
    </SVGIcon>
);
