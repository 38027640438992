import React, { FunctionComponent } from 'react';
import BrandingView from '../../views/BrandingView/BrandingView';
import { Routes, Route, Navigate } from 'react-router-dom';
import ApiSpecExportView from '../../views/ApiSpecExportView/ApiSpecExportView';
import ConsoleCallsView from '../../views/ConsoleCallsView/ConsoleCallsView';
import NewPageView from '../../views/CustomPagesView/NewPageView';
import HomePageView from '../../views/HomePageView/HomePageView';
import PlatformsView from '../../views/PlatformsView/PlatformsView';
import GeneratedContentView from '../../views/GeneratedContentView/GeneratedContentView';
import CustomContentView from '../../views/CustomContentView/CustomContentView';
import HostingView from '../../views/HostingView/HostingView';
import PublishWizard from '../PublishWizard/PublishWizard';
import { PublishedPortalProvider } from '../api-docs/PublishedPortal/PublishedPortalContext';
import { PublishWizardProvider } from '../api-docs/PublishedPortal/PublishWizardContext';
import { NotificationProvider } from '../Notification/NotificationBarContext';
import AIChatbotView from '../../views/AIChatbotView/AIChatbotView';

const Main: FunctionComponent = () => {
    return (
        <main
            className="relative w-full h-full overflow-auto bg-snow-500 main-wrapper custom-scroll md:scroll"
            style={{ zIndex: 1 }}
        >
            <Routes>
                <Route path="/portal-settings">
                    <Route path="/home-page">
                        <HomePageView />
                    </Route>
                    <Route path="/branding">
                        <BrandingView />
                    </Route>
                    <Route path="/published-portals">
                        <HostingView />
                    </Route>
                    <Route path="/ai-chatbot">
                        <AIChatbotView />
                    </Route>
                    <Route path="/publishing-wizard">
                        <NotificationProvider>
                            <PublishedPortalProvider>
                                <PublishWizardProvider>
                                    <PublishWizard />
                                </PublishWizardProvider>
                            </PublishedPortalProvider>
                        </NotificationProvider>
                    </Route>
                </Route>
                <Route path="/documentation-settings">
                    <Route path="/api-spec-export">
                        <ApiSpecExportView />
                    </Route>
                    <Route path="/console-calls">
                        <ConsoleCallsView />
                    </Route>
                    <Route path="/platforms">
                        <PlatformsView />
                    </Route>
                </Route>
                <Route path="/custom-pages">
                    <Route path=":pathKey">
                        <NewPageView />
                    </Route>
                </Route>
                <Route path="/custom-content">
                    <CustomContentView />
                </Route>
                <Route path="/generated-content">
                    <Route path=":key">
                        <GeneratedContentView />
                    </Route>
                </Route>
                <Route path="/">
                    <Navigate to="/portal-settings/home-page" replace />
                </Route>
            </Routes>
        </main>
    );
};

export default Main;
