import { PlatformTemplateType, PlatformType } from '../Platforms/PlatformConstants';

type TemplatePlatformMapDef = {
    [template in PlatformTemplateType]: PlatformType;
};

export interface SubscriptionDef {
    readonly allowedPlatforms: PlatformType[];
    readonly templatePlatformMap: TemplatePlatformMapDef;
}

export const subscriptionInitialState: SubscriptionDef = {
    allowedPlatforms: [],
    templatePlatformMap: {
        objc_cocoa_touch_ios_lib: 'IOS',
        angular_javascript_lib: 'Angular',
        node_javascript_lib: 'Node',
        cs_net_standard_lib: 'DotNet',
        cs_portable_net_lib: 'DotNet',
        cs_universal_windows_platform_lib: 'DotNet',
        ruby_generic_lib: 'Ruby',
        python_generic_lib: 'Python',
        go_generic_lib: 'Go',
        java_gradle_android_lib: 'Android',
        php_generic_lib: 'PHP',
        php_generic_lib_v2: 'PHP',
        ts_generic_lib: 'TypeScript',
        java_eclipse_jre_lib: 'Java',
        java_eclipse_jax_rs: 'Java',
    },
};
