import React, { ReactElement } from 'react';
import { GettingStartedIcon } from '../../../../icons/Ui/GettingStartedIcon';
import { AuthenticationIcon } from '../../../../icons/Ui/AuthenticationIcon';
import { ApiConsoleIcon } from '../../../../icons/Ui/ApiConsoleIcon';
import { HttpReferenceIcon } from '../../../../icons/Ui/HttpReferenceIcon';
import { ApiEntitiesIcon } from '../../../../icons/Ui/ApiEntitiesIcon';
import { SdksAndCodeSamplesIcon } from '../../../../icons/Ui/SdksAndCodeSamplesIcon';
import { GuidesIcon } from '../../../../icons/Ui/GuidesIcon';
import { TransformerIcon } from '../../../../icons/Ui/TransformerIcon';

export interface BadgeDef {
    icon: ReactElement;
    value: string;
    title: string;
    description: string;
}

export const BadgeConstants: BadgeDef[] = [
    {
        icon: <GettingStartedIcon />,
        title: 'Getting Started',
        value: 'GettingStarted',
        description:
            'A step-by-step guide for getting started, that will take you through installing the SDK and making your first API call.',
    },
    {
        icon: <AuthenticationIcon />,
        title: 'Authentication',
        value: 'Authentication',
        description: 'Easily authenticate the client for making calls to the server.',
    },
    {
        icon: <ApiConsoleIcon />,
        title: 'API Console',
        value: 'ApiConsole',
        description:
            'Start making live API calls with your input without writing a single line of code.',
    },
    {
        icon: <HttpReferenceIcon />,
        title: 'HTTP Reference',
        value: 'Comprehensive HTTP documentation with sample request and responses.',
        description: '',
    },
    {
        icon: <ApiEntitiesIcon />,
        title: 'ApiEntities',
        value: 'ApiEntities',
        description: 'A complete reference of entities in the API.',
    },
    {
        icon: <GuidesIcon />,
        title: 'Guides',
        value: 'Guides',
        description: '',
    },
    {
        icon: <TransformerIcon />,
        title: 'API Transformation',
        value: 'ApiTransformation',
        description:
            'Transform your API description in a format of your choice. Benefit from a wide range of tools available associated with any format, not just one.',
    },
    {
        icon: <SdksAndCodeSamplesIcon />,
        title: 'SDK & Code Samples',
        value: 'SdksAndCodeSamples',
        description:
            'Code Samples are the quickest path to the first hello world, while SDKs lift the heavy burden of communication with an API.',
    },
];
