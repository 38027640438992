import React, { FunctionComponent, useRef, useState } from 'react';
import { FieldBox, TextField, Button } from '../../../../../design-system';
import useOutsideClick from '../../../../../hooks/useOutsideClick';
import { BrowserWindowIcon } from '../../../../../icons/Ui/BrowserWindowIcon';
import { LinkIcon } from '../../../../../icons/Ui/LinkIcon';
import { FormValidatorDef } from '../../../../../types/form-validator';
import { utilityFunctions } from '../../../../../utilities/functions';
import { NavLinkItem } from '../../../ApiDocsHeader/ApiDocsHeaderInitialState';

interface LinkEditorCardProps {
    className?: string;
    state?: NavLinkItem;
    onSave: (state: NavLinkItem) => void;
    onCancel: () => void;
    onDelete: () => void;
}

const LinkEditorCard: FunctionComponent<LinkEditorCardProps> = (props) => {
    const { onCancel, onDelete, onSave, className, state } = props;

    const [editorState, setEditorState] = useState<NavLinkItem>(
        state ? state : { name: '', url: '' }
    );
    const [formValidator, setFormValidator] = useState<FormValidatorDef>({
        isSubmitted: false,
        errors: {},
    });

    const _handleSave = () => {
        const form = { state: formValidator, setState: setFormValidator };
        if (utilityFunctions.formIsValid(form)) {
            onSave(editorState);
        }
    };

    const ref = useRef(null);
    useOutsideClick(ref, () => {
        onCancel();
    });

    return (
        <div
            className={`absolute p-6 mx-5 my-6 bg-white border border-solid theme-editor-card-wrapper rounded-12 border-goose-600 ${className}`}
            style={{ zIndex: 20, top: '20px', right: '0px', width: '80vw', maxWidth: '400px' }}
            ref={ref}
        >
            <div className="flex flex-col w-full body">
                <div className="flex w-full">
                    <FieldBox label="" className="mb-6">
                        <TextField
                            placeholder="text"
                            name="name"
                            value={editorState.name}
                            onChange={(e) =>
                                setEditorState({
                                    name: e.target.value,
                                    url: editorState.url,
                                })
                            }
                            validation={{
                                key: '_title',
                                required: true,
                            }}
                            form={{ state: formValidator, setState: setFormValidator }}
                        />
                    </FieldBox>
                </div>

                <div className="flex items-center w-full mb-6">
                    <FieldBox label="">
                        <TextField
                            placeholder="Link"
                            name="url"
                            value={editorState.url}
                            onChange={(e) =>
                                setEditorState({
                                    name: editorState.name,
                                    url: e.target.value,
                                })
                            }
                            validation={{
                                key: '_title',
                                url: true,
                            }}
                            form={{ state: formValidator, setState: setFormValidator }}
                        />
                    </FieldBox>

                    {editorState && editorState.url && (
                        <a href={editorState?.url} target="_blank" rel="noreferrer">
                            <Button
                                className="ml-2"
                                fill="default"
                                text=""
                                icon={<LinkIcon />}
                                onClick={() => {}}
                            />
                        </a>
                    )}
                </div>
            </div>

            <div className="flex justify-end footer">
                {state && <Button fill="danger" text="Delete" onClick={onDelete} />}
                <Button fill="danger" text="Cancel" onClick={onCancel} />
                <Button className="ml-2" fill="default" text="Done" onClick={_handleSave} />
            </div>
        </div>
    );
};

export default LinkEditorCard;
