export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_TOGGLE = 'UPDATE_TOGGLE';
export const UPDATE_WELCOME_MESSAGE = 'UPDATE_WELCOME_MESSAGE';

export type UPDATE_STATE_TYPE = {
    type: 'UPDATE_STATE';
    payload: AIChatbotStateDef;
};

export type UPDATE_TOGGLE_TYPE = {
    type: 'UPDATE_TOGGLE';
    payload: boolean;
};

export type UPDATE_WELCOME_MESSAGE_TYPE = {
    type: 'UPDATE_WELCOME_MESSAGE';
    payload: string;
};

export interface AIChatbotStateDef {
    isAllowedInSubscription: boolean;
    isAiEnabled: boolean;
    welcomeMessage: string;
}
