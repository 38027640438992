import React, {
    FunctionComponent,
    useReducer,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
    useCallback,
} from 'react';
import {
    ADD_NAV_LINK,
    CHANGE_LOGO,
    CLEAR_LOGO_FAVICON_FILES,
    homePageReducer,
    ON_NAV_LINK_DRAG_END,
    REMOVE_LOGO,
    REMOVE_NAV_LINK,
    TOGGLE_HOMEPAGE,
    UPDATE_LOGO_LINK,
    UPDATE_LOGO_URI,
    UPDATE_STATE,
} from './HomePageReducer';
import { HomePageStateDef, homePageInitialState } from './HomePageIntialState';
import { getHomePage } from '../../../api-client/HomePageService';
import { UiStateDef } from '../../../types/ui-state';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { HomePageStoreKey } from './HomePageConstant';
import { FileType } from '../Branding/Logo/LogoConstant';
import { NavLinkItem } from '../ApiDocsHeader/ApiDocsHeaderInitialState';
import { useLocation } from 'react-router';
import useUpdateEffect from '../../../hooks/UseUpdateEffect';

export interface HomePageProviderProps {
    children: ReactNode;
}

interface CoverImageStateDef {
    fileUri: string | null;
    file: File | null | undefined;
}

export interface HomePageProviderValue {
    homePageState: HomePageStateDef;
    uiState: UiStateDef;
    updateUiState: (newState: UiStateDef) => void;
    coverImageState: CoverImageStateDef;
    updateCoverImage: (newCoverImageState: CoverImageStateDef) => void;
    onCoverImageReset: () => void;
    updateLogoLink: (newLink: string) => void;
    changeLogo: (file: FileType) => void;
    updateLogoUri: (uri: string) => void;
    removeLogo: () => void;
    addNavLink: (item: NavLinkItem, index?: number) => void;
    removeNavLink: (index: number) => void;
    onNavLinkDragEnd: (newNavLinks: NavLinkItem[]) => void;
    toggleHomepage: (state: boolean) => void;
    clearLogoFile: () => void;
    homePageDispatch: any;
}

export type HomePageContextValue = undefined | HomePageProviderValue;

export const HomePageContext = createContext<HomePageContextValue>(undefined);

const HomePageProvider: FunctionComponent<HomePageProviderProps> = ({ children }) => {
    const [homePageState, homePageDispatch] = useReducer(homePageReducer, homePageInitialState);
    const [uiState, setUiState] = useState<UiStateDef>({
        isLoading: true,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    });
    const coverImageStateInitialState: CoverImageStateDef = {
        fileUri: null,
        file: null,
    };
    const [coverImageState, setCoverImageState] = useState<CoverImageStateDef>({
        ...coverImageStateInitialState,
    });
    const { updateStoreApiGroup, storeState } = useStore() as StoreProviderValue;
    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        // discard selected file
        updateCoverImage({ fileUri: null, file: undefined });
        const cacheData = storeState.apiGroup.homePage;
        if (cacheData) {
            setUiState({
                ...uiState,
                isLoading: false,
            });
            return updateState(cacheData);
        }

        const promise = getHomePage();
        promise
            .then((resp: HomePageStateDef) => {
                setUiState({
                    ...uiState,
                    isLoading: false,
                });
                updateState(resp);
                updateStoreApiGroup(resp, HomePageStoreKey);
            })
            .catch((e: Response) => {
                setUiState({
                    isLoading: false,
                    isError: true,
                    statusCode: e.status,
                    message: e.statusText,
                });
            });
    };

    const location = useLocation();

    useUpdateEffect(() => {
        loadData();
    }, [location]);

    const updateUiState = (newState: UiStateDef): void => {
        setUiState(newState);
    };

    const updateState = (updatedState: HomePageStateDef): void => {
        homePageDispatch({
            type: UPDATE_STATE,
            payload: updatedState,
        });
    };

    const updateCoverImage = (newCoverImageState: CoverImageStateDef) => {
        setCoverImageState(newCoverImageState);
    };

    const updateLogoLink = (newLink: string) => {
        homePageDispatch({
            type: UPDATE_LOGO_LINK,
            payload: newLink,
        });
    };

    const onCoverImageReset = () => {
        setCoverImageState((prevState) => ({
            fileUri: null,
            file: null,
        }));
    };

    const changeLogo = useCallback(
        (file: FileType) => {
            homePageDispatch({
                type: CHANGE_LOGO,
                payload: {
                    file,
                },
            });
        },
        [homePageDispatch]
    );
    const updateLogoUri = useCallback(
        (uri: string) => {
            homePageDispatch({
                type: UPDATE_LOGO_URI,
                payload: {
                    uri,
                },
            });
        },
        [homePageDispatch]
    );
    const removeLogo = useCallback(() => {
        homePageDispatch({
            type: REMOVE_LOGO,
        });
    }, [homePageDispatch]);

    const removeNavLink = (index: number) => {
        homePageDispatch({
            type: REMOVE_NAV_LINK,
            payload: {
                index: index,
            },
        });
    };

    const addNavLink = (item: NavLinkItem, index?: number): void => {
        homePageDispatch({
            type: ADD_NAV_LINK,
            payload: { item, index },
        });
    };

    const onNavLinkDragEnd = (newNavLinks: NavLinkItem[]): void => {
        homePageDispatch({
            type: ON_NAV_LINK_DRAG_END,
            payload: newNavLinks,
        });
    };

    const toggleHomepage = (state: boolean): void => {
        homePageDispatch({
            type: TOGGLE_HOMEPAGE,
            payload: state,
        });
    };

    const clearLogoFile = useCallback(() => {
        homePageDispatch({
            type: CLEAR_LOGO_FAVICON_FILES,
        });
    }, [homePageDispatch]);

    const value = {
        homePageState,
        uiState,
        coverImageState,
        updateUiState,
        updateCoverImage,
        onCoverImageReset,
        updateLogoLink,
        changeLogo,
        updateLogoUri,
        removeLogo,
        removeNavLink,
        addNavLink,
        onNavLinkDragEnd,
        toggleHomepage,
        homePageDispatch,
        clearLogoFile,
    };

    return <HomePageContext.Provider value={value}>{children}</HomePageContext.Provider>;
};

const useHomePage = () => useContext(HomePageContext);

export { HomePageProvider, useHomePage };
