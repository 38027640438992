import React from 'react';
import {
    AndroidIcon,
    AngularIcon,
    GoIcon,
    JavaIcon,
    NodeIcon,
    ObjectiveCIcon,
    PhpIcon,
    PythonIcon,
    RubyIcon,
    TypeScriptIcon,
    WindowsIcon,
} from '../../../icons/Ui/PlatformIcons';
import { PlatformType, TemplateType } from '../Platforms/PlatformConstants';

export const HomePageStoreKey = 'homePage';

interface PlatformIconDef {
    name: PlatformType;
    value: TemplateType;
    icon: React.ReactNode;
}

export const PlatformIconConstant: Array<PlatformIconDef> = [
    {
        name: 'IOS',
        value: 'objc_cocoa_touch_ios_lib',
        icon: <ObjectiveCIcon />,
    },
    {
        name: 'Angular',
        value: 'angular_javascript_lib',
        icon: <AngularIcon />,
    },
    {
        name: 'Node',
        value: 'node_javascript_lib',
        icon: <NodeIcon />,
    },
    {
        name: 'DotNet',
        value: 'cs_net_standard_lib',
        icon: <WindowsIcon />,
    },
    {
        name: 'Ruby',
        value: 'ruby_generic_lib',
        icon: <RubyIcon />,
    },
    {
        name: 'Python',
        value: 'python_generic_lib',
        icon: <PythonIcon />,
    },
    {
        name: 'Go',
        value: 'go_generic_lib',
        icon: <GoIcon />,
    },
    {
        name: 'Android',
        value: 'java_gradle_android_lib',
        icon: <AndroidIcon />,
    },
    {
        name: 'PHP',
        value: 'php_generic_lib_v2',
        icon: <PhpIcon />,
    },
    {
        name: 'TypeScript',
        value: 'ts_generic_lib',
        icon: <TypeScriptIcon />,
    },
    {
        name: 'Java',
        value: 'java_eclipse_jre_lib',
        icon: <JavaIcon />,
    },
];
