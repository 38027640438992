import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export let StationIcon = (props: SVGIconProps) => (
    <SVGIcon viewBox="0 0 15 15.002" width="15" height="15.002" {...props}>
        <path
            d="M6.75,8.8a1.5,1.5,0,1,1,1.5,0V15H6.75Zm3.93,1.883L9.622,9.622a3,3,0,1,0-4.245,0L4.32,10.679a4.5,4.5,0,1,1,6.368,0ZM12.8,12.8l-1.057-1.057a6,6,0,1,0-8.483,0L2.2,12.8a7.5,7.5,0,1,1,10.605,0Z"
            transform="translate(0 0.002)"
        />
    </SVGIcon>
);
