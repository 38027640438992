import React, { FunctionComponent, useRef } from 'react';
import useCancelEvent from '../../../hooks/useCancelEvent';
import useOutsideClick from '../../../hooks/useOutsideClick';
import Button from '../../../design-system/Button/Button';

const DeleteConfirmation: FunctionComponent<{
    name: string;
    close: (a: boolean) => void;
}> = ({ name, close }) => {
    const ref = useRef(null);

    const _handleCancel = () => {
        close(false);
    };

    useCancelEvent(_handleCancel);

    useOutsideClick(ref, _handleCancel);
    return (
        <div className="w-full p-4 bg-gray-300" ref={ref}>
            <div>Delete {name}?</div>

            <div className="flex">
                <Button text="Cancel" fill="transparent" onClick={_handleCancel} />

                <Button
                    text="Delete"
                    fill="transparent"
                    onClick={() => {
                        close(true);
                    }}
                />
            </div>
        </div>
    );
};

export default DeleteConfirmation;
