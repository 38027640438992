import { useEffect } from 'react';

var ESCAPE_KEY = 27;

const useCancelEvent = (callback: Function) => {
    const _handleKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === ESCAPE_KEY) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', _handleKeyDown);

        return () => {
            document.removeEventListener('keydown', _handleKeyDown);
        };
    }, []);
};

export default useCancelEvent;
