import React, { FunctionComponent } from 'react';
import { H4 } from '../../../../design-system/Headings/Headings';
import { RocketIcon } from '../../../../icons/Ui/RocketIcon';

const GettingStarted: FunctionComponent = () => {
    return (
        <>
            {/* <RocketIcon className="w-24 h-24" />  */}
            <H4 className="mt-6 mb-4 font-medium">Getting Started</H4>
            <p className="pb-2 leading-relaxed">
                This section will be automatically generated by APIMatic from your API Specification
                when you publish your portal.
            </p>
            <p className="pb-2 leading-relaxed">
                It will contain instructions for the user to get started with using your API. For
                each SDK, it will contain language-specific instructions for:
            </p>
            <ul
                style={{ listStyle: 'disc', listStylePosition: 'inside' }}
                className="leading-relaxed"
            >
                <li>Installing the SDK</li>
                <li>Initializing the SDK</li>
                <li>Authenticating the API Calls</li>
                <li>Handling Errors from the API Calls</li>
            </ul>
        </>
    );
};

export default GettingStarted;
