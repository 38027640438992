import React, { FunctionComponent, ReactNode } from 'react';
import './Breadcrumbs.module.scss';
export interface Breadcrumb {
    text: ReactNode;
    href?: string;
    truncate?: boolean;
    className?: string;
}

interface BreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
}

/***
 *
 * @param {Breadcrumb[]} breadcrumbs
 *
 ***/
const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ breadcrumbs }) => {
    return (
        <nav className="breadcrumbs">
            {breadcrumbs.map((item, index) => (
                <span key={item.text?.toString()}>
                    <span className="breadcrumbs-item">
                        {item.href && <a href={item.href}>{item.text}</a>}
                        {!item.href && <span>{item.text}</span>}
                    </span>
                    {index !== breadcrumbs.length - 1 && <span className="seperator"></span>}
                </span>
            ))}
        </nav>
    );
};

export default Breadcrumbs;
