import { useState } from 'react';
import useUpdateEffect from './UseUpdateEffect';
import { FormValidatorDef } from '../types/form-validator';

const CHAR_IDENTIFIER = '{value}';
const DEFAULT_ERRORS = {
    min: `Min value ${CHAR_IDENTIFIER}`,
    max: `Max value ${CHAR_IDENTIFIER}`,
    minLength: `Min ${CHAR_IDENTIFIER} charachters`,
    maxLength: `Max ${CHAR_IDENTIFIER} charachters`,
    required: 'Field cannot be left empty',
    email: 'Enter a valid email',
    url: 'Invalid Url',
    github: 'Invalid Github Url',
    version: 'Invalid Version Value',
};

function isRequired(val: any) {
    if (typeof val === 'number' && isNaN(val)) {
        return false;
    }

    if (val) {
        return true;
    }
    return false;
}

function isMin(val: number, length: number) {
    if (val >= length) {
        return true;
    }

    return false;
}

function isMax(val: number, length: number) {
    if (val <= length) {
        return true;
    }

    return false;
}

function isMinLength(val: string, length: number) {
    if (val.length >= length) {
        return true;
    }

    return false;
}

function isMaxLength(val: string, length: number) {
    if (val.length <= length) {
        return true;
    }

    return false;
}

function isEmail(val: string) {
    if (val === '') {
        return true;
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
        return true;
    }
    return false;
}

function isUrl(val: string) {
    if (val === '') {
        return true;
    }

    if (
        /^((http[s]?):\/)+\/?([^:\/\s]+)((\/\w+)*\/)*([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/.test(val)
    ) {
        return true;
    }
    return false;
}

function isRelativeUrl(val: string) {
    if (val === '') {
        return true;
    }

    if (/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/.test(val)) {
        return true;
    }
    return false;
}

function isGithubUrl(val: string) {
    if (val === '') {
        return true;
    }

    if (/(?:(?:https?:\/\/)(?:www)?\.?(?:github)(?:\.com)?\/(?:.*)*)/.test(val)) {
        return true;
    }
    return false;
}

function isRegularExpression(val: string, reg: RegExp) {
    if (reg.test(val)) {
        return true;
    }
    return false;
}

function isVersion(val: string) {
    if (val === '') {
        return true;
    }

    if (/^(\d+\.)?(\d+\.)?(\*|\d+)$/.test(val)) {
        return true;
    }

    return false;
}

function isCustom(val: string, custom: custom | custom[]): string {
    if (Array.isArray(custom)) {
        for (let x in custom) {
            if (custom[x].function(val)) {
                return custom[x].message;
            }
        }
    } else {
        if (custom.function(val)) {
            return custom.message;
        }
    }

    return '';
}

interface length {
    length: number;
    message: string;
}

interface custom {
    message: string;
    function: (val: any) => boolean;
}

export interface FormValidatorConfigDef {
    key: string;
    min?: length | number;
    max?: length | number;
    minLength?: length | number;
    maxLength?: length | number;
    required?: string | boolean;
    email?: string | boolean;
    url?: string | boolean;
    relativeUrl?: string | boolean;
    github?: string | boolean;
    pattern?: {
        regex: RegExp;
        message: string;
    };
    version?: string | boolean;
    custom?: custom | custom[];
}

export function useFieldValidator(
    fieldName: string,
    val: any,
    config: FormValidatorConfigDef,
    form?: {
        state: FormValidatorDef;
        setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
    }
): string {
    const fieldKey = `${fieldName}_${config.key}`;
    const [touched, setTouched] = useState(false);

    useUpdateEffect(() => {
        if (form?.state.isSubmitted) {
            setTouched(true);
        }
    }, [form]);

    useUpdateEffect(() => {
        setTouched(true);
    }, [val]);

    const errorMsg = validateField(val, config);

    if (form) {
        if (
            (!(fieldKey in form.state.errors) && errorMsg) ||
            (fieldKey in form.state.errors && form.state.errors[fieldKey] !== errorMsg)
        ) {
            setTimeout(() => {
                form.setState((previousState) => {
                    return {
                        ...previousState,
                        errors: {
                            ...previousState.errors,
                            [fieldKey]: errorMsg,
                        },
                    };
                });
            });
        }
    }

    // for empty fields non error message if field is not touched
    if (!isRequired(val) && !touched) {
        return '';
    }

    return errorMsg;
}

const validateField = (val: any, config: FormValidatorConfigDef): string => {
    if (config.required && !isRequired(val)) {
        if (config.required === true) {
            return DEFAULT_ERRORS.required;
        }
        return config.required;
    }

    if (config.min) {
        let length;
        let message;
        if (typeof config.min === 'object') {
            length = config.min.length;
            message = config.min.message;
        } else {
            length = config.min;
            message = DEFAULT_ERRORS.min.replace(CHAR_IDENTIFIER, length.toString());
        }

        if (!isMin(val, length)) {
            return message;
        }
    }

    if (config.max) {
        let length;
        let message;
        if (typeof config.max === 'object') {
            length = config.max.length;
            message = config.max.message;
        } else {
            length = config.max;
            message = DEFAULT_ERRORS.max.replace(CHAR_IDENTIFIER, length.toString());
        }

        if (!isMax(val, length)) {
            return message;
        }
    }

    if (config.minLength) {
        let length;
        let message;
        if (typeof config.minLength === 'object') {
            length = config.minLength.length;
            message = config.minLength.message;
        } else {
            length = config.minLength;
            message = DEFAULT_ERRORS.minLength.replace(CHAR_IDENTIFIER, length.toString());
        }

        if (!isMinLength(val, length)) {
            return message;
        }
    }

    if (config.maxLength) {
        let length;
        let message;
        if (typeof config.maxLength === 'object') {
            length = config.maxLength.length;
            message = config.maxLength.message;
        } else {
            length = config.maxLength;
            message = DEFAULT_ERRORS.maxLength.replace(CHAR_IDENTIFIER, length.toString());
        }

        if (!isMaxLength(val, length)) {
            return message;
        }
    }

    if (config.email && !isEmail(val)) {
        if (config.email === true) {
            return DEFAULT_ERRORS.email;
        }
        return config.email;
    }

    if (config.url && !isUrl(val)) {
        if (config.url === true) {
            return DEFAULT_ERRORS.url;
        }
        return config.url;
    }

    if (config.relativeUrl && !isRelativeUrl(val)) {
        if (config.relativeUrl === true) {
            return DEFAULT_ERRORS.url;
        }
        return config.relativeUrl;
    }

    if (config.github && !isGithubUrl(val)) {
        if (config.github === true) {
            return DEFAULT_ERRORS.github;
        }
        return config.github;
    }

    if (config.version && !isVersion(val)) {
        if (config.version === true) {
            return DEFAULT_ERRORS.version;
        }
        return config.version;
    }

    if (config.pattern && !isRegularExpression(val, config.pattern.regex)) {
        return config.pattern.message;
    }

    if (config.custom) {
        const message = isCustom(val, config.custom);
        if (message) {
            return message;
        }
    }

    return '';
};
