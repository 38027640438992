import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export let MinimizeIcon = (props: SVGIconProps) => (
    <SVGIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
        <g transform="translate(-5035 -5231)">
            <g transform="translate(5035 5231)">
                <rect width="32" height="32" rx="6" fill="#f5f7f9" />
            </g>
            <g transform="translate(5039 5235)">
                <path
                    d="M4,14h6v6"
                    fill="none"
                    stroke="#24313e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M20,10H14V4"
                    fill="none"
                    stroke="#24313e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    y1="7"
                    x2="7"
                    transform="translate(14 3)"
                    fill="none"
                    stroke="#24313e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    y1="7"
                    x2="7"
                    transform="translate(3 14)"
                    fill="none"
                    stroke="#24313e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </g>
    </SVGIcon>
);
