import React, { FunctionComponent } from 'react';
import TypographySubSectionView from './TypographySection/TypographSection';
import { TypographyConstant } from './TypographyConstant';

const Typography: FunctionComponent = () => {
    return (
        <div>
            {TypographyConstant.map((item) => (
                <TypographySubSectionView
                    key={item.sectionKey}
                    label={item.label}
                    subheading={item.subheading}
                    section={item.section}
                    fontCategory={item.fontCategory}
                    sectionKey={item.sectionKey}
                />
            ))}
        </div>
    );
};

export default Typography;
