import React, { FunctionComponent, useEffect } from 'react';
import GeneratedContent from '../../components/api-docs/GeneratedContent/GeneratedContent';

const GeneratedContentView: FunctionComponent = () => {
    return (
        <div>
            <GeneratedContent />
        </div>
    );
};

export default GeneratedContentView;
