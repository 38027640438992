import { NavLinkItem } from '../ApiDocsHeader/ApiDocsHeaderInitialState';
import { LogoType } from './Logo/LogoConstant';

export interface ContentDef {
    title: string;
    body: string;
}

type badgeValue =
    | 'GettingStarted'
    | 'Authentication'
    | 'SdksAndCodeSamples'
    | 'ApiConsole'
    | 'HttpReference'
    | 'ApiEntities'
    | 'ApiTransformation'
    | 'Guides';

export interface JumbotronContentDef {
    title: string;
    tagLine: string;
}

export interface coverImageDef {
    id: number;
    uri: string;
}

export interface HomePageStateDef extends JumbotronContentDef {
    enabled: boolean;
    logoUrl: string;
    coverImage?: coverImageDef;
    logo?: LogoType;
    navigationLinks: NavLinkItem[];
    cards: ContentDef[];
    badges: badgeValue[];
}

export const homePageInitialState: HomePageStateDef = {
    enabled: true,
    logoUrl: '',
    title: 'Welcome',
    tagLine: 'Welcome to APIMatic!',
    navigationLinks: [],
    cards: [
        {
            title: '[Welcome]',
            body:
                '[You can add up to five custom sections. These sections are commonly used to provide sufficient information for the user before they dive into the API documentation.]',
        },
        {
            title: '[Obtaining Credentials]',
            body: '[Use the editor on the left hand side to add content here]',
        },
    ],
    badges: ['GettingStarted', 'Authentication', 'SdksAndCodeSamples'],
};
