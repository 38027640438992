import { getHeaders, fetcher } from './ApiClient';
import { PlatformsStateDef } from '../components/api-docs/Platforms/PlatformInitialState';

const getPlatformsForApiEntity = function (apiEntityId: string): Promise<PlatformsStateDef> {
    const url = `api-entities/${apiEntityId}/draft-portal-settings/platforms`;

    return fetcher<PlatformsStateDef>(url, 'GET', getHeaders());
};

const updatePlatformForApiEntity = function (apiEntityId: string, body: PlatformsStateDef) {
    const uri = `api-entities/${apiEntityId}/draft-portal-settings/platforms`;

    return fetcher<PlatformsStateDef>(uri, 'PUT', getHeaders(), body);
};

export { getPlatformsForApiEntity, updatePlatformForApiEntity };
