import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

const styleA = {
    fill: 'none',
    strokeWidth: '2px',
};

const styleB = {
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '1.6px',
};

export const EmbedIcon = (props: SVGIconProps) => (
    <SVGIcon width="28" height="21" viewBox="0 0 28 21" {...props} fill="none">
        <g transform="translate(-286 -645)">
            <g transform="translate(82 -105)">
                <g
                    {...styleA}
                    stroke={props.fill || DEFAULT_SVG_COLOR}
                    transform="translate(204 750)"
                >
                    <rect stroke="none" width="28" height="21" rx="3" />
                    <rect fill="none" x="1" y="1" width="26" height="19" rx="2" />
                </g>
                <line
                    {...styleA}
                    stroke={props.fill || DEFAULT_SVG_COLOR}
                    x2="25"
                    transform="translate(205.5 754.5)"
                />
                <line
                    {...styleA}
                    stroke={props.fill || DEFAULT_SVG_COLOR}
                    y2="17"
                    transform="translate(210.5 753.5)"
                />
                <g transform="translate(213.288 752.573)">
                    <path
                        {...styleB}
                        stroke={props.fill || DEFAULT_SVG_COLOR}
                        d="M16,12.854l3.427-3.427L16,6"
                        transform="translate(-6.003)"
                    />
                    <path
                        {...styleB}
                        stroke={props.fill || DEFAULT_SVG_COLOR}
                        d="M5.427,6,2,9.427l3.427,3.427"
                    />
                </g>
            </g>
        </g>
    </SVGIcon>
);
