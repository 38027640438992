import React, { FunctionComponent, useContext, createContext, useState, Children } from 'react';
import { UiStateDef } from '../../../types/ui-state';

export interface PublishWizardStateDef {
    activeScreen: string;
}

export interface PublishWizardProviderValue {
    publishWizardState: PublishWizardStateDef;
    updateActiveScreen: (newState: string) => void;
}

export type PublishWizardContextValue = undefined | PublishWizardProviderValue;
const PublishWizardContext = createContext<PublishWizardContextValue>(undefined);
const PublishWizardProvider: FunctionComponent = ({ children }) => {
    const publishWizardInitialState: PublishWizardStateDef = {
        activeScreen: 'screen_1',
    };
    const [publishWizardState, setPublishWizardState] = useState(publishWizardInitialState);

    const updateActiveScreen = (newState: string): void => {
        setPublishWizardState({
            activeScreen: newState,
        });
    };

    const value = {
        publishWizardState,
        updateActiveScreen,
    };

    return <PublishWizardContext.Provider value={value}>{children}</PublishWizardContext.Provider>;
};

const usePublishWizard = () => useContext(PublishWizardContext);

export { PublishWizardProvider, usePublishWizard };
