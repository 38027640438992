import React, { FunctionComponent } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import darkTheme from 'react-syntax-highlighter/dist/esm/styles/hljs/dracula';

const CodeSample: FunctionComponent = () => {
    return (
        <div className="relative flex-col justify-center code-samples-wrapper">
            <div className="overflow-hidden code custom-scroll invert-scroll">
                <SyntaxHighlighter language="javascript" style={darkTheme}>
                    {/* <pre className="mt-4">
                    <code className="pr-3 text-white opacity-80"> */}
                    {`
var operation = OperationType.MULTIPLY;
var x = 222.14;
var y = 165.
try 
{
    double? result = simpleCalculator.GetCalculateAsync( 
        operation, x, y
    ).Result;
}
catch (APIException e)
{
    // TODO handle failure
}
`}
                </SyntaxHighlighter>
                {/* </code>
                </pre> */}
            </div>
            <div className="flex justify-end py-3">
                <div className="bottom-0 right-0 w-24 h-8 mr-4 rounded-12 try-out-btn"></div>
            </div>
        </div>
    );
};

export default CodeSample;
