import { getHeaders, fetcher } from './ApiClient';

// const updateApiDocs = (apiEntityId: string) => {
//     const uri = `api-entities/${apiEntityId}/portal/publish`;
//     return fetcher<any>(uri, 'PUT', getHeaders());
// };

const updateApiDocs = (apiEntityIds: string[], utmHeader?: string | null) => {
    const uri = `api-entities/portals/publish`;

    return fetcher<any>(uri, 'PUT', getHeaders(false, utmHeader), { apiEntityIds });
};

const getEmbeddedApiDocs = (apiEntityId: string) => {
    const uri = `api-entities/${apiEntityId}/portal/publish/embedded`;
    return fetcher<any>(uri, 'PUT', getHeaders());
};

const unpublishApiDocs = (apiEntityId: string) => {
    const uri = `api-entities/${apiEntityId}/portal/publish`;
    return fetcher<any>(uri, 'DELETE', getHeaders());
};

export { updateApiDocs, getEmbeddedApiDocs, unpublishApiDocs };
