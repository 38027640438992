import { ApiDocsHeaderStateDef } from '../components/api-docs/ApiDocsHeader/ApiDocsHeaderInitialState';
import { ApiConsoleOptionsResponseDef } from '../api-client/ApiConsoleOptionsService';
import { ApiDocsBrandingStateDef } from '../components/api-docs/Branding/BrandingTypes';
import { HomePageStateDef } from '../components/api-docs/HomePage/HomePageIntialState';
import { ApiSpecExportStateDef } from '../components/api-docs/ApiSpecExport/ApiSpecExportInitialState';
import { TocType } from '../components/CustomContent/CustomContentTypes';
import { HostingStateDef } from '../components/api-docs/HostingSlug/HostingSlugTypes';
import { PlatformsStateDef } from '../components/api-docs/Platforms/PlatformInitialState';
import { PortalEditorSettingsDef } from '../types/portal-editor-settings';
import { PublishedPortalStateDef } from '../components/api-docs/PublishedPortal/PublishedPortalTypes';
import { SubscriptionDef } from '../components/api-docs/Subscription/SubscriptionInitialState';
import { AIChatbotStateDef } from '../components/api-docs/AIChatbot/AIChatbotTypes';

export interface ApiGroupStoreDef {
    apiDocsBranding?: ApiDocsBrandingStateDef;
    apiDocsHeader?: ApiDocsHeaderStateDef;
    homePage?: HomePageStateDef;
    hosting?: HostingStateDef;
    publishedPortal?: PublishedPortalStateDef;
    aiChatbot?: AIChatbotStateDef;
}

export interface ApiEntityStoreDef {
    platforms?: PlatformsStateDef;
    apiConsoleOptions?: ApiConsoleOptionsResponseDef;
    apiSpecExport?: ApiSpecExportStateDef;
    customContent?: TocType;
    currentCustomPageData?: string;
}

export interface StoreStateDef {
    activeView: string;
    previousView?: string;
    containsUnsavedChanges?: boolean;
    activeApiEntityId: string;
    settings?: PortalEditorSettingsDef;
    subscription: SubscriptionDef | null;
    apiGroup: ApiGroupStoreDef;
    apiEntities: {
        [id: string]: ApiEntityStoreDef;
    };
    isSaving?: boolean;
    UTMMedium?: string;
}

export const storeInitialState: StoreStateDef = {
    activeView: '',
    activeApiEntityId: '',
    subscription: null,
    apiGroup: {},
    apiEntities: {},
};
