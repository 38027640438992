import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export let AddFolderOutlineIcon = (props: SVGIconProps) => (
    <SVGIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g transform="translate(-113 -413)">
            <rect
                width="24"
                height="24"
                transform="translate(113 413)"
                fill="rgba(255,255,255,0)"
            />
            <path
                d="M0,3.6A1.6,1.6,0,0,1,1.6,2H7.2L8.8,3.6h5.6A1.6,1.6,0,0,1,16,5.2v8a1.6,1.6,0,0,1-1.6,1.6H1.6A1.6,1.6,0,0,1,0,13.2ZM1.6,5.2v8H14.4v-8ZM7.2,8.4V6.8H8.8V8.4h1.6V10H8.8v1.6H7.2V10H5.6V8.4Z"
                transform="translate(117 417)"
                fill="#0c7ff2"
            />
        </g>
    </SVGIcon>
);
