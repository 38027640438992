import { Base64 } from 'js-base64';

const apiClientSettings = {
    // apiGroupId: '5eb514c616c1a83c2c9e2c07',
    // apiEntityId: '5eb514c616c1a83c2c9e2c08',
    apiGroupId: '64f8245b956c943a2c01c9cd',
    apiEntityId: '64f8245b956c943a2c01c9ce',
    baseUrl: 'https://api.apimatic.io/',
};

enum headerType {
    onboardingBanner = 'onboardingBanner',
}

type HeaderListType = {
    [key in headerType]: { headerName: string; headerValue: string };
};

const HEADER_LIST: HeaderListType = {
    [headerType.onboardingBanner]: {
        headerName: 'X-APIMatic-Web-TrackingComponent',
        headerValue: 'Onboarding Banner - Dashboard',
    },
};

const getHeaders = (isFormData = false, UTMHeader?: string | null): Headers => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');

    if (!isFormData) {
        headers.append('content-type', 'application/json');
    }

    if (UTMHeader && HEADER_LIST[UTMHeader as headerType]) {
        const { headerName, headerValue } = HEADER_LIST[UTMHeader as headerType];
        headers.append(headerName, headerValue);
    }
    // DO NOT ASSIGN CONTENT-TYPE FOR FORMDATA MANUALLY
    // LET THE BROWSER HANDLE IT
    return headers;
};

function fetcher<T>(
    uri: string,
    method: string,
    headers: Headers,
    body: T | null = null,
    isFormData = false
): Promise<T> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const settings = JSON.parse(localStorage.getItem('portalEditorSettings')!);
    const url = `${settings.baseUrl}/${uri
        .replace('{apiGroupId}', settings.apiGroupId)
        .replace('{apiEntityId}', settings.apiEntityId)}`;

    let data: any = body;
    if (!isFormData && body) {
        data = JSON.stringify(body);
    }

    return fetch(url, {
        method: method,
        headers: headers,
        body: data,
        credentials: 'include',
    })
        .then((response: Response) => {
            if (!response.ok) {
                return Promise.reject(response);
            }

            if (response.status === 204) {
                return '';
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data as T;
        })
        .catch((error: Error) => {
            return Promise.reject(error);
        });
}

export { apiClientSettings, getHeaders, fetcher };
