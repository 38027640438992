export const sdkDownloadOptions = [
    {
        label: 'Download as ZIP file',
        name: 'apimaticSdk',
        value: 'zip-download',
    },
    {
        label: 'Download as ZIP file from a custom link',
        name: 'apimaticSdk',
        value: 'sdk-download-link',
    },
];
