import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

// TODO: set "#9caab8" in app
export let FolderIcon = (props: SVGIconProps) => (
    <SVGIcon viewBox="0 0 16 12" width="16" height="12" {...props} fill="none">
        <g id="Group_1279" data-name="Group 1279" transform="translate(-95 -91)">
            <g id="Group_1369" data-name="Group 1369">
                <path
                    id="folder-outline"
                    className="folder-icon-fill"
                    d="M0,3.4A1.4,1.4,0,0,1,1.4,2H6.3L7.7,3.4h4.9A1.4,1.4,0,0,1,14,4.8v7a1.4,1.4,0,0,1-1.4,1.4H1.4A1.4,1.4,0,0,1,0,11.8ZM1.4,4.8v7H12.6v-7Z"
                    transform="translate(95 89)"
                    fill={props.fill || DEFAULT_SVG_COLOR}
                />
                <path
                    id="Path_1683"
                    className="folder-icon-fill"
                    data-name="Path 1683"
                    d="M1,1H15a.95.95,0,0,1,1,.89L14,7.116a.95.95,0,0,1-1,.89H1a.95.95,0,0,1-1-.89V1.89A.95.95,0,0,1,1,1Z"
                    transform="translate(95 94.994)"
                    fill={props.fill || DEFAULT_SVG_COLOR}
                />
            </g>
        </g>
    </SVGIcon>
);
