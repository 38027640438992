import React, { FunctionComponent, useState, ChangeEvent } from 'react';
import { ContentDef } from '../HomePageIntialState';
import { TextField, TextareaField, Button, FieldBox } from '../../../../design-system';
import { FormValidatorDef } from '../../../../types/form-validator';
import { utilityFunctions } from '../../../../utilities/functions';

interface ThemeEditorCardProps {
    className?: string;
    initialState: ContentDef;
    onSave: (editorState: ContentDef) => void;
    onCancel: () => void;
}

const ThemeEditorCard: FunctionComponent<ThemeEditorCardProps> = (props) => {
    const { onSave, initialState, onCancel, className } = props;
    const [editorState, setEdiotrState] = useState(initialState);
    const [formValidator, setFormValidator] = useState<FormValidatorDef>({
        isSubmitted: false,
        errors: {},
    });

    const onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { name, value } = e.target;
        setEdiotrState({
            ...editorState,
            [name]: value,
        });
    };

    const _handleSave = () => {
        const form = { state: formValidator, setState: setFormValidator };
        if (utilityFunctions.formIsValid(form)) {
            onSave(editorState);
        }
    };

    return (
        <div
            className={`p-6 mx-5 my-6 bg-white border border-solid theme-editor-card-wrapper rounded-12 border-goose-600 ${className}`}
        >
            <div className="flex flex-col body">
                <FieldBox label="" className="mb-6">
                    <TextField
                        placeholder="Title"
                        name="title"
                        value={editorState.title}
                        onChange={(e) => onInputChange(e)}
                        validation={{
                            key: '_title',
                            required: true,
                        }}
                        form={{ state: formValidator, setState: setFormValidator }}
                    />
                </FieldBox>
                <FieldBox label="" className="mb-5">
                    <TextareaField
                        placeholder="Tag Line"
                        name="body"
                        rows={5}
                        value={editorState.body}
                        onChange={(e) => onInputChange(e)}
                        validation={{
                            key: '_textarea',
                            required: true,
                        }}
                        form={{ state: formValidator, setState: setFormValidator }}
                    />
                </FieldBox>
            </div>
            <div className="flex justify-end footer">
                <Button fill="danger" text="Cancel" onClick={onCancel} />
                <Button className="ml-2" fill="default" text="Done" onClick={_handleSave} />
            </div>
        </div>
    );
};

export default ThemeEditorCard;
