import React, { FunctionComponent, useEffect } from 'react';
import { PublishedPortalProvider } from '../../components/api-docs/PublishedPortal/PublishedPortalContext';
import PublishedPortal from '../../components/api-docs/PublishedPortal/PublishedPortal';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { HostingKeyForSidenav, SideNavActiveItemStoreKey } from '../ViewConstant';

const HostingView: FunctionComponent = () => {
    const { updateStore } = useStore() as StoreProviderValue;
    useEffect(() => {
        updateStore(HostingKeyForSidenav, SideNavActiveItemStoreKey);
    }, []);
    return (
        <div className="w-full h-full">
            <PublishedPortalProvider>
                <PublishedPortal />
            </PublishedPortalProvider>
        </div>
    );
};

export default HostingView;
