import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export let AddIcon = (props: SVGIconProps) => (
    <SVGIcon viewBox="0 0 10 10" width="10" height="10" {...props}>
        <path
            fill={props.fill || DEFAULT_SVG_COLOR}
            id="Path_36203"
            data-name="Path 36203"
            d="M230.521,4205.5h1.966v4.048h4v1.959h-4v3.989h-1.966v-3.989h-4.032v-1.959h4.032Z"
            transform="translate(-226.489 -4205.5)"
        />
    </SVGIcon>
);
