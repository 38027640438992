import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

// TODO: set this color in app '#6b7e8f'
const styleA = {
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '1.8px',
};

export const CopyIcon = (props: SVGIconProps) => (
    <SVGIcon width="15.8" height="15.8" viewBox="0 0 15.8 15.8" {...props} fill="none">
        <g transform="translate(-5.1 -5.1)">
            <rect
                width="11.375"
                height="11.375"
                rx="2"
                transform="translate(8.625 8.625)"
                {...styleA}
                stroke={props.fill ? props.fill : DEFAULT_SVG_COLOR}
            />
            <path
                d="M2,11.625V3.75A1.75,1.75,0,0,1,3.75,2h7.875"
                transform="translate(4 4)"
                {...styleA}
                stroke={props.fill ? props.fill : DEFAULT_SVG_COLOR}
            />
        </g>
    </SVGIcon>
);
