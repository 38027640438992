import React, { FunctionComponent, useState, useEffect } from 'react';
import { Dropdown } from '../../design-system';
import { CustomContentContextValue, ToCElementType, ToCItemError } from './CustomContentTypes';
import { useTableofContent } from './CustomContentContext';
import ToCFile from './ToCFile';
import { FolderIcon } from '../../icons/Ui/FolderIcon';
import { AddDocumentIcon } from '../../icons/Ui/AddDocumentIcon';
import { AddFolderOutlineIcon } from '../../icons/Ui/AddFolderOutlineIcon';
import { EditIcon } from '../../icons/Ui/EditIcon';
import { TrashIcon } from '../../icons/Ui/TrashIcon';
import CustomSectionInput from './DropdownOptions/CustomSectionInput';
import DeleteConfirmation from './DropdownOptions/DeleteConfirmation';
import ToCGeneratedContent from './ToCGeneratedContent';
import { DropdownItemDef } from '../../design-system/DropDown/DropDown';
import {
    getTocElementKey,
    getFilesFromLevel,
    transformPath,
    getDraggingItemStyle,
    getDraggingListStyle,
    getDefaultErrorMessage,
} from './CustomContentConstant';
import { EllipsisIcon } from '../../icons/Ui/EllipsisIcon';
import { useNavigate } from 'react-router';
import { Draggable, Droppable } from 'react-beautiful-dnd';

const Section: FunctionComponent<{
    path: string[];
    slug: string[];
    content: ToCElementType;
    collapse?: boolean;
    draggingItemId: string;
    depth: number;
}> = ({ path, content, collapse, draggingItemId, depth, slug }) => {
    const [showAddFile, setShowAddFile] = useState(false);
    const [showAddFolder, setShowAddFolder] = useState(false);

    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const [errorMsg, setErrorMsg] = useState<ToCItemError>(getDefaultErrorMessage());

    const {
        addFile,
        setNewFile,
        addSection,
        renameSection,
        deleteSection,
        tableOfContents,
        setFileToBeDeleted,
    } = useTableofContent() as CustomContentContextValue;

    const navigate = useNavigate();

    const closeDelete = (val: boolean) => {
        if (val) {
            const filesTobeDeleted = getFilesFromLevel(content.items, getPath()).map(
                (fTBD) => fTBD.file.file
            );
            deleteSection(tableOfContents, getPath(), content.group);
            setFileToBeDeleted(filesTobeDeleted);
        }
        setDeleting(false);
    };

    const getPath = () => {
        return [...path, content.group];
    };

    const getKey = (group: string) => {
        return getPath().join('_') + '_' + group;
    };

    const dropdownList: DropdownItemDef[] = [
        {
            name: 'Add Page',
            icon: <AddDocumentIcon />,
            onClick: () => {
                setShowAddFile(true);
                setShowAddFolder(false);
                setEditing(false);
                setDeleting(false);
            },
        },
        {
            name: 'Add Section',
            icon: <AddFolderOutlineIcon />,
            hr: 'below',
            onClick: () => {
                setShowAddFolder(true);
                setShowAddFile(false);
                setEditing(false);
                setDeleting(false);
            },
        },
        {
            name: 'Rename',
            icon: <EditIcon fill="#0c7ff2" />,
            onClick: () => {
                setEditing(true);
                setShowAddFile(false);
                setShowAddFolder(false);
                setDeleting(false);
            },
        },
        {
            name: 'Delete',
            icon: <TrashIcon fill="#FF4451" />,
            onClick: () => {
                setDeleting(true);
                setShowAddFile(false);
                setShowAddFolder(false);
                setEditing(false);
            },
        },
    ];

    // add file
    const _handleAddFile = (data?: { name: string; slug: string }) => {
        if (data) {
            addFile(getPath(), data.name, data.slug)
                .then((file) => {
                    setNewFile(file);
                    setShowAddFile(false);
                    setErrorMsg(getDefaultErrorMessage());
                    navigate(`custom-pages/${file.file}`);
                })
                .catch((e) => {
                    setErrorMsg(e);
                });
        } else {
            setErrorMsg(getDefaultErrorMessage());
            setShowAddFile(false);
        }
    };

    useEffect(() => {
        setErrorMsg(getDefaultErrorMessage());
    }, [showAddFile, showAddFolder]);

    // add folder
    const _handleAddFolder = (data?: { name: string; slug: string }) => {
        if (data) {
            addSection(getPath(), data.name, data.slug)
                .then(() => {
                    setShowAddFolder(false);
                    setErrorMsg(getDefaultErrorMessage());
                })
                .catch((e) => {
                    setErrorMsg(e);
                });
        } else {
            setErrorMsg(getDefaultErrorMessage());
            setShowAddFolder(false);
        }
    };

    // rename folder
    const _handleRenameFolder = (data?: { name: string; slug: string }) => {
        if (data) {
            renameSection(getPath(), content.group, data.name, data.slug)
                .then(() => {
                    setErrorMsg(getDefaultErrorMessage());
                    setEditing(false);
                })
                .catch((e) => {
                    setErrorMsg(e);
                });
        } else {
            setErrorMsg(getDefaultErrorMessage());
            setEditing(false);
        }
    };

    const isDropDisabled = (droppableId: string) => {
        const index = droppableId.indexOf(draggingItemId);
        return index === 0 && draggingItemId !== '';
    };

    const [isDraggingOver, setIsDraggingOver] = useState(false);

    return (
        <div className="relative flex flex-wrap justify-between w-full transition-all section-wrapper">
            {!editing && !deleting && (
                <div className="flex items-center justify-between w-full h-6 nav-item-wrapper">
                    <div
                        className={
                            'flex items-center w-full nav-item ' +
                            (isDraggingOver || !draggingItemId ? ' ' : 'opacity-50')
                        }
                        style={{
                            paddingLeft: depth * 0.5 + 'rem',
                        }}
                    >
                        <FolderIcon width="11px" />
                        <div
                            className={
                                'text-14 pl-2 blur-overflow lh-21 ' +
                                (getPath().length === 1
                                    ? ' nav-item-title'
                                    : ' nav-item-folder')
                            }
                        >
                            {content.group}
                        </div>
                    </div>
                    <div>
                        <Dropdown
                            name=""
                            className="dropdown-icon-button section-dropdown"
                            size="sm"
                            fill="transparent"
                            icon={<EllipsisIcon width="10px" fill="#455666" />}
                            dropdownItems={dropdownList}
                        />
                    </div>
                </div>
            )}

            {showAddFile && (
                <CustomSectionInput
                    title="Add Page"
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    close={_handleAddFile}
                />
            )}

            {showAddFolder && (
                <CustomSectionInput
                    title="Add Section"
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    close={_handleAddFolder}
                />
            )}

            {editing && (
                <CustomSectionInput
                    title="Rename Section"
                    oldName={content.group}
                    oldSlug={content.slug}
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    close={_handleRenameFolder}
                />
            )}

            {deleting && <DeleteConfirmation name={content.group} close={closeDelete} />}

            {!collapse && (
                <Droppable
                    droppableId={transformPath(['SECTION', ...getPath()], 'SECTION')}
                    isDropDisabled={isDropDisabled(transformPath(getPath(), ''))}
                >
                    {(provided, snapshot) => {
                        if (snapshot.isDraggingOver !== isDraggingOver) {
                            setIsDraggingOver(snapshot.isDraggingOver);
                        }
                        return (
                            <div
                                className={
                                    'w-full ' + (content.items.length === 0 ? 'pt-1 pb-2' : '')
                                }
                                ref={provided.innerRef}
                                style={getDraggingListStyle(
                                    snapshot.isDraggingOver,
                                    draggingItemId !== ''
                                )}
                            >
                                {content.items?.map((item, idx) => (
                                    <div key={'outer_key_' + getKey('') + getTocElementKey(item)}>
                                        <Draggable
                                            index={idx}
                                            key={'inner_key' + getKey('') + getTocElementKey(item)}
                                            draggableId={transformPath(
                                                getPath(),
                                                'group' in item
                                                    ? item.group
                                                    : getTocElementKey(item)
                                            )}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getDraggingItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    {'group' in item && (
                                                        <Section
                                                            path={getPath()}
                                                            slug={[...slug, content.slug]}
                                                            key={getKey(item.group)}
                                                            content={item}
                                                            draggingItemId={draggingItemId}
                                                            depth={depth + 1}
                                                        ></Section>
                                                    )}

                                                    <div
                                                        className={
                                                            isDraggingOver || !draggingItemId
                                                                ? ''
                                                                : 'opacity-50'
                                                        }
                                                        style={{
                                                            paddingLeft: (depth + 1) * 0.5 + 'rem',
                                                        }}
                                                    >
                                                        {'file' in item && (
                                                            <ToCFile
                                                                path={getPath()}
                                                                slug={[...slug, content.slug]}
                                                                file={item}
                                                            />
                                                        )}

                                                        {'generate' in item && (
                                                            <ToCGeneratedContent
                                                                path={getPath()}
                                                                content={item}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                ))}

                                <div className="w-full bg-white extra-drop-space"></div>
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            )}
        </div>
    );
};

export default Section;
