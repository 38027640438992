import React, { FunctionComponent } from 'react';
import { ApiSpecExportProvider } from '../ApiSpecExport/ApiSpecExportContext';
import { ApiDocsBrandingProvider } from '../Branding/BrandingContext';
import { PlatformsProvider } from '../Platforms/PlatformsContext';
import './api-docs-preview.scss';
import CodeSample from './CodeSample';
import Endpoint from './Endpoint';
import Header from './Header';
import Sidebar from './SideBar';

const ApiDocsPreview: FunctionComponent = () => {
    return (
        <div className="flex flex-col w-full preview-card-wrapper">
            <div className="w-full border border-solid border-goose-600 preview-wrapper">
                <ApiDocsBrandingProvider>
                    <Header />
                </ApiDocsBrandingProvider>

                <div className="flex main-wrapper">
                    <PlatformsProvider>
                        <ApiSpecExportProvider>
                            <ApiDocsBrandingProvider>
                                <Sidebar />
                            </ApiDocsBrandingProvider>
                        </ApiSpecExportProvider>
                    </PlatformsProvider>

                    <Endpoint />

                    <CodeSample />
                </div>
            </div>
        </div>
    );
};

export default ApiDocsPreview;
