import {
    TocType,
    ADD_SECTION,
    RENAME_SECTION,
    RENAME_FILE,
    DELETE_SECTION,
    ActionType,
    ADD_FILE,
    ADD_SECTION_TYPE,
    ADD_FILE_TYPE,
    ToCElementType,
    RENAME_SECTION_TYPE,
    UPDATE_TOC,
    ToCItemsType,
    DELETE_FILE,
    UPDATE_STATE,
    UPDATE_STATE_TYPE,
    ToCFileType,
    DELETE_GENERATED_CONTENT,
    RENAME_GENERATED_CONTENT,
    HANDLE_DROP_END_OF_TOC_ITEMS,
    GeneratedContentType,
    ADD_NEW_DYNAMIC_ELEMENT,
    ToCItemType,
    ADD_NEW_DYNAMIC_ELEMENT_TYPE,
    DISABLE_GENERATED_CONTENT,
} from '../CustomContentTypes';
import {
    findSectionIndex,
    UNEXPECTED_ERROR,
    findGeneratedContentIndex,
    DUPLICATE_GENERATED_CONTENT_ERROR,
    canGeneratedContentRenamedOnLevel,
    reorderList,
} from '../CustomContentConstant';
import { caseReducer } from './caseReducer';

const secondLevelReducer: (
    state: ToCItemsType,
    action: ActionType,
    path: string[]
) => ToCItemsType = (state, action, path) => {
    // base case for recursion will activate the required action
    if (path.length === 0) {
        return caseReducer(state, action);
    } else {
        // recursively go deeper into the path
        const group = path.shift();
        if (typeof group === 'string') {
            const index = findSectionIndex(state, group);
            const currentObj = state[index] as ToCElementType;

            return [
                ...state.slice(0, index),
                {
                    ...currentObj,
                    items: secondLevelReducer(currentObj.items, action, path),
                },
                ...state.slice(index + 1),
            ];
        }
        throw UNEXPECTED_ERROR;
    }
};

export const customContentReducer: (state: TocType, action: ActionType) => TocType = (
    state,
    action
) => {
    try {
        if (action.type === UPDATE_STATE) {
            const currentAction = action as UPDATE_STATE_TYPE;
            return [...currentAction.payload];
        }

        // Action can be applied on base level
        if (
            action.type === ADD_SECTION ||
            action.type === DISABLE_GENERATED_CONTENT ||
            action.type === DELETE_GENERATED_CONTENT
        ) {
            const currentAction = action as ADD_SECTION_TYPE;
            const { path } = currentAction.payload;
            return secondLevelReducer(state, action, path);
        }

        // Action can be applied on base level
        // remove the last group in path which is the oldGroup
        if (action.type === RENAME_SECTION || action.type === DELETE_SECTION) {
            const currentAction = action as RENAME_SECTION_TYPE;

            const { path } = currentAction.payload;
            path.pop();
            return secondLevelReducer(state, action, path);
        }

        if (action.type === RENAME_GENERATED_CONTENT) {
            const currentAction = action as RENAME_GENERATED_CONTENT;

            const { path, newName, from } = currentAction.payload;

            if (path.length === 0) {
                const index = findGeneratedContentIndex(state, from);
                const obj = state[index] as ToCElementType;

                // check whether
                // oldGroup exists and
                // newGroup does not exists
                if (index !== -1) {
                    if (canGeneratedContentRenamedOnLevel(state, action.payload.newName)) {
                        currentAction.payload.resolve('');
                        return [
                            ...state.slice(0, index),
                            {
                                ...obj,
                                generate: newName,
                            },
                            ...state.slice(index + 1),
                        ];
                    }
                    throw { custom: true, title: DUPLICATE_GENERATED_CONTENT_ERROR, slug: '' };
                } else {
                    throw { custom: true, title: UNEXPECTED_ERROR, slug: '' };
                }
            } else {
                let basePath = path.shift();
                if (typeof basePath === 'string') {
                    const index = findSectionIndex(state, basePath);
                    const obj = state[index] as ToCElementType;

                    if (index !== -1) {
                        return [
                            ...state.slice(0, index),
                            {
                                ...obj,
                                items: secondLevelReducer(obj.items, action, path),
                            },
                            ...state.slice(index + 1),
                        ];
                    }

                    action.payload.resolve(UNEXPECTED_ERROR);
                }
            }
        }

        // Action not permitted on base level
        if (
            action.type === ADD_FILE ||
            action.type === RENAME_FILE ||
            action.type === DELETE_FILE
        ) {
            const currentAction = action as ADD_FILE_TYPE;

            const { path } = currentAction.payload;
            if (path.length === 0) {
                throw UNEXPECTED_ERROR;
            }

            return secondLevelReducer(state, action, path);
        }

        // Action is only permitted at base level
        if (action.type === UPDATE_TOC) {
            return caseReducer(state, action);
        }

        if (action.type === HANDLE_DROP_END_OF_TOC_ITEMS) {
            const currentAction = action as HANDLE_DROP_END_OF_TOC_ITEMS;
            const { source, destination, sourceObj } = currentAction.payload;

            // base level reordering
            if (source.path.length === 0 && destination.path.length === 0) {
                return reorderList(state, source.index, destination.index) as TocType;
            }

            // delete source

            let newState;

            if (!action.payload.isReorderOnSamelevel) {
                if ('group' in sourceObj) {
                    newState = customContentReducer(state, {
                        type: DELETE_SECTION,
                        payload: {
                            path: [...source.path, ''],
                            group: (sourceObj as ToCElementType).group,
                            generatedContentList: [],
                        },
                    });
                } else if ('generate' in sourceObj) {
                    newState = customContentReducer(state, {
                        type: DELETE_GENERATED_CONTENT,
                        payload: {
                            path: [...source.path],
                            content: sourceObj as GeneratedContentType,
                        },
                    });
                } else if ('file' in sourceObj) {
                    newState = customContentReducer(state, {
                        type: DELETE_FILE,
                        payload: {
                            path: source.path,
                            file: (sourceObj as ToCFileType).file,
                            resolve: (a: any) => {},
                        },
                    });
                }
            } else {
                newState = state;
            }

            // ADD ELEMENT

            if (newState) {
                try {
                    if (destination.path.length === 0) {
                        return [
                            ...(newState as TocType).slice(0, destination.index),
                            { ...sourceObj },
                            ...(newState as TocType).slice(destination.index),
                        ];
                    }

                    let basePath = destination.path.shift();

                    if (typeof basePath === 'string') {
                        const index = findSectionIndex(newState, basePath);
                        const obj = newState[index] as ToCElementType;

                        let newAction: ADD_NEW_DYNAMIC_ELEMENT_TYPE = {
                            type: ADD_NEW_DYNAMIC_ELEMENT,
                            payload: {
                                sourceIndex: source.index,
                                isReorderOnSamelevel: action.payload.isReorderOnSamelevel,
                                newObj: sourceObj as ToCItemType,
                                ...destination,
                            },
                        };

                        const updatedState =
                            index !== -1
                                ? [
                                      ...newState.slice(0, index),
                                      {
                                          ...obj,
                                          items: secondLevelReducer(
                                              obj.items,
                                              newAction,
                                              destination.path
                                          ),
                                      },
                                      ...newState.slice(index + 1),
                                  ]
                                : state;

                        return updatedState;
                    }
                } catch (e) {
                    return state;
                }
            }
        }

        throw UNEXPECTED_ERROR;
    } catch (e) {
        if ('reject' in action) {
            action.reject(e && e.custom ? e : UNEXPECTED_ERROR);
        }
        return state;
    }
};
