import React, { FunctionComponent } from 'react';
import './field-group-card.scss';

interface FieldGroupCardProps {
    className?: string;
}

const FieldGroupCard: FunctionComponent<FieldGroupCardProps> = (props) => {
    return (
        <div
            className={`field-group-card w-full bg-snow-500 border border-solid border-goose-600 mb-4 rounded-6 ${props.className}`}
        >
            {props.children}
        </div>
    );
};

export default FieldGroupCard;
