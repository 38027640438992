import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const CollapseSectionIcon = (props: SVGIconProps) => (
    <SVGIcon viewBox="0 0 12 12" width="12" height="12" {...props} fill="none">
        <g id="Group_2343" data-name="Group 2343" transform="translate(-183 -181)">
            <path
                id="indent-decrease"
                d="M7,5H19V7H7Z"
                transform="translate(176 181)"
                fill={props.fill || DEFAULT_SVG_COLOR}
            />
            <path
                id="indent-decrease-2"
                data-name="indent-decrease"
                d="M5,6v8L1,10Z"
                transform="translate(199 188) rotate(90)"
                fill={props.fill || DEFAULT_SVG_COLOR}
            />
            <path
                id="indent-decrease-3"
                data-name="indent-decrease"
                d="M5,6v8L1,10Z"
                transform="translate(179 186) rotate(-90)"
                fill={props.fill || DEFAULT_SVG_COLOR}
            />
        </g>
    </SVGIcon>
);
