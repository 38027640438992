import { useEffect } from 'react';

const useMousePosition = (detectChanges: any | undefined, callback: Function) => {
    const updateMousePosition = (ev: MouseEvent) => {
        if (detectChanges) {
            callback({ x: ev.clientX, y: ev.clientY });
        }
    };

    useEffect(() => {
        if (detectChanges) {
            window.addEventListener('mousemove', updateMousePosition);
        }
        return () => window.removeEventListener('mousemove', updateMousePosition);
    }, [detectChanges]);
};

export default useMousePosition;
