import React, { FunctionComponent, useEffect } from 'react';
import Header from '../Header/Header';
import SideNav from '../SideNav/SideNav';
import Main from '../Main/Main';
import { useHeader, HeaderProviderValue } from '../Header/HeaderContext';
import { NotificationProvider } from '../Notification/NotificationBarContext';
import { CustomContentProvider } from '../CustomContent/CustomContentContext';
import { HomePageProvider } from '../api-docs/HomePage/HomePageContext';
import './layout.scss';
import GuidePreview from '../GuidePreview/GuidePreview';
import { SubscriptionProvider } from '../api-docs/Subscription/SubscriptionContext';
import { PublishedPortalProvider } from '../api-docs/PublishedPortal/PublishedPortalContext';
import { useLocation } from 'react-router-dom';
import { StoreProviderValue, useStore } from '../../store/storeContext';
import { AIChatbotProvider } from '../api-docs/AIChatbot/AIChatbotContext';

const Layout: FunctionComponent = () => {
    const { headerState } = useHeader() as HeaderProviderValue;
    const location = useLocation();
    const { updateUTMMedium } = useStore() as StoreProviderValue;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const utmMedium = queryParams.get('utm_medium');

        if (utmMedium) {
            updateUTMMedium(utmMedium);
        }
    }, []);

    return (
        <div className="flex flex-col w-full ">
            <Header />
            {!headerState.isPublishClicked && (
                <div className="relative flex w-full h-full main-wrapper">
                    <CustomContentProvider>
                        <NotificationProvider>
                            <SubscriptionProvider>
                                <HomePageProvider>
                                    <PublishedPortalProvider>
                                        <AIChatbotProvider>
                                            <SideNav />
                                            <GuidePreview />
                                            <Main />
                                        </AIChatbotProvider>
                                    </PublishedPortalProvider>
                                </HomePageProvider>
                            </SubscriptionProvider>
                        </NotificationProvider>
                    </CustomContentProvider>
                </div>
            )}
        </div>
    );
};

export default Layout;
