import React, { FunctionComponent, useContext } from 'react';
import { FieldBox, TextField, SelectField } from '../../../../../../design-system';
import {
    TypographyElementType,
    fontWeight,
    getFontsList,
    TypographyElementIdentifierType,
} from '../TypographySectionConstant';
import { FontCategoryType } from '../../TypographyConstant';
import { BrandingContext } from '../../../BrandingContext';
import { utilityFunctions } from '../../../../../../utilities/functions';
import { LineHeightIcon } from '../../../../../../icons/Ui/LineHeightIcon';
import Tooltip from '../../../../../../design-system/Tooltip/Tooltip';
import { InfoToolTipType } from '../../../../../../types/design-system';

const TypographyOptions: FunctionComponent<{
    label: string;
    tooltip: InfoToolTipType;
    fontCategory: FontCategoryType;
    identifier: TypographyElementIdentifierType;
    value: TypographyElementType;
}> = ({ label, tooltip, fontCategory, identifier, value }) => {
    const { changeAdvancedFont, form } = useContext(BrandingContext);
    return (
        <FieldBox
            className={
                'py-4 border-b border-solid border-goose-600 typography-' + identifier.subSectionKey
            }
            label={label}
            tooltip={tooltip}
        >
            <div className="flex items-center">
                <SelectField
                    className="w-full max-w-xs mr-5 select-font-family"
                    id=""
                    name=""
                    options={getFontsList(fontCategory)}
                    value={value.fontFamily}
                    onChange={(e): void =>
                        changeAdvancedFont(identifier, 'fontFamily', e.target.value)
                    }
                />

                <TextField
                    className="w-24 mr-1 font-size-field min-w-5"
                    type="number"
                    name=""
                    placeholder={'Font Size'}
                    value={utilityFunctions.pixelToInt(value.fontSize)}
                    onChange={(e): void =>
                        changeAdvancedFont(
                            identifier,
                            'fontSize',
                            utilityFunctions.valToPixel(e.target.value)
                        )
                    }
                    validation={{
                        key: `$fontSize_${label}`,
                        required: 'Field Required',
                        min: 0,
                        max: 70,
                    }}
                    form={form}
                />
                <span className="ml-1 mr-5">px</span>

                <SelectField
                    className="w-40 mr-5 select-font-weight"
                    id=""
                    name=""
                    options={fontWeight}
                    value={value.fontWeight}
                    onChange={(e): void =>
                        changeAdvancedFont(identifier, 'fontWeight', e.target.value)
                    }
                />

                <div className="mr-1">
                    <LineHeightIcon />
                </div>
                <TextField
                    className="w-24 mr-1 line-height-field"
                    type="number"
                    name=""
                    placeholder={'Line Height'}
                    value={value.lineHeight}
                    onChange={(e): void =>
                        changeAdvancedFont(identifier, 'lineHeight', e.target.value)
                    }
                    validation={{
                        key: `$lineHeight_${label}`,
                        required: 'Field Required',
                        min: 0,
                        max: 5,
                    }}
                    form={form}
                />
            </div>
        </FieldBox>
    );
};

export default TypographyOptions;
