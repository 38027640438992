import React, { FunctionComponent, useContext } from 'react';
import TypographyOptionsView from './TypographyOptions';
import {
    TypographySectionType,
    FontCategoryType,
    TypographySectionKeyType,
} from '../../TypographyConstant';
import { BrandingContext } from '../../../BrandingContext';
import { TypographyElementType } from '../TypographySectionConstant';

const TypographySectionAdvanced: FunctionComponent<{
    ItemList: TypographySectionType[];
    fontCategory: FontCategoryType;
    sectionKey: TypographySectionKeyType;
}> = ({ ItemList, fontCategory, sectionKey }) => {
    const { apiDocsBrandingState } = useContext(BrandingContext);

    const getOptionVal: (a: any, b: string) => TypographyElementType = (a, b) => {
        return a[b] as TypographyElementType;
    };
    return (
        <div>
            {ItemList.map((item) => (
                <div className="flex justify-between typograph-option-wrapper" key={item.label}>
                    <TypographyOptionsView
                        label={item.label}
                        tooltip={item.tooltip}
                        fontCategory={fontCategory}
                        identifier={{
                            sectionKey,
                            subSectionKey: item.key,
                        }}
                        value={getOptionVal(apiDocsBrandingState.typography[sectionKey], item.key)}
                    ></TypographyOptionsView>
                </div>
            ))}
        </div>
    );
};

export default TypographySectionAdvanced;
