import { apiClientSettings, getHeaders, fetcher } from './ApiClient';
import { HomePageStateDef } from '../components/api-docs/HomePage/HomePageIntialState';

const uri = `api-groups/{apiGroupId}/draft-portal-settings/home-page`;

const getHomePage = (): Promise<HomePageStateDef> => {
    return fetcher<HomePageStateDef>(uri, 'GET', getHeaders());
};

const updateHomePage = (body: HomePageStateDef) => {
    return fetcher<HomePageStateDef>(uri, 'PUT', getHeaders(), body);
};

const updateHomePageCoverImage = (formData: FormData) => {
    return fetcher<FormData>(`${uri}/cover-image`, 'PUT', getHeaders(true), formData, true);
};

const updateHomepageLogo = (formData: FormData) => {
    return fetcher<FormData>(uri + '/logo', 'PUT', getHeaders(true), formData, true);
};

const removeHomepageLogo = () => {
    return fetcher<null>(uri + '/logo', 'DELETE', getHeaders());
};

const updateToggleHomePage = (data: any) => {
    return fetcher<FormData>(`${uri}/enable`, 'PUT', getHeaders(), data);
};

export {
    getHomePage,
    updateHomePage,
    updateHomePageCoverImage,
    updateToggleHomePage,
    updateHomepageLogo,
    removeHomepageLogo,
};
