import React, { FunctionComponent } from 'react';
import { WebIcon } from '../../../../icons/Ui/PlatformIcons';
import { StoreProviderValue, useStore } from '../../../../store/storeContext';
import { utilityFunctions } from '../../../../utilities/functions';
import { platformConstants } from '../../Platforms/PlatformConstants';
import { PlatformsProviderValue, usePlatforms } from '../../Platforms/PlatformsContext';
import './PlatformsIcon.scss';

const PlatformsIcon: FunctionComponent = () => {
    const { storeState } = useStore() as StoreProviderValue;
    const {
        platformsState: { sdkPlatforms, http },
    } = usePlatforms() as PlatformsProviderValue;
    const filteredPlatformConstants = utilityFunctions.filterAllowedPlatform(
        platformConstants,
        storeState.subscription?.allowedPlatforms
    );

    const isAlphaRelease = (lang: string) => {
        return ['go'].includes(lang.toLocaleLowerCase());
    }

    return (
        <div className="flex justify-center my-8">
            {http.enabled && (
                <div className="flex flex-col items-center mx-6 icon-wrapper">
                    <WebIcon />
                    <p className="mt-4 text-center text-13 text-charcoal-800 content">HTTP</p>
                </div>
            )}
            {filteredPlatformConstants &&
                filteredPlatformConstants.map((p) => {
                    return (
                        sdkPlatforms &&
                        sdkPlatforms[p.templates[0].value] &&
                        sdkPlatforms[p.templates[0].value].platformEnabled && (
                            <div
                                key={p.value}
                                className="relative flex flex-col items-center mx-6 icon-wrapper"
                            >
                                {p.icon}
                                <p className="mt-4 text-center text-13 text-charcoal-800 content">
                                    {p.name}
                                </p>
                                {isAlphaRelease(p.value) && (
                                    <div className='absolute pre-release-tag'>ALPHA RELEASE</div>
                                )}
                            </div>
                        )
                    );
                })}
        </div>
    );
};

export default PlatformsIcon;
