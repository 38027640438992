import { HomePageStateDef } from './HomePageIntialState';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_LOGO_LINK = 'UPDATE_LOGO_LINK';
export const TOGGLE_HOMEPAGE = 'TOGGLE_HOMEPAGE';
export const CLEAR_LOGO_FAVICON_FILES = 'CLEAR_LOGO_FAVICON_FILES';
export const ADD_CONTENT_BOX = 'ADD_CONTENT_BOX';
export const UPDATED_CONTENT_BOX = 'UPDATED_CONTENT_BOX';
export const DELETE_CONTENT_BOX = 'DELETE_CONTENT_BOX';
export const UPDATE_JUMBOTRON = 'UPDATE_JUMBOTRON';
export const UPDATE_JUMBOTRON_IMAGE = 'UPDATE_JUMBOTRON_IMAGE';
export const ADD_BADGE = 'ADD_BADGE';
export const DELETE_BADGE = 'DELETE_BADGE';
export const REPLACE_BADGE = 'REPLACE_BADGE';
export const ON_BADGE_DRAG_END = 'ON_BADGE_DRAG_END';
export const UPDATE_COVER_IMAGE = 'UPDATE_COVER_IMAGE';
export const CHANGE_LOGO = 'CHANGE_LOGO';
export const UPDATE_LOGO_URI = 'UPDATE_LOGO_URI';
export const REMOVE_LOGO = 'REMOVE_LOGO';

export const ADD_NAV_LINK = 'ADD_NAV_LINK';
export const REMOVE_NAV_LINK = 'REMOVE_NAV_LINK';
export const ON_NAV_LINK_DRAG_END = 'NAV_LINK_ON_DRAG_END';

export const homePageReducer = (state: HomePageStateDef, action: any) => {
    switch (action.type) {
        case UPDATE_STATE:
            return {
                ...action.payload,
            };
            break;
        case UPDATE_LOGO_LINK:
            return {
                ...state,
                logoUrl: action.payload,
            };
            break;

        case TOGGLE_HOMEPAGE:
            return {
                ...state,
                enabled: action.payload,
            };
            break;

        case UPDATE_JUMBOTRON:
            return {
                ...state,
                title: action.payload.title,
                tagLine: action.payload.tagLine,
            };
            break;

        case UPDATE_JUMBOTRON_IMAGE:
            return {
                ...state,
                coverImage: action.payload.coverImage,
            };
            break;

        case UPDATE_COVER_IMAGE:
            return {
                ...state,
                coverImage: {
                    ...state.coverImage,
                    uri: action.payload.uri,
                },
            };
            break;

        case ADD_CONTENT_BOX:
            return {
                ...state,
                cards: [
                    ...state.cards,
                    {
                        title: 'Heading',
                        body: 'Your description here',
                    },
                ],
            };
            break;

        case UPDATED_CONTENT_BOX: {
            return {
                ...state,
                cards: [
                    ...state.cards.slice(0, action.payload.index),
                    {
                        ...action.payload.updatedCard,
                    },
                    ...state.cards.slice(action.payload.index + 1),
                ],
            };
            break;
        }

        case DELETE_CONTENT_BOX: {
            return {
                ...state,
                cards: [
                    ...state.cards.slice(0, action.payload.index),
                    ...state.cards.slice(action.payload.index + 1),
                ],
            };
            break;
        }
        case ADD_BADGE: {
            return {
                ...state,
                badges: [...state.badges, action.payload.newBadge],
            };
            break;
        }
        case DELETE_BADGE: {
            const index = state.badges.indexOf(action.payload.deletedBadge);
            return {
                ...state,
                badges: [...state.badges.slice(0, index), ...state.badges.slice(index + 1)],
            };
            break;
        }
        case REPLACE_BADGE: {
            const index = state.badges.indexOf(action.payload.oldBadge);
            console.log(state, index, action.payload.newBadge);
            return {
                ...state,
                badges: [
                    ...state.badges.slice(0, index),
                    action.payload.newBadge,
                    ...state.badges.slice(index + 1),
                ],
            };
            break;
        }
        case ON_BADGE_DRAG_END: {
            return {
                ...state,
                badges: action.payload.newBadges,
            };
            break;
        }

        case CHANGE_LOGO: {
            return {
                ...state,
                logo: {
                    ...state.logo,
                    file: action.payload.file,
                    delete: false,
                },
            };
        }

        case UPDATE_LOGO_URI: {
            return {
                ...state,
                logo: {
                    ...state.logo,
                    uri: action.payload.uri,
                },
            };
        }

        case REMOVE_LOGO: {
            return {
                ...state,
                logo: {
                    uri: null,
                    file: null,
                    delete: true,
                },
            };
        }

        case ADD_NAV_LINK:
            if (action.payload.index || action.payload.index === 0) {
                return {
                    ...state,
                    navigationLinks: [
                        ...state.navigationLinks.slice(0, action.payload.index),
                        { ...action.payload.item },
                        ...state.navigationLinks.slice(action.payload.index + 1),
                    ],
                };
            }
            return {
                ...state,
                navigationLinks: state.navigationLinks
                    ? [...state.navigationLinks, action.payload.item]
                    : [action.payload.item],
            };
            break;

        case REMOVE_NAV_LINK: {
            return {
                ...state,
                navigationLinks: [
                    ...state.navigationLinks.slice(0, action.payload.index),
                    ...state.navigationLinks.slice(action.payload.index + 1),
                ],
            };
            break;
        }

        case ON_NAV_LINK_DRAG_END:
            return {
                ...state,
                navigationLinks: action.payload,
            };
            break;

        case CLEAR_LOGO_FAVICON_FILES:
            return {
                ...state,
                logo: {
                    uri: state.logo?.uri,
                    file: null,
                    delete: false,
                },
            };
            break;

        default:
            return state;
            break;
    }
};
