import React, { FunctionComponent, useEffect } from 'react';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import CustomPage from '../../components/api-docs/CustomPage/CustomPage';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { CustomContentFileKeyForSidenav, SideNavActiveItemStoreKey } from '../ViewConstant';

const NewPageView: FunctionComponent = () => {
    return (
        <div className="w-full h-full">
            <CustomPage />
        </div>
    );
};

export default NewPageView;
