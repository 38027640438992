import React, { FunctionComponent } from 'react';
import './spinner.scss';

interface SpinnerProps {
    size: 's' | 'm' | 'l' | 'xl';
    className?: string;
}

const Spinner: FunctionComponent<SpinnerProps> = (props) => {
    return (
        <div
            className={
                'lds-dual-ring ' +
                (props.size ? props.size : 'm') +
                (props.className ? ' ' + props.className : '')
            }
        ></div>
    );
};

export default Spinner;
