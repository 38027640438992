import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ConsoleIllustration = (props: SVGIconProps) => (
    <SVGIcon
        width={props.width || '507'}
        height={props.height || '294'}
        viewBox={props.viewBox || '0 0 507 319'}
        {...props}
    >
        <rect
            id="Rounded_Rectangle_16_copy_17"
            data-name="Rounded Rectangle 16 copy 17"
            width="507"
            height="294"
            rx="3"
            transform="translate(0 25)"
            fill="#f5f7f9"
        />
        <g
            id="Rounded_Rectangle_16_copy_16"
            data-name="Rounded Rectangle 16 copy 16"
            transform="translate(28.137 72.032)"
            fill="#fff"
        >
            <path
                d="M 134.3584747314453 19.43109703063965 L 3.000000953674316 19.43109703063965 C 1.6214919090271 19.43109703063965 0.5000010132789612 18.30960655212402 0.5000010132789612 16.93109703063965 L 0.5000010132789612 3.000006914138794 C 0.5000010132789612 1.621497750282288 1.6214919090271 0.5000068545341492 3.000000953674316 0.5000068545341492 L 46.00696563720703 0.5000068545341492 L 88.92616271972656 0.5000068545341492 L 134.3584747314453 0.5000068545341492 C 135.7369842529297 0.5000068545341492 136.8584747314453 1.621497750282288 136.8584747314453 3.000006914138794 L 136.8584747314453 16.93109703063965 C 136.8584747314453 18.30960655212402 135.7369842529297 19.43109703063965 134.3584747314453 19.43109703063965 Z"
                stroke="none"
            />
            <path
                d="M 3 1.000007629394531 C 1.897201538085938 1.000007629394531 1 1.897207260131836 1 3.000007629394531 L 1 16.93109703063965 C 1 18.03389739990234 1.897201538085938 18.93109703063965 3 18.93109703063965 L 134.3584747314453 18.93109703063965 C 135.4612731933594 18.93109703063965 136.3584747314453 18.03389739990234 136.3584747314453 16.93109703063965 L 136.3584747314453 3.000007629394531 C 136.3584747314453 1.897207260131836 135.4612731933594 1.000007629394531 134.3584747314453 1.000007629394531 L 88.9261474609375 1.000007629394531 L 46.00696563720703 1.000007629394531 L 3 1.000007629394531 M 3 7.62939453125e-06 L 46.00696563720703 7.62939453125e-06 L 88.9261474609375 7.62939453125e-06 L 134.3584747314453 7.62939453125e-06 C 136.0153350830078 7.62939453125e-06 137.3584747314453 1.34315299987793 137.3584747314453 3.000007629394531 L 137.3584747314453 16.93109703063965 C 137.3584747314453 18.58795166015625 136.0153350830078 19.93109703063965 134.3584747314453 19.93109703063965 L 3 19.93109703063965 C 1.3431396484375 19.93109703063965 0 18.58795166015625 0 16.93109703063965 L 0 3.000007629394531 C 0 1.34315299987793 1.3431396484375 7.62939453125e-06 3 7.62939453125e-06 Z"
                stroke="none"
                fill="#d9dee5"
            />
        </g>
        <rect
            id="Rounded_Rectangle_16_copy_16-2"
            data-name="Rounded Rectangle 16 copy 16"
            width="137.358"
            height="19.931"
            rx="3"
            transform="translate(28.137 164.569)"
            fill="#fff"
            stroke="#d9dee5"
            strokeWidth="1"
        />
        <g
            id="Rounded_Rectangle_16_copy_16-3"
            data-name="Rounded Rectangle 16 copy 16"
            transform="translate(28.137 257.107)"
            fill="#fff"
            stroke="#d9dee5"
            strokeWidth="1"
        >
            <rect width="137.358" height="19.931" rx="3" stroke="none" />
            <rect x="0.5" y="0.5" width="136.358" height="18.931" rx="2.5" fill="none" />
        </g>
        <rect
            id="Rounded_Rectangle_16_copy_17-2"
            data-name="Rounded Rectangle 16 copy 17"
            width="56"
            height="8"
            rx="3"
            transform="translate(28 57)"
            fill="#d9dee5"
        />
        <rect
            id="Rounded_Rectangle_16_copy_17-3"
            data-name="Rounded Rectangle 16 copy 17"
            width="56"
            height="9"
            rx="3"
            transform="translate(28 149)"
            fill="#d9dee5"
        />
        <rect
            id="Rounded_Rectangle_16_copy_17-4"
            data-name="Rounded Rectangle 16 copy 17"
            width="56"
            height="8"
            rx="3"
            transform="translate(28 242)"
            fill="#d9dee5"
        />
        <text
            id="Parameters"
            transform="translate(90 17)"
            fill="#455666"
            fontSize="10"
            fontFamily="Heebo-Regular, Heebo"
        >
            <tspan x="0" y="0">
                PARAMETERS
            </tspan>
        </text>
        <rect
            id="Rectangle_2314"
            data-name="Rectangle 2314"
            width="79"
            height="28"
            rx="3"
            fill="#f5f7f9"
        />
        <text
            id="Console"
            transform="translate(14 17)"
            fill="#24313e"
            fontSize="10"
            fontFamily="Heebo-Medium, Heebo"
            fontWeight="500"
        >
            <tspan x="0" y="0">
                CONSOLE
            </tspan>
        </text>
    </SVGIcon>
);
