import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

const style = {
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
};

export const InfoIcon = (props: SVGIconProps) => (
    <SVGIcon width="22" height="22" viewBox="0 0 22 22" {...props} fill="none">
        <g transform="translate(23 23) rotate(180)">
            <circle
                {...style}
                stroke={props.fill || DEFAULT_SVG_COLOR}
                cx="10"
                cy="10"
                r="10"
                transform="translate(2 2)"
            />
            <line
                {...style}
                stroke={props.fill || DEFAULT_SVG_COLOR}
                y1="4"
                transform="translate(12 12)"
            />
            <line
                {...style}
                stroke={props.fill || DEFAULT_SVG_COLOR}
                x2="0.01"
                transform="translate(12 8)"
            />
        </g>
    </SVGIcon>
);
