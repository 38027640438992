import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const BrowserWindowIcon = (props: SVGIconProps) => (
    <SVGIcon
        viewBox={props.width || '0 0 15 12.5'}
        width={props.width || '15'}
        height={props.width || '12.5'}
        {...props}
    >
        <path
            d="M0,2.5A1.5,1.5,0,0,1,1.5,1h12A1.5,1.5,0,0,1,15,2.5V12a1.5,1.5,0,0,1-1.5,1.5H1.5A1.5,1.5,0,0,1,0,12ZM1.5,4v8h12V4Z"
            transform="translate(0 -1)"
        />
    </SVGIcon>
);
