import React, { FunctionComponent } from 'react';
import './CallOut.scss';

interface CallOutProps {
    type?: 'info';
    children: any;
}

const CallOut: FunctionComponent<CallOutProps> = (props) => {
    const { type, children } = props;

    const getClass = () => {
        if (!type || type === 'info') {
            return 'border-skin-600 bg-skin-300';
        }

        return '';
    };

    return <div className={`text-12 p-4 border-l-4 text-brown rounded-6 callout ${getClass()}`}>{children}</div>;
};

export default CallOut;
