import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './Tooltip.scss';

export interface TooltipProps {
    message: string;
    duration?: number;
    position?: 'top' | 'bottom' | 'left' | 'right';
    event?: 'click' | 'hover';
    children: any;
    displayNone?: boolean;
}

/***
 * Title Element
 * @param {string} message message to show in tooltip
 * @param {number} duration default 2000 : tooltip duration in ms for click event only
 * @param {'top' | 'bottom' | 'left' | 'right'} position default top : tooltip position wrt element
 * @param {'click' | 'hover'} event default 'hover' : show tooltip on hover or click
 * @param {string} children inner html content
 ***/

const TOOLTIP_GAP = 4;
const Tooltip: FunctionComponent<TooltipProps> = ({
    message,
    position,
    duration,
    children,
    event,
    displayNone,
}) => {
    const tooltip = document.getElementById('tooltip-portal');

    event = event ? event : 'hover';
    const [displayTooltip, setDisplayTooltip] = useState(false);

    const _handleMouseLeave = () => {
        if (event === 'hover') {
            setDisplayTooltip(false);
        }
    };

    const _handleMouseOver = () => {
        if (event === 'hover') {
            setDisplayTooltip(true);
        }
    };

    const _handleClick = () => {
        setDisplayTooltip(true);
        if (event === 'click') {
            setTimeout(
                () => {
                    setDisplayTooltip(false);
                },
                duration ? duration : 2000
            );
        }
    };

    const ref = useRef<HTMLSpanElement>(null);

    const [pos, setPos] = useState<{
        top: number;
        left: number;
        // offsetAnchor: string;
    }>();

    useEffect(() => {
        if (displayTooltip) {
            const boundingBox = ref.current?.getBoundingClientRect();
            if (boundingBox) {
                let x = 0,
                    y = 0;

                if (position === 'bottom') {
                    x = boundingBox.width / 2 + TOOLTIP_GAP;
                    y = boundingBox.height + TOOLTIP_GAP;
                } else if (position === 'left') {
                    x = -TOOLTIP_GAP;
                    y = boundingBox.height / 2;
                } else if (position === 'right') {
                    x = boundingBox.width + TOOLTIP_GAP;
                    y = boundingBox.height / 2;
                } else if (!position || position === 'top') {
                    x = boundingBox.width / 2;
                    y = -TOOLTIP_GAP;
                }

                setPos({
                    top: boundingBox.top + y,
                    left: boundingBox.left + x,
                });
            }
        }
    }, [displayTooltip]);

    return (
        <div className="tooltip" onMouseLeave={_handleMouseLeave} style={{ width: 'fit-content' }}>
            {displayTooltip &&
                tooltip &&
                pos &&
                ReactDOM.createPortal(
                    <div
                        className={`tooltip-bubble tooltip-${position ? position : 'top'}`}
                        style={pos}
                    >
                        <div className="tooltip-message">{message}</div>
                    </div>,
                    tooltip
                )}
            <span
                className="tooltip-trigger"
                onClick={displayNone ? () => {} : _handleClick}
                onMouseOver={displayNone ? () => {} : _handleMouseOver}
                ref={ref}
            >
                {children}
            </span>
        </div>
    );
};

export default Tooltip;
