import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const SidebarIllustration2 = (props: SVGIconProps) => (
    <SVGIcon width="141" height="69.39" viewBox="0 0 141 69.39" {...props}>
        <g id="Group_28" data-name="Group 28" transform="translate(-310 -845.03)">
            <rect
                id="Rounded_Rectangle_16_copy_25"
                data-name="Rounded Rectangle 16 copy 25"
                width="81"
                height="17.04"
                rx="3"
                transform="translate(310 845.03)"
                fill="#d9dee5"
            />
            <rect
                id="Rounded_Rectangle_16_copy_25-2"
                data-name="Rounded Rectangle 16 copy 25"
                width="81"
                height="17.04"
                rx="3"
                transform="translate(310 872.04)"
                fill="#d9dee5"
            />
            <rect
                id="Rounded_Rectangle_16_copy_25-3"
                data-name="Rounded Rectangle 16 copy 25"
                width="141"
                height="17.04"
                rx="3"
                transform="translate(310 897.38)"
                fill="#d9dee5"
            />
        </g>
    </SVGIcon>
);
