import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const ChevronIcon = (props: SVGIconProps) => (
    <SVGIcon viewBox="0 0 24 24" width="12px" {...props} fill="none">
        <path
            stroke={props.fill || DEFAULT_SVG_COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M19 9l-7 7-7-7"
        />
    </SVGIcon>
);
