import { getHeaders, fetcher } from './ApiClient';

export interface ApiConsoleOptionsResponseDef {
    consoleCalls: boolean;
    proxy: boolean;
}

const getApiConsoleOptions = (apiEntityId: string): Promise<ApiConsoleOptionsResponseDef> => {
    const uri = `api-entities/${apiEntityId}/draft-portal-settings/api-console-options`;

    return fetcher<ApiConsoleOptionsResponseDef>(uri, 'GET', getHeaders());
};

const updateApiConsoleOptions = (apiEntityId: string, body: ApiConsoleOptionsResponseDef) => {
    const uri = `api-entities/${apiEntityId}/draft-portal-settings/api-console-options`;

    return fetcher<ApiConsoleOptionsResponseDef>(uri, 'PUT', getHeaders(), body);
};

export { updateApiConsoleOptions, getApiConsoleOptions };
