import React, { FunctionComponent } from 'react';

import './publishing-sdk-card.scss';
import { Button } from '../../../design-system';
import { environment } from '../../../enviroment';

export const PublishSDKCard: FunctionComponent<{ classes?: string }> = ({
    classes = 'border-goose-600 border-solid border',
}) => {
    const localState = localStorage.getItem('portalEditorSettings');
    const settings = localState && JSON.parse(localState);

    const { apiGroupId, defaultApiEntityId } = settings;

    const PACKAGE_PUBLISHING_REDIRECT_URL = `${environment}/publish/${apiGroupId}/publish-now?api_version=${defaultApiEntityId}`;

    return (
        <div
            className={`publishing-sdk-card flex flex-col mb-5 w-full bg-white p-6 rounded-12 ${classes}`}
        >
            <div className="publishing-sdk flex flex-col">
                <div>
                    <h3 className="font-medium">Publish</h3>
                    <p>
                        You must Publish your SDKs so developers can use them to integrate with your
                        API.
                    </p>
                    <p className="font-medium ">
                        You can Publish your SDKs with APIMatic in 3 simple steps:
                    </p>
                    <ul className="publishing-sdk-ul">
                        <li>
                            <span>Connect</span> to a package registry
                        </li>
                        <li>
                            Provide your <span>package configurations</span>
                        </li>
                        <li>
                            Hit <span>publish</span>
                        </li>
                    </ul>
                    <p>
                        Once published, you can add these packages to your Portal with the click of
                        a button.
                    </p>
                </div>
                <div className="flex mt-3 flex-row justify-end">
                    <a href={PACKAGE_PUBLISHING_REDIRECT_URL} target="_blank" rel="noreferrer">
                        <Button text="Publish SDKs" fill="primary" />
                    </a>
                </div>
            </div>
        </div>
    );
};
