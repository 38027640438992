import { fontFamilyList } from './FontListConstant';
import { FontCategoryType } from '../TypographyConstant';
import { headingDefaultFont, codeDefaultFont } from '../../BrandingInitialState';

export type TypographyHeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type TypographyBodyType = 'text1' | 'text2' | 'text3';
export type TypographyCodingType = 'blockCode' | 'inlineCode';
export type TypographyElementProperty =
    | 'fontFamily'
    | 'fontSize'
    | 'fontWeight'
    | 'fontStyle'
    | 'lineHeight';

export type TypographyElementIdentifierType =
    | { sectionKey: 'heading'; subSectionKey: TypographyHeadingType }
    | { sectionKey: 'body'; subSectionKey: TypographyBodyType }
    | { sectionKey: 'code'; subSectionKey: TypographyCodingType };

export interface TypographyElementType {
    fontFamily: string;
    fontSize: string;
    fontWeight: number;
    fontStyle: string;
    lineHeight: string;
}

export type TypographyType = {
    heading: Record<TypographyHeadingType, TypographyElementType> & Record<'fontFamily', string>;
    body: Record<TypographyBodyType, TypographyElementType> & Record<'fontFamily', string>;
    code: Record<TypographyCodingType, TypographyElementType> & Record<'fontFamily', string>;
};

export const getFontsList = (category: FontCategoryType) => {
    const isCategory = category === 'sans-serif';

    const defaultFonts = isCategory
        ? { text: 'Rubik (Default)', value: headingDefaultFont }
        : { text: 'Courier Prime (Default)', value: codeDefaultFont };

    const filteredFonts = fontFamilyList.filter((elem) => elem.category === category);

    return [defaultFonts, ...filteredFonts];
};

export const fontWeight = [
    {
        text: 'Bold',
        value: 700,
    },
    {
        text: 'Semi Bold',
        value: 600,
    },
    {
        text: 'Medium',
        value: 500,
    },
    {
        text: 'Normal',
        value: 400,
    },
    {
        text: 'Lighter',
        value: 300,
    },
];
