import React, { FunctionComponent } from 'react';
import './card.scss';

interface CardProps {
    className?: string;
}

const Card: FunctionComponent<CardProps> = ({ className, children }) => {
    return (
        <div className={`w-full bg-white p-6 card-wrapper rounded-12 border-goose-600 border-solid border ${className}`}>{children}</div>
    );
};

export default Card;
