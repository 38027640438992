import React, { FunctionComponent, useContext, Fragment } from 'react';
import { FieldBox, Button } from '../../../../design-system';
import { BrandingContext } from '../BrandingContext';
import { FileType } from './LogoConstant';
import './Logo.scss';
import { TrashIcon } from '../../../../icons/Ui/TrashIcon';
import FileSelector from '../../../../design-system/FileSelector/FileSelector';

const Logo: FunctionComponent = () => {
    const {
        apiDocsBrandingState,
        changeLogo,
        changeFavicon,
        updateLogoUri,
        updateFaviconUri,
        removeLogo,
        removeFavicon,
    } = useContext(BrandingContext);

    const _handleLogoSelected = (files: FileType) => {
        if (files) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = function (e) {
                updateLogoUri(reader.result as string);
                changeLogo(files);
            };
        }
    };

    const _handleFaviconSelected = (files: FileType) => {
        if (files) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = function (e) {
                updateFaviconUri(reader.result as string);
                changeFavicon(files);
            };
        }
    };

    return (
        <div className="flex space-around logo-wrapper">
            {/* <FieldBox className="mr-8" label="Logo">
                <>
                    {apiDocsBrandingState.logo?.uri && (
                        <div className="relative flex justify-center mb-5 overflow-hidden border border-solid image-preview border-goose-500 rounded-12 align-center" >
                            <Button
                                className="absolute top-0 right-0 mt-3 mr-3"
                                text=""
                                fill="default"
                                icon={<TrashIcon width="12" height="12" fill="#455666" />}
                                onClick={removeLogo}

                            />
                            <img className="self-center" src={apiDocsBrandingState.logo.uri} alt="" />
                        </div>
                    )}

                    <FileSelector
                        className="file-button"
                        text='Upload'
                        fill="default"
                        file={apiDocsBrandingState.logo.file}
                        onChange={_handleLogoSelected} />
                </>
            </FieldBox> */}
            <FieldBox className="mt-0" label="Favicon">
                <>
                    {apiDocsBrandingState.favicon?.uri && (
                        <div className="relative flex justify-center mb-5 overflow-hidden border border-solid image-preview border-goose-500 rounded-12 align-center">
                            <Button
                                className="absolute top-0 right-0 mt-3 mr-3"
                                text=""
                                fill="default"
                                icon={<TrashIcon width="12" height="12" fill="#455666" />}
                                onClick={removeFavicon}
                            />
                            <img
                                className="self-center"
                                alt=""
                                src={apiDocsBrandingState.favicon.uri}
                            />
                        </div>
                    )}
                    <FileSelector
                        className="file-button"
                        text="Upload"
                        fill="default"
                        file={apiDocsBrandingState.favicon.file}
                        onChange={_handleFaviconSelected}
                    />
                </>
            </FieldBox>
        </div>
    );
};

export default Logo;
