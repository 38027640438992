import React, {
    FunctionComponent,
    createContext,
    useReducer,
    ReactNode,
    useContext,
    useState,
    useEffect,
} from 'react';
import { UiStateDef } from '../../../types/ui-state';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { utilityFunctions } from '../../../utilities/functions';
import { getAllowedPlatform } from '../../../api-client/SubscriptionService';
import { SubscriptionReducer } from './SubscriptionReducer';
import { SubscriptionDef, subscriptionInitialState } from './SubscriptionInitialState';

/***
 * Sample context implementation with typescript
 ***/

export interface SubscriptionProviderProps {
    children: ReactNode;
}

export interface SubscriptionProviderValue {
    subscriptionState: SubscriptionDef;
    uiState: UiStateDef;
}

export type SubscriptionContextValue = undefined | SubscriptionProviderValue;

export const SubscriptionContext = createContext<SubscriptionContextValue>(undefined);

const SubscriptionProvider: FunctionComponent<SubscriptionProviderProps> = ({ children }) => {
    const [subscriptionState, dispatch] = useReducer(SubscriptionReducer, subscriptionInitialState);
    const [uiState, setUiState] = useState<UiStateDef>({
        isLoading: true,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    });

    const {
        updateStore,
        storeState: { subscription },
    } = useStore() as StoreProviderValue;

    useEffect(() => {
        setUiState(utilityFunctions.getLoadingUiState());
        const cacheData = subscription;
        if (cacheData) {
            setUiState(utilityFunctions.getDefaultUiState());
            return updateSubscriptionState(cacheData);
        }

        const promise = getAllowedPlatform();
        promise
            .then((resp) => {
                setUiState(utilityFunctions.getDefaultUiState());
                updateSubscriptionState(resp);
                updateStore(resp, 'subscription');
            })
            .catch((e: Response) => {
                setUiState({
                    isLoading: false,
                    isError: true,
                    isSaving: false,
                    statusCode: e.status,
                    message: e.statusText,
                });
            });
    }, []);

    const updateSubscriptionState = (newState: SubscriptionDef) => {
        dispatch({
            action: 'UPDATE_SUBSCRIPTION_STATE',
            payload: newState,
        });
    };

    const value = {
        subscriptionState,
        uiState,
    };
    return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>;
};

const useSubscription = () => useContext(SubscriptionContext);

export { SubscriptionProvider, useSubscription };

/***
 * context usage
 * const { onInputChange, onSdkDownloadOptionChange } = usePlatforms() as PlatformsProviderValue;
 ***/
