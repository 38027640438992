import React, { FunctionComponent } from 'react';

interface FieldErrorTextProps {
    error: string;
    type?: 'error' | 'warning';
    className?: string;
}

const FieldErrorText: FunctionComponent<FieldErrorTextProps> = ({ error }) => {
    return <p className={`text-red text-13 ${error && 'm-1'}`}>{error}</p>;
};

export default FieldErrorText;
