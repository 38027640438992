import React, { FunctionComponent, useState } from 'react';
import { Button } from '../../../../design-system';
import { HomePageProviderValue, useHomePage } from '../HomePageContext';
import LinkEditorCard from './LinkEditor/LinkEditor';
import NavigationLinkItem from './NavigationItem';

const NavigationLinks: FunctionComponent = () => {
    const [toggleAddNavigation, setToggleAddNavigation] = useState(false);

    const {
        homePageState: { navigationLinks },
        addNavLink,
    } = useHomePage() as HomePageProviderValue;

    return (
        <div className="flex w-full">
            <div className="flex" style={{ marginLeft: 'auto' }}>
                {navigationLinks?.map((link, index) => (
                    <NavigationLinkItem key={link.name + index} link={link} index={index} />
                ))}
                {(!navigationLinks || navigationLinks.length < 3) && (
                    <div className="relative">
                        <Button
                            fill="link"
                            text="+ ADD LINK"
                            onClick={() => setToggleAddNavigation(!toggleAddNavigation)}
                        />
                        {toggleAddNavigation && (
                            <LinkEditorCard
                                onSave={(item) => {
                                    addNavLink(item);
                                    setToggleAddNavigation(!toggleAddNavigation);
                                }}
                                onCancel={() => setToggleAddNavigation(!toggleAddNavigation)}
                                onDelete={() => {}}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NavigationLinks;
