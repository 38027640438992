import React, { FunctionComponent } from 'react';
import Spinner from '../../../design-system/Spinner/spinner';
import { useApiDocsBranding } from '../Branding/BrandingContext';
import { ApiDocsBrandingContextValue } from '../Branding/BrandingTypes';
import { useHomePage, HomePageProviderValue } from '../HomePage/HomePageContext';

const Header: FunctionComponent = () => {
    const { homePageState, uiState: homePageUiState } = useHomePage() as HomePageProviderValue;
    const { apiDocsBrandingState } = useApiDocsBranding() as ApiDocsBrandingContextValue;

    return (
        <div
            className="flex flex-col border-0 border-b-4 border-solid"
            style={{ borderColor: apiDocsBrandingState.colors.cta }}
        >
            <div className="flex justify-between px-4 header">
                {homePageUiState.isLoading ? (
                    <Spinner size="m" className="mx-auto " />
                ) : (
                    <>
                        <div className="flex items-center logo-wrapper">
                            {homePageState.logo?.uri && <img alt="" src={homePageState.logo.uri} />}
                            <span className="mx-2">|</span>
                            <p className=" text-14 text-charcoal-900">{homePageState.title}</p>
                        </div>
                        <div className="flex items-center navigation">
                            <ul className="flex">
                                {homePageState.navigationLinks.map((link) => (
                                    <a key={link.name} href={link.url} className="mx-2 text-14">
                                        <li>{link.name}</li>
                                    </a>
                                ))}
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Header;
