import React, { FunctionComponent } from 'react';
import { Button } from '../../design-system';
import { InfoIcon } from '../../icons/Ui/InfoIcon';
import { StoreProviderValue, useStore } from '../../store/storeContext';
import { useNavigate } from 'react-router';
import { CloseIconFilled } from '../../icons/Ui/CloseIcon';
import { PublishSDKCard } from '../api-docs/PubliskSDKCard/PublishSDKCard';

export interface InfoIconWrapperProps {
    fill: string;
    iconFill: string;
}
export const InfoIconWrapper: FunctionComponent<InfoIconWrapperProps> = ({ fill, iconFill }) => {
    return (
        <div className={`flex items-center justify-center w-10 h-10 ${fill} rounded-6`}>
            <InfoIcon fill={iconFill} />
        </div>
    );
};

export const PublishSuccessInfo: FunctionComponent<{
    selectedVersions: Record<string, boolean>;
    status: Record<string, boolean>;
}> = ({ selectedVersions, status }) => {
    const { storeState } = useStore() as StoreProviderValue;

    const isAnySuccess = () => {
        const isTrue = Object.values(status).findIndex((e) => e === true) !== -1;
        return isTrue;
    };

    return (
        <div className="flex flex-col">
            {!isAnySuccess() && (
                <>
                    <div className="flex items-center mb-4 font-medium text-red text-22">
                        <CloseIconFilled fill="#FF4451" className="mr-2"></CloseIconFilled>
                        <div>Error</div>
                    </div>
                    <div className="mb-8 text-14 text-charcoal-800">
                        Something went wrong. Please go back and try publishing it again.
                    </div>
                </>
            )}
            {isAnySuccess() && (
                <>
                    <div className="flex items-center mb-4 widget-body ">
                        <InfoIconWrapper fill="bg-apimatic-blue" iconFill="#fff" />
                        <div className="flex flex-col px-3">
                            <p className="mb-1 text-base font-medium text-charcoal-900">
                                On its way!
                            </p>
                        </div>
                    </div>
                    <div className="mb-8 text-14 text-charcoal-800">
                        Sit back and relax while we publish your portal. You should get a
                        notification in your inbox, shortly.
                    </div>
                </>
            )}

            {/* <p className="mb-8 text-base">
                Sit back and relax while we publish your portal. You should get a notification in
                your inbox, shortly.
            </p> */}
            <table className="w-full text-left text-14 version-selector">
                <thead className="border-b border-solid text-goose-700 border-goose-600">
                    <tr>
                        <th className="pb-2 font-medium" style={{ width: '22%' }}>
                            Version
                        </th>
                        <th className="w-full pb-2 font-medium">Status</th>
                    </tr>
                </thead>
                <tbody className="text-charcoal-800">
                    {storeState.settings?.apiEntities.map((apiEntity) => {
                        const isPublishing =
                            selectedVersions.hasOwnProperty(apiEntity.id) &&
                            selectedVersions[apiEntity.id];
                        const isSuccess = status[apiEntity.id];

                        return !isPublishing ? (
                            <></>
                        ) : (
                            <tr key={apiEntity.version}>
                                <td>
                                    <div className="py-2">v {apiEntity.version}</div>
                                </td>
                                <td>
                                    <div className="flex items-center py-2 ">
                                        {isSuccess === true && (
                                            <span className="text-charcoal-900">
                                                Publishing in progress
                                            </span>
                                        )}
                                        {!isSuccess && (
                                            <span className="flex items-center text-red">
                                                Publishing failed
                                                <InfoIcon
                                                    fill="#FF4451"
                                                    className="pl-2"
                                                    width="20px"
                                                ></InfoIcon>
                                            </span>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const PublishSuccess: FunctionComponent<{
    selectedVersions: Record<string, boolean>;
    status: Record<string, boolean>;
}> = ({ selectedVersions, status }) => {
    const { storeState } = useStore() as StoreProviderValue;

    const navigate = useNavigate();

    const close = () => {
        navigate(
            storeState.previousView
                ? storeState.previousView.split('#')[1]
                : './../../portal-settings/home-page'
        );
    };

    return (
        <>
            <div className="px-12 border border-solid py-7 rounded-6 border-goose-600 mb-4">
                <div className="widget-content">
                    <PublishSuccessInfo status={status} selectedVersions={selectedVersions} />
                    <div className="flex justify-end pt-3 mt-5 widget-footer ">
                        <Button text="Back to Project" fill="transparent" onClick={close} />
                    </div>
                </div>
            </div>
            <PublishSDKCard />
        </>
    );
};

export default PublishSuccess;
