import React, {
  FunctionComponent,
  useContext,
  createContext,
  useState,
  useEffect,
  useReducer,
} from 'react';
import { UiStateDef } from '../../../types/ui-state';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { FormValidatorDef } from '../../../types/form-validator';
import { PublishedPortalStateDef } from './PublishedPortalTypes';
import { getPublishedPortal, updatePublishedPortal } from '../../../api-client/PublishingService';
import { publishedPortalStoreKey } from './PublishedPortalConstant';
import {
  PublishedPortalReducer,
  UPDATE_PUBLISH_PORTAL_STATE,
  UPDATE_PUBLISH_STATUS,
  UPDATE_SLUG,
  UPDATE_UNPUBLISH_STATUS,
} from './PublishedPortalReducer';
import {
  NotificationProviderValue,
  useNotification,
} from '../../Notification/NotificationBarContext';
import { unpublishApiDocs } from '../../../api-client/ApiDocsPublishService';
import { ApiEntityDef } from '../../../types/portal-editor-settings';

export interface PublishedPortalProviderValue {
  publishedPortalState: PublishedPortalStateDef;
  slugValidationState: string;
  uiState: UiStateDef;
  loadingState?: UiStateDef;
  form: {
    state: FormValidatorDef;
    setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
  };
  saveData: (newPublishedPortalState: PublishedPortalStateDef) => void;
  updateSlug: (newSlug: string) => void;
  updatePublishStatus: (newStatus: string) => void;
  updateUnpublishStatus: (newStatus: string) => void;
  fetchPublishPortalData: (hardReload?: true) => void;
}

export type PublishedPortalContextValue = undefined | PublishedPortalProviderValue;

const PublishedPortalContext = createContext<PublishedPortalContextValue>(undefined);

const PublishedPortalProvider: FunctionComponent = ({ children }) => {
  const publishedPortalInitialState: PublishedPortalStateDef = {
    slug: '',
    status: {},
    pendingUpdates: {},
    portalPublished: false,
  };
  const [publishedPortalState, dispatch] = useReducer(
    PublishedPortalReducer,
    publishedPortalInitialState
  );
  const { updateStoreApiGroup, storeState } = useStore() as StoreProviderValue;
  const { addNotification } = useNotification() as NotificationProviderValue;
  const [slugValidationState, setSlugValidationState] = useState('');

  const [uiState, setUiState] = useState({
    isLoading: true,
    isError: false,
    statusCode: 0,
    message: '',
  });

  const [loadingState, setLoadingState] = useState(false);

  const [formValidator, setFormValidator] = useState<FormValidatorDef>({
    isSubmitted: false,
    errors: {},
  });

  const fetchPublishPortalData = (hardReload?: true) => {
    const cacheData = storeState.apiGroup.publishedPortal;

    if (cacheData && !hardReload) {
      setUiState({
        ...uiState,
        isLoading: false,
      });
      return updatePublishPortalSate(cacheData);
    } else {
      setLoadingState(true);
    }

    const promise = getPublishedPortal(storeState.settings!.apiGroupId);
    promise
      .then((resp: PublishedPortalStateDef) => {
        setUiState({
          ...uiState,
          isLoading: false,
        });
        updatePublishPortalSate(resp);
        updateStoreApiGroup(resp, publishedPortalStoreKey);
        setLoadingState(false);
      })
      .catch((e: Response) => {
        updateStoreApiGroup(publishedPortalState, publishedPortalStoreKey);
        setLoadingState(false);

        setUiState({
          isLoading: false,
          isError: true,
          statusCode: e.status,
          message: e.statusText,
        });
      });
  };

  useEffect(() => {
    fetchPublishPortalData();
  }, []);

  const saveData = (newPublishedPortalState: PublishedPortalStateDef) => {
    setSlugValidationState('');
    const promise = updatePublishedPortal(newPublishedPortalState, storeState.settings!.apiGroupId);
    promise
      .then((resp) => {
        console.log('UPDATE PORTAL', newPublishedPortalState);
        updatePublishPortalSate(newPublishedPortalState);
        updateStoreApiGroup(newPublishedPortalState, publishedPortalStoreKey);
      })
      .catch((e: Response) => {
        if (e.status === 409) {
          setSlugValidationState('Sorry! This slug is already taken.');
        } else {
          addNotification({
            show: true,
            type: 'error',
            message: `Oops! ${e.statusText ? e.statusText : 'Something went Wrong.'} - (${
              e.status
            })`,
          });
        }
      });
  };

  const unpublishPortal = (apiEntity: ApiEntityDef) => {
    const promise = unpublishApiDocs(apiEntity.id);
    const newPublishedPortalState = {
      ...publishedPortalState,
      status: {
        ...publishedPortalState.status,
        [publishedPortalState.payload]: false,
      },
    };
    promise
      .then(() => {
        addNotification({
          show: true,
          type: 'success',
          message: `${apiEntity.name} - ${apiEntity.version} portal unpublished successfully.`,
        });
        updatePublishPortalSate(newPublishedPortalState);
        updateStoreApiGroup(newPublishedPortalState, publishedPortalStoreKey);
      })
      .catch((e: Response) =>
        addNotification({
          show: true,
          type: 'error',
          message: `Failed to unpublish portal ${apiEntity.name} - ${apiEntity.version}. (${e.status})`,
        })
      );
  };

  const updatePublishPortalSate = (publishedPortalState: PublishedPortalStateDef): void => {
    dispatch({
      type: UPDATE_PUBLISH_PORTAL_STATE,
      payload: publishedPortalState,
    });
  };

  const updateSlug = (newSlug: string): void => {
    dispatch({
      type: UPDATE_SLUG,
      payload: newSlug,
    });
  };

  const updateUnpublishStatus = (apiEntityId: string): void => {
    dispatch({
      type: UPDATE_UNPUBLISH_STATUS,
      payload: apiEntityId,
    });
  };

  const updatePublishStatus = (apiEntityId: string): void => {
    dispatch({
      type: UPDATE_PUBLISH_STATUS,
      payload: apiEntityId,
    });
  };

  const value = {
    publishedPortalState,
    slugValidationState,
    uiState,
    loadingState,
    form: {
      state: formValidator,
      setState: setFormValidator,
    },
    saveData,
    updateSlug,
    updateUnpublishStatus,
    updatePublishStatus,
    fetchPublishPortalData,
  };

  return (
    <PublishedPortalContext.Provider value={value}>{children}</PublishedPortalContext.Provider>
  );
};

const usePublishedPortal = () => useContext(PublishedPortalContext);

export { PublishedPortalProvider, usePublishedPortal };
