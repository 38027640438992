import React, {
    createContext,
    FunctionComponent,
    useReducer,
    useCallback,
    useEffect,
    useState,
    useContext,
    ReactNode,
} from 'react';
import {
    ADD_SECTION,
    ADD_FILE,
    RENAME_SECTION,
    RENAME_FILE,
    DELETE_SECTION,
    CustomContentContextValue,
    TocType,
    UPDATE_TOC,
    DELETE_FILE,
    UPDATE_STATE,
    GeneratedContentType,
    DELETE_GENERATED_CONTENT,
    RENAME_GENERATED_CONTENT,
    ToCFileType,
    FilesToBeRenamedType,
    HANDLE_DROP_END_OF_TOC_ITEMS,
    DISABLE_GENERATED_CONTENT,
} from './CustomContentTypes';
import { customContentReducer } from './reducer/CustomContentReducer';
import {
    transformFileName,
    transformGroupName,
    EMPTY_SECTION_ERROR,
    EMPTY_FILE_NAME_ERROR,
    CustomContentStoreKey,
    getUsedGeneratedContentList,
    getAllGeneratedContentList,
    EMPTY_GENERATED_CONTENT_ERROR,
    getSectionFromPath,
    findSectionIndex,
    findFileIndex,
    findGeneratedContentIndex,
    getUsedGeneratedContentItems,
    findSlugIndex,
    DUPLICATE_SLUG_ERROR,
} from './CustomContentConstant';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { customContentService } from '../../api-client/CustomContentService';
import { utilityFunctions } from '../../utilities/functions';
import { v4 as uuidv4 } from 'uuid';

const defaultCustomContentValue: TocType = [];

export interface CustomContentProviderProps {
    children: ReactNode;
}

export const CustomContentContext = createContext<undefined | CustomContentContextValue>(undefined);

const CustomContentProvider: FunctionComponent<CustomContentProviderProps> = ({ children }) => {
    // TODO: make this a part of main state and update it in reducer
    // this has to be done to ensure a single call after toc update
    // This is to create a new empty file
    const [newFile, setNewFile] = useState<ToCFileType>();
    const [isSaving, setIsSaving] = useState(false);
    const [fileToBeRenamed, setFileToBeRenamed] = useState<FilesToBeRenamedType[]>([]);

    const [fileToBeDeleted, setFileToBeDeleted] = useState<string[]>([]);
    const [activeApiEntityChanged, setActiveApiEntityChanged] = useState<boolean>(false);

    const [tableOfContents, dispatch] = useReducer(customContentReducer, defaultCustomContentValue);

    const [uiState, setUiState] = useState(utilityFunctions.getLoadingUiState());

    const {
        updateStoreApiEntity,
        storeState: { activeApiEntityId, apiEntities },
    } = useStore() as StoreProviderValue;

    useEffect(() => {
        setActiveApiEntityChanged(true);
        setUiState(utilityFunctions.getLoadingUiState());

        const cacheData =
            Object.keys(apiEntities).includes(activeApiEntityId) &&
            apiEntities[activeApiEntityId].customContent;

        if (cacheData) {
            updateState(cacheData);
            return setUiState(utilityFunctions.getDefaultUiState());
        }

        const promise = customContentService(activeApiEntityId).getCustomContent();
        promise
            .then((resp: { tableOfContents: string; tableOfContentsFrontEnd: string }) => {
                const toc = resp.tableOfContentsFrontEnd
                    ? resp.tableOfContentsFrontEnd
                    : resp.tableOfContents;
                let respData = JSON.parse(toc).toc as TocType;
                setActiveApiEntityChanged(false);
                updateState(respData);
                updateStoreApiEntity(respData, CustomContentStoreKey, activeApiEntityId);

                setUiState(utilityFunctions.getDefaultUiState());
            })
            .catch((e: Response) => {
                setUiState({
                    isLoading: false,
                    isError: true,
                    statusCode: e.status,
                    message: e.statusText,
                });
            });
    }, [activeApiEntityId]);

    useEffect(() => {
        // TODO: Remove This and all related redundent code.
        // const usedGCL = getUsedGeneratedContentList(tableOfContents);
        // const gcl = getAllGeneratedContentList().map((gcl) => {
        //     const isUsed = usedGCL.findIndex((item) => item === gcl.from);
        //     const newGCLItem = { ...gcl, disabled: isUsed === -1 ? false : true };
        //     return newGCLItem;
        // });
        // setGeneratedContentList(gcl);

        // Save changes to db on every state change
        // except when ui is still in loading phase
        if (uiState.isLoading === false) {
            // console.log('Hello', activeApiEntityChanged, uiState.isLoading);
            if (activeApiEntityChanged) {
                setActiveApiEntityChanged(false);
                return;
            }

            setIsSaving(true);
            const promise = customContentService(activeApiEntityId).updateCustomContent(
                tableOfContents
            );
            promise
                .then((resp) => {
                    setIsSaving(false);
                    updateStoreApiEntity(tableOfContents, CustomContentStoreKey, activeApiEntityId);
                })
                .catch(() => {
                    setIsSaving(false);
                });
        }
    }, [tableOfContents]);

    useEffect(() => {
        if (newFile) {
            const fileDetails = newFile;
            setNewFile(undefined);
            let promise = customContentService(activeApiEntityId).updateCustomContentMarkDownFile(
                tableOfContents,
                {
                    file: fileDetails.file,
                    newName: fileDetails.page,
                    markdown: '',
                }
            );

            // if unable to save file retry after some time
            promise.catch(() => {
                setTimeout(() => {
                    setNewFile(fileDetails);
                }, 5000);
            });
        }
    }, [newFile]);

    useEffect(() => {
        if (fileToBeRenamed.length > 0) {
            let fTBR = [...fileToBeRenamed];
            setFileToBeRenamed([]);

            customContentService(activeApiEntityId).updateCustomContentMarkDownFiles(
                tableOfContents,
                fTBR
            );
        }
    }, [fileToBeRenamed]);

    useEffect(() => {
        if (fileToBeDeleted.length > 0) {
            let pathKeys = [...fileToBeDeleted];
            setFileToBeDeleted([]);
            customContentService(activeApiEntityId).deleteCustomContentMarkDownFiles(
                tableOfContents,
                pathKeys
            );
        }
    }, [fileToBeDeleted]);

    const updateState = (updatedState: TocType): void => {
        dispatch({
            type: UPDATE_STATE,
            payload: updatedState,
        });
    };

    const addSection = useCallback(
        (path: string[], group: string, slug: string) => {
            return new Promise<true>((resolve, reject) => {
                group = transformGroupName(group);

                if (group.length === 0) {
                    reject(EMPTY_SECTION_ERROR);
                    return;
                }

                dispatch({
                    type: ADD_SECTION,
                    payload: {
                        group,
                        slug,
                        path,
                    },
                    resolve,
                    reject,
                });
            });
        },
        [dispatch]
    );

    const addFile = useCallback(
        (path: string[], page: string, slug: string) => {
            return new Promise<ToCFileType>((resolve, reject) => {
                const newFile: ToCFileType = {
                    page: transformFileName(page),
                    file: uuidv4(),
                    slug,
                };

                if (newFile.page.length === 0) {
                    return reject(EMPTY_FILE_NAME_ERROR);
                }

                dispatch({
                    type: ADD_FILE,
                    payload: {
                        path,
                        ...newFile,
                    },
                    resolve,
                    reject,
                });
            });
        },
        [dispatch]
    );

    const renameSection = useCallback(
        (path, oldGroup: string, newGroup: string, slug: string) => {
            return new Promise<true>((resolve, reject) => {
                oldGroup = transformGroupName(oldGroup);
                newGroup = transformGroupName(newGroup);

                if (newGroup.length === 0) {
                    return reject(EMPTY_SECTION_ERROR);
                }

                dispatch({
                    type: RENAME_SECTION,
                    payload: {
                        path,
                        oldGroup,
                        newGroup,
                        slug,
                    },
                    resolve,
                    reject,
                });
            });
        },
        [dispatch]
    );

    const renameFile = useCallback(
        (path: string[], oldFile: ToCFileType, newPageName: string, slug: string) => {
            return new Promise<ToCFileType>((resolve, reject) => {
                const newFile = { page: transformFileName(newPageName), slug, file: oldFile.file };

                if (newFile.page.length === 0) {
                    reject(EMPTY_FILE_NAME_ERROR);
                    return;
                }

                dispatch({
                    type: RENAME_FILE,
                    payload: {
                        path,
                        oldFile,
                        newFile,
                    },
                    resolve,
                    reject,
                });
            });
        },
        [dispatch]
    );

    const renameGeneratedContent = useCallback(
        (path: string[], from: string, newName: string) => {
            return new Promise<string>((resolve) => {
                newName = newName.trim();

                if (newName.length === 0) {
                    resolve(EMPTY_GENERATED_CONTENT_ERROR);
                    return;
                }

                dispatch({
                    type: RENAME_GENERATED_CONTENT,
                    payload: {
                        path,
                        newName,
                        from,
                        resolve,
                    },
                });
            });
        },
        [dispatch]
    );

    const deleteSection = useCallback(
        (toc: TocType, path: string[], group: string) => {
            group = transformGroupName(group);

            if (group.length === 0) {
                return;
            }

            const sourceFolder = getSectionFromPath(toc, [...path]);
            const generatedContentInDeletedList = getUsedGeneratedContentItems(sourceFolder);

            dispatch({
                type: DELETE_SECTION,
                payload: {
                    path,
                    group,
                    generatedContentList: generatedContentInDeletedList,
                },
            });
        },
        [dispatch]
    );

    const deleteFile = useCallback(
        (path: string[], file: string, resolve: Function) => {
            dispatch({
                type: DELETE_FILE,
                payload: {
                    path,
                    file,
                    resolve,
                },
            });
        },
        [dispatch]
    );

    const deleteGeneratedContent = useCallback(
        (path: string[], content: GeneratedContentType) => {
            dispatch({
                type: DELETE_GENERATED_CONTENT,
                payload: {
                    path,
                    content,
                },
            });
        },
        [dispatch]
    );

    const disableGeneratedContent = useCallback(
        (path: string[], content: GeneratedContentType) => {
            dispatch({
                type: DISABLE_GENERATED_CONTENT,
                payload: {
                    path,
                    content,
                },
            });
        },
        [dispatch]
    );

    const updateToc = useCallback(
        (toc: TocType) => {
            dispatch({
                type: UPDATE_TOC,
                payload: {
                    toc,
                },
            });
        },
        [dispatch]
    );

    const handleDropEnd = useCallback(
        (toc, source, destination) => {
            const sourceFolder = getSectionFromPath(toc, [...source.path]);
            const destinationFolder = getSectionFromPath(toc, [...destination.path]);

            const sourceObj = JSON.parse(JSON.stringify(sourceFolder[source.index]));

            if (source.path.join(',') !== destination.path.join(',')) {
                let index = -1;
                let slugIndex = -1;

                if ('slug' in sourceObj) {
                    slugIndex = findSlugIndex(destinationFolder, sourceObj.slug);
                }

                if ('group' in sourceObj) {
                    index = findSectionIndex(destinationFolder, sourceObj.group);
                } else if ('file' in sourceObj) {
                    if (destination.path.length === 0) {
                        throw { status: 'self', message: 'A page must be inside a section.' };
                    }
                    index = findFileIndex(destinationFolder, sourceObj.page, 'page');
                } else if ('generate' in sourceObj) {
                    index = findGeneratedContentIndex(destinationFolder, sourceObj.from);
                }

                const errorMsg = [];

                if (index !== -1) {
                    errorMsg.push('Duplicate Title');
                }

                if (slugIndex !== -1) {
                    errorMsg.push(DUPLICATE_SLUG_ERROR);
                }

                if (errorMsg.length > 0) {
                    throw { status: 'self', message: errorMsg.join(". ") };
                }
            }

            dispatch({
                type: HANDLE_DROP_END_OF_TOC_ITEMS,
                payload: {
                    isReorderOnSamelevel: source.path.join('_') === destination.path.join('_'),
                    source: source,
                    destination: destination,
                    sourceObj,
                },
            });
        },
        [dispatch]
    );

    const value: CustomContentContextValue = {
        tableOfContents,
        uiState,
        isSaving,
        fileToBeRenamed,
        fileToBeDeleted,
        addSection,
        addFile,
        renameSection,
        renameFile,
        renameGeneratedContent,
        deleteSection,
        deleteFile,
        deleteGeneratedContent,
        disableGeneratedContent,
        updateToc,
        setFileToBeRenamed,
        setFileToBeDeleted,
        handleDropEnd,
        setNewFile,
    };

    return (
        <CustomContentContext.Provider value={{ ...value }}>
            {children}
        </CustomContentContext.Provider>
    );
};

const useTableofContent = () =>
    useContext<undefined | CustomContentContextValue>(CustomContentContext);

export { CustomContentProvider, useTableofContent };
