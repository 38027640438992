import React, {
    FunctionComponent,
    useContext,
    createContext,
    useState,
    useEffect,
    useReducer,
} from 'react';
import {
    AIChatbotConfigs,
    getAIChatbotConfigs,
    updateAIChatbotConfigs,
} from '../../../api-client/AIChatbotService';
import { UiStateDef } from '../../../types/ui-state';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { aiChatbotStoreKey } from './AIChatbotConstant';
import { aiChatbotReducer } from './AIChatbotReducer';
import {
    AIChatbotStateDef,
    UPDATE_STATE,
    UPDATE_TOGGLE,
    UPDATE_WELCOME_MESSAGE,
} from './AIChatbotTypes';
import {
    NotificationProviderValue,
    useNotification,
} from '../../Notification/NotificationBarContext';

export interface AIChatbotProviderValue {
    uiState: UiStateDef;
    updateUiState: (newState: UiStateDef) => void;
    aiChatbotConfigs: AIChatbotConfigs;
    setAIChatbotConfigs: React.Dispatch<React.SetStateAction<AIChatbotConfigs>>;
}

export type AIChatbotContextValue = AIChatbotProviderValue | undefined;

const AIChatbotContext = createContext<AIChatbotContextValue>(undefined);

const AIChatbotProvider: FunctionComponent = ({ children }) => {
    const aiChatbotInitialState: AIChatbotStateDef = {
        isAllowedInSubscription: false,
        isAiEnabled: false,
        welcomeMessage: '',
    };
    const [aiChatbotState, aiChatbotDispatch] = useReducer(aiChatbotReducer, aiChatbotInitialState);
    const { updateStoreApiGroup, storeState } = useStore() as StoreProviderValue;
    const { addNotification } = useNotification() as NotificationProviderValue;

    const [uiState, updateUiState] = useState<UiStateDef>({
        isLoading: true,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    });

    const updateState = (updatedState: AIChatbotStateDef): void => {
        aiChatbotDispatch({
            type: UPDATE_STATE,
            payload: updatedState,
        });
    };

    const updateToggleState = (updatedState: boolean): void => {
        aiChatbotDispatch({
            type: UPDATE_TOGGLE,
            payload: updatedState,
        });
    };

    const updateWelcomeMessageState = (updatedState: string): void => {
        aiChatbotDispatch({
            type: UPDATE_WELCOME_MESSAGE,
            payload: updatedState,
        });
    };

    useEffect(() => {
        const cacheData = storeState.apiGroup.aiChatbot;

        if (cacheData) {
            updateUiState({
                ...uiState,
                isLoading: false,
            });
            return updateState(cacheData);
        } else {
            updateUiState({
                ...uiState,
                isLoading: true,
            });
        }

        getAIChatbotConfigs()
            .then((value) => {
                updateState(value);
                updateStoreApiGroup(value, aiChatbotStoreKey);
                updateUiState({
                    ...uiState,
                    isLoading: false,
                });
            })
            .catch(() => {
                updateStoreApiGroup(aiChatbotState, aiChatbotStoreKey);
                updateUiState({ ...uiState, isLoading: false, isError: true });
            });
    }, []);

    const saveData = () => {
        updateUiState({
            ...uiState,
            isSaving: true,
        });
        updateAIChatbotConfigs({
            isEnabled: aiChatbotState.isAiEnabled,
            welcomeMessage: aiChatbotState.welcomeMessage,
        })
            .then(() => {
                updateUiState({
                    ...uiState,
                    isSaving: false,
                });
                addNotification({
                    show: true,
                    type: 'success',
                    message: 'Page was saved successfully.',
                });
                updateState({
                    isAllowedInSubscription: aiChatbotState.isAllowedInSubscription,
                    isAiEnabled: aiChatbotState.isAiEnabled,
                    welcomeMessage: aiChatbotState.welcomeMessage,
                });
                updateStoreApiGroup(
                    {
                        isAllowedInSubscription: aiChatbotState.isAllowedInSubscription,
                        isAiEnabled: aiChatbotState.isAiEnabled,
                        welcomeMessage: aiChatbotState.welcomeMessage,
                    },
                    aiChatbotStoreKey
                );
            })
            .catch(() => {
                updateUiState({
                    ...uiState,
                    isSaving: false,
                });
                addNotification({
                    show: true,
                    type: 'error',
                    message: 'Page was not saved successfully.',
                });
            });
    };

    const value = {
        aiChatbotState,
        uiState,
        updateUiState,
        updateState,
        storeState,
        updateToggleState,
        updateWelcomeMessageState,
        saveData,
    };

    return <AIChatbotContext.Provider value={value}>{children}</AIChatbotContext.Provider>;
};

const useAIChatbot = () => useContext(AIChatbotContext);

export { AIChatbotProvider, useAIChatbot };
