import React, { FunctionComponent } from 'react';
import { usePlatforms, PlatformsProviderValue } from './PlatformsContext';
import Card from '../../Card/Card';
import { ToggleSwitch } from '../../../design-system';
import Tooltip from '../../../design-system/Tooltip/Tooltip';

const HttpSettings: FunctionComponent<{ showToggle: boolean }> = ({ showToggle }) => {
    const {
        platformsState: { http },
        onHttpInputChange,
    } = usePlatforms() as PlatformsProviderValue;

    return (
        // <EuiDraggable index={0} draggableId={`http-0`} customDragHandle={true}>
        //     {(provided) => (
        <Card key={name} className="flex flex-col pt-0 pl-0 pr-0 mb-5">
            <div className="relative flex items-center justify-between w-full h-16 p-6 border-t-0 border-b border-l-0 border-r-0 border-solid header text-16 border-goose-600">
                {/* <div
                            className="absolute top-0 left-0 invisible drag-icon"
                            {...provided.dragHandleProps}
                        >
                            <DragIcon />
                        </div> */}
                <div className="flex">
                    <h3 className="mr-4 font-medium">HTTP</h3>
                    {(showToggle || !http.enabled) && (
                        <Tooltip message="Toggle HTTP documentation in portal." position="right">
                            <ToggleSwitch
                                name="enabled"
                                checked={http.enabled}
                                onChange={(e) => onHttpInputChange(e)}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className={`px-6 pt-6 ${!http.enabled && 'opacity-60'}`}>
                <ToggleSwitch
                    disabled={!http.enabled}
                    className="m-2"
                    label="Rename HTTP to REST"
                    name="renameToRest"
                    onChange={(e) => onHttpInputChange(e)}
                    checked={http.renameToRest}
                />
            </div>
        </Card>
        //     )}
        // </EuiDraggable>
    );
};

export default HttpSettings;
