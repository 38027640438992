import React, { FunctionComponent, useState } from 'react';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { updateApiDocs } from '../../api-client/ApiDocsPublishService';
import { Button } from '../../design-system';
import Hosting from '../api-docs/HostingSlug/HostingSlug';
import {
    usePublishWizard,
    PublishWizardProviderValue,
} from '../api-docs/PublishedPortal/PublishWizardContext';
import Spinner from '../../design-system/Spinner/spinner';
import Checkbox from '../../design-system/Checkbox/Checkbox';
import { InfoIcon } from '../../icons/Ui/InfoIcon';
import PublishWizardFooter from './PublishWizardFooter';
import { useNavigate } from 'react-router';
import PublishSuccess from './PublishSuccess';

const PublishApiDocs: FunctionComponent<{
    setBlankPortal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
    const { setBlankPortal } = props;
    const [isSlugEditable, setIsSlugEditable] = useState(false);
    const [activeScreen, setActiveScreen] = useState('screen_1');
    const { updateActiveScreen } = usePublishWizard() as PublishWizardProviderValue;

    const {
        storeState: {
            UTMMedium,
            apiGroup: { publishedPortal },
        },
    } = useStore() as StoreProviderValue;

    const [uiState, setUiState] = useState({
        isLoading: false,
        isError: false,
        statusCode: 0,
        message: '',
    });

    const { storeState } = useStore() as StoreProviderValue;

    const [selectedVersions, setSelectedVersions] = useState<Record<string, boolean>>({});

    const getSelectedVersions = () => {
        return Object.keys(selectedVersions).filter((version) => selectedVersions[version]);
    };

    const [responseStatus, setResponseStatus] = useState({});

    const publishApiDocs = () => {
        setUiState({
            ...uiState,
            isLoading: true,
        });
        const promise = updateApiDocs(getSelectedVersions(), UTMMedium);
        promise
            .then((resp) => {
                setUiState({
                    ...uiState,
                    isLoading: false,
                });
                setResponseStatus(resp);
                setBlankPortal(true);
                setActiveScreen('screen_3');
                /*   updateStoreApiGroup(
          {
            ...storeState.apiGroup.publishedPortal,
            status: {
              ...publishedPortal?.status,
              ...selectedVersions,
            },
          },
          publishedPortalStoreKey
        ); */
            })
            .catch((e: Response) => {
                setBlankPortal(true);
                setUiState({
                    isLoading: false,
                    isError: true,
                    statusCode: e.status,
                    message: e.statusText,
                });
            });
    };

    const renderPublishApiDocsView = () => {
        return (
            <>
                <p className="mb-6 text-goose-700 text-16">
                    Your portal will be hosted at the following public URL
                </p>
                <div className="p-8 border border-solid rounded-6 border-goose-600 mb-4">
                    <Hosting
                        className="justify-between"
                        showEditControls={true}
                        isSlugEditable={isSlugEditable}
                        setIsSlugEditable={setIsSlugEditable}
                    />
                    <PublishWizardFooter>
                        <div className="flex">
                            <Button
                                className="mr-2 "
                                text="Back"
                                fill="default"
                                onClick={() => setActiveScreen('screen_1')}
                            />

                            <Button
                                disabled={
                                    uiState.isLoading ||
                                    !storeState.apiGroup.publishedPortal?.slug ||
                                    isSlugEditable
                                }
                                icon={uiState.isLoading && <Spinner size="s" />}
                                text="Publish"
                                fill="primary"
                                onClick={() => publishApiDocs()}
                            />
                        </div>
                    </PublishWizardFooter>
                </div>
            </>
        );
    };

    const renderAddSlug = () => {
        return (
            <>
                <p className="mb-6 text-goose-700 text-16">
                    Please enter url slug to publish portal.
                </p>
                <div className="p-8 border border-solid rounded-6 border-goose-600">
                    <Hosting
                        className="justify-between"
                        showEditControls={true}
                        isSlugEditable={isSlugEditable}
                        setIsSlugEditable={setIsSlugEditable}
                        hostedFooter={true}
                        setActiveScreen={setActiveScreen}
                    />
                </div>
            </>
        );
    };

    const isVersionSelected = () => {
        return Object.values(selectedVersions).findIndex((e) => e === true) !== -1;
    };

    const toggleSelectAll = () => {
        const selected = isAllSelected();
        const temp: Record<string, boolean> = {};
        storeState.settings?.apiEntities.forEach((apiEntity) => {
            if (!isVersionDisabled(apiEntity.id)) {
                temp[apiEntity.id] = !selected;
            }
        });

        setSelectedVersions(temp);
    };

    const isAllSelected = () => {
        const isAnyDeselected = storeState.settings?.apiEntities.find((apiEntity) => {
            if (!isVersionDisabled(apiEntity.id)) {
                if (!selectedVersions[apiEntity.id]) {
                    return true;
                }
            }
            return false;
        });

        return isAnyDeselected || Object.keys(selectedVersions).length === 0 ? false : true;
    };

    const isVersionDisabled = (id: string) => {
        return false;
        const isPublished = storeState.apiGroup.publishedPortal?.status[id];
        const isPendingChanges = storeState.apiGroup.publishedPortal?.pendingUpdates[id];

        return isPublished && !isPendingChanges;
    };

    const onVersionSelect = (entityId: string, check: boolean) => {
        setSelectedVersions({
            ...selectedVersions,
            [entityId]: check,
        });
    };

    const renderVersionSelector = () => {
        const switchScreen = publishedPortal?.slug ? 'screen_2' : 'screen_slug';
        return (
            <>
                <p className="mb-6 text-goose-700 text-16">
                    Select the API versions that you want to host on this portal
                </p>
                <div className="p-8 border border-solid rounded-6 border-goose-600">
                    <table className="w-full text-left text-14 version-selector">
                        <thead className="border-b border-solid text-goose-700 border-goose-600">
                            <tr>
                                <th className="pb-2 font-medium" style={{ width: '22%' }}>
                                    Version
                                </th>
                                <th className="w-full pb-2 font-medium">Status</th>
                                <th className="pb-2">
                                    <Checkbox
                                        label=""
                                        name="version"
                                        checked={isAllSelected()}
                                        onChange={(c) => {
                                            toggleSelectAll();
                                        }}
                                    ></Checkbox>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-charcoal-800">
                            {storeState.settings?.apiEntities.map((apiEntity) => {
                                const isPublished =
                                    storeState.apiGroup.publishedPortal?.status[apiEntity.id];
                                const pendingChanges =
                                    storeState.apiGroup.publishedPortal?.pendingUpdates[
                                        apiEntity.id
                                    ];

                                const vsDisabled = isVersionDisabled(apiEntity.id);
                                return (
                                    <tr key={apiEntity.version}>
                                        <td>
                                            <div className="py-2">v {apiEntity.version}</div>
                                        </td>
                                        <td>
                                            <div className="flex items-center py-2 ">
                                                {isPublished && (
                                                    <>
                                                        <span className="pr-5 text-green">
                                                            Published
                                                        </span>
                                                        {pendingChanges && (
                                                            <span className="flex items-center pr-1 text-orange text-10">
                                                                <InfoIcon
                                                                    fill="#FF9E3D"
                                                                    className="pr-2"
                                                                    width="20px"
                                                                ></InfoIcon>
                                                                Pending Changes
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                                {!isPublished && 'Draft'}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="py-2">
                                                <Checkbox
                                                    label=""
                                                    disabled={vsDisabled}
                                                    name="version"
                                                    checked={
                                                        vsDisabled
                                                            ? false
                                                            : selectedVersions[apiEntity.id]
                                                    }
                                                    onChange={(c) => {
                                                        onVersionSelect(
                                                            apiEntity.id,
                                                            c.target.checked
                                                        );
                                                    }}
                                                ></Checkbox>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <PublishWizardFooter>
                        <div className="flex">
                            <Button
                                className="mr-2 "
                                text="Back"
                                fill="default"
                                onClick={() => updateActiveScreen('screen_1')}
                            />

                            <Button
                                disabled={!isVersionSelected()}
                                icon={uiState.isLoading && <Spinner size="s" />}
                                text="Next"
                                fill="primary"
                                onClick={() => setActiveScreen(switchScreen)}
                            />
                        </div>
                    </PublishWizardFooter>
                </div>
            </>
        );
    };

    const renderView = () => (
        <>
            {activeScreen === 'screen_1' && renderVersionSelector()}
            {activeScreen === 'screen_slug' && renderAddSlug()}
            {activeScreen === 'screen_2' && renderPublishApiDocsView()}
            {activeScreen === 'screen_3' && (
                <PublishSuccess
                    status={responseStatus}
                    selectedVersions={selectedVersions}
                ></PublishSuccess>
            )}
        </>
    );

    const navigate = useNavigate();

    const close = () => {
        navigate(
            storeState.previousView
                ? storeState.previousView.split('#')[1]
                : './../../portal-settings/home-page'
        );
    };

    const renderErrorView = () => (
        <PublishSuccess
            status={responseStatus}
            selectedVersions={selectedVersions}
        ></PublishSuccess>
    );

    return (
        <div className="widget-content">{uiState.isError ? renderErrorView() : renderView()}</div>
    );
};

export default PublishApiDocs;
