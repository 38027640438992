import React, { FunctionComponent, ChangeEvent } from 'react';
import { CheckIcon } from '../../icons/Ui/CheckIcon';
import './checkbox.scss';

interface CheckboxProps {
    label: string;
    className?: string;
    id?: string;
    name: string;
    checked?: boolean;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    /** Callback when input is focused */
    onFocus?(): void;
    /** Callback when focus is removed */
    onBlur?(): void;
}

const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
    const { label, className, id, name, checked, disabled, onChange } = props;

    return (
        <div className={`checkbox-wrapper ${className}`}>
            <label htmlFor={id}>
                <input
                    id={id}
                    type="checkbox"
                    className="radio-button-input"
                    name={name}
                    checked={checked}
                    onChange={(e) => onChange(e)}
                    disabled={disabled}
                />
                <span className="checkbox">{checked && <CheckIcon fill="white" />}</span>
                <span className="font-medium label text-14">{label}</span>
            </label>
        </div>
    );
};

export default Checkbox;
