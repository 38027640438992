import React, { FunctionComponent, useEffect } from 'react';
import { ApiDocsBrandingProvider } from '../../components/api-docs/Branding/BrandingContext';
import HomePage from '../../components/api-docs/HomePage/HomePage';
import { PlatformsProvider } from '../../components/api-docs/Platforms/PlatformsContext';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { HomepageItemKeyForSidenav, SideNavActiveItemStoreKey } from '../ViewConstant';

const HomePageView: FunctionComponent = () => {
    const { updateStore } = useStore() as StoreProviderValue;
    useEffect(() => {
        updateStore(HomepageItemKeyForSidenav, SideNavActiveItemStoreKey);
    }, []);

    //This is a parent for homepage
    return (
        <div className="w-full h-full">
            <PlatformsProvider>
                <ApiDocsBrandingProvider>
                    <HomePage />
                </ApiDocsBrandingProvider>
            </PlatformsProvider>
        </div>
    );
};

export default HomePageView;
