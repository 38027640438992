import React, { FunctionComponent, useEffect } from 'react';
import { ApiSpecExportProvider } from '../../components/api-docs/ApiSpecExport/ApiSpecExportContext';
import ApiSpecExport from '../../components/api-docs/ApiSpecExport/ApiSpecExport';
import { StoreProviderValue, useStore } from '../../store/storeContext';
import { ApiSpecExportItemKeyForSidenav, SideNavActiveItemStoreKey } from '../ViewConstant';

const ApiSpecExportView: FunctionComponent = () => {
    const { updateStore } = useStore() as StoreProviderValue;
    useEffect(() => {
        updateStore(ApiSpecExportItemKeyForSidenav, SideNavActiveItemStoreKey);
    }, []);
    return (
        <ApiSpecExportProvider>
            <ApiSpecExport />
        </ApiSpecExportProvider>
    );
};

export default ApiSpecExportView;
