import React, { FunctionComponent } from 'react';
import { ApiSpecExportConstants, ApiSpecExportConstantsDef } from './ApiSpecExportConstants';
import ApiSpecExportTemplate from './ApiSpecExportTemplate';
import { FieldGroupCard } from '../../../design-system';

interface ApiSpecExportFormatProps {
    exportSpecDisabled?: boolean;
}

const ApiSpecExportFormat: FunctionComponent<ApiSpecExportFormatProps> = ({
    exportSpecDisabled,
}) => {
    return (
        <div className="flex-col ">
            {ApiSpecExportConstants.map((format: ApiSpecExportConstantsDef) => {
                return (
                    <div className="mb-6" key={format.value}>
                        <h3 className="mb-2 font-medium text-16">{format.name}</h3>
                        <FieldGroupCard className="flex flex-flow-row-wrap">
                            {format.templates.map((template) => (
                                <ApiSpecExportTemplate
                                    key={template.value}
                                    template={template}
                                    format={format.value}
                                    disableCheckbox={exportSpecDisabled}
                                />
                            ))}
                        </FieldGroupCard>
                    </div>
                );
            })}
        </div>
    );
};

export default ApiSpecExportFormat;
