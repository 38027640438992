/* eslint-disable jsx-a11y/no-autofocus */
import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { FieldBox, TextField, Button, FieldErrorText } from '../../../design-system';
import { EditIcon } from '../../../icons/Ui/EditIcon';
import { CloseIcon } from '../../../icons/Ui/CloseIcon';
import { CheckIconLight } from '../../../icons/Ui/CheckIcon';
import { CopyIcon } from '../../../icons/Ui/CopyIcon';
import Tooltip from '../../../design-system/Tooltip/Tooltip';
import {
    PublishedPortalProviderValue,
    usePublishedPortal,
} from '../PublishedPortal/PublishedPortalContext';
import { updatePublishingSlug } from '../../../api-client/PublishingService';
import { publishedPortalStoreKey } from '../PublishedPortal/PublishedPortalConstant';
import {
    NotificationProviderValue,
    useNotification,
} from '../../Notification/NotificationBarContext';
import './hosting-slug.scss';
import Spinner from '../../../design-system/Spinner/spinner';
import ConfirmUnsavedChanges from '../../ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import { Link } from 'react-router-dom';
import { FormValidatorDef } from '../../../types/form-validator';
import { utilityFunctions } from '../../../utilities/functions';
import PublishWizardFooter from '../../PublishWizard/PublishWizardFooter';
interface HostingProps {
    showEditControls: boolean;
    className?: string;
    isSlugEditable: boolean;
    setIsSlugEditable: React.Dispatch<React.SetStateAction<boolean>>;
    hostedFooter?: boolean;
    setActiveScreen?: React.Dispatch<React.SetStateAction<string>>;
}

// const BaseURL = 'https://www.apimatic.io/api-docs/';
const Hosting: FunctionComponent<HostingProps> = (props) => {
    const {
        showEditControls,
        className,
        isSlugEditable,
        setIsSlugEditable,
        hostedFooter,
        setActiveScreen,
    } = props;
    const {
        publishedPortalState,
        uiState: publishedPortalUiState,
        updateSlug,
    } = usePublishedPortal() as PublishedPortalProviderValue;
    const { storeState, updateStoreApiGroup } = useStore() as StoreProviderValue;
    const { addNotification } = useNotification() as NotificationProviderValue;

    const [slugState, setSlugState] = useState('');
    // State to watch whether input slug is valid or not
    const [formValidator, setFormValidator] = useState<FormValidatorDef>({
        isSubmitted: false,
        errors: {},
    });
    const [uiState, setUiState] = useState({
        isLoading: false,
        isError: false,
        statusCode: 0,
        message: '',
    });

    const hostingSlugUrl = `https://${slugState}.${storeState.settings?.hostedPortalDomain || ''}`;

    const form = {
        state: formValidator,
        setState: setFormValidator,
    };
    useEffect(() => {
        setSlugState(publishedPortalState.slug);
        setIsSlugEditable(!publishedPortalState.slug);
    }, [publishedPortalState]);

    const saveData = () => {
        setUiState({
            ...uiState,
            isLoading: true,
        });
        const promise = updatePublishingSlug({ slug: slugState }, storeState.settings!.apiGroupId);

        promise
            .then(() => {
                setUiState({
                    isLoading: false,
                    isError: false,
                    statusCode: 0,
                    message: '',
                });

                if (hostedFooter && setActiveScreen) {
                    setActiveScreen('screen_2');
                }

                setIsSlugEditable(false);
                updateSlug(slugState);
                updateStoreApiGroup(
                    {
                        ...publishedPortalState,
                        slug: slugState,
                    },
                    publishedPortalStoreKey
                );
            })
            .catch((e) => {
                if (e.status === 409) {
                    setUiState({
                        isLoading: false,
                        isError: true,
                        statusCode: e.status,
                        message: 'Sorry! This Slug is already taken.',
                    });
                } else {
                    addNotification({
                        show: true,
                        type: 'error',
                        message: `Oops! ${
                            e.statusText ? e.statusText : 'Something went Wrong.'
                        } - (${e.status})`,
                    });
                }
            });
    };

    const saveSlugClicked = () => {
        if (slugState === publishedPortalState.slug) {
            return setIsSlugEditable(false);
        }

        if (!showModal && hostingSlugUrl && publishedPortalState.slug) {
            return setShowModal(true);
        }

        if (showModal) {
            setShowModal(false);
        }
        saveData();
    };

    const _handleEnterKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter') {
            saveSlugClicked();
        }
    };

    const [showModal, setShowModal] = useState(false);

    const discardChanges = () => {
        setShowModal(false);
        setIsSlugEditable(false);
        setSlugState(publishedPortalState.slug);
        setUiState({
            ...uiState,
            isError: false,
            statusCode: 0,
            message: '',
        });
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const renderHosting = () => (
        <>
            <ConfirmUnsavedChanges
                confirmationText="Changing slug name here may break links pointing to the old slug"
                show={showModal}
                closeModal={closeModal}
                saveChanges={saveSlugClicked}
                discardChanges={discardChanges}
            />
            <FieldBox label="Hosted At">
                <div className="flex flex-col">
                    <div className={`flex`} style={{ height: '33px' }}>
                        {isSlugEditable ? (
                            <>
                                <p
                                    className="items-center leading-baggy text-14"
                                    style={{ whiteSpace: 'nowrap', paddingTop: '0.15rem' }}
                                >
                                    https://
                                </p>
                                <div className="flex flex-col slug-input-field-wrapper">
                                    <TextField
                                        className="ml-1 slug-input-field editable"
                                        type="text"
                                        placeholder="enter-url-slug-here"
                                        name="hostingSlug"
                                        value={slugState}
                                        onKeyDown={_handleEnterKeyDown}
                                        validation={{
                                            key: `$hostingSlug`,
                                            required: 'Field Required',
                                            relativeUrl: true,
                                        }}
                                        form={form}
                                        onChange={(e) => {
                                            setSlugState(e.target.value);
                                        }}
                                    />

                                    {uiState.isError && <FieldErrorText error={uiState.message} />}
                                </div>
                                <p
                                    className="items-center leading-baggy text-14"
                                    style={{ whiteSpace: 'nowrap', paddingTop: '0.15rem' }}
                                >
                                    .{storeState.settings?.hostedPortalDomain}
                                </p>

                                <div className="flex items-start">
                                    {!hostedFooter &&
                                        slugState &&
                                        utilityFunctions.formIsValid(form) && (
                                            <Button
                                                text=""
                                                fill="success"
                                                className="ml-3"
                                                onClick={(e) => saveSlugClicked()}
                                                disabled={uiState.isLoading}
                                                icon={
                                                    uiState.isLoading ? (
                                                        <Spinner size="s" />
                                                    ) : (
                                                        <CheckIconLight fill="#28c397" />
                                                    )
                                                }
                                            />
                                        )}
                                    {publishedPortalState.slug.length > 0 && (
                                        <Button
                                            text=""
                                            fill="error"
                                            className="ml-1"
                                            onClick={discardChanges}
                                            icon={<CloseIcon fill="#FF4451" />}
                                        />
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <a
                                    href={hostingSlugUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center text-link-blue text-14"
                                >
                                    {hostingSlugUrl}
                                </a>

                                <div className="flex items-center">
                                    {showEditControls && (
                                        <Button
                                            text=""
                                            fill="transparent"
                                            className="ml-1"
                                            onClick={(e: any) => {
                                                setIsSlugEditable(true);
                                            }}
                                            icon={<EditIcon />}
                                        />
                                    )}

                                    <Tooltip message="Copied!" event="click">
                                        <Button
                                            text=""
                                            fill="transparent"
                                            icon={<CopyIcon fill="#24313E" />}
                                            className="ml-1"
                                            onClick={() =>
                                                navigator.clipboard.writeText(hostingSlugUrl)
                                            }
                                        />
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </div>
                    {hostedFooter && setActiveScreen && (
                        <PublishWizardFooter>
                            <div className="flex">
                                <Button
                                    className="mr-2 "
                                    text="Back"
                                    fill="default"
                                    onClick={() => setActiveScreen('screen_1')}
                                />

                                <Button
                                    disabled={slugState ? false : true}
                                    icon={uiState.isLoading && <Spinner size="s" />}
                                    text="Save & Next"
                                    fill="primary"
                                    onClick={() => saveSlugClicked()}
                                />
                            </div>
                        </PublishWizardFooter>
                    )}
                </div>
            </FieldBox>
        </>
    );

    const renderErrorView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <p className="text-base font-bold text-red ">
                    {publishedPortalUiState.message
                        ? `${publishedPortalUiState.statusCode} - ${publishedPortalUiState.message}`
                        : 'Oops! Something went wrong.'}
                </p>
            </div>
        );
    };

    const renderLoadingView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <Spinner size="m" />
            </div>
        );
    };

    return (
        <Fragment>
            {publishedPortalUiState.isLoading
                ? renderLoadingView()
                : publishedPortalUiState.isError
                ? renderErrorView()
                : renderHosting()}
        </Fragment>
    );
};

export default Hosting;
