import React, { FunctionComponent, useEffect, useState } from 'react';
import { SideNavItemsConstantType } from './SideNavConstant';
import { MenuItem } from './Item';
import { List } from './List';

export const Section: FunctionComponent<{
    item: SideNavItemsConstantType;
    activeNavValue: string;
    firstLevel?: boolean;
    renderCustomItems?: (menuItem: SideNavItemsConstantType) => any;
}> = ({ item, activeNavValue, renderCustomItems, firstLevel }) => {
    const isActiveSubMenu = () => {
        return item.items.findIndex((i) => i.value === activeNavValue) !== -1;
    };

    // const [collapsed, setCollapsed] = useState(!isActiveSubMenu());
    const [collapsed, setCollapsed] = useState(false);

    // auto collapse/uncollapse on changing selection
    // useEffect(() => {
    //     if (isActiveSubMenu() === collapsed) {
    //         setCollapsed(!isActiveSubMenu());
    //     }
    // }, [activeNavValue]);

    return (
        <div className={'w-full side-nav-section-item ' + (firstLevel ? '' : 'pl-2')}>
            <MenuItem
                item={item}
                activeNavValue={activeNavValue}
                _handleOnClick={() => setCollapsed(!collapsed)}
            />

            {!collapsed && item.items.length > 0 && (
                <div className="w-full pl-4 side-nav-section-item-list">
                    <List
                        items={item.items}
                        activeNavValue={activeNavValue}
                        renderCustomItems={renderCustomItems}
                    />
                </div>
            )}
        </div>
    );
};
