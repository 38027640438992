import React, { FunctionComponent, useState } from 'react';
import { unpublishApiDocs } from '../../../api-client/ApiDocsPublishService';
import { Button } from '../../../design-system';
import Spinner from '../../../design-system/Spinner/spinner';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { ApiEntityDef } from '../../../types/portal-editor-settings';
import { UiStateDef } from '../../../types/ui-state';
import {
    NotificationProviderValue,
    useNotification,
} from '../../Notification/NotificationBarContext';
import {
    usePublishedPortal,
    PublishedPortalProviderValue,
} from '../PublishedPortal/PublishedPortalContext';

interface UnpublishPortalActionProps {
    apiEntity: ApiEntityDef;
}

const UnpublishPortalAction: FunctionComponent<UnpublishPortalActionProps> = ({ apiEntity }) => {
    const { addNotification } = useNotification() as NotificationProviderValue;
    const { updateUnpublishStatus } = usePublishedPortal() as PublishedPortalProviderValue;
    const [uiState, setUiState] = useState<UiStateDef>({
        isLoading: false,
        isError: false,
        statusCode: 0,
        message: '',
    });

    const onUnpublishClicked = () => {
        setUiState({
            isLoading: true,
            isError: false,
            statusCode: 0,
            message: '',
        });
        const promise = unpublishApiDocs(apiEntity.id);
        promise
            .then(() => {
                setUiState({
                    isLoading: false,
                    isError: false,
                    statusCode: 0,
                    message: '',
                });
                addNotification({
                    show: true,
                    type: 'success',
                    message: `${apiEntity.name} - ${apiEntity.version} portal unpublished successfully.`,
                });
                updateUnpublishStatus(apiEntity.id);
            })
            .catch((e: Response) =>
                addNotification({
                    show: true,
                    type: 'error',
                    message: `Failed to unpublish portal ${apiEntity.name} - ${apiEntity.version}. (${e.status})`,
                })
            );
    };

    return (
        <Button
            className="float-right "
            disabled={uiState.isLoading}
            icon={uiState.isLoading && <Spinner size="s" />}
            text="Unpublish"
            fill="default"
            onClick={() => onUnpublishClicked()}
        />
    );
};

export default UnpublishPortalAction;
