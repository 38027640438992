/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FunctionComponent } from 'react';
import { HostedIcon } from '../../icons/Ui/HostedIcon';
import { EmbedIcon } from '../../icons/Ui/EmbedIcon';
import {
  usePublishWizard,
  PublishWizardProviderValue,
} from '../api-docs/PublishedPortal/PublishWizardContext';
import { StoreProviderValue, useStore } from '../../store/storeContext';
import PublishWizardFooter from './PublishWizardFooter';

const PublishOptions: FunctionComponent = () => {
  const { updateActiveScreen } = usePublishWizard() as PublishWizardProviderValue;
  const { storeState } = useStore() as StoreProviderValue;

  const isAnyPublished = () => {
    if (storeState.apiGroup.publishedPortal) {
      return Object.values(storeState.apiGroup.publishedPortal.status).some((e) => e === true);
    }

    return false;
  };

  return (
    <>
      <p className="mb-6 text-goose-700 text-16">
        Please select how you want to publish the portal
      </p>
      <div className="p-8 border border-solid rounded-6 border-goose-600">
        <div className="widget-content">
          <div className="flex flex-col widget-body">
            <div
              className="flex w-full p-10 mb-8 cursor-pointer publish-option-card rounded-12"
              onClick={() => updateActiveScreen('screen_2')}
            >
              <div className="mr-3 icon-wrapper">
                <HostedIcon fill="#0c7ff2" />
              </div>
              <div>
                <p className="mt-1 mb-2 font-medium text-apimatic-blue text-14">
                  {isAnyPublished() ? 'Update Hosted' : 'Hosted'}
                </p>
                <p className="text-charcoal-800 text-14">
                  Host your API portal on: 
                  <p>{`https://<your-slug-name>.${storeState.settings?.hostedPortalDomain}`}</p>
                </p>
              </div>
            </div>
            <div
              className="flex w-full p-10 mb-8 cursor-pointer publish-option-card rounded-12"
              onClick={() => updateActiveScreen('screen_4')}
            >
              <div className="mr-3 icon-wrapper">
                <EmbedIcon fill="#0c7ff2" />
              </div>
              <div>
                <p className="mt-1 mb-2 font-medium text-apimatic-blue text-14">
                  {isAnyPublished() ? 'Update Embedded' : 'Embedded'}
                </p>
                <p className="text-charcoal-800 text-14">
                  Embed docs into your existing developer portal or a website
                </p>
              </div>
            </div>
            <div>
              <p className="p-3 mx-3 font-medium text-charcoal-800 text-14 white-label">
                Looking for a complete White-labeled portal hosted at your own domain?{' '}
                <a
                  href="https://www.apimatic.io/contact/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Request it here.
                </a>
              </p>
            </div>
          </div>

          <PublishWizardFooter></PublishWizardFooter>
        </div>
      </div>
    </>
  );
};

export default PublishOptions;
