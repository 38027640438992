import React, { FunctionComponent, ChangeEvent } from 'react';
import './toggle-switch.scss';

interface ToggleSwicthProps {
    className?: string;
    label?: string;
    name?: string;
    size?: 'sm';
    checked: boolean;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ToggleSwitch: FunctionComponent<ToggleSwicthProps> = (props) => {
    const { className, label, name, checked, disabled, size, onChange } = props;

    return (
        <div>
            <label className={`flex items-center ${className}`}>
                <span className="mr-2  font-medium text-14 toggle-label">{label}</span>
                <input
                    id="ap-switch"
                    type="checkbox"
                    className="toggle-switch-input"
                    disabled={disabled}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                />
                <span className={`toggle-switch ${size}`}></span>
            </label>
        </div>
    );
};

export default ToggleSwitch;
