import React, { FunctionComponent, useState } from 'react';
import { Button } from '../../../../design-system';
import { NavLinkItem } from '../../ApiDocsHeader/ApiDocsHeaderInitialState';
import { HomePageProviderValue, useHomePage } from '../HomePageContext';
import LinkEditorCard from './LinkEditor/LinkEditor';

const NavigationLinkItem: FunctionComponent<{ link: NavLinkItem; index: number }> = ({
    link,
    index,
}) => {
    const [toggleAddNavigation, setToggleAddNavigation] = useState(false);

    const { addNavLink, removeNavLink } = useHomePage() as HomePageProviderValue;

    return (
        <div className="relative">
            <Button
                className="px-3 mr-2 text-goose-800 text-13"
                text={link.name}
                fill="transparent"
                onClick={() => setToggleAddNavigation(!toggleAddNavigation)}
            ></Button>
            {toggleAddNavigation && (
                <LinkEditorCard
                    state={link}
                    onSave={(item) => {
                        addNavLink(item, index);
                        setToggleAddNavigation(!toggleAddNavigation);
                    }}
                    onCancel={() => setToggleAddNavigation(!toggleAddNavigation)}
                    onDelete={() => removeNavLink(index)}
                />
            )}
        </div>
    );
};

export default NavigationLinkItem;
