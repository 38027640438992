import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

const style = {
    fill: 'none',
    strokeWidth: '1.5px',
};

export const LineHeightIcon = (props: SVGIconProps) => (
    <SVGIcon width="19.105" height="16.678" viewBox="0 0 19.105 16.678" {...props} fill="none">
        <g transform="translate(-1206.323 -1101.271)">
            <rect
                fill={props.fill || '#9caab8'}
                width="1.5"
                height="9.316"
                transform="translate(1216.111 1106.099) rotate(-90)"
            />
            <rect
                fill={props.fill || '#9caab8'}
                width="1.5"
                height="9.316"
                transform="translate(1216.111 1110.099) rotate(-90)"
            />
            <rect
                fill={props.fill || '#9caab8'}
                width="1.5"
                height="9.316"
                transform="translate(1216.111 1114.099) rotate(-90)"
            />
            <g transform="translate(1)">
                <rect
                    fill={props.fill || '#9caab8'}
                    width="1.5"
                    height="13.82"
                    transform="translate(1210.119 1117.029) rotate(180)"
                />
                <path
                    {...style}
                    stroke={props.fill || '#9caab8'}
                    d="M0,0,3.557,3.557,7.084.029"
                    transform="translate(1212.938 1105.889) rotate(180)"
                />
                <path
                    {...style}
                    stroke={props.fill || '#9caab8'}
                    d="M0,3.557,3.557,0,7.084,3.528"
                    transform="translate(1212.938 1116.888) rotate(180)"
                />
            </g>
        </g>
    </SVGIcon>
);
