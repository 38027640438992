import React, { FunctionComponent } from 'react';
import { SideNavItemsConstantType } from './SideNavConstant';
import { List } from './List';

export const Group: FunctionComponent<{
    item: SideNavItemsConstantType;
    activeNavValue: string;
    renderCustomItems?: (menuItem: SideNavItemsConstantType) => any;
}> = ({ item, activeNavValue, renderCustomItems }) => {
    return (
        <div className="w-full side-nav-group">
            <div className="w-full side-nav-group-title">
                <div className="mb-2 text-sm text-gray-400">{item.title}</div>
                <hr />
            </div>

            <div className="w-full side-nav-section">
                <List
                    items={item.items}
                    activeNavValue={activeNavValue}
                    renderCustomItems={renderCustomItems}
                    firstLevel={true}
                />
            </div>
        </div>
    );
};
