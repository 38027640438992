import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export let ExpandAllIcon = (props: SVGIconProps) => (
    <SVGIcon viewBox="0 0 12 12" width="12" height="12" {...props} fill="none">
        <g id="Group_1366" data-name="Group 1366" transform="translate(-183 -578)">
            <path
                id="indent-decrease"
                d="M7,5H19V7H7Z"
                transform="translate(176 578)"
                fill={props.fill || DEFAULT_SVG_COLOR}
            />
            <path
                id="indent-decrease-2"
                data-name="indent-decrease"
                d="M4,8V0L0,4Z"
                transform="translate(185 590) rotate(-90)"
                fill={props.fill || DEFAULT_SVG_COLOR}
            />
            <path
                id="indent-decrease-3"
                data-name="indent-decrease"
                d="M4,8V0L0,4Z"
                transform="translate(193 578) rotate(90)"
                fill={props.fill || DEFAULT_SVG_COLOR}
            />
        </g>
    </SVGIcon>
);
