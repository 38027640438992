import { FormValidatorConfigDef } from '../../../hooks/useFieldValidator';
import { PlatformTemplateType } from './PlatformConstants';

export interface PublishedPackageDef {
    packageRepository: string;
    packageSettings: Record<string, string>;
}

export interface HttpDef {
    enabled: boolean;
    renameToRest: boolean;
}

export interface SdkPlatformSettingsDef {
    platformEnabled: boolean;
    sdkEnabled: boolean;
    apimaticSdk: boolean;
    externalLink: string;
    githubLink: string;
    publishedPackage: PublishedPackageDef;
}

export type SdkPlatforms = {
    [template in PlatformTemplateType]: SdkPlatformSettingsDef;
};

export type PackageRepositoriesSettingsDef = {
    packageRepository: string;
    repositoryFields: {
        Field: string;
        FieldLabel: string;
        Type: string;
        ValidationRules: FormValidatorConfigDef;
    }[];
};

export type PackageRepositories = {
    [template in PlatformTemplateType]: PackageRepositoriesSettingsDef;
};

export type PlatformsStateDef = {
    http: HttpDef;
    sdkPlatforms: SdkPlatforms;
    packageRepositoriesSettings: PackageRepositories;
};

export const platformsInitialState: PlatformsStateDef = {
    http: {
        enabled: true,
        renameToRest: true,
    },
    sdkPlatforms: {
        java_gradle_android_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'JCenter',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
        cs_net_standard_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'NuGet',
                packageSettings: { PackageName: '', Version: '' },
            },
        },

        objc_cocoa_touch_ios_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'Cocoa',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
        java_eclipse_jre_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'Maven',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
        php_generic_lib_v2: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'Packagist',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
        python_generic_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'PyPI',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
        ruby_generic_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'RubyGems',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
        ts_generic_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'Npm',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
        angular_javascript_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'Npm',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
        node_javascript_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: 'Npm',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
        go_generic_lib: {
            sdkEnabled: true,
            platformEnabled: true,
            apimaticSdk: true,
            externalLink: '',
            githubLink: '',
            publishedPackage: {
                packageRepository: '',
                packageSettings: { PackageName: '', Version: '' },
            },
        },
    },
    packageRepositoriesSettings: {
        java_gradle_android_lib: {
            packageRepository: '',
            repositoryFields: [],
        },
        cs_net_standard_lib: {
            packageRepository: '',
            repositoryFields: [],
        },

        objc_cocoa_touch_ios_lib: {
            packageRepository: '',
            repositoryFields: [],
        },
        java_eclipse_jre_lib: {
            packageRepository: '',
            repositoryFields: [],
        },
        php_generic_lib_v2: {
            packageRepository: '',
            repositoryFields: [],
        },
        python_generic_lib: {
            packageRepository: '',
            repositoryFields: [],
        },
        ruby_generic_lib: {
            packageRepository: '',
            repositoryFields: [],
        },
        angular_javascript_lib: {
            packageRepository: '',
            repositoryFields: [],
        },
        node_javascript_lib: {
            packageRepository: '',
            repositoryFields: [],
        },
        ts_generic_lib: {
            packageRepository: '',
            repositoryFields: [],
        },
        go_generic_lib: {
            packageRepository: '',
            repositoryFields: [],
        },
    },
};
