import React, { FunctionComponent, ChangeEvent } from 'react';
import { FormValidatorConfigDef, useFieldValidator } from '../../hooks/useFieldValidator';
import { FieldErrorText } from '..';
import { FormValidatorDef } from '../../types/form-validator';

interface TextareaFieldProps {
    className?: string;
    id?: string;
    value: string;
    name: string;
    placeholder: string;
    disabled?: boolean;
    /** Disable editing of the input */
    readOnly?: boolean;
    /** Determine type of input */
    maxlength?: number;
    rows?: number;
    cols?: number;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    /** Callback when input is focused */
    onFocus?(): void;
    /** Callback when focus is removed */
    onBlur?(): void;
    validation?: FormValidatorConfigDef;
    form?: {
        state: FormValidatorDef;
        setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
    };
}

const TextareaField: FunctionComponent<TextareaFieldProps> = (props) => {
    const {
        id,
        name,
        value,
        className,
        placeholder,
        disabled,
        readOnly,
        maxlength,
        rows,
        cols,
        onChange,
        onFocus,
        onBlur,
        validation,
        form,
    } = props;
    return (
        <>
            <textarea
                className={`textarea-field px-3 py-1 leading-normal bg-white border border-solid rounded-6 text-charcoal-800 text-14 border-goose-600 ${className}`}
                id={id}
                name={name}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                maxLength={maxlength}
                rows={rows}
                cols={cols}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            {validation && (
                <FieldErrorText error={useFieldValidator(name, value, validation, form)} />
            )}
        </>
    );
};

export default TextareaField;
