import { getHeaders, fetcher } from './ApiClient';

export type AIChatbotConfigs = {
    isAllowedInSubscription: boolean;
    isAiEnabled: boolean;
    welcomeMessage: string;
};

export type AIChatbotConfigsPost = {
    isEnabled: boolean;
    welcomeMessage: string;
};

const uri = `api-groups/{apiGroupId}/ai-configuration`;

const getAIChatbotConfigs = (): Promise<AIChatbotConfigs> => {
    return fetcher<AIChatbotConfigs>(uri, 'GET', getHeaders());
};

const updateAIChatbotConfigs = (body: AIChatbotConfigsPost): Promise<AIChatbotConfigsPost> => {
    return fetcher<AIChatbotConfigsPost>(uri, 'POST', getHeaders(), body);
};

export { getAIChatbotConfigs, updateAIChatbotConfigs };
