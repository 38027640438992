import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const RobotIcon = (props: SVGIconProps): React.ReactNode => (
    <SVGIcon viewBox="0 0 18.5 14.652" width="18.5" height="14.652" {...props}>
        <g id="ai-bot-icon" transform="translate(-1.25 -3.25)">
            <path
                id="Path_37980"
                data-name="Path 37980"
                d="M11.4,7.4V4H8"
                transform="translate(-0.9)"
                fill="none"
                stroke="#24313e"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <rect
                id="Rectangle_2377"
                data-name="Rectangle 2377"
                width="12.703"
                height="9.527"
                rx="2"
                transform="translate(4.149 7.625)"
                fill="none"
                stroke="#24313e"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_37981"
                data-name="Path 37981"
                d="M2,14H3.7"
                transform="translate(0 -1.5)"
                fill="none"
                stroke="#24313e"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_37982"
                data-name="Path 37982"
                d="M20,14h1.7"
                transform="translate(-2.7 -1.5)"
                fill="none"
                stroke="#24313e"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_37983"
                data-name="Path 37983"
                d="M15,13v1.7"
                transform="translate(-1.95 -1.35)"
                fill="none"
                stroke="#24313e"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_37984"
                data-name="Path 37984"
                d="M9,13v1.7"
                transform="translate(-1.05 -1.35)"
                fill="none"
                stroke="#24313e"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </SVGIcon>
);
