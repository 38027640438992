import React, {
    FunctionComponent,
    useReducer,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import { UiStateDef } from '../../../types/ui-state';
import {
    ApiSpecExportStateDef,
    apiSpecExportInitialState,
    ExportTemplateValueDef,
    ExportFormatValueDef,
} from './ApiSpecExportInitialState';
import {
    apiSpecExportReducer,
    UPDATE_API_SPEC_EXPORT_STATE,
    ON_EXPORT_ENABLED_CHANGE,
    ON_EXPORT_TEMPLATE_CHANGE,
} from './ApiSpecExportReducer';
import { getApiSpecExport } from '../../../api-client/ApiSpecExportService';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { ApiSpecExportStoreKey } from './ApiSpecExportConstants';
import { utilityFunctions } from '../../../utilities/functions';

export interface ApiSpecExportProviderProps {
    children: ReactNode;
}

export type onExportTemplateChange = (
    templateValue: ExportTemplateValueDef,
    formatValue: ExportFormatValueDef,
    templateState: boolean
) => void;

export type onExportEnabledChange = (exportEnabled: boolean) => void;

export interface ApiSpecExportProviderValue {
    apiSpecExportState: ApiSpecExportStateDef;
    uiState: UiStateDef;
    updateUiState: (newState: UiStateDef) => void;
    onExportEnabledChange: onExportEnabledChange;
    onExportTemplateChange: onExportTemplateChange;
}

export type ApiSpecExportContextValue = undefined | ApiSpecExportProviderValue;

export const ApiSpecExportContext = createContext<ApiSpecExportContextValue>(undefined);

const ApiSpecExportProvider: FunctionComponent<ApiSpecExportProviderProps> = ({ children }) => {
    const [apiSpecExportState, apiSpecExportDispatch] = useReducer(
        apiSpecExportReducer,
        apiSpecExportInitialState
    );
    const [uiState, setUiState] = useState({
        isLoading: true,
        isError: false,
        statusCode: 0,
        message: '',
    });

    const {
        updateStoreApiEntity,
        storeState: { activeApiEntityId, apiEntities },
    } = useStore() as StoreProviderValue;

    useEffect(() => {
        setUiState(utilityFunctions.getLoadingUiState());
        const cacheData =
            Object.keys(apiEntities).includes(activeApiEntityId) &&
            apiEntities[activeApiEntityId].apiSpecExport;

        if (cacheData) {
            setUiState(utilityFunctions.getDefaultUiState());
            return updateState(cacheData);
        }

        const promise = getApiSpecExport(activeApiEntityId);
        promise
            .then((resp: ApiSpecExportStateDef) => {
                if (Object.keys(resp.exportFormats).length > 0) {
                    updateState(resp);
                    updateStoreApiEntity(resp, ApiSpecExportStoreKey, activeApiEntityId);
                }

                setUiState(utilityFunctions.getDefaultUiState());
            })
            .catch((e: Response) => {
                setUiState({
                    isLoading: false,
                    isError: true,
                    statusCode: e.status,
                    message: e.statusText,
                });
            });
    }, [activeApiEntityId]);

    const updateUiState = (newState: UiStateDef): void => {
        setUiState(newState);
    };

    const updateState = (updatedState: ApiSpecExportStateDef): void => {
        apiSpecExportDispatch({
            type: UPDATE_API_SPEC_EXPORT_STATE,
            payload: updatedState,
        });
    };

    const onExportEnabledChange: onExportEnabledChange = (exportEnabled) => {
        apiSpecExportDispatch({
            type: ON_EXPORT_ENABLED_CHANGE,
            payload: exportEnabled,
        });
    };

    const onExportTemplateChange: onExportTemplateChange = (
        templateValue,
        formatValue,
        templateState
    ) => {
        apiSpecExportDispatch({
            type: ON_EXPORT_TEMPLATE_CHANGE,
            payload: {
                templateValue,
                formatValue,
                templateState,
            },
        });
    };

    const value = {
        apiSpecExportState,
        uiState,
        updateUiState,
        onExportEnabledChange,
        onExportTemplateChange,
    };

    return <ApiSpecExportContext.Provider value={value}>{children}</ApiSpecExportContext.Provider>;
};

const useApiSpecExport = () => useContext(ApiSpecExportContext);

export { ApiSpecExportProvider, useApiSpecExport };
