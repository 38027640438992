import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import Card from '../../components/Card/Card';
import { ToggleSwitch } from '../../design-system';
import { StoreProviderValue, useStore } from '../../store/storeContext';
import { HeaderProviderValue, useHeader } from '../../components/Header/HeaderContext';
import useUpdateEffect from '../../hooks/UseUpdateEffect';
import {
    getApiConsoleOptions,
    updateApiConsoleOptions,
    ApiConsoleOptionsResponseDef,
} from '../../api-client/ApiConsoleOptionsService';
import {
    useNotification,
    NotificationProviderValue,
} from '../../components/Notification/NotificationBarContext';
import { consoleCallsStoreKey } from './ConsoleCallsConstant';
import useConfirmUnsavedChanges from '../../hooks/useConfirmUnsavedChanges';
import ConfirmUnsavedChanges from '../../components/ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import { ConsoleCallsItemKeyForSidenav, SideNavActiveItemStoreKey } from '../ViewConstant';
import { H4, SubHeading } from '../../design-system/Headings/Headings';
import { utilityFunctions } from '../../utilities/functions';
import NotificationBar from '../../components/Notification/NotificationBar';
import SaveAction from '../../components/SaveAction/SaveAction';
import { UiStateDef } from '../../types/ui-state';
import Tooltip from '../../design-system/Tooltip/Tooltip';
import Spinner from '../../design-system/Spinner/spinner';

const ConsoleCallsView: FunctionComponent = () => {
    const { addNotification } = useNotification() as NotificationProviderValue;
    const {
        updateStore,
        updateStoreApiEntity,
        storeState: { activeApiEntityId, apiEntities },
    } = useStore() as StoreProviderValue;
    const { headerState, resetHeaderState } = useHeader() as HeaderProviderValue;
    const consoleCallsExist =
        Object.keys(apiEntities).includes(activeApiEntityId) &&
        Object.prototype.hasOwnProperty.call(apiEntities[activeApiEntityId], 'apiConsoleOptions');

    const [consoleCallsEnabled, setConsoleCallsEnabled] = useState(
        consoleCallsExist ? apiEntities[activeApiEntityId].apiConsoleOptions!.consoleCalls : false
    );
    const [proxyEnabled, setProxyEnabled] = useState(
        consoleCallsExist ? apiEntities[activeApiEntityId].apiConsoleOptions!.proxy : false
    );

    const [uiState, setUiState] = useState<UiStateDef>({
        isLoading: true,
        isError: false,
        isSaving: true,
        statusCode: 0,
        message: '',
    });

    const [showModal, setShowModal] = useState(false);
    const [discard, setDiscard] = useState(false);

    useEffect(() => {
        updateStore(ConsoleCallsItemKeyForSidenav, SideNavActiveItemStoreKey);
    }, []);

    useEffect(() => {
        setUiState(utilityFunctions.getLoadingUiState());
        const cacheData =
            Object.keys(apiEntities).includes(activeApiEntityId) &&
            apiEntities[activeApiEntityId].apiConsoleOptions;

        if (cacheData) {
            setUiState(utilityFunctions.getDefaultUiState());
            setConsoleCallsEnabled(cacheData.consoleCalls);
            setProxyEnabled(cacheData.proxy);
            return;
        }

        const promise = getApiConsoleOptions(activeApiEntityId);
        promise
            .then((resp: ApiConsoleOptionsResponseDef) => {
                setUiState(utilityFunctions.getDefaultUiState());
                setConsoleCallsEnabled(resp.consoleCalls);
                setProxyEnabled(resp.proxy);
                updateStoreApiEntity(
                    {
                        consoleCalls: resp.consoleCalls || false,
                        proxy: resp.proxy || false,
                    },
                    consoleCallsStoreKey,
                    activeApiEntityId
                );
            })
            .catch((e: Response) => {
                setUiState({
                    isLoading: false,
                    isError: true,
                    statusCode: e.status,
                    message: e.statusText,
                });
                setConsoleCallsEnabled(false);
                setProxyEnabled(false);
                updateStoreApiEntity(
                    {
                        consoleCalls: false,
                        proxy: false,
                    },
                    consoleCallsStoreKey,
                    activeApiEntityId
                );
            });
    }, [activeApiEntityId]);

    useUpdateEffect(() => {
        if (headerState.isSaveClicked) {
            saveData();
            resetHeaderState();
        }
    }, [headerState]);

    const saveData = () => {
        setUiState(utilityFunctions.getSavingUiState());
        const promise = updateApiConsoleOptions(activeApiEntityId, {
            consoleCalls: consoleCallsEnabled,
            proxy: proxyEnabled,
        });
        promise
            .then((r) => {
                addNotification({
                    show: true,
                    type: 'success',
                    message: 'API Explorer settings saved successfully.',
                });
                updateStoreApiEntity(
                    {
                        consoleCalls: consoleCallsEnabled,
                        proxy: proxyEnabled,
                    },
                    consoleCallsStoreKey,
                    activeApiEntityId
                );
            })
            .catch((e) => {
                addNotification({
                    show: true,
                    type: 'error',
                    message: 'Failed to save API Explorer settings.',
                });
            })
            .finally(() => {
                setUiState(utilityFunctions.getDefaultUiState());
            });
    };

    useConfirmUnsavedChanges(
        discard,
        {
            consoleCalls: consoleCallsEnabled,
            proxy: proxyEnabled,
        },
        'apiEntities',
        () => {
            setShowModal(true);
        },
        consoleCallsStoreKey,
        activeApiEntityId
    );

    const saveChanges = () => {
        setShowModal(false);
        saveData();
    };

    const discardChanges = () => {
        setShowModal(false);
        setDiscard(true);
    };

    const renderConsoleCallsView = () => {
        return (
            <>
                <NotificationBar />
                <ConfirmUnsavedChanges
                    show={showModal}
                    closeModal={setShowModal}
                    discardChanges={discardChanges}
                    saveChanges={saveChanges}
                />
                <SaveAction
                    disabled={uiState.isLoading}
                    isSaving={uiState.isSaving!}
                    onClick={saveChanges}
                />
                <div className="p-8 pt-0 fade-in-animation">
                    <H4 className="mt-10 mb-3">API Explorer</H4>
                    <SubHeading text="Manage settings for API calls made from the portal."></SubHeading>
                    <Card>
                        <div className="flex flex-col fade-in-animation">
                            <div>
                                <Tooltip
                                    message='Toggle the "Try It Out" button in the portal.'
                                    position="right"
                                >
                                    <ToggleSwitch
                                        className="mb-8 fade-in-animation toggle-switch-api-call"
                                        label="Enable API Calls"
                                        checked={consoleCallsEnabled}
                                        onChange={(e) => setConsoleCallsEnabled(e.target.checked)}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip
                                    message="When enabled, API calls will be routed though a proxy. Required for APIs that do not support CORS."
                                    position="right"
                                >
                                    <ToggleSwitch
                                        className="toggle-switch-proxy-enabled"
                                        label="Use APIMatic proxy for API Calls"
                                        checked={proxyEnabled}
                                        onChange={(e) => setProxyEnabled(e.target.checked)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </Card>
                </div>
            </>
        );
    };

    const renderLoadingView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <Spinner size="xl" />
            </div>
        );
    };
    const renderErrorView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <p className="text-base font-bold text-red ">
                    {uiState.message
                        ? `${uiState.statusCode} - ${uiState.message}`
                        : 'Oops! Something went wrong.'}
                </p>
            </div>
        );
    };

    return (
        <Fragment>
            {uiState.isLoading
                ? renderLoadingView()
                : uiState.isError
                ? renderErrorView()
                : renderConsoleCallsView()}
        </Fragment>
    );
};

export default ConsoleCallsView;
