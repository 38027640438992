import React, {
    FunctionComponent,
    useReducer,
    ReactNode,
    createContext,
    useContext,
    useState,
    useEffect,
} from 'react';
import useUpdateEffect from '../../hooks/UseUpdateEffect';

interface NotificationStateDef {
    show: boolean;
    type: 'default' | 'success' | 'error' | 'warning';
    message: string;
    className?: string;
}

export interface NotficationProviderProps {
    children: ReactNode;
}

export interface NotificationProviderValue {
    notificationState: NotificationStateDef;
    addNotification: (newNotification: NotificationStateDef) => void;
}

export type NotificationContextValue = undefined | NotificationProviderValue;

export const NotificationContext = createContext<NotificationContextValue>(undefined);

const NotificationProvider: FunctionComponent<NotficationProviderProps> = ({ children }) => {
    const notificationInitialState: NotificationStateDef = {
        show: false,
        message: '',
        type: 'default',
    };

    const [notificationState, setNotification] = useState<NotificationStateDef>(
        notificationInitialState
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (notificationState.show) {
                setNotification({
                    show: false,
                    message: '',
                    type: 'default',
                });
            }
        }, 4001);

        return () => clearTimeout(timeout);
    }, [notificationState]);

    const addNotification = (newNotification: NotificationStateDef) => {
        setNotification({ ...newNotification });
    };

    const value = {
        notificationState,
        addNotification,
    };

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

const useNotification = () => useContext(NotificationContext);

export { NotificationProvider, useNotification };
