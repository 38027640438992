import React, { FunctionComponent, ReactChildren, ReactNode } from 'react';

interface HeadingProps {
    className?: string;
    id?: string;
    children: ReactNode;
}
export const H1: FunctionComponent<HeadingProps> = ({ children, className }) => {
    return <h1 className={`font-medium leading-tight font text-24 ${className}`}>{children}</h1>;
};

export const H2: FunctionComponent<HeadingProps> = ({ children, className }) => {
    return <h2 className={`font-medium leading-tight font text-22 ${className}`}>{children}</h2>;
};

export const H3: FunctionComponent<HeadingProps> = ({ children, className }) => {
    return (
        <h3 className={`font-medium leading-relaxed font text-20 text-charcoal-900 ${className}`}>
            {children}
        </h3>
    );
};

export const H4: FunctionComponent<HeadingProps> = ({ children, className }) => {
    return (
        <h4 className={`font-medium leading-normal font text-18 text-charcoal-900 ${className}`}>
            {children}
        </h4>
    );
};
export const H5: FunctionComponent<HeadingProps> = ({ children, className }) => {
    return (
        <h5 className={`font-medium leading-loose font text-16 text-charcoal-900 ${className}`}>
            {children}
        </h5>
    );
};

export const H6: FunctionComponent<HeadingProps> = ({ children, className }) => {
    return (
        <h6 className={`font-medium leading-loose font text-14 text-charcoal-900 ${className}`}>
            {children}
        </h6>
    );
};

export const SubHeading: FunctionComponent<{ text: string }> = ({ text }) => {
    return <p className="mb-4 text-goose-800 text-14">{text}</p>;
};

export default { H1, H2, H3, H4, H5, H6 };
