import {
    ToCItemsType,
    GeneratedContentType,
    ToCFileType,
    ToCElementType,
    ToCItemError,
} from './CustomContentTypes';

export const CustomContentStoreKey = 'customContent';
export const CUSTOM_FILE_PATH_SEPERATOR = '$_$';

export const transformFileName: (page: string) => string = (page) => {
    return page.trim();
};

export const transformFileKey = (key: string) => {
    return key;
};

export const transformGroupName: (group: string) => string = (group) => {
    return group.trim();
};

export const transformPath = (path: string[], name: string) => {
    return [...path, name]
        .map((e) => e.replace(/[^\w\s\][^,]/gi, '-'))
        .join(CUSTOM_FILE_PATH_SEPERATOR)
        .toLowerCase()
        .split(' ')
        .join('_');
};

export const getFileFromPath = (
    state: ToCItemsType,
    path: string[],
    fileName: string
): ToCFileType | null => {
    if (path.length === 0) {
        const index = state.findIndex((section) => {
            if ('file' in section) {
                return (
                    transformPath([section.page], '').split(CUSTOM_FILE_PATH_SEPERATOR)[0] ===
                    fileName
                );
            }
        });

        if (index !== -1) {
            return state[index] as ToCFileType;
        }
    } else {
        const folder = path.shift();
        if (folder) {
            const index = state.findIndex((section) => {
                if ('group' in section) {
                    return (
                        transformPath([section.group], '').split(CUSTOM_FILE_PATH_SEPERATOR)[0] ===
                        transformPath([folder], '').split(CUSTOM_FILE_PATH_SEPERATOR)[0]
                    );
                }
            });

            if (index !== -1 && 'items') {
                const level = state[index] as ToCElementType;
                return getFileFromPath(level.items, path, fileName);
            }
        }
    }

    return null;
};

export const getFileById = (state: ToCItemsType, file: string): ToCFileType | null => {
    const i = findFileIndex(state, file, 'file');

    if (i !== -1) {
        return state[i] as ToCFileType;
    }

    for (let j = 0; j < state.length; j++) {
        const obj = state[j];
        if ('group' in obj) {
            const _file = getFileById(obj.items, file);
            if (_file) {
                return _file;
            }
        }
    }

    return null;
};

export const getSectionFromPath = (state: ToCItemsType, path: string[]): ToCItemsType => {
    if (path.length === 0) {
        return state;
    } else {
        const folder = path.shift();
        if (folder) {
            const index = state.findIndex((section) => {
                if ('group' in section) {
                    return (
                        transformPath([section.group], '').split(CUSTOM_FILE_PATH_SEPERATOR)[0] ===
                        transformPath([folder], '').split(CUSTOM_FILE_PATH_SEPERATOR)[0]
                    );
                }
            });

            if (index !== -1) {
                const level = state[index] as ToCElementType;
                return getSectionFromPath(level.items, path);
            } else {
                throw 'Unexpected Error';
            }
        }
    }

    throw 'Unexpected Error';
};

export const getFilesFromLevel = (state: ToCItemsType, path: string[]) => {
    let files: { path: string; file: ToCFileType }[] = [];

    state.map((item) => {
        if ('file' in item) {
            files.push({ path: transformPath(path, item.page), file: item });
        }

        if ('items' in item) {
            files = [...files, ...getFilesFromLevel(item.items, [...path, item.group])];
        }
        return item;
    });

    return files;
};

export const findSectionIndex: (state: ToCItemsType, group: string) => number = (state, group) => {
    return state.findIndex((section) => {
        if ('group' in section) {
            return transformPath([section.group], '') === transformPath([group], '');
        }
        return false;
    });
};

export const findSlugIndex: (state: ToCItemsType, slug: string) => number = (state, slug) => {
    return state.findIndex((item) => {
        if ('slug' in item) {
            return item.slug.toLowerCase() === slug.toLowerCase();
        }
        return false;
    });
};

export const findGeneratedContentIndex: (state: ToCItemsType, from: string) => number = (
    state,
    from
) => {
    return state.findIndex((section) => {
        if ('from' in section) {
            return section.from.toLowerCase() === from.toLowerCase();
        }
        return false;
    });
};

// Check uniquness of new name at the current level
export const canGeneratedContentRenamedOnLevel = (state: ToCItemsType, generate: string) => {
    return (
        state.findIndex((section) => {
            if ('from' in section) {
                return section.generate.toLowerCase() === generate.toLowerCase();
            }
            return false;
        }) === -1
    );
};

export const findFileIndex: (state: ToCItemsType, val: string, by: 'file' | 'page') => number = (
    state,
    file,
    by
) => {
    return state.findIndex((section) => {
        if ('file' in section) {
            return by === 'file'
                ? section.file === file
                : section.page.toLowerCase() === file.toLowerCase();
        }
        return false;
    });
};

export const getUsedGeneratedContentList: (toc: ToCItemsType) => string[] = (toc) => {
    let usedGeneratedList: string[] = [];
    toc.map((tocItem) => {
        if ('from' in tocItem) {
            usedGeneratedList.push(tocItem.from);
        }

        if ('items' in tocItem) {
            usedGeneratedList = [
                ...usedGeneratedList,
                ...getUsedGeneratedContentList(tocItem.items),
            ];
        }
    });
    return usedGeneratedList;
};

export const getUsedGeneratedContentItems: (toc: ToCItemsType) => GeneratedContentType[] = (
    toc
) => {
    let usedGeneratedList: GeneratedContentType[] = [];
    toc.map((tocItem) => {
        if ('from' in tocItem) {
            usedGeneratedList.push(tocItem);
        }

        if ('items' in tocItem) {
            usedGeneratedList = [
                ...usedGeneratedList,
                ...getUsedGeneratedContentItems(tocItem.items),
            ];
        }
    });
    return usedGeneratedList;
};

export const getAllGeneratedContentList = (): GeneratedContentType[] => {
    return [
        { generate: 'Getting Started', from: 'getting-started' },
        { generate: 'API Endpoints', from: 'endpoints' },
        { generate: 'Models', from: 'models' },
    ];
};

// delete disabled content from the orignal tableofcontent
export const adjustGeneratedContentOnSave = (state: ToCItemsType): any => {
    const gettingStartedIndex = findGeneratedContentIndex(state, 'getting-started');

    if (gettingStartedIndex === -1) {
        for (let i in state) {
            if ('group' in state[i]) {
                (state[i] as ToCElementType).items = adjustGeneratedContentOnSave(
                    (state[i] as ToCElementType).items
                );
            }
        }

        return state;
    }

    if ((state[gettingStartedIndex] as GeneratedContentType).disabled === true) {
        return [...state.slice(0, gettingStartedIndex), ...state.slice(gettingStartedIndex + 1)];
    }

    return [
        ...state.slice(0, gettingStartedIndex),
        { ...state[gettingStartedIndex], disabled: undefined },
        ...state.slice(gettingStartedIndex + 1),
    ];
};

export const getTocElementKey = (item: GeneratedContentType | ToCFileType | ToCElementType) => {
    if ('group' in item) {
        return `generated_${item.group}`;
    }

    if ('from' in item) {
        return `from_${item.from}`;
    }

    if ('file' in item) {
        return `file_${item.file}`;
    }

    return 'unknown_key';
};

export const getDraggingItemStyle = (isDragging: any, draggableStyle: any) => {
    const sty = {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        background: 'white',
        // border: isDragging ? '1px solid #006BB4' : undefined,
        borderRadius: '4px',
        ...draggableStyle,
        opacity: isDragging ? '0' : '1',
    };

    return sty;
};

export const getDraggingListStyle = (isDraggingOver: boolean, isDraggingItem: boolean) => ({
    // border: isDraggingOver
    //     ? '1px solid #006BB4'
    //     : isDropDisabled
    //     ? '1px solid coral'
    //     : '1px solid green',

    // border: isDraggingOver ? '1px solid #006BB4' : 'none',
    borderRadius: '4px',
    // opacity: isDraggingOver || !isDraggingItem ? '1' : '0.5',
    // backgroundColor: isDraggingOver ? 'lightblue' : 'white',
});

export const reorderList = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const generateDocSlug = (path: string[]) => {
    return `[link text](page:${path.join('/')})`;
};

export const sanitizeSlug = (slug: string) => {
    return slug
        .toLowerCase()
        .replace(/\\/g, '')
        .replace(/[^\w\d\s]/g, ' ')
        .replace(/\s+/g, ' ')
        .trim()
        .replace(/\s/g, '-');
};

export const getDefaultErrorMessage: () => ToCItemError = () => ({
    slug: '',
    name: '',
    custom: true,
});

export const DUPLICATE_SECTION_ERROR = 'Destination already contains a section named {{title}}.';
export const DUPLICATE_GENERATED_CONTENT_ERROR =
    'A generated content with this name already exists.';
export const DUPLICATE_FILE_ERROR = 'Destination already contains a page named {{title}}.';
export const DUPLICATE_SLUG_ERROR = 'Destination already contains an item with slug {{title}}.';
export const EMPTY_FILE_NAME_ERROR = 'Empty file name not allowed.';
export const EMPTY_SECTION_ERROR = 'Empty section name not allowed.';
export const EMPTY_GENERATED_CONTENT_ERROR = 'Empty generated content name not allowed.';
export const UNEXPECTED_ERROR = 'Unexpected error. Contact APIMatic Team.';
