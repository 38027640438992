import React, { FunctionComponent, ChangeEvent } from 'react';
import { ChevronIcon } from '../../icons/Ui/ChevronIcon';
import './select-field.scss';

export interface SelectOptionDef {
    text: string;
    value: string | number;
}

interface SelectFieldProps {
    className?: string;
    options: SelectOptionDef[];
    id?: string;
    value: string | number;
    name: string;
    disabled?: boolean;
    /** Determine type of input */
    multiple?: boolean;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    /** Callback when input is focused */
    onFocus?(): void;
    /** Callback when focus is removed */
    onBlur?(): void;
    /** Callback on keypress */
    onKeyDown?(ev: React.KeyboardEvent<HTMLSelectElement>): void;

    autoFocus?: boolean;
}

const SelectField: FunctionComponent<SelectFieldProps> = (props) => {
    const {
        id,
        name,
        options,
        value,
        multiple,
        className,
        disabled,
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
        autoFocus,
    } = props;

    return (
        <select
            className={`h-8 py-1 lead bg-white border border-solid rounded-6 text-charcoal-800 text-14 border-goose-600 ${className}`}
            id={id}
            name={name}
            value={value}
            multiple={multiple}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
        >
            {options.map((option: SelectOptionDef) => (
                <option key={option.value} value={option.value}>
                    {option.text}
                </option>
            ))}
        </select>
    );
};

export default SelectField;
