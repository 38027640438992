import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';
const styleA = {
  fill: 'none',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: '2px',
};

export const ReloadIcon = (props: SVGIconProps) => (
  <SVGIcon width="18" height="18" viewBox="0 0 24 24" fill="none">
    <polyline
      points="23 4 23 10 17 10"
      stroke={props.fill ? props.fill : DEFAULT_SVG_COLOR}
      {...styleA}
    ></polyline>
    <path
      d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"
      stroke={props.fill ? props.fill : DEFAULT_SVG_COLOR}
      {...styleA}
    ></path>
  </SVGIcon>
);
{
  /* <svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  class="feather feather-rotate-cw"
>
  <polyline points="23 4 23 10 17 10"></polyline>
  <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
</svg>; */
}
