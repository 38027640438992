import React, { FunctionComponent, useState } from 'react';
import './drop-down-style.scss';
import { DropdownItemDef } from './DropDown';
import { ChevronIcon } from '../../icons/Ui/ChevronIcon';

const DropdownSubMenu: FunctionComponent<DropdownItemDef> = (props) => {
    const { name, icon, dropdownItems } = props;
    const [toggleDropdown, setToggleDropDown] = useState(false);

    return (
        <li className="dropdown-item dropdown-submenu">
            <a
                className="test"
                onClick={() => {
                    setToggleDropDown(!toggleDropdown);
                }}
            >
                {icon && <div className="mr-2 icon">{icon}</div>}
                <div className="item-text">{name}</div>
                <div className="rotate-90 icon">
                    <ChevronIcon width="8px" height="4px" />
                </div>
            </a>
            <ul className="dropdown-menu">
                {toggleDropdown &&
                    dropdownItems &&
                    dropdownItems.map(
                        ({ name, icon, disabled, data, dropdownItems, hr, onClick }) => (
                            <li
                                key={name}
                                className={'dropdown-item' + (disabled ? ' disabled' : '')}
                                onClick={() => {
                                    if (!disabled) {
                                        setToggleDropDown(!toggleDropdown);
                                        onClick(data);
                                    }
                                }}
                            >
                                <a>
                                    {icon && <div className="mr-2 icon">{icon}</div>}
                                    <div className="item-text">
                                        {name} {disabled}
                                    </div>
                                </a>
                            </li>
                        )
                    )}
            </ul>
        </li>
    );
};

export default DropdownSubMenu;
