import React, { FunctionComponent } from 'react';
import { SideNavItemsConstantType } from './SideNavConstant';
import { Group } from './Group';
import { Section } from './Section';

export const List: FunctionComponent<{
    items: ReadonlyArray<SideNavItemsConstantType>;
    activeNavValue: string;
    renderCustomItems?: (menuItem: SideNavItemsConstantType) => any;
    firstLevel?: true;
}> = ({ items, activeNavValue, renderCustomItems, firstLevel }) => {
    return (
        <>
            {items.map((item) => (
                <div
                    key={item.title + item.value + item.customItem + item.group}
                    className={`${firstLevel ? 'mt-4' : 'mt-4'} list-item`}
                >
                    {item.group && (
                        <Group
                            item={item}
                            activeNavValue={activeNavValue}
                            renderCustomItems={renderCustomItems}
                        ></Group>
                    )}

                    {!item.group && !item.customItem && (
                        <Section
                            item={item}
                            activeNavValue={activeNavValue}
                            renderCustomItems={renderCustomItems}
                            firstLevel={firstLevel}
                        />
                    )}

                    {item.customItem && renderCustomItems && renderCustomItems(item)}
                </div>
            ))}
        </>
    );
};
