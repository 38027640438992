import React, { FunctionComponent, ChangeEvent, useState, Fragment, useEffect } from 'react';
import { PlatformConstantDef, platformConstants, platformStoreKey } from './PlatformConstants';

import { useStore, StoreProviderValue } from '../../../store/storeContext';
import useUpdateEffect from '../../../hooks/UseUpdateEffect';
import { HeaderProviderValue, useHeader } from '../../Header/HeaderContext';
import {
    useNotification,
    NotificationProviderValue,
} from '../../Notification/NotificationBarContext';
import { updatePlatformForApiEntity } from '../../../api-client/ApiPlatformsService';
import { PublishedPackageDef } from './PlatformInitialState';
import PlatformSettings from './PlatformSettings';
import HttpSettings from './HttpSettings';
import { PlatformsProviderValue, usePlatforms } from './PlatformsContext';
import { utilityFunctions } from '../../../utilities/functions';
import ConfirmUnsavedChanges from '../../ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import useConfirmUnsavedChanges from './../../../hooks/useConfirmUnsavedChanges';
import { H4, SubHeading } from '../../../design-system/Headings/Headings';
import NotificationBar from '../../Notification/NotificationBar';
import SaveAction from '../../SaveAction/SaveAction';
import Spinner from '../../../design-system/Spinner/spinner';
import { PublishSDKCard } from '../PubliskSDKCard/PublishSDKCard';

export type OnInputChangeType = (e: ChangeEvent<HTMLInputElement>, template: string) => void;
export type OnPublishPackageChangeType = (
    publishedPackageInfo: PublishedPackageDef,
    template: string
) => void;

const Platforms: FunctionComponent = () => {
    const { addNotification } = useNotification() as NotificationProviderValue;
    const {
        storeState: { activeApiEntityId, subscription },
        updateStoreApiEntity,
    } = useStore() as StoreProviderValue;
    const { headerState, resetHeaderState } = useHeader() as HeaderProviderValue;
    const [platformConstantsState, setPlatformConstants] = useState<PlatformConstantDef[]>([]);
    const {
        platformsState,
        uiState,
        form,
        updateUiState,
    } = usePlatforms() as PlatformsProviderValue;
    const [showModal, setShowModal] = useState(false);
    const [discard, setDiscard] = useState(false);

    useEffect(() => {
        const filteredPlatformConstants = utilityFunctions.filterAllowedPlatform(
            platformConstants,
            subscription?.allowedPlatforms
        );
        if (filteredPlatformConstants) {
            setPlatformConstants(filteredPlatformConstants);
        }
    }, [subscription]);

    useUpdateEffect(() => {
        if (headerState.isSaveClicked) {
            if (utilityFunctions.formIsValid(form)) {
                saveData();
            }
            resetHeaderState();
        }
    }, [platformConstants]);

    const saveData = () => {
        updateUiState(utilityFunctions.getSavingUiState());
        const promise = updatePlatformForApiEntity(activeApiEntityId, platformsState);
        promise
            .then((resp) => {
                addNotification({
                    show: true,
                    type: 'success',
                    message: 'Available Languages settings saved successfully.',
                });
                updateStoreApiEntity(platformsState, platformStoreKey, activeApiEntityId);
            })
            .catch((e) => {
                addNotification({
                    show: true,
                    type: 'error',
                    message: 'Failed to save Available Languages settings.',
                });
            })
            .finally(() => {
                updateUiState(utilityFunctions.getDefaultUiState());
            });
    };

    // const onPlatformDragEnd = ({ source, destination }: DropResult): void => {
    //     if (source && destination) {
    //         const newPlatforms = euiDragDropReorder(
    //             platformConstantsState,
    //             source.index,
    //             destination.index
    //         );
    //         setPlatformConstants([...newPlatforms]);
    //     }
    // };

    useConfirmUnsavedChanges(
        discard,
        platformsState,
        'apiEntities',
        () => {
            setShowModal(true);
        },
        platformStoreKey,
        activeApiEntityId
    );

    const saveChanges = () => {
        setShowModal(false);
        saveData();
    };

    const discardChanges = () => {
        setShowModal(false);
        setDiscard(true);
    };

    const calculateSelectedPlaformLength = () => {
        return (
            Object.values(platformsState.sdkPlatforms).reduce(
                (p, c) => (c.platformEnabled ? 1 : 0) + p,
                0
            ) + (platformsState.http.enabled ? 1 : 0)
        );
    };
    const [selectedPlaformLength, setSelectedPlatformLength] = useState(
        calculateSelectedPlaformLength()
    );

    useEffect(() => {
        setSelectedPlatformLength(calculateSelectedPlaformLength());
    }, [platformsState.sdkPlatforms, platformsState.http]);

    const renderPlatforms = () => (
        <>
            <NotificationBar />

            <SaveAction
                disabled={uiState.isLoading}
                isSaving={uiState.isSaving!}
                onClick={saveChanges}
            />

            <ConfirmUnsavedChanges
                show={showModal}
                closeModal={setShowModal}
                discardChanges={discardChanges}
                saveChanges={saveChanges}
            />

            <div className="p-8 pt-0 fade-in-animation">
                <H4 className="mt-10 mb-3">Available Languages</H4>
                <SubHeading text="Manage which languages to show documentation for and the settings for each language."></SubHeading>
                <div>
                    <div className="card-wrapper">
                        <PublishSDKCard classes="greenBorder" />
                    </div>
                    <HttpSettings showToggle={selectedPlaformLength > 1} />
                    {platformConstantsState.map(
                        (platformConst: PlatformConstantDef, index: number) =>
                            platformConst.supported && (
                                <PlatformSettings
                                    key={index}
                                    index={index}
                                    platformConst={platformConst}
                                    showToggle={selectedPlaformLength > 1}
                                />
                            )
                    )}
                </div>
            </div>
        </>
    );

    const renderErrorView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <p className="text-base font-bold text-red ">
                    {uiState.message
                        ? `${uiState.statusCode} - ${uiState.message}`
                        : 'Oops! Something went wrong.'}
                </p>
            </div>
        );
    };

    const renderLoadingView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <Spinner size="xl" />
            </div>
        );
    };

    return (
        <Fragment>
            {uiState.isLoading
                ? renderLoadingView()
                : uiState.isError
                ? renderErrorView()
                : renderPlatforms()}
        </Fragment>
    );
};

export default Platforms;
