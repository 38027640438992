import React, { FunctionComponent, useContext } from 'react';
import { FieldBox } from '../../../../design-system';
import ColorSelector from '../../../../design-system/ColorSelector/ColorSelector';
import { BrandingContext } from '../BrandingContext';

const Colors: FunctionComponent = () => {
    const { apiDocsBrandingState, changeColor } = useContext(BrandingContext);

    // const changeAccent = (text: string, isValid: boolean): void => {
    //     changeColor(text, 'accent');
    // };

    const changeCta = (text: string, isValid: boolean): void => {
        changeColor(text, 'cta');
    };

    const changeHyperlink = (text: string, isValid: boolean): void => {
        changeColor(text, 'hyperLink');
    };

    // const accentSuggestedColors = [
    //     '#FF603D',
    //     '#28C397',
    //     '#FFBB0C',
    //     '#6b78c9',
    //     '#FA6285',
    //     '#00B1FF',
    // ];

    return (
        <div className="">
            <div className="flex">
                <FieldBox label="Primary Color">
                    <ColorSelector color={apiDocsBrandingState.colors.cta} onChange={changeCta} />
                </FieldBox>
                <FieldBox label="Link Color">
                    <ColorSelector
                        color={apiDocsBrandingState.colors.hyperLink}
                        onChange={changeHyperlink}
                    />
                </FieldBox>
            </div>
            {/* <div className="flex mt-10">
                <FieldBox label="Accent Color (Homepage-only)">
                    <ColorSelector
                        color={apiDocsBrandingState.colors.accent}
                        suggestedColors={accentSuggestedColors}
                        onChange={changeAccent}
                    />
                </FieldBox>
            </div> */}
        </div>
    );
};

export default Colors;
