/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { FunctionComponent, useEffect, useState } from 'react';
import './ConfirmUnsavedChanges.scss';
import { InfoIcon } from '../../icons/Ui/InfoIcon';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { Button } from '../../design-system';
import Spinner from '../../design-system/Spinner/spinner';
import ReactDOM from 'react-dom';

/***
 * ConfirmUnsavedChanges Element
 * @param {show} boolean show/hide modal
 * @param {closeModal} Function will be called on clicking close button
 * @param {discardChanges[]} Function will be called on clicking discard button
 * @param {saveChanges} Function will be called on clicking discard button
 ***/
const ConfirmUnsavedChanges: FunctionComponent<{
    show: boolean;
    discardText?: string;
    saveText?: string;
    confirmationText?: string;
    closeModal: React.Dispatch<React.SetStateAction<boolean>>;
    discardChanges: VoidFunction;
    saveChanges: VoidFunction;
}> = ({
    show,
    closeModal,
    discardChanges,
    saveChanges,
    discardText,
    saveText,
    confirmationText,
}) => {
    const confirmationPortal = document.getElementById('confirmation-dialog-portal');

    const {
        storeState: { isSaving },
    } = useStore() as StoreProviderValue;

    const [localStateIsSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // set saving state
        if (isSaving !== localStateIsSaving) {
            setIsSaving(isSaving ? true : false);
        }

        // modal is shown .... save state has been changed from saving to not saving
        if (localStateIsSaving === true && isSaving === false && show) {
            saveChanges(true);
        }
    }, [isSaving]);

    const showButton = saveText !== 'Save & Preview';

    return (
        <>
            {show &&
                confirmationPortal &&
                ReactDOM.createPortal(
                    <div className="confirm-unsaved-changes-wrapper">
                        <div className="overlay">
                            <div className="modal">
                                <div className="modal-body">
                                    <div className="flex w-full mt-4">
                                        <InfoIcon width="22" height="22" fill="#fff" />
                                        <div className="ml-2">
                                            {confirmationText
                                                ? confirmationText
                                                : 'You have unsaved changes that will be lost if not saved. Do you want to save them now?'}
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <div
                                        className={`flex ${
                                            showButton ? 'justify-between' : 'justify-end'
                                        } w-full`}
                                    >
                                        <Button
                                            onClick={() => {
                                                discardChanges();
                                            }}
                                            className="ghost"
                                            text={discardText ? discardText : 'Discard'}
                                        ></Button>
                                        <div className="flex">
                                            <Button
                                                onClick={() => {
                                                    closeModal();
                                                }}
                                                text="Cancel"
                                                className="ghost"
                                            ></Button>

                                            {showButton && (
                                                <Button
                                                    onClick={() => {
                                                        saveChanges();
                                                    }}
                                                    text={saveText ? saveText : 'Save Changes'}
                                                    icon={isSaving && <Spinner size="s" />}
                                                ></Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>,
                    confirmationPortal
                )}
        </>
    );
};

export default ConfirmUnsavedChanges;
