import React, {
    FunctionComponent,
    createContext,
    useReducer,
    ChangeEvent,
    ReactNode,
    useContext,
    useState,
    useEffect,
} from 'react';
import {
    ON_SDK_DOWNLOAD_OPTION_CHANGE,
    UPDATE_PLATFORMS_STATE,
    platformsReducer,
    ON_PLATFORM_INPUT_CHANGE,
    ON_HTTP_INPUT_CHANGE,
    UPDATE_PUBLISHED_PACKAGE,
} from './platformsReducer';
import {
    PublishedPackageDef,
    platformsInitialState,
    PlatformsStateDef,
} from './PlatformInitialState';
import { getPlatformsForApiEntity } from '../../../api-client/ApiPlatformsService';
import { UiStateDef } from '../../../types/ui-state';
import { PlatformTemplateType, platformStoreKey, platformConstants } from './PlatformConstants';
import { FormValidatorDef } from '../../../types/form-validator';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { utilityFunctions } from '../../../utilities/functions';

/***
 * Sample context implementation with typescript
 ***/

export interface PlatformsProviderProps {
    children: ReactNode;
}

export interface PlatformsProviderValue {
    platformsState: PlatformsStateDef;
    uiState: UiStateDef;
    form: {
        state: FormValidatorDef;
        setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
    };
    updateUiState: (newState: UiStateDef) => void;
    onSdkDownloadOptionChange: (
        currentTemplate: PlatformTemplateType,
        apimaticSdk: boolean
    ) => void;
    onPlatformInputChange: (
        e: ChangeEvent<HTMLInputElement>,
        currentTemplate: PlatformTemplateType
    ) => void;
    onHttpInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    updatePublishedPackage: (
        newState: PublishedPackageDef,
        currentTemplate: PlatformTemplateType
    ) => void;
}

export type PlatformsContextValue = undefined | PlatformsProviderValue;

export const PlatformsContext = createContext<PlatformsContextValue>(undefined);

const PlatformsProvider: FunctionComponent<PlatformsProviderProps> = ({ children }) => {
    const [platformsState, dispatch] = useReducer(platformsReducer, platformsInitialState);
    const [uiState, setUiState] = useState<UiStateDef>({
        isLoading: true,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    });
    const [formValidator, setFormValidator] = useState<FormValidatorDef>({
        isSubmitted: false,
        errors: {},
    });

    const {
        updateStoreApiEntity,
        storeState: { activeApiEntityId, apiEntities: apiEntity },
    } = useStore() as StoreProviderValue;

    useEffect(() => {
        setUiState(utilityFunctions.getLoadingUiState());
        const cacheData =
            Object.keys(apiEntity).includes(activeApiEntityId) &&
            apiEntity[activeApiEntityId].platforms;
        if (cacheData) {
            setUiState(utilityFunctions.getDefaultUiState());
            return updateState(cacheData);
        }

        const promise = getPlatformsForApiEntity(activeApiEntityId);
        promise
            .then((resp: PlatformsStateDef) => {
                platformConstants.forEach((platform) => {
                    if (
                        platform.templates[0] &&
                        !resp.sdkPlatforms[platform.templates[0].value as PlatformTemplateType]
                    ) {
                        resp.sdkPlatforms[platform.templates[0].value as PlatformTemplateType] = {
                            platformEnabled: false,
                            sdkEnabled: false,
                            apimaticSdk: true,
                            externalLink: '',
                            githubLink: '',
                            publishedPackage: {
                                packageRepository: '',
                                packageSettings: {},
                            },
                        };
                    }
                });
                setUiState(utilityFunctions.getDefaultUiState());
                updateState(resp);
                updateStoreApiEntity(resp, platformStoreKey, activeApiEntityId);
            })
            .catch((e: Response) => {
                setUiState({
                    isLoading: false,
                    isError: true,
                    isSaving: false,
                    statusCode: e.status,
                    message: e.statusText,
                });
            });
    }, [activeApiEntityId]);

    const updateUiState = (newState: UiStateDef): void => {
        setUiState(newState);
    };

    const updateState = (updatedState: PlatformsStateDef): void => {
        dispatch({
            type: UPDATE_PLATFORMS_STATE,
            payload: updatedState,
        });
    };

    const onPlatformInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        currentTemplate: PlatformTemplateType
    ) => {
        const { name, checked, value, type } = e.target;
        dispatch({
            type: ON_PLATFORM_INPUT_CHANGE,
            payload: {
                currentTemplate: currentTemplate,
                name: name,
                value: type === 'checkbox' ? checked : value,
            },
        });
    };

    const onSdkDownloadOptionChange = (
        currentTemplate: PlatformTemplateType,
        apimaticSdk: boolean
    ): void => {
        dispatch({
            type: ON_SDK_DOWNLOAD_OPTION_CHANGE,
            payload: {
                apimaticSdk,
                currentTemplate,
            },
        });
    };

    const onHttpInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const { name, checked, value, type } = e.target;
        dispatch({
            type: ON_HTTP_INPUT_CHANGE,
            payload: {
                name: name,
                value: type === 'checkbox' ? checked : value,
            },
        });
    };

    const updatePublishedPackage = (
        newPublishedPackage: PublishedPackageDef,
        currentTemplate: PlatformTemplateType
    ): void => {
        dispatch({
            type: UPDATE_PUBLISHED_PACKAGE,
            payload: {
                newPublishedPackage,
                currentTemplate,
            },
        });
    };

    const value = {
        platformsState,
        uiState,
        form: { state: formValidator, setState: setFormValidator },
        updateUiState,
        onSdkDownloadOptionChange,
        onPlatformInputChange,
        onHttpInputChange,
        updatePublishedPackage,
    };
    return <PlatformsContext.Provider value={value}>{children}</PlatformsContext.Provider>;
};

const usePlatforms = () => useContext(PlatformsContext);

export { PlatformsProvider, usePlatforms };

/***
 * context usage
 * const { onInputChange, onSdkDownloadOptionChange } = usePlatforms() as PlatformsProviderValue;
 ***/
