import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export let ShareIcon = (props: SVGIconProps) => (
    <SVGIcon viewBox="0 0 15 15" width="15" height="15" {...props}>
        <path
            d="M1,8.5A1.5,1.5,0,0,1,2.5,7h12A1.5,1.5,0,0,1,16,8.5v5A1.5,1.5,0,0,1,14.5,15H2.5A1.5,1.5,0,0,1,1,13.5Zm1.5,0v5h12v-5H13V7H4V8.5ZM7.75,2.872V12h1.5V2.872l2.3,2.3,1.065-1.057L8.5,0,7.975.525l-3.6,3.6L5.44,5.175l2.31-2.3Z"
            transform="translate(-1)"
        />
    </SVGIcon>
);
