import React, { FunctionComponent, useEffect } from 'react';
import Platforms from '../../components/api-docs/Platforms/Platforms';
import { PlatformsProvider } from '../../components/api-docs/Platforms/PlatformsContext';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { PlatformItemKeyForSidenav, SideNavActiveItemStoreKey } from '../ViewConstant';

const PlatformsView: FunctionComponent = () => {
    const { updateStore } = useStore() as StoreProviderValue;
    useEffect(() => {
        updateStore(PlatformItemKeyForSidenav, SideNavActiveItemStoreKey);
    }, []);

    return (
        <PlatformsProvider>
            <Platforms />
        </PlatformsProvider>
    );
};

export default PlatformsView;
