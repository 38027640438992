import { ApiDocsBrandingContextValue } from './BrandingTypes';
import { LogoType } from './Logo/LogoConstant';

export const headingDefaultFont = 'Rubik, sans-serif';
export const bodyDefaultFont = 'Rubik, sans-serif';
export const codeDefaultFont = 'Courier Prime, monospace';

const defaultTypographyObject = {
    heading: {
        fontFamily: headingDefaultFont,
        h1: {
            fontFamily: headingDefaultFont,
            fontSize: '27px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '0.9',
        },
        h2: {
            fontFamily: headingDefaultFont,
            fontSize: '24px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '0.82',
        },
        h3: {
            fontFamily: headingDefaultFont,
            fontSize: '21.36px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '1',
        },
        h4: {
            fontFamily: headingDefaultFont,
            fontSize: '18px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '1.17',
        },
        h5: {
            fontFamily: headingDefaultFont,
            fontSize: '16px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '1.26',
        },
        h6: {
            fontFamily: headingDefaultFont,
            fontSize: '15px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '1.35',
        },
    },
    body: {
        fontFamily: bodyDefaultFont,
        text1: {
            fontFamily: bodyDefaultFont,
            fontSize: '15px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.78',
        },
        text2: {
            fontFamily: bodyDefaultFont,
            fontSize: '13.33px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.5',
        },
        text3: {
            fontFamily: bodyDefaultFont,
            fontSize: '11.85px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.66',
        },
    },
    code: {
        fontFamily: codeDefaultFont,
        blockCode: {
            fontFamily: codeDefaultFont,
            fontSize: '15px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.66',
        },
        inlineCode: {
            fontFamily: codeDefaultFont,
            fontSize: '15px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.66',
        },
    },
};

const defaultColorPallete = {
    accent: '#FF603D',
    cta: '#FFBB0C',
    hyperlink: '#6b78c9',
};

const defaultLogo: LogoType = { id: null, uri: '', file: null };
const defaultFavicon: LogoType = { id: null, uri: '', file: null };

export const brandingInitialState: ApiDocsBrandingContextValue = {
    apiDocsBrandingState: {
        typography: defaultTypographyObject,
        colors: defaultColorPallete,
        logo: defaultLogo,
        favicon: defaultFavicon,
    },
    uiState: {
        isLoading: true,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    },
    changeDefaultFont: () => {},
    changeAdvancedFont: () => {},
    changeColor: () => {},
    changeLogo: () => {},
    changeFavicon: () => {},
};
