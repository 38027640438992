import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import './text-field.scss';
import { FieldErrorText } from '..';
import { useFieldValidator, FormValidatorConfigDef } from '../../hooks/useFieldValidator';
import { FormValidatorDef } from '../../types/form-validator';
import cx from 'classnames';

type Type =
    | 'text'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | 'date'
    | 'datetime-local'
    | 'month'
    | 'time'
    | 'week'
    | 'currency';

interface TextFieldProps {
    className?: string;
    wrapperClassName?: string;
    id?: string;
    value: string | number;
    name: string;
    placeholder: string;
    disabled?: boolean;
    /** Disable editing of the input */
    readOnly?: boolean;
    /** Determine type of input */
    type?: Type;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    /** Callback when input is focused */
    onFocus?(): void;
    /** Callback when focus is removed */
    onBlur?(): void;
    /** Callback on keypress */
    onKeyDown?(ev: React.KeyboardEvent<HTMLInputElement>): void;

    validation?: FormValidatorConfigDef;

    autoFocus?: boolean;

    form?: {
        state: FormValidatorDef;
        setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
    };
}

const TextField: FunctionComponent<TextFieldProps> = (props) => {
    const {
        id,
        name,
        value,
        type,
        className,
        wrapperClassName,
        placeholder,
        disabled,
        readOnly,
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
        autoFocus,
        validation,
        form,
    } = props;

    return (
        <div className={cx('flex flex-col', wrapperClassName)}>
            <input
                className={`h-8 px-3 py-1 lead bg-white border border-solid rounded-6 text-field text-charcoal-800 text-14 border-goose-600 w-full ${className}`}
                id={id}
                name={name}
                value={value}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                autoFocus={autoFocus}
            />
            {validation && (
                <FieldErrorText error={useFieldValidator(name, value, validation, form)} />
            )}
        </div>
    );
};

export default TextField;
