import React, { FunctionComponent, Fragment, useState } from 'react';
import Card from '../../Card/Card';
import Logo from './Logo/Logo';
import Colors from './Colors/Colors';
import Typography from './Typography/Typography';
import useUpdateEffect from '../../../hooks/UseUpdateEffect';
import { useHeader, HeaderProviderValue } from '../../Header/HeaderContext';
import { useApiDocsBranding } from './BrandingContext';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { ApiDocsBrandingContextValue } from './BrandingTypes';
import {
    updateApiDocsBranding,
    updateApiDocsBrandingLogo,
    updateApiDocsBrandingFavicon,
    removeApiDocsBrandingLogo,
    removeApiDocsBrandingFavicon,
} from '../../../api-client/ApiDocsBrandingService';
import {
    useNotification,
    NotificationProviderValue,
} from '../../Notification/NotificationBarContext';
import useConfirmUnsavedChanges from '../../../hooks/useConfirmUnsavedChanges';
import ConfirmUnsavedChanges from '../../ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import { BrandingStoreKey, getFontSources } from './BrandingConstant';
import { H4, SubHeading } from '../../../design-system/Headings/Headings';
import { utilityFunctions } from '../../../utilities/functions';
import NotificationBar from '../../Notification/NotificationBar';
import SaveAction from '../../SaveAction/SaveAction';
import Spinner from '../../../design-system/Spinner/spinner';

const Branding: FunctionComponent = () => {
    const { addNotification } = useNotification() as NotificationProviderValue;

    const { updateStoreApiGroup } = useStore() as StoreProviderValue;
    const { headerState, resetHeaderState } = useHeader() as HeaderProviderValue;

    const {
        apiDocsBrandingState,
        uiState,
        updateUiState,
        clearLogoFaviconFiles,
        form,
    } = useApiDocsBranding() as ApiDocsBrandingContextValue;

    const [showModal, setShowModal] = useState(false);
    const [discard, setDiscard] = useState(false);

    useUpdateEffect(() => {
        if (headerState.isSaveClicked) {
            saveData();
            resetHeaderState();
        }
    }, [headerState]);

    const saveData = () => {
        updateUiState({
            ...uiState,
            isSaving: true,
        });
        if (!utilityFunctions.formIsValid(form)) {
            return;
        }

        const promiseArray: Promise<any>[] = [
            updateApiDocsBranding({
                ...apiDocsBrandingState,
                fontSources: getFontSources(apiDocsBrandingState.typography),
            }),
        ];
        const logo = apiDocsBrandingState.logo ? apiDocsBrandingState.logo.file : null;

        if (logo && logo.length > 0) {
            const logoFormData = new FormData();
            logoFormData.append('logo', logo[0]);
            promiseArray.push(updateApiDocsBrandingLogo(logoFormData));
        }

        const favicon = apiDocsBrandingState.favicon ? apiDocsBrandingState.favicon.file : null;

        if (favicon && favicon.length > 0) {
            const faviconFormData = new FormData();
            faviconFormData.append('favicon', favicon[0]);
            promiseArray.push(updateApiDocsBrandingFavicon(faviconFormData));
        }

        if (apiDocsBrandingState.logo && apiDocsBrandingState.logo.delete) {
            promiseArray.push(removeApiDocsBrandingLogo());
        }

        if (apiDocsBrandingState.favicon && apiDocsBrandingState.favicon.delete) {
            promiseArray.push(removeApiDocsBrandingFavicon());
        }

        Promise.all(promiseArray)
            .then((resp) => {
                addNotification({
                    show: true,
                    type: 'success',
                    message: 'Appearance settings saved successfully.',
                });

                clearLogoFaviconFiles();
                updateStoreApiGroup(
                    {
                        ...apiDocsBrandingState,
                        logo: { uri: apiDocsBrandingState.logo.uri },
                        favicon: { uri: apiDocsBrandingState.favicon.uri },
                    },
                    'apiDocsBranding'
                );
            })
            .catch((e) =>
                addNotification({
                    show: true,
                    type: 'error',
                    message: 'Failed to save Appearance settings.',
                })
            )
            .finally(() => {
                updateUiState(utilityFunctions.getDefaultUiState());
            });
    };

    useConfirmUnsavedChanges(
        discard,
        apiDocsBrandingState,
        'apiGroup',
        () => {
            setShowModal(true);
        },
        BrandingStoreKey
    );

    const saveChanges = () => {
        setShowModal(false);
        saveData();
    };

    const discardChanges = () => {
        setShowModal(false);
        setDiscard(true);
    };

    const renderApiDocsBranding = () => {
        return (
            <>
                <NotificationBar />
                <ConfirmUnsavedChanges
                    show={showModal}
                    closeModal={setShowModal}
                    discardChanges={discardChanges}
                    saveChanges={saveChanges}
                />
                <SaveAction
                    disabled={uiState.isLoading}
                    isSaving={uiState.isSaving!}
                    onClick={saveChanges}
                />
                {/* {JSON.stringify(apiDocsBrandingState)} */}
                <div className="flex flex-col w-full h-full p-8 pt-0 fade-in-animation">
                    <div style={{ maxWidth: '705px' }} className="pb-10">
                        <H4 className="mt-10 mb-2">Branding</H4>
                        <SubHeading text="Upload your favicon to be displayed on the browser tab of your portal"></SubHeading>
                        <Card>
                            <Logo />
                        </Card>
                        <H4 className="mt-10 mb-2">Colors</H4>
                        <SubHeading text="Style your portal's color palette according to your brand's theme by customizing primary colors (for buttons) and hyperlink colors"></SubHeading>
                        <Card>
                            <Colors />
                        </Card>
                        <H4 className="mt-10 mb-2">Typography</H4>
                        <SubHeading text="Customize the typography for headings, body content and code snippets in your portal. You can apply font family styles globally or individually change the settings for each component"></SubHeading>
                        <Card>
                            <Typography />
                        </Card>
                    </div>
                </div>
            </>
        );
    };

    const renderErrorView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <p className="text-base font-bold text-red ">
                    {uiState.message
                        ? `${uiState.statusCode} - ${uiState.message}`
                        : 'Oops! Something went wrong.'}
                </p>
            </div>
        );
    };

    const renderLoadingView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <Spinner size="xl" />
            </div>
        );
    };

    return (
        <Fragment>
            {uiState.isLoading
                ? renderLoadingView()
                : uiState.isError
                ? renderErrorView()
                : renderApiDocsBranding()}
        </Fragment>
    );
};

export default Branding;
