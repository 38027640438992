import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const CheckIcon = (props: SVGIconProps) => (
    <SVGIcon width="7.978" height="6.385" viewBox="0 0 7.978 6.385" {...props}>
        <path
            d="M0,5.871l.7-.718L2.45,6.947,6.3,3l.7.718L2.45,8.383Z"
            transform="translate(0.489 -2.499)"
            fill={props.fill || DEFAULT_SVG_COLOR}
            stroke={props.fill || DEFAULT_SVG_COLOR}
            strokeWidth="0.7px"
        />
    </SVGIcon>
);

export const CheckIconLight = (props: SVGIconProps) => (
    <SVGIcon width="18.828" height="13.414" viewBox="0 0 18.828 13.414" {...props}>
        <path
            fill="none"
            stroke={props.fill || DEFAULT_SVG_COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            d="M20,6,9,17,4,12"
            transform="translate(-2.586 -4.586)"
        />
    </SVGIcon>
);
