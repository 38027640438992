import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const EditIcon = (props: SVGIconProps) => (
    <SVGIcon width="24" height="24" viewBox="0 0 24 24" {...props} fill="none">
        <g transform="translate(-113 -456)">
            <rect
                width="24"
                height="24"
                transform="translate(113 456)"
                fill="rgba(255,255,255,0)"
            />
            <path
                d="M8.61,2.59l2.8,2.8L2.8,14H0V11.2Zm.98-.98L11.2,0,14,2.8,12.39,4.41l-2.8-2.8Z"
                transform="translate(118 461)"
                fill={props.fill || DEFAULT_SVG_COLOR}
            />
        </g>
    </SVGIcon>
);
