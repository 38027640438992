const UPDATE_SUBSCRIPTION_STATE = 'UPDATE_SUBSCRIPTION_STATE';

export const SubscriptionReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_SUBSCRIPTION_STATE: {
            return action.payload;
        }

        default:
            return state;
            break;
    }
};
