import React, { FunctionComponent } from 'react';
import { H4 } from '../../../design-system/Headings/Headings';
import { ConsoleIllustration } from '../../../icons/Ui/ConsoleIllustration';

const Endpoint: FunctionComponent = () => {
    return (
        <div className="p-4 content-section-wrapper">
            <H4>Get Calculate</H4>
            <p className="leading-relaxed text-14 text-charcoal-900">
                Calculates the expression using the specified operation.
            </p>
            <div className="relative flex items-center w-full h-20 p-3 my-8 bg-snow-500 rounded-4">
                <div className="absolute top-0 right-0 w-6 h-6 mt-3 mr-3 bg-goose-500 rounded-4"></div>
                <pre className=" text-13">
                    <code>GetCalculateAsync(Models.GetCalculateInput input)</code>
                </pre>
            </div>

            <ConsoleIllustration width="100%" />
        </div>
    );
};

export default Endpoint;
