import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { SideNavItemsConstantType } from '../SideNav/SideNavConstant';
import AddSection from './AddSection';
import { CustomContentContext } from './CustomContentContext';
import Section from './Section';
import './CustomContent.scss';
import { ComposeIcon } from '../../icons/Ui/ComposeIcon';
import { CollapseSectionIcon } from '../../icons/Ui/CollapseSectionIcon';
import { ExpandAllIcon } from '../../icons/Ui/ExpandAllIcon';
import ToCGeneratedContent from './ToCGeneratedContent';
import {
    CUSTOM_FILE_PATH_SEPERATOR,
    getDraggingItemStyle,
    getDraggingListStyle,
    getTocElementKey,
    transformPath,
} from './CustomContentConstant';
import { StoreProviderValue, useStore } from '../../store/storeContext';
import { Button } from '../../design-system';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
    ResponderProvided,
    DragStart,
    BeforeCapture,
} from 'react-beautiful-dnd';
import { NotificationProviderValue, useNotification } from '../Notification/NotificationBarContext';
import useMousePosition from '../../hooks/useMousePosition';
import Spinner from '../../design-system/Spinner/spinner';
import { CheckIcon } from '../../icons/Ui/CheckIcon';
import { CustomContentContextValue } from './CustomContentTypes';

const CustomContent: FunctionComponent<SideNavItemsConstantType> = ({ title }) => {
    const { addNotification } = useNotification() as NotificationProviderValue;
    const { tableOfContents, handleDropEnd, uiState, isSaving } = useContext(
        CustomContentContext
    ) as CustomContentContextValue;
    const [collapse, setCollapse] = useState(false);
    const { storeState } = useStore() as StoreProviderValue;

    const [draggingItemId, setDraggingItemId] = useState<string>('');
    const [draggingItem, setDraggingItem] = useState<HTMLElement>();

    const onDragEnd: (result: DropResult, provided: ResponderProvided) => void = ({
        source,
        destination,
    }) => {
        changeDisplayOfExtraHeightElements('none');
        setDraggingItemId('');

        if (draggingItem) {
            draggingItem.remove();
            setDraggingItem(undefined);
        }

        if (source && destination) {
            let sourcePath = source.droppableId.split(CUSTOM_FILE_PATH_SEPERATOR);
            // remove prefix, postfix
            sourcePath.pop();
            sourcePath.shift();

            let destinationPath = destination.droppableId.split(CUSTOM_FILE_PATH_SEPERATOR);
            // remove prefix, postfix
            destinationPath.pop();
            destinationPath.shift();

            try {
                handleDropEnd(
                    tableOfContents,
                    { path: sourcePath, index: source.index },
                    { path: destinationPath, index: destination.index }
                );
            } catch (e) {
                if (e.status === 'self') {
                    addNotification({
                        show: true,
                        type: 'error',
                        message: e.message,
                    });
                }
            }
        }
    };

    // TODO: This is done inorder to recalculates things for dragdrop library after droppable positions have been changed
    // in future we need to optimize this and come up with a better logic
    useEffect(() => {
        refreshView();
    }, [tableOfContents]);

    // local loading state for refresh view only
    const [loading, setLoading] = useState(false);
    const refreshView = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        });
    };

    const onBeforeDragStart = (intials: DragStart) => {
        setDraggingItemId(intials.draggableId);
    };

    const onBeforeCapture = (intials: BeforeCapture) => {
        const elem = (document.querySelectorAll(
            `[data-rbd-draggable-id="${intials.draggableId}"]`
        ) as unknown) as HTMLElement[];
        if (elem) {
            const clone = elem[0].cloneNode(true) as HTMLElement;
            clone.style.position = 'fixed';
            clone.style.zIndex = '100';
            clone.style.border = '1px solid #006BB4';

            document.body.append(clone);
            elem[0].style.height = '6px';
            elem[0].style.padding = '4px';
            setDraggingItem(clone);
        }

        changeDisplayOfExtraHeightElements('block');
    };

    const changeDisplayOfExtraHeightElements = (display: 'none' | 'block') => {
        const exElems = (document.querySelectorAll(
            `.extra-drop-space`
        ) as unknown) as HTMLElement[];

        for (let e in exElems) {
            if (exElems[e] && exElems[e].style) exElems[e].style.display = display;
        }
    };

    useMousePosition(draggingItem, (pos: { x: number; y: number }) => {
        if (draggingItem) {
            draggingItem.style.left = pos.x - 20 + 'px';
            draggingItem.style.top = pos.y - 20 + 'px';
        }
    });

    const [isSaved, setIsSaved] = useState<boolean | null>(null);
    useEffect(() => {
        // omit first time redering via null;
        if (!isSaved && isSaved !== null) {
            setIsSaved(true);

            setTimeout(() => {
                setIsSaved(false);
            }, 2000);
        }

        if (isSaved === null) {
            setIsSaved(false);
        }
    }, [isSaving]);

    const renderCustomContent = () => {
        return (
            <div className="pr-2">
                <DragDropContext
                    onDragEnd={onDragEnd}
                    onDragStart={onBeforeDragStart}
                    onBeforeCapture={onBeforeCapture}
                >
                    <Droppable droppableId="CUSTOM_HANDLE_DROPPABLE_AREA_SECTION">
                        {(provided, snapshot) => (
                            <div
                                className="w-full ml-3 toc-generated-content-nav-wrapper"
                                ref={provided.innerRef}
                                style={getDraggingListStyle(
                                    snapshot.isDraggingOver,
                                    draggingItemId !== ''
                                )}
                            >
                                {tableOfContents.map((item, idx) => (
                                    <div
                                        key={'outer_key_' + getTocElementKey(item)}
                                        className="mt-2 group-wrapper"
                                    >
                                        <Draggable
                                            index={idx}
                                            key={'inner_key_' + getTocElementKey(item)}
                                            draggableId={transformPath(
                                                [],
                                                'group' in item
                                                    ? item.group
                                                    : getTocElementKey(item)
                                            )}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getDraggingItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    {'group' in item && (
                                                        <Section
                                                            key={item.group}
                                                            path={[]}
                                                            slug={[]}
                                                            content={item}
                                                            collapse={collapse}
                                                            draggingItemId={draggingItemId}
                                                            depth={0}
                                                        />
                                                    )}

                                                    {'generate' in item && (
                                                        <ToCGeneratedContent
                                                            path={[]}
                                                            content={item}
                                                            className={
                                                                draggingItemId ? 'opacity-50' : ''
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                ))}

                                <div className="w-full bg-white extra-drop-space"></div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    };

    const renderErrorView = () => {
        return (
            <div className="flex items-center justify-center w-full">
                <p className="text-base font-medium text-red">
                    {uiState.message
                        ? `${uiState.statusCode} - ${uiState.message}`
                        : 'Oops! Something went wrong.'}
                </p>
            </div>
        );
    };

    const renderLoadingView = () => {
        return (
            <div className="flex items-center justify-center w-full">
                <Spinner size="m" />
            </div>
        );
    };

    const isCustomPageActive = () => {
        return (
            'custom-pages' === storeState?.activeView.split('/')[0] ||
            storeState?.activeView.includes('generated-content')
        );
    };

    return (
        <div className="flex flex-col w-full dragdrop-wrapper">
            <div className="relative flex justify-between h-full max-h-32">
                <div className={'flex items-center h-6' + (isCustomPageActive() ? ' active' : '')}>
                    <div className={'pr-2'}>
                        <ComposeIcon width="15" height="15" fill="#455666" />
                    </div>
                    <div className="cursor-default">{title}</div>
                    {isSaving && (
                        <div className="ml-2">
                            <Spinner size="m" />
                        </div>
                    )}

                    {!isSaving && isSaved && (
                        <div className="flex items-center text-green text-13">
                            <CheckIcon className="ml-2 mr-1" fill="#28c397" />
                        </div>
                    )}
                </div>
                <div className="flex items-center h-6">
                    <Button
                        text=""
                        size="sm"
                        type="button"
                        className="mr-2"
                        icon={
                            collapse ? (
                                <ExpandAllIcon
                                    className="custom-content-expand"
                                    width="10"
                                    height="10"
                                    fill="#455666"
                                />
                            ) : (
                                <CollapseSectionIcon
                                    className="custom-content-collapse"
                                    width="10"
                                    height="10"
                                    fill="#455666"
                                />
                            )
                        }
                        onClick={() => setCollapse(!collapse)}
                    />

                    <AddSection path={[]} />
                </div>
            </div>
            {uiState.isLoading || loading
                ? renderLoadingView()
                : uiState.isError
                ? renderErrorView()
                : renderCustomContent()}
        </div>
    );
};

export default CustomContent;
