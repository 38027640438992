import React, { FunctionComponent, useState } from 'react';
import { FieldBox, TextField, Button } from '../../../../../design-system';
import { CloseIcon } from '../../../../../icons/Ui/CloseIcon';
import { LinkIcon } from '../../../../../icons/Ui/LinkIcon';
import { FormValidatorDef } from '../../../../../types/form-validator';
import { utilityFunctions } from '../../../../../utilities/functions';
import { HomePageProviderValue, useHomePage } from '../../HomePageContext';

interface LogoLinkCardProps {
    className?: string;
    onCancel: () => void;
    onDelete: () => void;
}

const LogoLinkCard: FunctionComponent<LogoLinkCardProps> = (props) => {
    const { onCancel, onDelete, className } = props;

    const {
        homePageState: { logoUrl },
        updateLogoLink,
    } = useHomePage() as HomePageProviderValue;

    const [logoUrlState, setLogoUrlState] = useState(logoUrl ? logoUrl : '');
    const [formValidator, setFormValidator] = useState<FormValidatorDef>({
        isSubmitted: false,
        errors: {},
    });

    const _handleSave = () => {
        const form = { state: formValidator, setState: setFormValidator };
        if (utilityFunctions.formIsValid(form)) {
            updateLogoLink(logoUrlState);
            onCancel();
        }
    };

    return (
        <div
            className={`absolute p-6 mx-5 my-6 bg-white border border-solid theme-editor-card-wrapper rounded-12 border-goose-600 ${className}`}
            style={{ zIndex: 20, top: '20px', left: '40px' }}
        >
            <div className="flex items-center w-full mb-6 body space-between">
                <FieldBox label="">
                    <TextField
                        placeholder="Logo Link"
                        name="logoUrl"
                        value={logoUrlState}
                        onChange={(e) => setLogoUrlState(e.target.value)}
                        validation={{
                            key: '_title',
                            url: true,
                        }}
                        form={{ state: formValidator, setState: setFormValidator }}
                    />
                </FieldBox>
                {logoUrlState && (
                    <>
                        <Button
                            className="ml-2"
                            fill="default"
                            text=""
                            icon={<CloseIcon />}
                            onClick={() => setLogoUrlState('')}
                        />

                        <a href={logoUrlState} rel="noreferrer" target="_blank">
                            <Button
                                className="ml-2"
                                fill="default"
                                text=""
                                icon={<LinkIcon />}
                                onClick={() => {}}
                            />
                        </a>
                    </>
                )}
            </div>
            <div className="flex justify-between footer">
                <Button fill="danger" text="Delete" onClick={onDelete} />
                <Button fill="danger" text="Cancel" onClick={onCancel} />
                <Button className="ml-2" fill="default" text="Done" onClick={_handleSave} />
            </div>
        </div>
    );
};

export default LogoLinkCard;
