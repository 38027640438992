import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export let CodeIcon = (props: SVGIconProps) => (
    <SVGIcon viewBox="0 0 15 8.242" width="15" height="8.242" {...props}>
        <path
            d="M.525,8.1l3.6-3.6,1.05,1.065L2.13,8.625l3.052,3.052L4.125,12.742,0,8.625Zm13.95,1.05L15,8.625,10.883,4.508,9.833,5.573,12.87,8.625,9.817,11.677l1.057,1.065L14.46,9.157Z"
            transform="translate(0 -4.5)"
        />
    </SVGIcon>
);
