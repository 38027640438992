export const IS_SAVE_CLICKED = 'IS_SAVE_CLICKED';
export const RESET_IS_SAVE_CLICKED = 'RESET_IS_SAVE_CLICKED';
export const IS_PUBLISH_CLICKED = 'IS_PUBLISH_CLICKED';
export const RESET_PUBLISH_CLICKED = 'RESET_PUBLISH_CLICKED';

export const headerReducer = (state: any, action: any) => {
    switch (action.type) {
        case IS_SAVE_CLICKED:
            console.log('IS_SAVE_CLICKED Triggered');
            return {
                isSaveClicked: action.payload.isSaveClicked,
                actionType: action.payload.actionType,
            };
            break;

        case RESET_IS_SAVE_CLICKED:
            console.log('RESET_IS_SAVE_CLICKED Triggered');
            return {
                ...state,
                isSaveClicked: false,
            };
            break;
        case IS_PUBLISH_CLICKED:
            console.log('IS_PUBLISH_CLICKED Triggered');
            return {
                ...state,
                isPublishClicked: action.payload.isPublishClicked,
            };
            break;

        case RESET_PUBLISH_CLICKED:
            console.log('RESET_PUBLISH_CLICKED Triggered');
            return {
                ...state,
                isPublishClicked: false,
            };
            break;

        default:
            return state;
            break;
    }
};
