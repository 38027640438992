import { TypographyType } from './Typography/TypographySection/TypographySectionConstant';
import { headingDefaultFont, bodyDefaultFont, codeDefaultFont } from './BrandingInitialState';

const defaultFontList = [headingDefaultFont, bodyDefaultFont, codeDefaultFont];
export const getFontSources = (typography: TypographyType) => {
    const googleFontSystemUrl = 'https://fonts.googleapis.com/css?family=';
    const fontSources: string[] = [];
    const fontWeight = ':100,100i,300,300i,400,400i,500,500i,700,700i,900,900i';
    const addSource = (font: string) => {
        if (font) {
            font = font.split(',')[0].split(' ').join('+');
            if (!defaultFontList.includes(font) && !fontSources.includes(font)) {
                fontSources.push(decodeURI(font));
            }
        }
    };

    Object.values(typography).map((section) => {
        addSource(section.fontFamily);
        Object.values(section).map((subSection) => {
            if (typeof subSection !== 'string') {
                addSource(subSection.fontFamily);
            }
        });
    });

    return fontSources.map((f) => googleFontSystemUrl + f + fontWeight);
};

export const BrandingStoreKey = 'apiDocsBranding';
