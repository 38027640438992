import React from 'react';
import {
    AndroidIcon,
    AngularIcon,
    GoIcon,
    JavaIcon,
    NodeIcon,
    ObjectiveCIcon,
    PhpIcon,
    PythonIcon,
    RubyIcon,
    TypeScriptIcon,
    WindowsIcon,
} from '../../../icons/Ui/PlatformIcons';

export type PlatformType =
    | 'IOS'
    | 'Angular'
    | 'Node'
    | 'DotNet'
    | 'Ruby'
    | 'Python'
    | 'Go'
    | 'Android'
    | 'PHP'
    | 'Java'
    | 'TypeScript';

export type HttpTemplateType = 'http_curl_v1';

export type PlatformTemplateType =
    | 'cs_net_standard_lib'
    | 'cs_portable_net_lib'
    | 'cs_universal_windows_platform_lib'
    | 'java_gradle_android_lib'
    | 'objc_cocoa_touch_ios_lib'
    | 'java_eclipse_jre_lib'
    | 'java_eclipse_jax_rs'
    | 'php_generic_lib'
    | 'python_generic_lib'
    | 'ruby_generic_lib'
    | 'angular_javascript_lib'
    | 'php_generic_lib_v2'
    | 'node_javascript_lib'
    | 'ts_generic_lib'
    | 'go_generic_lib';

export type TemplateType = HttpTemplateType | PlatformTemplateType;

export interface TemplateConstantDef {
    name: string;
    value: PlatformTemplateType;
    packageDeployment: PackageDeploymentDef;
}

export interface PackageDeploymentDef {
    supported: boolean;
    default: string;
    repositories: PackageDeploymentRepositoriesDef[];
}

export interface PackageDeploymentRepositoriesDef {
    name: string;
    value: string;
}

export type PlatformConstantDef = {
    name: string;
    value: PlatformType;
    supported: boolean;
    noSdk?: boolean;
    templates: TemplateConstantDef[];
    icon: React.ReactNode;
};

/**
 * All supported language and template definitions
 */
export const platformConstants: PlatformConstantDef[] = [
    // {
    //     name: 'HTTP',
    //     supported: false,
    //     noSdk: true,
    //     templates: [
    //         {
    //             name: 'HTTP',
    //             value: 'http_curl_v1',
    //             packageDeployment: {
    //                 supported: false,
    //                 default: '',
    //                 repositories: [
    //                     {
    //                         name: '',
    //                         value: '',
    //                     },
    //                 ],
    //             },
    //         },
    //     ],
    // },
    {
        name: '.NET',
        supported: true,
        value: 'DotNet',
        icon: <WindowsIcon />,
        templates: [
            {
                name: '.NET Standard Library',
                value: 'cs_net_standard_lib',
                packageDeployment: {
                    supported: false,
                    default: 'NuGet',
                    repositories: [
                        {
                            name: 'NuGet',
                            value: 'NuGet',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'Android',
        supported: false,
        value: 'Android',
        icon: <AndroidIcon />,
        templates: [
            {
                name: 'Android',
                value: 'java_gradle_android_lib',
                packageDeployment: {
                    supported: false,
                    default: 'JCenter',
                    repositories: [
                        {
                            name: 'JCenter',
                            value: 'JCenter',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'iOS',
        value: 'IOS',
        icon: <ObjectiveCIcon />,
        supported: false,
        templates: [
            {
                name: 'Objective-C',
                value: 'objc_cocoa_touch_ios_lib',
                packageDeployment: {
                    supported: false,
                    default: 'Cocoa',
                    repositories: [
                        {
                            name: 'Cocoa',
                            value: 'Cocoa',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'Java',
        value: 'Java',
        icon: <JavaIcon />,
        supported: true,
        templates: [
            {
                name: 'Java',
                value: 'java_eclipse_jre_lib',
                packageDeployment: {
                    supported: false,
                    default: 'Maven',
                    repositories: [
                        {
                            name: 'Maven',
                            value: 'Maven',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'PHP',
        value: 'PHP',
        icon: <PhpIcon />,
        supported: true,
        templates: [
            {
                name: 'PHP',
                value: 'php_generic_lib_v2',
                packageDeployment: {
                    supported: false,
                    default: 'Packagist',
                    repositories: [
                        {
                            name: 'Packagist',
                            value: 'Packagist',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'Python',
        value: 'Python',
        icon: <PythonIcon />,
        supported: true,
        templates: [
            {
                name: 'Python',
                value: 'python_generic_lib',
                packageDeployment: {
                    supported: false,
                    default: 'PyPI',
                    repositories: [
                        {
                            name: 'PyPI',
                            value: 'PyPI',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'Ruby',
        value: 'Ruby',
        icon: <RubyIcon />,
        supported: true,
        templates: [
            {
                name: 'Ruby',
                value: 'ruby_generic_lib',
                packageDeployment: {
                    supported: false,
                    default: 'RubyGems',
                    repositories: [
                        {
                            name: 'RubyGems',
                            value: 'RubyGems',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'TypeScript',
        value: 'TypeScript',
        icon: <TypeScriptIcon style={{ padding: '0.1rem' }} />,
        supported: true,
        templates: [
            {
                name: 'TypeScript',
                value: 'ts_generic_lib',
                packageDeployment: {
                    supported: false,
                    default: 'Npm',
                    repositories: [
                        {
                            name: 'Npm',
                            value: 'Npm',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'Angular',
        value: 'Angular',
        icon: <AngularIcon />,
        supported: false,

        templates: [
            {
                name: 'AngularJS',
                value: 'angular_javascript_lib',
                packageDeployment: {
                    supported: false,
                    default: 'Npm',
                    repositories: [
                        {
                            name: 'Npm',
                            value: 'Npm',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'Node',
        value: 'Node',
        icon: <NodeIcon />,
        supported: false,

        templates: [
            {
                name: 'Node.js',
                value: 'node_javascript_lib',
                packageDeployment: {
                    supported: false,
                    default: 'Npm',
                    repositories: [
                        {
                            name: 'Npm',
                            value: 'Npm',
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'Go',
        value: 'Go',
        icon: <GoIcon />,
        supported: true,
        templates: [
            {
                name: 'Go',
                value: 'go_generic_lib',
                packageDeployment: {
                    supported: false,
                    default: '',
                    repositories: [],
                },
            },
        ],
    },
];

export const platformStoreKey = 'platforms';
