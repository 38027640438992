export const BrandingItemKeyForSidenav = 'portal-settings/branding';
export const HeaderItemKeyForSidenav = 'portal-settings/header';
export const HomepageItemKeyForSidenav = 'portal-settings/home-page';
export const ApiSpecExportItemKeyForSidenav = 'documentation-settings/api-spec-export';
export const ConsoleCallsItemKeyForSidenav = 'documentation-settings/console-calls';
export const PlatformItemKeyForSidenav = 'documentation-settings/platforms';
export const CustomContentKeyForSidenav = 'custom-pages';
export const CustomContentFileKeyForSidenav = 'custom-pages';
export const GeneratedContentKeyForSidenav = 'generated-content';
export const HostingKeyForSidenav = 'portal-settings/published-portals';
export const AIChatbotKeyForSidenav = 'portal-settings/ai-chatbot';

export const SideNavActiveItemStoreKey = 'activeView';
