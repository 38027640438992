/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState } from 'react';
import { ContentDef } from '../HomePageIntialState';
import ThemeEditorCard from '../ThemeEditorCard/ThemeEditorCard';
import { HomePageProviderValue, useHomePage } from '../HomePageContext';
import { UPDATE_JUMBOTRON } from '../HomePageReducer';
import { Button } from '../../../../design-system';
import { EditIcon } from '../../../../icons/Ui/EditIcon';
import { H2 } from '../../../../design-system/Headings/Headings';
import './jumbotron-style.scss';
import FileSelector from '../../../../design-system/FileSelector/FileSelector';
import { FileType } from '../../Branding/Logo/LogoConstant';
import { StoreProviderValue, useStore } from '../../../../store/storeContext';

const Jumbotron: FunctionComponent = () => {
    const {
        homePageState,
        coverImageState: { file: coverImageFiles, fileUri: coverImageUri },
        updateCoverImage,
        homePageDispatch,
    } = useHomePage() as HomePageProviderValue;
    const [toggleThemeEditorCard, setToggleThemeEditorCard] = useState(false);
    const {
        storeState,
        storeState: {
            apiGroup: { apiDocsBranding },
        },
    } = useStore() as StoreProviderValue;

    const updateJumbotron = (updatedState: ContentDef) => {
        homePageDispatch({
            type: UPDATE_JUMBOTRON,
            payload: {
                title: updatedState.title,
                tagLine: updatedState.body,
            },
        });
        setToggleThemeEditorCard(!toggleThemeEditorCard);
    };
    const onCoverImageChange = (files: FileType) => {
        if (files) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = function () {
                updateCoverImage({
                    fileUri: reader.result as string,
                    file: files[0],
                });
            };
        }
    };

    return (
        <div
            style={
                coverImageUri
                    ? { backgroundImage: `url(${coverImageUri})` }
                    : homePageState.coverImage && homePageState.coverImage.uri
                    ? { backgroundImage: `url(${homePageState.coverImage.uri})` }
                    : {}
            }
            className="relative flex flex-col items-center justify-center jumbotron-wrapper"
        >
            <div className="jumbotron-overlay"></div>
            <div
                className="absolute w-full h-full bg-opacity-60 bg-charcoal-900"
                style={{ zIndex: -1 }}
            ></div>
            <Button
                text="Upload Image"
                fill="tertiary"
                className="absolute bottom-0 left-0 mb-3 ml-3 opacity-20 hover:opacity-100 upload-button"
                icon={<EditIcon fill="#fff" />}
                onClick={() => setToggleThemeEditorCard(!toggleThemeEditorCard)}
            />

            <FileSelector
                className="absolute bottom-0 left-0 mb-3 ml-3 opacity-50 hover:opacity-100 upload-button"
                text="Upload Image"
                fill="tertiary"
                file={coverImageFiles}
                icon={<EditIcon fill="#fff" />}
                onChange={onCoverImageChange}
            />

            {!toggleThemeEditorCard && (
                <div className="relative flex flex-col items-center justify-center w-1/2 p-3 content-box rounded-12">
                    <div className="w-full">
                        <H2 className="relative mb-2 leading-relaxed text-center text-white ">
                            {homePageState.title
                                ? homePageState.title
                                : storeState.settings?.apiEntities[0].name}
                        </H2>
                        <Button
                            text=""
                            fill="tertiary"
                            className="absolute top-0 right-0 mt-3 mr-3 opacity-50 edit-icon"
                            icon={<EditIcon fill="#fff" />}
                            onClick={() => setToggleThemeEditorCard(!toggleThemeEditorCard)}
                        />
                    </div>
                    <p className="mb-4 leading-relaxed text-center text-white text-14">
                        {homePageState.tagLine}
                    </p>
                    <Button
                        text="Get Started"
                        fill="primary"
                        style={{ backgroundColor: apiDocsBranding?.colors.cta }}
                        className=""
                        onClick={() => setToggleThemeEditorCard(!toggleThemeEditorCard)}
                    />
                </div>
            )}

            {toggleThemeEditorCard && (
                <ThemeEditorCard
                    className="w-1/2 z-2"
                    initialState={{
                        title: homePageState.title
                            ? homePageState.title
                            : storeState.settings && storeState.settings.apiEntities[0].name,
                        body: homePageState.tagLine,
                    }}
                    onSave={updateJumbotron}
                    onCancel={() => setToggleThemeEditorCard(!toggleThemeEditorCard)}
                />
            )}
        </div>
    );
};

export default Jumbotron;
