import React, { FunctionComponent, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from '../../design-system';
import { CustomContentContextValue, ToCFileType, ToCItemError } from './CustomContentTypes';
import DeleteConfirmation from './DropdownOptions/DeleteConfirmation';
import { CustomContentContext } from './CustomContentContext';
import CustomSectionInput from './DropdownOptions/CustomSectionInput';
import { FileIcon } from '../../icons/Ui/FileIcon';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { EllipsisIcon } from '../../icons/Ui/EllipsisIcon';
import { generateDocSlug, getDefaultErrorMessage } from './CustomContentConstant';
import { CopyIcon } from '../../icons/Ui/CopyIcon';

const ToCFile: FunctionComponent<{ path: string[]; file: ToCFileType; slug: string[] }> = ({
    path,
    file,
    slug,
}) => {
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [errorMsg, setErrorMsg] = useState<ToCItemError>(getDefaultErrorMessage());

    const { storeState } = useStore() as StoreProviderValue;

    const { renameFile, deleteFile, setFileToBeRenamed, setFileToBeDeleted } = useContext(
        CustomContentContext
    ) as CustomContentContextValue;

    const closeDelete = (confirmationValue: boolean) => {
        if (confirmationValue) {
            deleteFile(path, file.file, () => {
                setFileToBeDeleted([file.file]);
            });
        }
        setDeleting(false);
    };

    const getLink = () => {
        return `custom-pages/${file.file}`;
    };

    const dropdownList = [
        {
            name: 'Rename',
            onClick: () => {
                setEditing(true);
            },
        },
        {
            name: 'Delete',
            onClick: () => {
                setDeleting(true);
            },
        },
        {
            name: 'Copy Slug',
            onClick: () => {
                navigator.clipboard.writeText(
                    (generateDocSlug([...slug, file.slug]) as unknown) as string
                );
            },
        },
    ];

    const _handleRenameFile = (data?: { name: string; slug: string }) => {
        if (data) {
            renameFile([...path], file, data.name, data.slug)
                .then(() => {
                    setFileToBeRenamed([
                        { file: file.file, newName: data.name, markdown: 'Renamed' },
                    ]);
                    setErrorMsg(getDefaultErrorMessage());
                    setEditing(false);
                })
                .catch((e) => {
                    setErrorMsg(e);
                });
        } else {
            setErrorMsg(getDefaultErrorMessage());
            setEditing(false);
        }
    };

    const isActive = () => {
        return file.file === storeState.activeView?.split('/')[1];
    };

    return (
        <div className="flex w-full">
            {!editing && !deleting && (
                <div className="flex items-center justify-between w-full h-6 nav-item-wrapper">
                    <Link
                        className={
                            'flex items-center leading-normal nav-item nav-item-file' +
                            (isActive() ? ' active' : '')
                        }
                        key={getLink()}
                        to={getLink()}
                    >
                        <FileIcon height="12px" />
                        <div className="pl-2 blur-overflow lh-21 text-14 text-charcoal-800">
                            {file.page}
                        </div>
                    </Link>
                    <div>
                        <Dropdown
                            className="dropdown-icon-button page-dropdown"
                            name=""
                            size="sm"
                            fill="transparent"
                            icon={<EllipsisIcon width="10px" fill="#455666" />}
                            dropdownItems={dropdownList}
                        />
                    </div>
                </div>
            )}

            {editing && (
                <CustomSectionInput
                    title="Rename Page"
                    oldName={file.page}
                    oldSlug={file.slug}
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    close={_handleRenameFile}
                />
            )}

            {deleting && (
                <DeleteConfirmation
                    name={file.page}
                    close={(val) => {
                        closeDelete(val);
                    }}
                />
            )}
        </div>
    );
};

export default ToCFile;
