import React, { FunctionComponent } from 'react';
import * as ReactDOM from 'react-dom';
import './global.scss';
import Layout from './components/Layout/Layout';
import { HashRouter as Router } from 'react-router-dom';
import { StoreProvider } from './store/storeContext';
import { HeaderProvider } from './components/Header/HeaderContext';
import { PortalEditorSettingsDef } from './types/portal-editor-settings';

const portalEditorInterface = {
    // tslint:disable-next-line:no-any
    show(settings: PortalEditorSettingsDef) {
        localStorage.setItem('portalEditorSettings', JSON.stringify(settings));
        ReactDOM.render(
            <App settings={settings} />,
            document.getElementById('root') as HTMLElement
        );
    },
};

// Add APIMaticPortalEditor to window interface
declare global {
    interface Window {
        // tslint:disable-next-line:no-any
        APIMaticPortalEditor: any;
    }
}

// Add APIMaticDevPortal to global window
window.APIMaticPortalEditor = portalEditorInterface;

/**
 * Loads a script in global scope
 * @param url Url of script
 * @param callback Called after script is loaded
 */
function loadScript(url: string, callback: () => void) {
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = url;

    scriptTag.onload = callback;
    scriptTag.onerror = () => {
        throw new Error('APIMatic: Failed to load ' + url);
    };

    document.getElementsByTagName('head')[0].appendChild(scriptTag);
}

interface AppProps {
    settings: PortalEditorSettingsDef;
}

const App: FunctionComponent<AppProps> = ({ settings }) => (
    <React.StrictMode>
        <Router>
            <HeaderProvider>
                <StoreProvider settings={settings}>
                    <Layout />
                    <div id="notification-portal" className="absolute"></div>
                    <div id="tooltip-portal" className="absolute"></div>
                    <div id="confirmation-dialog-portal" className="absolute"></div>
                </StoreProvider>
            </HeaderProvider>
        </Router>
    </React.StrictMode>
);
