/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState } from 'react';
import { useHeader, HeaderProviderValue } from './HeaderContext';
import { Button } from '../../design-system';
import './header.scss';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import ConfirmUnsavedChanges from '../ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import Tooltip from '../../design-system/Tooltip/Tooltip';
import Breadcrumbs, { Breadcrumb } from '../../design-system/Breadcrumbs/Breadcrumbs';
import { useNavigate } from 'react-router';

const Header: FunctionComponent = () => {
    const {
        headerState: { isPublishClicked },
        headerPublishClicked,
    } = useHeader() as HeaderProviderValue;

    const { storeState, updateStore } = useStore() as StoreProviderValue;
    const [showModal, setShowModal] = useState(false);

    const saveButton = React.useRef<HTMLDivElement>(null);
    const [actionType, setActionType] = useState<string | undefined>();

    const activeApiEntity = storeState.settings?.apiEntities.find(
        (api) => api.id === storeState.activeApiEntityId
    );

    const navigate = useNavigate();

    const breadcrumbs: Breadcrumb[] = [
        {
            text: 'Dashboard',
            href: `/dashboard`,
        },
        {
            text: `${activeApiEntity?.name}`,
            href: '',
        },
    ];

    const handleClick = (next: VoidFunction, actionType: string) => {
        setActionType(actionType);
        if (storeState.containsUnsavedChanges) {
            setShowModal(true);
        } else {
            return next();
        }
    };

    const discardChanges = () => {
        setShowModal(false);
        if (actionType === 'publish') {
            headerPublishClicked();
        } else {
            previewClick();
        }
    };

    const saveChanges = (complete: any) => {
        if (complete) {
            if (showModal) {
                setShowModal(false);
                if (actionType === 'publish') {
                    headerPublishClicked();
                } else {
                    previewClick();
                }
            }
        } else {
            saveButton.current?.getElementsByTagName('button')[0].click();
        }
    };

    const previewClick = () => {
        const newWindow = window.open(previewUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
        // previewButton.current?.click();
    };

    const publishClicked = () => {
        if (!window.location.href.includes('portal-settings/publishing-wizard')) {
            updateStore(window.location.href, 'previousView');
            navigate('./../../portal-settings/publishing-wizard');
        }
    };

    const previewUrl = `/api-docs-preview/portal-editor/${storeState.activeApiEntityId}`;

    return (
        <header className="sticky z-0 flex items-center justify-between w-full px-5 bg-white header-wrapper">
            <ConfirmUnsavedChanges
                show={showModal}
                closeModal={setShowModal}
                discardChanges={discardChanges}
                saveChanges={saveChanges}
                saveText={actionType === 'publish' ? 'Save & Publish' : 'Save & Preview'}
                discardText={actionType === 'preview' ? 'Continue Anyway' : undefined}
                confirmationText={
                    actionType === 'preview'
                        ? 'You have unsaved changes. How do you wish to proceed?'
                        : undefined
                }
            />
            <div className="flex items-center">
                <Breadcrumbs breadcrumbs={breadcrumbs} aria-label="An example of Breadcrumbs" />
                <a
                    className="ml-4 text-13 text-apimatic-blue hover:underline"
                    href={`/apientity/#!/${activeApiEntity?.id}/edit`}
                >
                    Edit API
                </a>
            </div>

            {!isPublishClicked && (
                <div className="flex justify-end">
                    {/* TODO: use anchor button */}
                    <div id="save-action-portal" ref={saveButton}></div>
                    <a rel="noopener noreferrer" target="_blank">
                        <Tooltip
                            message="Preview the portal without publishing it."
                            position="bottom"
                        >
                            <Button
                                className="mr-3"
                                text="Preview"
                                fill="default"
                                onClick={() => handleClick(previewClick, 'preview')}
                            />
                        </Tooltip>
                    </a>
                    <Tooltip message="Publish new changes to the portal." position="bottom">
                        <Button text="Publish Portal" fill="primary" onClick={publishClicked} />
                    </Tooltip>
                </div>
            )}
        </header>
    );
};

export default Header;
