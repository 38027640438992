import { useEffect, useState } from 'react';
import { useNavigate, useBlocker } from 'react-router';
import { useStore, StoreProviderValue } from '../store/storeContext';
import { ApiEntityStoreDef, ApiGroupStoreDef, StoreStateDef } from '../store/storeInitialState';
import { TocType } from '../components/CustomContent/CustomContentTypes';

const useStopNavigation = (
    discard: boolean,
    newState: any,
    storeType: 'apiGroup' | 'apiEntities',
    showModal: Function,
    storeKey?: keyof ApiEntityStoreDef | keyof ApiGroupStoreDef,
    apiEntityId?: string,
    dependencyList?: any[]
) => {
    const navigate = useNavigate();
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [nextLocation, setNextLocation] = useState('');
    const { storeState, updateStore } = useStore() as StoreProviderValue;

    useEffect(() => {
        let oldState = undefined;

        // TODO - Refactor required
        if (storeKey) {
            if (storeType === 'apiEntities') {
                try {
                    oldState =
                        apiEntityId &&
                        storeState[storeType][apiEntityId][storeKey as keyof ApiEntityStoreDef];
                } catch (e) {}
            } else {
                oldState = storeState[storeType][storeKey as keyof ApiGroupStoreDef];
            }
        }
        let isUnsaved: boolean = false;
        if (oldState) {
            if (storeKey === 'apiDocsBranding') {
                const oldStateApiDocs = oldState as TocType;
                if (
                    JSON.stringify({ ...newState, logo: null, favicon: null }) !==
                        JSON.stringify({ ...oldStateApiDocs, logo: null, favicon: null }) ||
                    (newState.logo.file && newState.logo.file.length > 0) ||
                    (newState.favicon.file && newState.favicon.file.length > 0) ||
                    newState.logo.delete ||
                    newState.favicon.delete
                ) {
                    isUnsaved = true;
                }
            } else if (storeKey === 'homePage') {
                const coverImage = dependencyList ? dependencyList[0] : null;
                const isUnsavedLogo =
                    (newState.logo && newState.logo.file && newState.logo.file.length > 0) ||
                    (newState.logo && newState.logo.delete)
                        ? true
                        : false;

                const isUnsavedCoverImage = coverImage && coverImage.file ? true : false;

                isUnsaved =
                    JSON.stringify({
                        ...newState,
                        enabled: undefined,
                        logo: null,
                        coverImage: null,
                    }) !==
                        JSON.stringify({
                            ...(oldState as any),
                            enabled: undefined,
                            logo: null,
                            coverImage: null,
                        }) ||
                    isUnsavedLogo ||
                    isUnsavedCoverImage;
            } else if (storeKey === 'aiChatbot') {
                isUnsaved = JSON.stringify(newState) !== JSON.stringify(oldState);
            } else {
                isUnsaved = JSON.stringify(newState) !== JSON.stringify(oldState);
            }

            if (storeState.containsUnsavedChanges !== isUnsaved) {
                updateStore(isUnsaved, 'containsUnsavedChanges');
            }
            setShouldBlockNavigation(isUnsaved);
        } else if (storeKey === 'currentCustomPageData') {
            isUnsaved = newState !== '';
            if (storeState.containsUnsavedChanges !== isUnsaved) {
                updateStore(isUnsaved, 'containsUnsavedChanges');
            }
            setShouldBlockNavigation(isUnsaved);
        } else {
            if (storeState.containsUnsavedChanges) {
                updateStore(false, 'containsUnsavedChanges');
            }
            setShouldBlockNavigation(false);
        }
    }, [newState, storeState, dependencyList]);

    useEffect(() => {
        if (discard) {
            navigate(nextLocation);
        }
    }, [discard]);

    useBlocker((state) => {
        setNextLocation(state.location.pathname);
        showModal();
    }, !discard && shouldBlockNavigation);
};

export default useStopNavigation;
