import React, { FunctionComponent, ChangeEvent, useState } from 'react';
import RadioButton from '../RadioButton/RadioButton';

export interface RadioGroupOption {
    label: string;
    name: string;
    value: string;
}

export interface RadioGroupProps {
    id: string;
    name: string;
    disabled: boolean;
    selectedOption: string;
    options: RadioGroupOption[];
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup: FunctionComponent<RadioGroupProps> = ({
    selectedOption,
    options,
    disabled,
    onChange,
    name,
    id,
}) => {
    return (
        <div>
            {options.map(({ value, label }: RadioGroupOption) => (
                <RadioButton
                    id={`${id}-${value}`}
                    key={value}
                    label={label}
                    name={name}
                    disabled={disabled}
                    defaultChecked={selectedOption === value}
                    value={value}
                    onChange={onChange}
                />
            ))}
        </div>
    );
};

export default RadioGroup;
