/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FunctionComponent, useEffect, useState } from 'react';
import './publish-wizard.scss';
import EmbedSnippet from './EmbedSnippet';
import PublishOptions from './PublishOptions';
import PublishApiDocs from './PublishApiDocs';
import {
    PublishedPortalProvider,
    PublishedPortalProviderValue,
    usePublishedPortal,
} from '../api-docs/PublishedPortal/PublishedPortalContext';
import NotificationBar from '../Notification/NotificationBar';
import { H3 } from '../../design-system/Headings/Headings';
import {
    PublishWizardProviderValue,
    usePublishWizard,
} from '../api-docs/PublishedPortal/PublishWizardContext';
import cx from 'classnames';

const PublishWizard: FunctionComponent = () => {
    const {
        publishWizardState: { activeScreen },
    } = usePublishWizard() as PublishWizardProviderValue;

    const { fetchPublishPortalData } = usePublishedPortal() as PublishedPortalProviderValue;

    const [blankPortal, setBlankPortal] = useState(false);

    useEffect(() => {
        fetchPublishPortalData(true);
    }, []);

    const getScreen = () => (
        <>
            {activeScreen === 'screen_1' && <PublishOptions />}
            {activeScreen === 'screen_2' && <PublishApiDocs setBlankPortal={setBlankPortal} />}
            {activeScreen === 'screen_4' && <EmbedSnippet setBlankPortal={setBlankPortal} />}
        </>
    );

    return (
        <PublishedPortalProvider>
            <div className="flex justify-center w-full h-full overflow-scroll bg-white custom-scroll widget-wrapper">
                <NotificationBar />
                <div
                    className={cx({
                        'mt-20': activeScreen !== 'screen_4',
                        'mt-2': activeScreen === 'screen_4',
                        'widget-centered': true,
                    })}
                >
                    {!blankPortal && (
                        <div className="flex items-center justify-between">
                            <H3 className="my-2">Publish API Portal</H3>
                        </div>
                    )}
                    {getScreen()}
                </div>
            </div>
        </PublishedPortalProvider>
    );
};

export default PublishWizard;
