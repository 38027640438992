import { InfoToolTipType } from '../../../../types/design-system';

export type FontCategoryType = 'monospace' | 'sans-serif';
export type TypographySectionKeyType = 'heading' | 'body' | 'code';

export type TypographySectionType = {
    label: string;
    key: string;
    tooltip: InfoToolTipType;
};

export type TypographyConstantType = {
    label: string;
    subheading: string;
    sectionKey: TypographySectionKeyType;
    fontCategory: FontCategoryType;
    section: TypographySectionType[];
};

export const TypographyConstant: TypographyConstantType[] = [
    {
        label: 'Heading',
        fontCategory: 'sans-serif',
        sectionKey: 'heading',
        subheading: 'Select the typography for each heading level.',
        section: [
            {
                label: 'Heading Level 1',
                key: 'h1',
                tooltip: { enabled: false, message: '' },
            },
            {
                label: 'Heading Level 2',
                key: 'h2',
                tooltip: { enabled: false, message: '' },
            },
            {
                label: 'Heading Level 3',
                key: 'h3',
                tooltip: { enabled: false, message: '' },
            },
            {
                label: 'Heading Level 4',
                key: 'h4',
                tooltip: { enabled: false, message: '' },
            },
            {
                label: 'Heading Level 5',
                key: 'h5',
                tooltip: { enabled: false, message: '' },
            },
            {
                label: 'Heading Level 6',
                key: 'h6',
                tooltip: { enabled: false, message: '' },
            },
        ],
    },
    {
        label: 'Body',
        fontCategory: 'sans-serif',
        sectionKey: 'body',
        subheading: 'Select the typography for each body section.',
        section: [
            {
                label: 'Body 1',
                key: 'text1',
                tooltip: { enabled: true, message: 'Refers to the paragraph text.' },
            },
            {
                label: 'Body 2',
                key: 'text2',
                tooltip: {
                    enabled: true,
                    message: 'Refers to the typography in the Table of Contents.',
                },
            },
            {
                label: 'Body 3',
                key: 'text3',
                tooltip: {
                    enabled: true,
                    message: 'Refers to typography used for the API Explorer and API Console.',
                },
            },
        ],
    },
    {
        label: 'Code',
        fontCategory: 'monospace',
        sectionKey: 'code',
        subheading: 'Select the typography for block code snippets or inline code.',
        section: [
            {
                label: 'Block Code',
                key: 'blockCode',
                tooltip: { enabled: false, message: '' },
            },
            {
                label: 'Inline Code',
                key: 'inlineCode',
                tooltip: { enabled: false, message: '' },
            },
        ],
    },
];
