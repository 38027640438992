import React, { FunctionComponent, useReducer, ReactNode, createContext, useContext } from 'react';
import {
    headerReducer,
    IS_SAVE_CLICKED,
    RESET_IS_SAVE_CLICKED,
    IS_PUBLISH_CLICKED,
    RESET_PUBLISH_CLICKED,
} from './HeaderReducer';

export interface HeaderStateDef {
    isSaveClicked: boolean;
    isPublishClicked: boolean;
}

export interface HeaderProviderProps {
    children: ReactNode;
}

export interface HeaderProviderValue {
    headerState: HeaderStateDef;
    headerSaveClicked: (actionType: string) => void;
    resetHeaderState: () => void;
    headerPublishClicked: () => void;
    resetHeaderPublishClicked: () => void;
}

export type HeaderContextValue = undefined | HeaderProviderValue;

export const HeaderContext = createContext<HeaderContextValue>(undefined);

const HeaderProvider: FunctionComponent<HeaderProviderProps> = ({ children }) => {
    const initialState = {
        isSaveClicked: false,
        isPublishClicked: false,
    };

    const [headerState, dispatch] = useReducer(headerReducer, initialState);

    const headerSaveClicked = (actionType: string) => {
        dispatch({
            type: IS_SAVE_CLICKED,
            payload: {
                isSaveClicked: true,
                actionType: actionType,
            },
        });
    };

    const resetHeaderState = () => {
        dispatch({
            type: RESET_IS_SAVE_CLICKED,
        });
    };

    const headerPublishClicked = () => {
        dispatch({
            type: IS_PUBLISH_CLICKED,
            payload: {
                isPublishClicked: true,
            },
        });
    };

    const resetHeaderPublishClicked = () => {
        dispatch({
            type: RESET_PUBLISH_CLICKED,
        });
    };

    const value = {
        headerState: headerState,
        resetHeaderState: resetHeaderState,
        headerSaveClicked: headerSaveClicked,
        headerPublishClicked: headerPublishClicked,
        resetHeaderPublishClicked: resetHeaderPublishClicked,
    };

    return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

const useHeader = () => useContext(HeaderContext);

export { HeaderProvider, useHeader };
