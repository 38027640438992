import { getHeaders, fetcher } from './ApiClient';
import {
    TocType,
    MarkdownFileType,
    FilesToBeRenamedType,
} from '../components/CustomContent/CustomContentTypes';
import {
    adjustGeneratedContentOnSave,
    transformFileKey,
    transformFileName,
} from '../components/CustomContent/CustomContentConstant';

const formatToc = (body: TocType) => {
    // console.log(body);
    return {
        tableOfContents: JSON.stringify({
            toc: adjustGeneratedContentOnSave(JSON.parse(JSON.stringify(body))),
        }),
        tableOfContentsFrontEnd: JSON.stringify({ toc: body }),
    };
};

interface Markdown {
    tableOfContents: string;
    tableOfContentsFrontEnd: string;
    markdownFiles: MarkdownFileType;
}

const customContentService = function (apiEntityId: string) {
    const uri = `api-entities/${apiEntityId}/draft-portal-settings/content`;

    function getCustomContent(): Promise<{
        tableOfContents: string;
        tableOfContentsFrontEnd: string;
    }> {
        return fetcher<{ tableOfContents: string; tableOfContentsFrontEnd: string }>(
            `${uri}/table-of-contents`,
            'GET',
            getHeaders()
        );
    }

    function updateCustomContentMarkDownFile(body: TocType, fileContent: FilesToBeRenamedType) {
        const markdownFiles: MarkdownFileType = {};

        const transformedFile = transformFileName(fileContent.newName);
        markdownFiles[transformFileKey(fileContent.file)] = {
            title: transformedFile,
            name: fileContent.file,
            markdown: fileContent.markdown,
        };

        const tableOfContents = formatToc(body);
        return fetcher<Markdown>(
            `${uri}/markdown-file/${transformFileKey(fileContent.file)}`,
            'PUT',
            getHeaders(),
            {
                ...tableOfContents,
                markdownFiles,
            }
        );
    }

    function updateCustomContentMarkDownFiles(body: TocType, fileContent: FilesToBeRenamedType[]) {
        const markdownFiles: MarkdownFileType = {};

        fileContent.forEach((file) => {
            markdownFiles[transformFileKey(file.file)] = {
                title: transformFileName(file.newName),
                name: file.file,
                markdown: file.markdown,
                newPath: file.file,
                oldPath: file.file,
            };
        });

        const tableOfContents = formatToc(body);
        return fetcher<Markdown>(`${uri}/markdown-file`, 'PUT', getHeaders(), {
            ...tableOfContents,
            markdownFiles,
        });
    }

    function updateCustomContent(body: TocType) {
        const tableOfContents = formatToc(body);

        return fetcher<Markdown>(`${uri}/markdown-file/`, 'POST', getHeaders(), {
            ...tableOfContents,
            markdownFiles: {},
        });
    }

    function getMarkDownFile(pathKey: string): Promise<string> {
        return fetcher<string>(
            `${uri}/markdown-file/${transformFileKey(pathKey)}`,
            'GET',
            getHeaders()
        );
    }

    function deleteCustomContentMarkDownFiles(body: TocType, pathKeys: string[]) {
        const markdownFiles: MarkdownFileType = {};

        pathKeys.forEach((file) => {
            markdownFiles[file] = {};
        });

        const tableOfContents = formatToc(body);
        return fetcher<Markdown>(`${uri}/markdown-file`, 'DELETE', getHeaders(), {
            ...tableOfContents,
            markdownFiles,
        });
    }

    function uploadImageMarkDownFiles(formData: FormData): Promise<FormData> {
        return fetcher<FormData>(`${uri}/media-file/`, 'POST', getHeaders(true), formData, true);
    }

    return {
        getCustomContent,
        updateCustomContentMarkDownFile,
        updateCustomContentMarkDownFiles,
        updateCustomContent,
        getMarkDownFile,
        deleteCustomContentMarkDownFiles,
        uploadImageMarkDownFiles,
    };
};

export { customContentService };
