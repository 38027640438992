/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FunctionComponent, useState, useContext, useEffect, useCallback } from 'react';

import { FieldGroupCard, RadioButton } from '../../../../../design-system';
import TypographySectionDefault from './Default/TypographySectionDefault';
import TypographySectionAdvanced from './Advanced/TypographySectionAdvanced';
import { TypographyConstantType } from '../TypographyConstant';
import './TypographySection.scss';

import { H5 } from '../../../../../design-system/Headings/Headings';

import { BrandingContext } from '../../BrandingContext';
import { StoreProviderValue, useStore } from '../../../../../store/storeContext';
import { headingDefaultFont, bodyDefaultFont, codeDefaultFont } from '../../BrandingInitialState';

const getDefaultConstantValue = (sectionKey: string) => {
    switch (sectionKey) {
        case 'heading':
            return headingDefaultFont;
        case 'body':
            return bodyDefaultFont;
        case 'code':
            return codeDefaultFont;
        default:
            return headingDefaultFont;
    }
};

const radioList = [
    { label: 'Basic Settings', value: 'default', name: 'fontTypeSelector' },
    { label: 'Advanced Settings', value: 'advanced', name: 'fontTypeSelector' },
];

const TypographySection: FunctionComponent<TypographyConstantType> = ({
    label,
    subheading,
    section,
    fontCategory,
    sectionKey,
}) => {
    const { apiDocsBrandingState, changeDefaultFont, restAdvancedFont } = useContext(
        BrandingContext
    );

    const {
        storeState: {
            apiGroup: { apiDocsBranding },
        },
    } = useStore() as StoreProviderValue;

    const [fontsType, setFontsType] = useState('default');

    const defaultFontFamily = apiDocsBrandingState.typography[sectionKey].fontFamily;

    useEffect(() => {
        if (!defaultFontFamily) {
            setFontsType('advanced');
        } else {
            setFontsType('default');
        }
    }, [defaultFontFamily]);

    const setFontSetting = useCallback(
        (fontSetting: string) => {
            setFontsType(fontSetting);

            const typography = apiDocsBranding?.typography;

            const fontValue = typography && typography[sectionKey].fontFamily;

            if (fontSetting === 'default') {
                const defaultFont = fontValue || getDefaultConstantValue(sectionKey);

                changeDefaultFont(sectionKey, defaultFont);
            } else {
                typography && restAdvancedFont(typography, sectionKey);
            }
        },
        [apiDocsBranding?.typography, changeDefaultFont, restAdvancedFont, sectionKey]
    );

    return (
        <div className="typography-section">
            <H5>{label}</H5>
            <div className="flex fontSettings">
                {radioList.map((button, key) => {
                    return (
                        <span key={button.value}>
                            <RadioButton
                                defaultChecked={button.value === fontsType}
                                key={key}
                                value={button.value}
                                label={button.label}
                                name={button.name + label}
                                onChange={(e) => {
                                    setFontSetting(e.target.value);
                                }}
                            />
                        </span>
                    );
                })}
            </div>
            <FieldGroupCard className="flex-row items-center justify-center p-6">
                {fontsType === 'default' ? (
                    <div className="flex justify-between">
                        <TypographySectionDefault
                            fontCategory={fontCategory}
                            sectionKey={sectionKey}
                        />
                    </div>
                ) : (
                    <div className="relative flex justify-between">
                        <TypographySectionAdvanced
                            ItemList={section}
                            fontCategory={fontCategory}
                            sectionKey={sectionKey}
                        />
                    </div>
                )}
            </FieldGroupCard>
        </div>
    );
};

export default TypographySection;
