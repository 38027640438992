import { ExportFormatValueDef, ExportTemplateValueDef } from './ApiSpecExportInitialState';

export interface ApiSpecExportConstantsDef {
    name: string;
    value: ExportFormatValueDef;
    templates: ApiSpecExportTemplateDef[];
}

export interface ApiSpecExportTemplateDef {
    value: ExportTemplateValueDef;
    name: string;
    extension: string;
    enabled: boolean;
}

/**
 * Definitions for all export formats
 */
export const EXPORT_SPEC_INFO_TEXT = 'Please select atleast one specification export format';

export const ApiSpecExportConstants: ApiSpecExportConstantsDef[] = [
    {
        name: 'OpenAPI',
        value: 'openApiSpec',
        templates: [
            {
                value: 'OpenApi31Json',
                name: 'OAS 3.1 (JSON)',
                extension: 'openapi.json',
                enabled: true,
            },
            {
                value: 'OpenApi31Yaml',
                name: 'OAS 3.1 (YAML)',
                extension: 'openapi.yaml',
                enabled: true,
            },
            {
                value: 'OpenApi3Json',
                name: 'OAS 3.0.0 (JSON)',
                extension: 'openapi.json',
                enabled: true,
            },
            {
                value: 'OpenApi3Yaml',
                name: 'OAS 3.0.0 (YAML)',
                extension: 'openapi.yaml',
                enabled: true,
            },
            {
                value: 'Swagger20',
                name: 'OAS 2.0 JSON',
                extension: 'swagger.json',
                enabled: true,
            },
            {
                value: 'SwaggerYaml',
                name: 'OAS 2.0 YAML',
                extension: 'swagger.yaml',
                enabled: true,
            },
            {
                value: 'Swagger10',
                name: 'OAS 1.2',
                extension: 'swagger.json',
                enabled: true,
            },
        ],
    },

    {
        name: 'RAML',
        value: 'raml',
        templates: [
            {
                value: 'RAML10',
                name: 'RAML 1.0',
                extension: 'raml',
                enabled: true,
            },
            {
                value: 'RAML',
                name: 'RAML 0.8',
                extension: 'raml',
                enabled: true,
            },
        ],
    },

    {
        name: 'API Blueprint',
        value: 'apiBlueprint',
        templates: [
            {
                value: 'APIBluePrint',
                name: 'API Blueprint',
                extension: 'apib',
                enabled: true,
            },
        ],
    },

    {
        name: 'Insomnia',
        value: 'insomnia',
        templates: [
            {
                value: 'Insomnia',
                name: 'Insomnia Export Format (JSON)',
                extension: 'insomnia.json',
                enabled: true,
            },
            {
                value: 'InsomniaYaml',
                name: 'Insomnia Export Format (YAML)',
                extension: 'insomnia.yaml',
                enabled: true,
            },
        ],
    },

    {
        name: 'WADL',
        value: 'wadl',
        templates: [
            {
                value: 'WADL2009',
                name: 'WADL',
                extension: 'wadl',
                enabled: true,
            },
        ],
    },

    {
        name: 'WSDL',
        value: 'wsdl',
        templates: [
            {
                value: 'WSDL',
                name: 'WSDL',
                extension: 'wsdl',
                enabled: true,
            },
        ],
    },

    {
        name: 'APIMatic Format',
        value: 'apimatic',
        templates: [
            {
                value: 'APIMATIC',
                name: 'APIMatic Format',
                extension: 'apimatic.json',
                enabled: true,
            },
        ],
    },

    {
        name: 'Postman',
        value: 'postman',
        templates: [
            {
                value: 'Postman20',
                name: 'Postman 2.0',
                extension: 'postman_collection.json',
                enabled: true,
            },
            {
                value: 'Postman10',
                name: 'Postman 1.0',
                extension: 'postman_collection.json',
                enabled: true,
            },
        ],
    },
];

export const ApiSpecExportStoreKey = 'apiSpecExport';
