import { BadgeDef } from '../Badges/BadgeConstants';
import React, { FunctionComponent, useState } from 'react';
import { BadgeSelector } from '../BadgeSelector/BadgeSelector';
import { Button } from '../../../../design-system';

interface AddBadgeAcrionProps {
    inactiveBadges: BadgeDef[];
    addBadge: (selectedValue: string) => void;
}

const AddBadgeAction: FunctionComponent<AddBadgeAcrionProps> = (props) => {
    const [activeScreen, setActiveScreen] = useState('screen_1');
    const { inactiveBadges, addBadge } = props;

    const addBadgeCicked = () => {
        setActiveScreen('screen_2');
    };

    const onBadgeListChange = (selectedValue: string) => {
        setActiveScreen('screen_1');
        addBadge(selectedValue);
    };

    const onBadgeSelectorClick = () => {
        setActiveScreen('screen_1');
    };

    return (
        <div className="flex items-center justify-center w-2/6 px-3 py-5 mx-3 mt-6 border border-solid badge-selector-wrapper bg-snow-500 hover:bg-white border-goose-600 rounded-12">
            {activeScreen === 'screen_1' && (
                <Button
                    text="+ NEW BADGE"
                    className="flex-item-es text-apimatic-blue add-badge-action"
                    fill="transparent"
                    onClick={addBadgeCicked}
                />
            )}

            {activeScreen === 'screen_2' && (
                <BadgeSelector
                    inactiveBadges={inactiveBadges}
                    onChange={onBadgeListChange}
                    onCancel={onBadgeSelectorClick}
                />
            )}
        </div>
    );
};

export default AddBadgeAction;
