import React, { FunctionComponent, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { H3, H5 } from '../../design-system/Headings/Headings';
import useOutsideClick from '../../hooks/useOutsideClick';
import useUpdateEffect from '../../hooks/UseUpdateEffect';
import { ChevronIcon } from '../../icons/Ui/ChevronIcon';
import { MinimizeIcon } from '../../icons/Ui/MinimizeIcon';
import CustomContentView from '../../views/CustomContentView/CustomContentView';
import Card from '../Card/Card';

import './GuidePreview.scss';

const GuidePreview: FunctionComponent = () => {
    const location = useLocation();
    const canShowGuideFn = () => {
        return (
            location.pathname.includes('documentation-settings') ||
            location.pathname.includes('generated-content') ||
            location.pathname.includes('custom-pages')
        );
    };
    const [show, setShow] = useState(false);
    const [canShowGuidePreview, setCanShowGuidePreview] = useState(canShowGuideFn());

    const ref = useRef(null);
    useOutsideClick(ref, () => {
        if (show) {
            setShow(false);
        }
    });

    useUpdateEffect(() => {
        setCanShowGuidePreview(canShowGuideFn());
    }, [location]);

    return (
        <>
            {canShowGuidePreview && (
                <>
                    <button
                        className={'guideQuickViewToggle' + (show ? ' opaque' : '')}
                        onClick={() => setShow(true)}
                    >
                        <Card className="p-4 text-white bg-opacity-100 border border-solid bg-charcoal-800 border-goose-600">
                            <div className="flex items-center">
                                <div className="flex w-full font-normal text-14">Quick View</div>
                                <ChevronIcon fill="white" className="rotate-180"/>
                            </div>
                        </Card>
                    </button>

                    {show && <div className="overlay"></div>}

                    {show && (
                        <Card
                            className={
                                'guideQuickView border border-solid border-goose-600' +
                                (show ? ' opaque' : '')
                            }
                        >
                            <div ref={ref}>
                                <div className="flex items-center w-full mb-4">
                                    <H3 className="flex w-full font-bold">Quick View</H3>
                                    <button type="button" onClick={() => setShow(false)}>
                                        <MinimizeIcon />
                                    </button>
                                </div>
                                <CustomContentView></CustomContentView>
                            </div>
                        </Card>
                    )}
                </>
            )}
        </>
    );
};

export default GuidePreview;
