import {
    canGeneratedContentRenamedOnLevel,
    getDefaultErrorMessage,
    DUPLICATE_FILE_ERROR,
    DUPLICATE_GENERATED_CONTENT_ERROR,
    DUPLICATE_SECTION_ERROR,
    DUPLICATE_SLUG_ERROR,
    findFileIndex,
    findGeneratedContentIndex,
    findSectionIndex,
    findSlugIndex,
    reorderList,
    UNEXPECTED_ERROR,
} from '../CustomContentConstant';
import {
    ActionType,
    ADD_FILE_TYPE,
    ADD_NEW_DYNAMIC_ELEMENT_TYPE,
    ADD_SECTION_TYPE,
    DELETE_FILE_TYPE,
    DELETE_GENERATED_CONTENT_TYPE,
    DELETE_SECTION_TYPE,
    DISABLE_GENERATED_CONTENT_TYPE,
    ToCItemError,
    RENAME_FILE_TYPE,
    RENAME_GENERATED_CONTENT,
    RENAME_SECTION_TYPE,
    ToCElementType,
    ToCFileType,
    ToCItemsType,
} from '../CustomContentTypes';

const addSection = (state: ToCItemsType, action: ADD_SECTION_TYPE) => {
    const { group, slug } = action.payload;
    const index = findSectionIndex(state, group);
    const slugIndex = findSlugIndex(state, slug);

    const errors: ToCItemError = getDefaultErrorMessage();

    if (slugIndex !== -1) {
        errors.slug = DUPLICATE_SLUG_ERROR.replace('{{title}}', slug);
    }

    if (index !== -1) {
        errors.name = DUPLICATE_SECTION_ERROR.replace('{{title}}', group);
    }

    if (errors.slug || errors.name) throw errors;

    action.resolve(true);
    return [{ group, slug, items: [] }, ...state];
};

const addFile = (state: ToCItemsType, action: ADD_FILE_TYPE) => {
    const { file, page, slug } = action.payload;
    const index = findFileIndex(state, action.payload.page, 'page');

    const slugIndex = findSlugIndex(state, slug);
    const errors: ToCItemError = getDefaultErrorMessage();

    if (slugIndex !== -1) {
        errors.slug = DUPLICATE_SLUG_ERROR.replace('{{title}}', slug);
    }

    if (index !== -1) {
        errors.name = DUPLICATE_FILE_ERROR.replace('{{title}}', page);
    }

    if (errors.slug || errors.name) throw errors;

    action.resolve({ file, page, slug });
    return [{ file, page, slug }, ...state];
};

const renameSection = (state: ToCItemsType, action: RENAME_SECTION_TYPE) => {
    const { newGroup, slug, oldGroup } = action.payload;

    const index = findSectionIndex(state, oldGroup);
    const newGroupIndex = findSectionIndex(state, newGroup);
    const oldObj = state[index] as ToCElementType;

    const slugIndex = findSlugIndex(state, slug);
    const errors: ToCItemError = getDefaultErrorMessage();

    if (slugIndex !== -1 && slugIndex !== index) {
        errors.slug = DUPLICATE_SLUG_ERROR.replace('{{title}}', slug);
    }

    if (newGroupIndex !== -1 && newGroupIndex !== index) {
        errors.name = DUPLICATE_SECTION_ERROR.replace('{{title}}', newGroup);
    }

    if (errors.slug || errors.name) throw errors;

    action.resolve(true);
    return [
        ...state.slice(0, index),
        {
            ...oldObj,
            group: newGroup,
            slug,
        },
        ...state.slice(index + 1),
    ];
};

const renameFile = (state: ToCItemsType, action: RENAME_FILE_TYPE) => {
    const { slug, page } = action.payload.newFile;

    const { file: oldFile } = action.payload.oldFile;

    const index = findFileIndex(state, oldFile, 'file');
    const newFileIndex = findFileIndex(state, page, 'page');
    const oldObj = state[index] as ToCFileType;

    const slugIndex = findSlugIndex(state, slug);
    const errors: ToCItemError = getDefaultErrorMessage();

    if (slugIndex !== -1 && slugIndex !== index) {
        errors.slug = DUPLICATE_SLUG_ERROR.replace('{{title}}', slug);
    }

    if (newFileIndex !== -1 && newFileIndex !== index) {
        errors.name = DUPLICATE_FILE_ERROR.replace('{{title}}', page);
    }

    if (errors.slug || errors.name) throw errors;

    action.resolve(oldObj);
    return [
        ...state.slice(0, index),
        {
            ...oldObj,
            ...action.payload.newFile,
        },
        ...state.slice(index + 1),
    ];
};

const renameGeneratedContent = (state: ToCItemsType, action: RENAME_GENERATED_CONTENT) => {
    const { from, newName, resolve } = action.payload;
    const index = findGeneratedContentIndex(state, from);
    const oldObj = state[index] as ToCElementType;

    if (index !== -1) {
        if (canGeneratedContentRenamedOnLevel(state, newName)) {
            resolve('');
            return [
                ...state.slice(0, index),
                {
                    ...oldObj,
                    generate: newName,
                },
                ...state.slice(index + 1),
            ];
        } else {
            throw {
                custom: true,
                title: DUPLICATE_GENERATED_CONTENT_ERROR.replace('{{title}}', newName),
                slug: '',
            };
        }
    }

    throw { custom: true, title: UNEXPECTED_ERROR, slug: '' };
};

const deleteSection = (state: ToCItemsType, action: DELETE_SECTION_TYPE) => {
    const index = findSectionIndex(state, action.payload.group);
    return index !== -1
        ? [
              ...state.slice(0, index),
              ...state.slice(index + 1),
              ...action.payload.generatedContentList,
          ]
        : [...state];
};

const deleteFile = (state: ToCItemsType, action: DELETE_FILE_TYPE) => {
    const index = findFileIndex(state, action.payload.file, 'file');
    action.payload.resolve(action.payload.file);
    return index !== -1 ? [...state.slice(0, index), ...state.slice(index + 1)] : [...state];
};

const deleteGeneratedContent = (state: ToCItemsType, action: DELETE_GENERATED_CONTENT_TYPE) => {
    const index = findGeneratedContentIndex(state, action.payload.content.from);
    if (index !== -1) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    return state;
};

const disableGeneratedContent = (state: ToCItemsType, action: DISABLE_GENERATED_CONTENT_TYPE) => {
    const index = findGeneratedContentIndex(state, action.payload.content.from);
    if (index !== -1) {
        return [...state.slice(0, index), { ...action.payload.content }, ...state.slice(index + 1)];
    }
    return state;
};

// Ensuring all the rules for example unique slug will be the responsibility of higher level
const addNewDynamicElement = (state: ToCItemsType, action: ADD_NEW_DYNAMIC_ELEMENT_TYPE) => {
    return action.payload.isReorderOnSamelevel
        ? (reorderList(state, action.payload.sourceIndex, action.payload.index) as ToCItemsType)
        : [
              ...state.slice(0, action.payload.index),
              { ...action.payload.newObj },
              ...state.slice(action.payload.index),
          ];
};

const reducerMapper: Record<string, (state: ToCItemsType, action: any) => ToCItemsType> = {
    ADD_SECTION: addSection,
    ADD_FILE: addFile,
    RENAME_SECTION: renameSection,
    RENAME_FILE: renameFile,
    RENAME_GENERATED_CONTENT: renameGeneratedContent,
    DELETE_SECTION: deleteSection,
    DELETE_FILE: deleteFile,
    DELETE_GENERATED_CONTENT: deleteGeneratedContent,
    DISABLE_GENERATED_CONTENT: disableGeneratedContent,
    ADD_NEW_DYNAMIC_ELEMENT: addNewDynamicElement,
};

export const caseReducer = (state: ToCItemsType, action: ActionType) => {
    return reducerMapper[action.type](state, action);
};
