import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export let ModelsIcon = (props: SVGIconProps) => (
    <SVGIcon width="12.085" height="11.991" viewBox="0 0 12.085 11.991" {...props} fill="none">
        <g transform="translate(-19.314 -491.629)">
            <path
                fill="none"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeWidth="1.6px"
                d="M2013.6,3015.66h-3.485v10.391h3.485"
                transform="translate(-1990 -2523.231)"
            />
            <path
                fill="none"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeWidth="1.6px"
                d="M2010.114,3015.66h3.485v10.391h-3.485"
                transform="translate(-1983 -2523.231)"
            />
        </g>
    </SVGIcon>
);
