import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '../../design-system';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import './publish-wizard.scss';

const PublishWizardFooter: FunctionComponent = (props) => {
    const {
        storeState: { previousView },
    } = useStore() as StoreProviderValue;

    const navigate = useNavigate();

    const { children } = props;

    const close = () => {
        navigate(previousView ? previousView.split('#')[1] : './../../portal-settings/home-page');
    };
    return (
        <div className="flex justify-between pt-3 mt-12 widget-footer">
            <Button text="Cancel" fill="transparent" onClick={close} />
            {children}
        </div>
    );
};
export default PublishWizardFooter;
