import React, { FunctionComponent, useRef, useState } from 'react';
import { FieldBox, Button } from '../../../../design-system';
import { FileType } from './LogoConstant';
import './Logo.scss';
import FileSelector from '../../../../design-system/FileSelector/FileSelector';
import { EllipsisIcon } from '../../../../icons/Ui/EllipsisIcon';
import LogoLinkCard from './LogoLinkCard/LogoLinkCard';
import { useHomePage, HomePageProviderValue } from '../HomePageContext';
import useOutsideClick from '../../../../hooks/useOutsideClick';

const Logo: FunctionComponent = () => {
    const {
        homePageState: { logo },
        changeLogo,
        updateLogoUri,
        removeLogo,
    } = useHomePage() as HomePageProviderValue;

    const [toggleLogoLinkCard, setToggleLogoLinkCard] = useState(false);

    const _handleLogoSelected = (files: FileType) => {
        if (files) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = function (e) {
                updateLogoUri(reader.result as string);
                changeLogo(files);
            };
        }
    };

    const ref = useRef(null);
    useOutsideClick(ref, () => {
        setToggleLogoLinkCard(false);
    });

    return (
        <div className="flex w-full space-around homepage-logo-wrapper">
            {!logo?.uri && (
                <FileSelector
                    className="file-button"
                    text="+ ADD LOGO"
                    fill="link"
                    file={logo?.file}
                    onChange={_handleLogoSelected}
                />
            )}
            {logo?.uri && (
                <div className="relative flex items-center">
                    <div className="mr-2">
                        <img src={logo?.uri} alt="" />
                    </div>
                    <div ref={ref}>
                        <Button
                            text=""
                            fill="default"
                            icon={<EllipsisIcon className="rotate-90" />}
                            onClick={() => setToggleLogoLinkCard(!toggleLogoLinkCard)}
                        />

                        {toggleLogoLinkCard && (
                            <LogoLinkCard
                                onCancel={() => setToggleLogoLinkCard(!toggleLogoLinkCard)}
                                onDelete={() => removeLogo()}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Logo;
