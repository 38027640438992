import PublishedPackages from '../PublishedPackages/PublishedPackages';

export const UPDATE_PLATFORMS_STATE = 'UPDATE_PLATFORMS_STATE';
export const ON_PLATFORM_INPUT_CHANGE = 'ON_PLATFORM_INPUT_CHANGE';
export const ON_SDK_DOWNLOAD_OPTION_CHANGE = 'ON_SDK_DOWNLOAD_OPTION_CHANGE';
export const ON_SDK_SETTINGS_CHANGE = 'ON_SDK_SETTINGS_CHANGE';
export const ON_PLATFORM_DRAG_END = 'ON_PLATFORM_DRAG_END';
export const ON_HTTP_INPUT_CHANGE = 'ON_HTTP_INPUT_CHANGE';
export const UPDATE_PUBLISHED_PACKAGE = 'UPDATE_PUBLISHED_PACKAGE';
export const platformsReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_PLATFORMS_STATE: {
            return action.payload;
            break;
        }

        case ON_PLATFORM_INPUT_CHANGE: {
            return {
                ...state,
                sdkPlatforms: {
                    ...state.sdkPlatforms,
                    [action.payload.currentTemplate]: {
                        ...state.sdkPlatforms[action.payload.currentTemplate],
                        [action.payload.name]: action.payload.value,
                    },
                },
            };
            break;
        }

        case ON_SDK_DOWNLOAD_OPTION_CHANGE: {
            return {
                ...state,
                sdkPlatforms: {
                    ...state.sdkPlatforms,
                    [action.payload.currentTemplate]: {
                        ...state.sdkPlatforms[action.payload.currentTemplate],
                        apimaticSdk: action.payload.apimaticSdk,
                    },
                },
            };
            break;
        }

        case UPDATE_PUBLISHED_PACKAGE: {
            return {
                ...state,
                sdkPlatforms: {
                    ...state.sdkPlatforms,
                    [action.payload.currentTemplate]: {
                        ...state.sdkPlatforms[action.payload.currentTemplate],
                        publishedPackage: action.payload.newPublishedPackage,
                    },
                },
            };
            break;
        }

        case ON_HTTP_INPUT_CHANGE: {
            return {
                ...state,
                http: {
                    ...state.http,
                    [action.payload.name]: action.payload.value,
                },
            };
            break;
        }

        case ON_PLATFORM_DRAG_END:
            return action.playload;
            break;

        default:
            return state;
            break;
    }
};
