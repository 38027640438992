import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const EndPointIcon = (props: SVGIconProps) => (
    <SVGIcon width="13.885" height="11.6" viewBox="0 0 13.885 11.6" {...props} fill="none">
        <g transform="translate(-21.115 -488.7)">
            <g
                fill={props.fill || DEFAULT_SVG_COLOR}
                stroke={props.fill || DEFAULT_SVG_COLOR}
                transform="translate(28 491)"
            >
                <circle stroke="none" cx="3.5" cy="3.5" r="3.5" />
                <circle fill="none" cx="3.5" cy="3.5" r="2.7" />
            </g>
            <path
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeWidth="1.6px"
                fill="none"
                d="M2010.114,3015.66h3.485v10h-3.485"
                transform="translate(-1989 -2526.16)"
            />
            <path
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeWidth="1.6px"
                fill="none"
                d="M2010.114,3015.66h3.485"
                transform="translate(-1985 -2521.16)"
            />
        </g>
    </SVGIcon>
);
