export const CustomPageStoreKey = 'currentCustomPageData';

export const UPDATE_STATE = 'UPDATE_STATE';

const SEPERATOR = '$_$';
export const pathKeyToPath = (pathKey: string, title: string) => {
    const filePath = pathKey.split(SEPERATOR);
    filePath.pop();
    return { filePath, title: title ? title : '' };
};

export const pathKeyToPathString = (pathKey: string) => {
    return pathKey.split('$_$').join('/');
};
