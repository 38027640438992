import React, { FunctionComponent, Fragment, useState } from 'react';
import cx from 'classnames';

import {
    NotificationProviderValue,
    useNotification,
} from '../../Notification/NotificationBarContext';
import { StoreProviderValue, useStore } from '../../../store/storeContext';
import { useHeader, HeaderProviderValue } from '../../Header/HeaderContext';
import { useApiSpecExport, ApiSpecExportProviderValue } from './ApiSpecExportContext';
import useUpdateEffect from '../../../hooks/UseUpdateEffect';
import { updateApiSpecExport } from '../../../api-client/ApiSpecExportService';
import { ToggleSwitch } from '../../../design-system';
import Card from '../../Card/Card';
import ApiSpecExportFormat from './ApiSpecExportFormat';
import ConfirmUnsavedChanges from '../../ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import useConfirmUnsavedChanges from '../../../hooks/useConfirmUnsavedChanges';
import { ApiSpecExportStoreKey, EXPORT_SPEC_INFO_TEXT } from './ApiSpecExportConstants';
import { H4, SubHeading } from '../../../design-system/Headings/Headings';
import NotificationBar from '../../Notification/NotificationBar';
import SaveAction from '../../SaveAction/SaveAction';
import { utilityFunctions } from '../../../utilities/functions';
import Tooltip from '../../../design-system/Tooltip/Tooltip';
import Spinner from '../../../design-system/Spinner/spinner';
import { InfoIcon } from '../../../icons/Ui/InfoIcon';
import { ApiSpecExportStateDef } from './ApiSpecExportInitialState';

type InfoMessageType = 'success' | 'warning' | 'info';

interface InfoIconMessageProps {
    show: boolean;
    type: InfoMessageType;
}

const getInfoMessageType = (type: InfoMessageType) => {
    switch (type) {
        case 'warning':
            return {
                textColor: 'text-red',
                fill: '#FF4451',
            };
            break;
        case 'success':
            return {
                textColor: 'text-green',
                fill: '#28c397',
            };
            break;
        case 'info':
            return {
                textColor: 'text-goose-700',
                fill: '#9caab8',
            };
            break;
        default:
            return {
                textColor: 'text-goose-700',
                fill: '#9caab8',
            };
            break;
    }
};

const InfoIconMessage: FunctionComponent<InfoIconMessageProps> = ({ show, type }) => {
    const { textColor, fill } = getInfoMessageType(type);

    if (!show) return null;

    return (
        <div className={cx('flex items-center', textColor)}>
            <InfoIcon fill={fill} className="pl-2" width="20px"></InfoIcon>
            <span className="pl-2 text-14">
                <p>{EXPORT_SPEC_INFO_TEXT}</p>
            </span>
        </div>
    );
};
const toggleInfoIcon = (apiSpecExportState: ApiSpecExportStateDef) => {
    const { checkApiSpecExport } = utilityFunctions;
    const { exportFormats, enabled } = apiSpecExportState;
    return checkApiSpecExport(exportFormats) && enabled;
};

const ApiSpecExport: FunctionComponent = () => {
    const { addNotification } = useNotification() as NotificationProviderValue;
    const {
        storeState: { activeApiEntityId },
        updateStoreApiEntity,
    } = useStore() as StoreProviderValue;
    const { headerState, resetHeaderState } = useHeader() as HeaderProviderValue;

    const {
        uiState,
        apiSpecExportState,
        updateUiState,
        onExportEnabledChange,
    } = useApiSpecExport() as ApiSpecExportProviderValue;

    const [showModal, setShowModal] = useState(false);
    const [discard, setDiscard] = useState(false);

    useUpdateEffect(() => {
        if (headerState.isSaveClicked) {
            saveData();
            resetHeaderState();
        }
    }, [headerState]);

    const saveData = () => {
        const { checkApiSpecExport, getSavingUiState } = utilityFunctions;
        const { exportFormats, enabled } = apiSpecExportState;

        const checkApiSpecExist = checkApiSpecExport(exportFormats);

        const isEnabled = !enabled ? enabled : !checkApiSpecExist;

        const apiSpecExport = {
            ...apiSpecExportState,
            enabled: isEnabled,
        };

        updateUiState(getSavingUiState());
        const promise = updateApiSpecExport(activeApiEntityId, apiSpecExport);
        promise
            .then((r) => {
                addNotification({
                    show: true,
                    type: 'success',
                    message: 'API Spec Export settings saved successfully.',
                });

                updateStoreApiEntity(apiSpecExport, 'apiSpecExport', activeApiEntityId);
                onExportEnabledChange(isEnabled);
            })
            .catch(() => {
                addNotification({
                    show: true,
                    type: 'error',
                    message: `Failed to save API Spec Export settings.`,
                });
            })
            .finally(() => {
                updateUiState(utilityFunctions.getDefaultUiState());
            });
    };

    useConfirmUnsavedChanges(
        discard,
        apiSpecExportState,
        'apiEntities',
        () => {
            setShowModal(true);
        },
        ApiSpecExportStoreKey,
        activeApiEntityId
    );

    const saveChanges = () => {
        setShowModal(false);
        saveData();
    };

    const discardChanges = () => {
        setShowModal(false);
        setDiscard(true);
    };
    const isToggleInfoIcon = toggleInfoIcon(apiSpecExportState);

    const renderApiSpecExportView = () => (
        <div className="p-8 pt-0">
            <NotificationBar />
            <ConfirmUnsavedChanges
                show={showModal}
                closeModal={setShowModal}
                discardChanges={discardChanges}
                saveChanges={saveChanges}
            />
            <SaveAction
                disabled={uiState.isLoading}
                isSaving={uiState.isSaving!}
                onClick={saveChanges}
            />
            <div className="flex items-center mt-10 mb-3">
                <H4 className="mr-4">API Spec Export</H4>
                <Tooltip
                    message="Toggle the API Spec export button in the portal."
                    position="right"
                >
                    <ToggleSwitch
                        className="toggle-switch-api-spec-export"
                        label=""
                        checked={apiSpecExportState.enabled}
                        onChange={(e) => onExportEnabledChange(e.target.checked)}
                    />
                </Tooltip>
                <InfoIconMessage show={isToggleInfoIcon} type="warning" />
            </div>
            <SubHeading text="Select which specification formats and versions you want your API to be exported in"></SubHeading>
            <Card>
                <ApiSpecExportFormat exportSpecDisabled={!apiSpecExportState.enabled} />
            </Card>
        </div>
    );

    const renderLoadingView = () => (
        <div className="flex items-center justify-center w-full h-full">
            <Spinner size="xl" />
        </div>
    );

    const renderErrorView = () => (
        <div className="flex items-center justify-center w-full h-full">
            <p className="text-base font-medium text-red ">
                {uiState.message
                    ? `${uiState.statusCode} - ${uiState.message}`
                    : 'Oops! Something went wrong.'}
            </p>
        </div>
    );

    return (
        <Fragment>
            {uiState.isLoading
                ? renderLoadingView()
                : uiState.isError
                ? renderErrorView()
                : renderApiSpecExportView()}
        </Fragment>
    );
};

export default ApiSpecExport;
