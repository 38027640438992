import React, { FunctionComponent, ChangeEvent, useState, useEffect, ReactNode } from 'react';
import { FileType } from '../../components/api-docs/Branding/Logo/LogoConstant';
import { Button } from '..';
import '../Button/button.scss';

interface FileSelectorProps {
    id?: string;
    className?: string;
    text: string;
    file: FileType | undefined;
    fill: 'transparent' | 'default' | 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link';
    icon?: ReactNode;
    onChange: (file: FileType) => void;
}

const FileSelector: FunctionComponent<FileSelectorProps> = ({
    id,
    className,
    text,
    file,
    icon,
    fill,
    onChange,
}) => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    // useEffect(() => {
    //     if (hiddenFileInput && hiddenFileInput.current) {
    //         hiddenFileInput.current!.value = "";
    //     }
    // }, [file]);

    const handleClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current!.value = '';
            hiddenFileInput.current!.click();
        }
    };

    return (
        <>
            <Button
                id={id}
                className={className}
                text={text}
                fill={fill}
                icon={icon}
                onClick={handleClick}
            />
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={(e) => onChange(e.target.files)}
                className="hidden"
            />
        </>
    );
};

export default FileSelector;
