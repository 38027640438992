import React, { FunctionComponent } from 'react';
import { ApiSpecExportTemplateDef } from './ApiSpecExportConstants';
import { ExportFormatValueDef, ExportTemplate } from './ApiSpecExportInitialState';
import { useApiSpecExport, ApiSpecExportProviderValue } from './ApiSpecExportContext';
import Checkbox from '../../../design-system/Checkbox/Checkbox';

interface ApiSpecExportTemplateProps {
    template: ApiSpecExportTemplateDef;
    format: ExportFormatValueDef;
    disableCheckbox?: boolean;
}

const ApiSpecExportTemplate: FunctionComponent<ApiSpecExportTemplateProps> = (props) => {
    const { template, format, disableCheckbox } = props;
    const {
        apiSpecExportState,
        onExportTemplateChange,
    } = useApiSpecExport() as ApiSpecExportProviderValue;
    const filteredTemplateState = apiSpecExportState.exportFormats[format].find(
        (t: ExportTemplate) => t.exportFormat === template.value
    );
    // console.log('template', template);
    // console.log('filtered', format);
    return (
        <div className="m-3">
            <Checkbox
                label={template.name}
                name={`export-format-${template.value}`}
                checked={filteredTemplateState ? filteredTemplateState.enabled : false}
                onChange={(e) => onExportTemplateChange(template.value, format, e.target.checked)}
                disabled={disableCheckbox}
            />
        </div>
    );
};

export default ApiSpecExportTemplate;
