import React, { FunctionComponent, useEffect } from 'react';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { AIChatbotKeyForSidenav, SideNavActiveItemStoreKey } from '../ViewConstant';
import { AIChatbotProvider } from '../../components/api-docs/AIChatbot/AIChatbotContext';
import AIChatbot from '../../components/api-docs/AIChatbot/AIChatbot';

const AIChatbotView: FunctionComponent = () => {
    const { updateStore } = useStore() as StoreProviderValue;
    useEffect(() => {
        updateStore(AIChatbotKeyForSidenav, SideNavActiveItemStoreKey);
    }, []);
    return (
        <div className="w-full h-full">
            <AIChatbotProvider>
                <AIChatbot />
            </AIChatbotProvider>
        </div>
    );
};

export default AIChatbotView;
