import React, { FunctionComponent, useEffect, useState } from 'react';
import { PackageDeploymentDef, PlatformTemplateType } from '../Platforms/PlatformConstants';
import { PublishedPackageDef } from '../Platforms/PlatformInitialState';
import PackageDeployment from '../PackageDeployment/PackageDeployment';
import Package from '../Package/Package';
import { usePlatforms, PlatformsProviderValue } from '../Platforms/PlatformsContext';
import { Button } from '../../../design-system';
import Tooltip from '../../../design-system/Tooltip/Tooltip';
import { utilityFunctions } from '../../../utilities/functions';

interface PublishedPackagesProps {
    templateValue: PlatformTemplateType;
    packageDeployment: PackageDeploymentDef;
    // packageDeploymentInformation: PackageDeploymentInformationDef;
    // onPublishPackageChange: OnPublishPackageChangeType;
}

const PublishedPackages: FunctionComponent<PublishedPackagesProps> = (props) => {
    const { templateValue, packageDeployment } = props;
    const [isPackagePublished, setIsPackagePublished] = useState(false);

    const {
        platformsState: { sdkPlatforms, packageRepositoriesSettings },
        updatePublishedPackage,
        form,
    } = usePlatforms() as PlatformsProviderValue;

    const { publishedPackage: publishedPackageState } = sdkPlatforms[templateValue];
    const [activeScreen, setActiveScreen] = useState('screen_1');

    useEffect(() => {
        if (publishedPackageState && publishedPackageState.packageSettings) {
            const isPublished =
                Object.keys(publishedPackageState.packageSettings).findIndex(
                    (e) => publishedPackageState.packageSettings[e]
                ) !== -1;

            setIsPackagePublished(isPublished);
            if (isPublished) {
                setActiveScreen('screen_2');
            } else {
                setActiveScreen('screen_1');
            }
        }
    }, [publishedPackageState]);

    const onCancel = () => {
        setActiveScreen('screen_1');

        const emptyObj = Object.keys(publishedPackageState.packageSettings).reduce(
            (accumulator: any, key) => {
                accumulator[key] = '';
                return accumulator;
            },
            {}
        );

        updatePublishedPackage(
            {
                packageRepository: publishedPackageState.packageRepository,
                packageSettings: {
                    ...emptyObj,
                },
            },
            templateValue
        );
    };

    const onSave = (packageInfo: PublishedPackageDef) => {
        if (!utilityFunctions.formIsValid(form)) {
            return;
        }
        setActiveScreen('screen_2');
        updatePublishedPackage(packageInfo, templateValue);
    };
    const renderPackage = (
        <Package
            publishedPackageState={publishedPackageState}
            form={form}
            isPackagePublished={isPackagePublished}
            packageRepositoriesSettings={packageRepositoriesSettings[templateValue]}
            packageDeployment={packageDeployment}
            onSave={onSave}
            onCancel={onCancel}
        />
    );

    const renderPackageDeployment = (
        <PackageDeployment
            packageDeployment={packageDeployment}
            onSave={onSave}
            onCancel={onCancel}
        />
    );

    const renderPackagePublishActions = (
        <div>
            <Tooltip
                message="Add package information for this SDK if you are publishing the package yourself."
                position="right"
            >
                <Button
                    text="Add Self-Published Package"
                    fill="raised-link"
                    onClick={() => setActiveScreen('screen_2')}
                />
            </Tooltip>

            {packageDeployment.supported && (
                <Button
                    text="Publish via APIMatic"
                    fill="raised-link"
                    onClick={() => setActiveScreen('screen_3')}
                />
            )}
        </div>
    );

    return (
        <div>
            <h3 className="my-4 text-base font-medium text-14">Published Packages</h3>
            {activeScreen === 'screen_1' && renderPackagePublishActions}
            {activeScreen === 'screen_2' && renderPackage}
            {activeScreen === 'screen_3' && packageDeployment.supported && renderPackageDeployment}
        </div>
    );
};

export default PublishedPackages;
