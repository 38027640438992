import React, { FunctionComponent, ChangeEvent } from 'react';
import { RadioGroup, ToggleSwitch, FieldBox, TextField } from '../../../../design-system';
import { sdkDownloadOptions } from './SdkSettingsConstants';
import { PlatformTemplateType } from '../PlatformConstants';
import { PlatformsProviderValue, usePlatforms } from '../PlatformsContext';
import './sdk-settings.scss';
import Tooltip from '../../../../design-system/Tooltip/Tooltip';

interface SdkSettingsProps {
    templateValue: PlatformTemplateType;
}

// eslint-disable-next-line react/display-name
const SdkSettings: FunctionComponent<SdkSettingsProps> = (props) => {
    const { templateValue } = props;
    const {
        platformsState: { sdkPlatforms },
        onPlatformInputChange,
        onSdkDownloadOptionChange,
        form,
    } = usePlatforms() as PlatformsProviderValue;
    const { sdkEnabled, apimaticSdk, externalLink, platformEnabled } = sdkPlatforms[templateValue];

    const onRadioGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newApimaticSdk = e.target.value === 'zip-download';
        onSdkDownloadOptionChange(templateValue, newApimaticSdk);
    };

    return (
        <div className="sdk-settings-wrapper">
            <FieldBox label="SDK Source Code Download" type="inline" className="justify-between">
                <Tooltip
                    message="When enabled, portal user can download the SDK source code as a ZIP file."
                    position="right"
                >
                    <ToggleSwitch
                        disabled={!platformEnabled}
                        checked={sdkEnabled}
                        name="sdkEnabled"
                        onChange={(e) => onPlatformInputChange(e, templateValue)}
                    />
                </Tooltip>
            </FieldBox>
            <div className={`${!sdkEnabled && 'opacity-60'}`}>
                <RadioGroup
                    id={`download-option-${templateValue}`}
                    disabled={!sdkEnabled || !platformEnabled}
                    name="downloadOptions"
                    options={sdkDownloadOptions}
                    selectedOption={apimaticSdk ? 'zip-download' : 'sdk-download-link'}
                    onChange={(e) => onRadioGroupChange(e)}
                />
                <FieldBox label="" className="ml-6">
                    <TextField
                        disabled={apimaticSdk || !sdkEnabled || !platformEnabled}
                        className="external-sdk-link"
                        placeholder="Link to ZIP file for SDK"
                        name="externalLink"
                        value={externalLink}
                        onChange={(e) => onPlatformInputChange(e, templateValue)}
                        validation={{
                            key: props.templateValue,
                            required: !apimaticSdk && sdkEnabled && platformEnabled,
                            url: !apimaticSdk && sdkEnabled && platformEnabled,
                        }}
                        form={form}
                    />
                </FieldBox>
            </div>
        </div>
    );
};

export default SdkSettings;
