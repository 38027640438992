import React, { FunctionComponent, ReactNode } from 'react';
import './button.scss';

type FillType =
    | 'transparent'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger'
    | 'success'
    | 'error'
    | 'link'
    | 'raised-link';

interface ButtonProps {
    icon?: ReactNode;
    text: string;
    id?: string;
    size?: 'sm' | 'xs';
    fill?: FillType;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    type?: 'submit' | 'reset' | 'button';
    disabled?: boolean;
    style?: React.CSSProperties;
}

interface AnchorButtonProps {
    id?: string;
    className?: string;
    text: string;
    size?: 'sm';
    fill?: FillType;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    icon?: ReactNode;
    href: string;
    target: string;
    style?: React.CSSProperties;
}

/***
 * Title Element
 * @param {string} id
 * @param {string} className
 * @param {string} text Button element text
 * @param {string} size Button size "sm" (not need to define size for default button)
 * @param {string} fill "transparent" | "default" | "primary" | "secondary" | "tertiary" | "danger" | "link"
 * @param {React.MouseEvent<HTMLButtonElement>} onClick
 * @param {ReactNode} icon Icon as react node
 * @param {string} type  'submit' | 'reset' | 'button'
 *
 *
 ***/
const Button: FunctionComponent<ButtonProps> = ({
    id,
    className,
    text,
    size,
    type,
    icon,
    fill,
    disabled,
    onClick,
    style,
}) => {
    return (
        <button
            id={id}
            className={`button-wrapper ${fill ? fill : 'default'} ${icon && 'icon-fix'} ${
                size && size
            } ${className}`}
            onClick={(e) => onClick && onClick(e)}
            type={type}
            style={style}
            disabled={disabled}
        >
            {icon && <span className={`${text && 'icon'}`}>{icon}</span>}
            {text}
        </button>
    );
};

export default Button;
