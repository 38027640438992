import {
    ExportFormat,
    ExportFormatValueDef,
} from '../components/api-docs/ApiSpecExport/ApiSpecExportInitialState';
import {
    PlatformConstantDef,
    PlatformType,
} from '../components/api-docs/Platforms/PlatformConstants';
import { FormValidatorDef } from '../types/form-validator';
import { ApiEntityDef } from '../types/portal-editor-settings';
import { UiStateDef } from '../types/ui-state';

const pixelToInt = (value: string) => {
    return parseFloat(value);
};

const valToPixel = (value: string) => {
    return value.toString() + 'px';
};

const formIsValid = (form: {
    state: FormValidatorDef;
    setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
}): boolean => {
    if (!form.state.isSubmitted) {
        form.setState((previousState) => {
            return { ...previousState, isSubmitted: true };
        });
    }
    return Object.values(form.state.errors).filter((elem) => elem.length > 0).length === 0;
};

const resetForm = (form: {
    state: FormValidatorDef;
    setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
}) => {
    form.setState((previousState) => {
        return {
            ...previousState,
            errors: {},
        };
    });
};

function getActiveApiEntity(
    apiEntityId: string,
    apiEntities: ApiEntityDef[]
): ApiEntityDef | undefined {
    const activeApiEntity = apiEntities.find(
        (apiEntity: ApiEntityDef) => apiEntity.id === apiEntityId
    );
    return activeApiEntity;
}

function getLoadingUiState(): UiStateDef {
    return {
        isLoading: true,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    };
}

function getDefaultUiState(): UiStateDef {
    return {
        isLoading: false,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    };
}

function getSavingUiState(): UiStateDef {
    return {
        isLoading: false,
        isError: false,
        isSaving: true,
        statusCode: 0,
        message: '',
    };
}

const filterAllowedPlatform = (
    platformConstants: PlatformConstantDef[],
    allowedPlatform: PlatformType[] | undefined
): PlatformConstantDef[] | undefined => {
    return (
        allowedPlatform &&
        platformConstants.filter(
            (p: PlatformConstantDef) => p.supported && allowedPlatform.includes(p.value)
        )
    );
};

function mergeAndOverrideArrays(mergeToArray: any, mergeFromArray: any, key: string): Array<any> {
    return mergeToArray
        .filter(
            (dataArrayOne: any) =>
                !mergeFromArray.find((dataArrayTwo: any) => dataArrayOne[key] === dataArrayTwo[key])
        )
        .concat(mergeFromArray);
}

const checkApiSpecExport = (exportFormats: ExportFormat): boolean => {
    let isExportCheck: boolean;

    for (const apiFormats in exportFormats) {
        const item = apiFormats as ExportFormatValueDef;

        isExportCheck = exportFormats[item].every((t) => !t.enabled);
        if (!isExportCheck) {
            break;
        }
    }
    return isExportCheck;
};

export const utilityFunctions = {
    pixelToInt,
    valToPixel,
    formIsValid,
    resetForm,
    getActiveApiEntity,
    getLoadingUiState,
    getDefaultUiState,
    getSavingUiState,
    filterAllowedPlatform,
    mergeAndOverrideArrays,
    checkApiSpecExport,
};
