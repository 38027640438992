import React, { FunctionComponent, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { SideNavItemsConstantType } from './SideNavConstant';
import Tooltip from '../../design-system/Tooltip/Tooltip';
import { StoreProviderValue, useStore } from '../../store/storeContext';
import { PreReleaseTag } from '../api-docs/AIChatbot/PreReleaseTag/PreReleaseTag';
import { aiChatbotTitle } from '../api-docs/AIChatbot/AIChatbot';

export const MenuItem: FunctionComponent<{
    item: SideNavItemsConstantType;
    activeNavValue: string;
    _handleOnClick: MouseEventHandler<any>;
    toggleSwitch?: any;
}> = ({ item, activeNavValue, _handleOnClick, toggleSwitch }) => {
    const { storeState } = useStore() as StoreProviderValue;
    const isAIChatbotAllowed = storeState.apiGroup.aiChatbot?.isAllowedInSubscription;
    const isActiveSubMenu = () => {
        return item.items.findIndex((i) => i.value === activeNavValue) !== -1;
    };

    const renderItem = () => (
        <>
            <div className={'pr-2 icon side-nav-link-icon'}>{item.icon}</div>
            <div className="title blur-overflow lh-22">{item.title}</div>
        </>
    );

    const renderAIChatbotItem = () => (
        <>
            <div className={`pr-2 icon side-nav-link-icon`}>{item.icon}</div>
            <div className={`title lh-22 ${isAIChatbotAllowed ? '' : 'text-charcoal-800'}`}>
                {item.title}
            </div>
        </>
    );

    const getClasses = () =>
        `flex w-full items-center ${item.itemClass ? ` ${item.itemClass} ` : ''} ${
            isActiveSubMenu() || (activeNavValue === item.value ? ' active' : '') ? 'active' : ''
        }`;

    return (
        <div className="flex items-center w-full">
            {item.items.length === 0 && item.title !== aiChatbotTitle && (
                <Link className={`${getClasses()} side-nav-link`} key={item.value} to={item.value}>
                    {renderItem()}
                </Link>
            )}

            {item.title === aiChatbotTitle && isAIChatbotAllowed && (
                <Tooltip
                    message="Please upgrade your subscription to enable this feature."
                    position="right"
                    displayNone={isAIChatbotAllowed}
                >
                    <Link
                        className={`${getClasses()} side-nav-link ${
                            isAIChatbotAllowed ? '' : 'pointer-events-none'
                        }`}
                        key={item.value}
                        to={item.value}
                    >
                        {renderAIChatbotItem()}
                        <PreReleaseTag title="BETA" />
                    </Link>
                </Tooltip>
            )}

            {item.items.length !== 0 && (
                <a className={`${getClasses()} font-medium`} onClick={_handleOnClick}>
                    {renderItem()}
                </a>
            )}

            {toggleSwitch}
        </div>
    );
};
