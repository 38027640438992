import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const DownloadIcon = (props: SVGIconProps) => (
    <SVGIcon
        width={props.width || '12.52'}
        height={props.height || '13.86'}
        viewBox={props.viewBox || '0 0 12.52 13.86'}
        {...props}
    >
        <g transform="translate(-2.4 -1.06)">
            <path
                d="M14.32,15v2.516a1.258,1.258,0,0,1-1.258,1.258h-8.8A1.258,1.258,0,0,1,3,17.516V15"
                transform="translate(0 -4.453)"
                fill="none"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.2"
            />
            <path
                d="M7,10l3.145,3.145L13.289,10"
                transform="translate(-1.484 -2.598)"
                fill="none"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.2"
            />
            <line
                y1="8"
                transform="translate(8.66 1.66)"
                strokeWidth="1.2"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </g>
    </SVGIcon>
);
