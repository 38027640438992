import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

const style = {
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
};

export const LinkIcon = (props: SVGIconProps) => (
    <SVGIcon width="14.889" height="14.889" viewBox="0 0 14.889 14.889" {...props} fill="none">
        <g transform="translate(-2.25 -1.939)">
            <path
                fill="none"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
                d="M13.9,11.086v4.359A1.453,1.453,0,0,1,12.445,16.9H4.453A1.453,1.453,0,0,1,3,15.445V7.453A1.453,1.453,0,0,1,4.453,6H8.813"
                transform="translate(0 -0.82)"
            />
            <path
                fill="none"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
                d="M15,3h4.359V7.359"
                transform="translate(-3.281)"
            />
            <line
                fill="none"
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
                y1="7.992"
                x2="7.992"
                transform="translate(8.086 3)"
            />
        </g>
    </SVGIcon>
);
