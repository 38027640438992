export const UPDATE_SLUG = 'UPDATE_SLUG';
export const UPDATE_PUBLISH_PORTAL_STATE = 'UPDATE_PUBLISH_PORTAL_STATE';
export const UPDATE_UNPUBLISH_STATUS = 'UPDATE_UNPUBLISH_STATUS';
export const UPDATE_PUBLISH_STATUS = 'UPDATE_PUBLISH_STATUS';

export const PublishedPortalReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_PUBLISH_PORTAL_STATE: {
            return action.payload;
        }

        case UPDATE_SLUG: {
            return {
                ...state,
                slug: action.payload,
            };
            break;
        }

        case UPDATE_UNPUBLISH_STATUS: {
            return {
                ...state,
                status: {
                    ...state.status,
                    [action.payload]: false,
                },
            };
            break;
        }

        case UPDATE_PUBLISH_STATUS: {
            return {
                ...state,
                status: {
                    ...state.status,
                    [action.payload]: true,
                },
            };
            break;
        }

        default:
            return state;
            break;
    }
};
