import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export let AddDocumentIcon = (props: SVGIconProps) => (
    <SVGIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g transform="translate(-113 -380)">
            <rect
                width="24"
                height="24"
                transform="translate(113 380)"
                fill="rgba(255,255,255,0)"
            />
            <path
                d="M7.25,7.5V6h1.5V7.5h1.5V9H8.75v1.5H7.25V9H5.75V7.5Zm-3.75,6h9v-9h-3v-3h-6ZM2,14.25V0h9l3,3V15H2Z"
                transform="translate(117 384)"
                fill="#0c7ff2"
            />
        </g>
    </SVGIcon>
);
