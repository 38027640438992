import React, { FunctionComponent } from 'react';
import { H4 } from '../../../../design-system/Headings/Headings';

const Endpoints: FunctionComponent = () => {
    return (
        <>
            {/* <EndPointIcon className="w-24 h-24" /> */}
            <H4 className="mt-6 mb-4 font-medium">API Endpoints</H4>
            <p className="pb-2 leading-relaxed">
                This section will be automatically generated by APIMatic from your API Specification
                when you publish your portal.
            </p>
            <p className="pb-2 leading-relaxed">
                It will document how to call each endpoint in your API. It will contain
                language-specific documentation for:
            </p>
            <ul
                style={{ listStyle: 'disc', listStylePosition: 'inside' }}
                className="pb-2 leading-relaxed"
            >
                <li>Parameters for the API Request</li>
                <li>Schema for the API Response</li>
                <li>Example Code for Calling the API</li>
                <li>Errors Returned by the API</li>
            </ul>
            <p className="leading-relaxed">
                The user can also try the API calls from the portal using the API Explorer.
            </p>
        </>
    );
};

export default Endpoints;
