import React, {
    FunctionComponent,
    useReducer,
    ReactNode,
    createContext,
    useContext,
    useEffect,
} from 'react';
import {
    storeReducer,
    UPDATE_STORE,
    UPDATE_STORE_API_ENTITY,
    UPDATE_STORE_API_GROUP,
    UPDATE_UTM_MEDIUM,
} from './storeReducer';
import { storeInitialState, StoreStateDef } from './storeInitialState';

/***
 * Sample context implementation with typescript
 ***/
export interface StoreProviderProps {
    children: ReactNode;
    settings: any;
}

type updateStoreType = (newState: any, key: string) => void;
type updateStoreApiGroupType = (newState: any, key: string) => void;
type updateStoreApiEntityType = (newState: any, key: string, apiEntityId: string) => void;
type updateUTMMedium = (utmMedium: string) => void;

export interface StoreProviderValue {
    updateStore: updateStoreType;
    updateStoreApiGroup: updateStoreApiGroupType;
    updateStoreApiEntity: updateStoreApiEntityType;
    updateUTMMedium: updateUTMMedium;
    storeState: StoreStateDef;
}

export type StoreContextValue = undefined | StoreProviderValue;

export const StoreContext = createContext<StoreContextValue>(undefined);

const StoreProvider: FunctionComponent<StoreProviderProps> = ({ children, settings }) => {
    const [storeState, dispatch] = useReducer(storeReducer, {
        ...storeInitialState,
        settings,
        activeApiEntityId: settings.defaultApiEntityId,
    });

    const updateStore: updateStoreType = (newState, key) => {
        // console.log('updateStore' + newState);
        dispatch({
            type: UPDATE_STORE,
            payload: {
                newState: newState,
                key: key,
            },
        });
    };
    const updateStoreApiGroup: updateStoreApiGroupType = (newState, key) => {
        // console.log('updateStoreApiGroup' + newState);
        dispatch({
            type: UPDATE_STORE_API_GROUP,
            payload: {
                newState,
                key,
            },
        });
    };

    const updateStoreApiEntity: updateStoreApiEntityType = (newState, key, apiEntityId) => {
        // console.log('updateStoreApiEntity' + newState);
        dispatch({
            type: UPDATE_STORE_API_ENTITY,
            payload: {
                newState,
                key,
                apiEntityId,
            },
        });
    };

    const updateUTMMedium: updateUTMMedium = (UTMMedium) => {
        dispatch({
            type: UPDATE_UTM_MEDIUM,
            payload: {
                UTMMedium,
            },
        });
    };

    const value = {
        updateStore,
        updateStoreApiGroup,
        updateStoreApiEntity,
        updateUTMMedium,
        storeState: React.useMemo(() => storeState, [storeState]),
    };

    return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

const useStore = () => useContext(StoreContext);

export { StoreProvider, useStore };

/***
 * context usage
 * const { onInputChange, onSdkDownloadOptionChange } = usePlatforms() as PlatformsProviderValue;
 ***/
