import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button } from '../../design-system';
import Tooltip from '../../design-system/Tooltip/Tooltip';
import Spinner from '../../design-system/Spinner/spinner';
import { useStore, StoreProviderValue } from '../../store/storeContext';

interface SaveActionProps {
  disabled: boolean;
  isSaving: boolean;
  onClick: () => void;
}

export const SaveActionPortal: FunctionComponent = ({ children }) => {
  const domElement = document.getElementById('save-action-portal') as HTMLElement;

  return domElement && ReactDOM.createPortal(children, domElement);
};

const SaveAction: FunctionComponent<SaveActionProps> = ({ disabled, onClick, isSaving }) => {
  const { updateStore } = useStore() as StoreProviderValue;
  useEffect(() => {
    updateStore(isSaving, 'isSaving');
  }, [isSaving]);

  return (
    <SaveActionPortal>
      <Tooltip
        message="Save changes. Changes will not appear on the portal unless you publish them."
        position="bottom"
      >
        <Button
          className="mr-3"
          text="Save"
          fill="default"
          onClick={onClick}
          disabled={disabled}
          icon={isSaving && <Spinner size="s" />}
        />
      </Tooltip>
    </SaveActionPortal>
  );
};

export default SaveAction;
