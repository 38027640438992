import React, { FunctionComponent, useState } from 'react';
import { BadgeConstants, BadgeDef } from '../Badges/BadgeConstants';
import Badge from '../Badges/Badges';
import AddBadgeAction from '../AddBadgeAction/AddBadgeAction';
import { useHomePage, HomePageProviderValue } from '../HomePageContext';
import { ADD_BADGE, REPLACE_BADGE, DELETE_BADGE, ON_BADGE_DRAG_END } from '../HomePageReducer';

export function sortBadgeConstant(badgeState: string[], badgeConstant: BadgeDef[]): any {
    const unsortedPlatforms = badgeConstant;
    const sortedPlatforms: BadgeDef[] = [];

    badgeState.forEach((badgeState) => {
        unsortedPlatforms.forEach((badgeConst, i) => {
            if (badgeConst.value === badgeState) {
                sortedPlatforms.push(badgeConst);
                unsortedPlatforms.splice(i, 1);
            }
        });
    });

    return {
        activeBadges: sortedPlatforms,
        inactiveBadges: unsortedPlatforms,
    };
}

const Footer: FunctionComponent = () => {
    const { homePageState, homePageDispatch } = useHomePage() as HomePageProviderValue;
    // const [badgesState, setBadgesState] = useState(homePageInitialState.badges);
    const sortedBadgeConstants = sortBadgeConstant(homePageState.badges, [...BadgeConstants]);

    const addBadge = (newBadge: string) => {
        homePageDispatch({
            type: ADD_BADGE,
            payload: {
                newBadge,
            },
        });
    };

    const replaceBadge = (newBadge: string, oldBadge: string): void => {
        homePageDispatch({
            type: REPLACE_BADGE,
            payload: {
                newBadge,
                oldBadge,
            },
        });
    };

    const deleteBadge = (deletedBadge: string): void => {
        homePageDispatch({
            type: DELETE_BADGE,
            payload: {
                deletedBadge,
            },
        });
    };

    // const onBadgeDragEnd = ({ source, destination }: DropResult): void => {
    //     if (source && destination) {
    //         const newConstant = euiDragDropReorder(BadgeConstants, source.index, destination.index);
    //         const newBadges: string[] = [];
    //         newConstant.forEach(
    //             ({ value }) => homePageState.badges.includes(value) && newBadges.push(value)
    //         );
    //         homePageDispatch({
    //             type: ON_BADGE_DRAG_END,
    //             payload: {
    //                 newBadges,
    //             },
    //         });
    //     }
    // };

    return (
        <div className="flex">
            {/* <EuiDragDropContext onDragEnd={onBadgeDragEnd}>
                <EuiDroppable
                    className="w-full"
                    direction="horizontal"
                    droppableId="CUSTOM_HANDLE_DROPPABLE_AREA"
                > */}
            <div className="flex w-full">
                {sortedBadgeConstants.activeBadges.map((badge: BadgeDef, index: number) => (
                    <Badge
                        key={index}
                        index={index}
                        badge={badge}
                        replaceBadge={replaceBadge}
                        deleteBadge={deleteBadge}
                        inactiveBadges={sortedBadgeConstants.inactiveBadges}
                    />
                ))}
                {sortedBadgeConstants.activeBadges.length < 4 && (
                    <AddBadgeAction
                        addBadge={addBadge}
                        inactiveBadges={sortedBadgeConstants.inactiveBadges}
                    />
                )}
            </div>
            {/* </EuiDroppable> */}
            {/* </EuiDragDropContext> */}
        </div>
    );
};

export default Footer;
