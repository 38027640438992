import React, { FunctionComponent, useRef, useState, useEffect, Fragment, useContext } from 'react';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { useParams, useNavigate } from 'react-router-dom';

import { Editor } from '@toast-ui/react-editor';
import useUpdateEffect from '../../../hooks/UseUpdateEffect';
import { useHeader, HeaderProviderValue } from '../../Header/HeaderContext';
import useConfirmUnsavedChanges from '../../../hooks/useConfirmUnsavedChanges';
import ConfirmUnsavedChanges from '../../ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import { CustomPageStoreKey } from './CustomPageConstant';
import { customContentService } from '../../../api-client/CustomContentService';
import {
    NotificationProviderValue,
    useNotification,
} from '../../Notification/NotificationBarContext';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { CustomContentContext } from '../../CustomContent/CustomContentContext';
import { CustomContentContextValue } from '../../CustomContent/CustomContentTypes';
import { getFileById } from '../../CustomContent/CustomContentConstant';
import {
    CustomContentFileKeyForSidenav,
    SideNavActiveItemStoreKey,
} from '../../../views/ViewConstant';

import './CustomPage.scss';
import { UiStateDef } from '../../../types/ui-state';
import { utilityFunctions } from '../../../utilities/functions';
import NotificationBar from '../../Notification/NotificationBar';
import SaveAction from '../../SaveAction/SaveAction';
import Spinner from '../../../design-system/Spinner/spinner';
import useDragEvent from '../../../hooks/useDragEnterEvent';

const CustomPage: FunctionComponent = () => {
    enum fileTypes {
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
    }
    const maxFileSize = 5242880; // 5MB

    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { addNotification } = useNotification() as NotificationProviderValue;
    const {
        updateStoreApiEntity,
        storeState: { activeApiEntityId },
    } = useStore() as StoreProviderValue;
    const [currentApiEntityId] = useState(activeApiEntityId);

    const { tableOfContents, fileToBeDeleted } = useContext(
        CustomContentContext
    ) as CustomContentContextValue;

    const { pathKey } = useParams();
    const editorRef = useRef<any>(null);

    const { headerState, resetHeaderState } = useHeader() as HeaderProviderValue;

    const [customPageData, setCustomPageData] = useState('');

    const [uiState, setUiState] = useState<UiStateDef>({
        isLoading: true,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    });

    const [isFileUploading, setFileUploading] = useState(false);
    const [isFileDraggedIn, setFileDraggedIn] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [discard, setDiscard] = useState(false);

    useEffect(() => {
        const fTBD: string[] = JSON.parse(JSON.stringify(fileToBeDeleted));

        if (fTBD.includes(pathKey)) {
            navigate('./../../custom-content');
        }
    }, [fileToBeDeleted]);

    const { updateStore } = useStore() as StoreProviderValue;

    useEffect(() => {
        if (activeApiEntityId !== currentApiEntityId) {
            navigate('./../../custom-content');
        }
    }, [activeApiEntityId]);

    useEffect(() => {
        updateStore(CustomContentFileKeyForSidenav + '/' + pathKey, SideNavActiveItemStoreKey);

        setUiState({
            isLoading: true,
            isError: false,
            statusCode: 0,
            message: '',
        });

        const promise = customContentService(activeApiEntityId).getMarkDownFile(pathKey);
        promise
            .then((resp: any) => {
                setCustomPageData(resp.markdown);
                setUiState({
                    ...uiState,
                    isLoading: false,
                });
                updateStoreApiEntity(resp.markdown, CustomPageStoreKey, activeApiEntityId);
            })
            .catch((e: Response) => {
                if (e.status === 404) {
                    setCustomPageData('');
                    updateStoreApiEntity('', CustomPageStoreKey, activeApiEntityId);
                    setUiState({
                        isLoading: false,
                        isError: false,
                        statusCode: 0,
                        message: '',
                    });
                } else {
                    setUiState({
                        isLoading: false,
                        isError: e.status !== 404 && true,
                        statusCode: e.status,
                        message: e.statusText,
                    });
                }
            });
    }, [pathKey]);

    useUpdateEffect(() => {
        if (headerState.isSaveClicked) {
            saveData();
            resetHeaderState();
        }
    }, [headerState]);

    useDragEvent(ref, () => {
        setFileDraggedIn(false);
    });

    const saveData = () => {
        setUiState(utilityFunctions.getSavingUiState());
        const fileName = pathKey;
        const file = getFileById(tableOfContents, fileName);

        if (file) {
            const promise = customContentService(activeApiEntityId).updateCustomContentMarkDownFile(
                tableOfContents,
                {
                    file: file.file,
                    newName: file.page,
                    markdown: customPageData,
                }
            );
            promise
                .then(() => {
                    addNotification({
                        show: true,
                        type: 'success',
                        message: 'Page was saved successfully.',
                    });
                    updateStoreApiEntity(customPageData, CustomPageStoreKey, activeApiEntityId);
                })
                .catch((e) => {
                    addNotification({
                        show: true,
                        type: 'error',
                        message: 'Failed to save page.',
                    });
                })
                .finally(() => {
                    setUiState(utilityFunctions.getDefaultUiState());
                });
        }
    };

    useConfirmUnsavedChanges(
        discard,
        customPageData,
        'apiEntities',
        () => {
            setShowModal(true);
        },
        CustomPageStoreKey,
        activeApiEntityId
    );

    const saveChanges = () => {
        setShowModal(false);
        saveData();
    };

    const discardChanges = () => {
        setShowModal(false);
        setDiscard(true);
    };

    const _handleOnChange = () => {
        setCustomPageData(editorRef.current.getInstance().getMarkdown());
    };

    const handleDragEvent = (event: React.DragEvent<HTMLDivElement>, state: boolean) => {
        event.stopPropagation();
        event.preventDefault();
        setFileDraggedIn(state);
    };

    type HookCallback = (url: string, text?: string) => void;

    const onImageUpload = (file: Blob | File, callback: HookCallback) => {
        /* File validation on image i.e. type and size */
        setFileDraggedIn(false);
        if (Object.values(fileTypes).includes(file.type) && file.size <= maxFileSize) {
            setFileUploading(true);
            const formData = new FormData();
            formData.append('file', file as File);
            const promise = customContentService(activeApiEntityId).uploadImageMarkDownFiles(
                formData
            );
            promise
                .then((url) => {
                    setFileUploading(false);
                    callback((url as unknown) as string, 'alt text');
                })
                .catch(() => {
                    setFileUploading(false);
                    addNotification({
                        show: true,
                        type: 'error',
                        message: 'Error uploading file, please try again',
                    });
                });
        } else {
            addNotification({
                show: true,
                type: 'error',
                message: 'Invalid file type or file size exceeded 5 MB',
            });
        }
    };

    const renderHomePage = () => {
        return (
            <>
                <NotificationBar />
                <SaveAction
                    disabled={uiState.isLoading}
                    isSaving={uiState.isSaving!}
                    onClick={saveChanges}
                />
                <ConfirmUnsavedChanges
                    show={showModal}
                    closeModal={setShowModal}
                    discardChanges={discardChanges}
                    saveChanges={saveChanges}
                />
                <div
                    className="w-full h-full fade-in-animation"
                    onDragEnter={(e) => handleDragEvent(e, true)}
                    ref={ref}
                >
                    {isFileUploading && <div className="w-full h-full spinner"></div>}

                    <div
                        className={`w-full h-full ${
                            isFileDraggedIn || isFileUploading ? 'opacity-50' : ''
                        }`}
                    >
                        <Editor
                            initialValue={customPageData}
                            previewStyle="vertical"
                            height="100%"
                            initialEditType="markdown"
                            useCommandShortcut={true}
                            usageStatistics={false}
                            ref={editorRef}
                            onChange={_handleOnChange}
                            hooks={{
                                addImageBlobHook: onImageUpload,
                            }}
                        />
                    </div>
                </div>
            </>
        );
    };

    const renderErrorView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <p className="text-base font-medium text-red ">
                    {uiState.message
                        ? `${uiState.statusCode} - ${uiState.message}`
                        : 'Oops! Something went wrong.'}
                </p>
            </div>
        );
    };

    const renderLoadingView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <Spinner size="xl" />
            </div>
        );
    };

    return (
        <Fragment>
            {uiState.isLoading
                ? renderLoadingView()
                : uiState.isError
                ? renderErrorView()
                : renderHomePage()}
        </Fragment>
    );
};

export default CustomPage;
