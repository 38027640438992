import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const RocketIcon = (props: SVGIconProps) => (
    <SVGIcon width="15.559" height="17.553" viewBox="0 0 15.559 17.553" {...props}>
        <g transform="translate(-18.687 -507.658)">
            <path
                fill={props.fill || DEFAULT_SVG_COLOR}
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeWidth="0.3px"
                d="M-1431.834-513.747l-3.006-.023a.68.68,0,0,1-.625-.426l-.86-2.128a2.691,2.691,0,0,0-2.289,1.27.681.681,0,0,1-.888-.063l-.7-.7a2.108,2.108,0,0,1-.158-2.831l2.561-4.4v-.62a8.757,8.757,0,0,1,3.044-6.4l.008-.009a4.685,4.685,0,0,1,1.466-.923,4.932,4.932,0,0,1,1.483.957,8.709,8.709,0,0,1,2.984,6.413v.011l0,.537c.013.013.025.026.037.04l2.561,4.392a2.112,2.112,0,0,1-.054,2.722c-.1.113-.232.251-.362.382-.229.228-.448.433-.463.446a.679.679,0,0,1-.809.089,5.087,5.087,0,0,0-2.484-1.279l-.794,2.1a.674.674,0,0,1-.155.24.68.68,0,0,1-.481.2Zm-2.541-1.381,2.077.017.461-1.22-3.03-.015Zm.583-13.987-.009.009a7.426,7.426,0,0,0-2.645,5.45l.013,5.941,6.214.031.039-5.939a7.37,7.37,0,0,0-2.586-5.458c-.181-.182-.4-.4-.531-.508C-1433.419-529.487-1433.606-529.3-1433.792-529.115Zm-4.5,9.227.489-.593v-.409Zm9.449-.94.145.176-.144-.292Zm-5.513-3.981a1,1,0,0,1,1-1,1,1,0,0,1,1,1,1,1,0,0,1-1,1A1,1,0,0,1-1434.353-524.808Z"
                transform="translate(1459.773 1038.808)"
            />
        </g>
    </SVGIcon>
);
