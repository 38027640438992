/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { sideNavItemsConstant, SideNavItemsConstantType } from './SideNavConstant';
import { List } from './List';
import CustomContent from '../CustomContent/CustomContent';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { SelectField, ToggleSwitch } from '../../design-system';
import { ApiEntityDef } from '../../types/portal-editor-settings';
import { SelectOptionDef } from '../../design-system/SelectField/SelectField';
import ConfirmUnsavedChanges from '../ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import { useHeader, HeaderProviderValue } from '../Header/HeaderContext';
import { MenuItem } from './Item';
import { HomePageProviderValue, useHomePage } from '../api-docs/HomePage/HomePageContext';
import './SideNav.scss';
import { updateToggleHomePage } from '../../api-client/HomePageService';
import {
    usePublishedPortal,
    PublishedPortalProviderValue,
} from '../api-docs/PublishedPortal/PublishedPortalContext';
import Badge from '../../design-system/Badge/Badge';
import Tooltip from '../../design-system/Tooltip/Tooltip';

function getVersionOptionsList(apiVersions: ReadonlyArray<ApiEntityDef>) {
    return apiVersions.reduce((acc, v) => {
        return acc.concat({ text: v.version, value: v.id });
    }, [] as Array<SelectOptionDef>);
}

const SideNav: FunctionComponent = () => {
    const { storeState, updateStore, updateStoreApiGroup } = useStore() as StoreProviderValue;
    const { homePageState, toggleHomepage, uiState } = useHomePage() as HomePageProviderValue;

    const { headerSaveClicked } = useHeader() as HeaderProviderValue;
    const [activeNavValue, setActiveNavValue] = useState(storeState.activeView);
    const { publishedPortalState } = usePublishedPortal() as PublishedPortalProviderValue;
    const isPublished = publishedPortalState.status[storeState.activeApiEntityId];

    useEffect(() => {
        setActiveNavValue(storeState.activeView);
    }, [storeState.activeView]);

    const [nextAPIEntity, setNextAPIEntity] = useState('');

    const _handleToggleHomepage = (enabled: boolean) => {
        toggleHomepage(enabled);

        updateToggleHomePage({ enabled })
            .then(() => {
                updateStoreApiGroup({ ...homePageState, enabled: enabled }, 'homePage');
            })
            .catch((e) => {
                // reset state to orignal state in case of api failure
                toggleHomepage(storeState.apiGroup.homePage?.enabled ? true : false);
            });
    };

    const onApiVersionChange = (newState: string) => {
        setNextAPIEntity(newState);

        if (
            !storeState.containsUnsavedChanges ||
            storeState.activeView.split('/')[0] === 'portal-settings'
        ) {
            updateStore(newState, 'activeApiEntityId');
        } else {
            setShowModal(true);
        }
    };

    const discardChanges = () => {
        setShowModal(false);
        updateStore(nextAPIEntity, 'activeApiEntityId');
    };

    const saveChanges = () => {
        setShowModal(false);
        const actionType = `SAVE${location.pathname.toUpperCase().replace(/\/|-/gi, '_')}`;
        headerSaveClicked(actionType);
    };

    const [showModal, setShowModal] = useState(false);

    const renderCustomItems = (menuItem: SideNavItemsConstantType) => (
        <>
            {menuItem.customItem === 'custom-content' && (
                <CustomContent key={menuItem.value} {...menuItem} />
            )}

            {menuItem.customItem === 'homepage' && (
                <div className="side-nav-section-item">
                    <MenuItem
                        item={menuItem}
                        activeNavValue={storeState.activeView}
                        _handleOnClick={() => {}}
                        toggleSwitch={
                            !uiState.isLoading && (
                                <Tooltip
                                    message="Toggle home page visibility. When off, the portal user is taken directly to the documentation."
                                    position="right"
                                >
                                    <ToggleSwitch
                                        size="sm"
                                        className="toggle-switch-homepage-enabled"
                                        checked={homePageState?.enabled}
                                        onChange={(e) => _handleToggleHomepage(e.target.checked)}
                                    />
                                </Tooltip>
                            )
                        }
                    />
                </div>
            )}
            {menuItem.customItem === 'version-settings' && (
                <div className="w-full">
                    <ConfirmUnsavedChanges
                        show={showModal}
                        closeModal={setShowModal}
                        discardChanges={discardChanges}
                        saveChanges={saveChanges}
                    />
                    <div className="flex-col w-full mb-8 version-selector-wrapper">
                        <div className="flex items-center justify-between w-full">
                            <div className="flex items-center">
                                <label className="font-medium">API Version</label>
                                {!isPublished ? (
                                    <Tooltip
                                        message="You have not yet published this version."
                                        position="bottom"
                                    >
                                        <Badge title="draft" type="default" className="mx-2 " />
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        message="Your portal for this version is live."
                                        position="bottom"
                                    >
                                        <Badge title="Live" type="success" className="mx-2 " />
                                    </Tooltip>
                                )}
                            </div>

                            <SelectField
                                id=""
                                name=""
                                className="ml-1 font-medium border border-solid sm text-charcoal-900 rounded-6 border-goose-600 bg-snow-500 version-select"
                                options={getVersionOptionsList(storeState.settings!.apiEntities)}
                                value={storeState.activeApiEntityId}
                                disabled={false}
                                onChange={(e): void => onApiVersionChange(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    return (
        <div className="sticky w-1/5 p-4 pr-3 overflow-auto bg-white side-nav-wrapper custom-scroll">
            <List
                items={sideNavItemsConstant}
                activeNavValue={activeNavValue}
                renderCustomItems={renderCustomItems}
                firstLevel={true}
            />
        </div>
    );
};
export default SideNav;
