import React, { FunctionComponent } from 'react';
import { H4 } from '../../../../design-system/Headings/Headings';

const Models: FunctionComponent = () => {
    return (
        <>
            {/* <ModelsIcon className="w-24 h-24" /> */}
            <H4 className="mt-6 mb-4 font-medium">Models</H4>
            <p className="pb-2 leading-relaxed">
                This section will be automatically generated by APIMatic from your API Specification
                when you publish your portal.
            </p>
            <p className="pb-2 leading-relaxed">
                It will document the schema for the resources used in your API. For each resource,
                the documentation will contain:
            </p>
            <ul
                style={{ listStyle: 'disc', listStylePosition: 'inside' }}
                className="pb-2 leading-relaxed"
            >
                <li>Description of the resource</li>
                <li>List of members with their types and descriptions</li>
                <li>Example of the resource</li>
            </ul>
        </>
    );
};

export default Models;
