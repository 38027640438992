import React, { FunctionComponent, Fragment, useState } from 'react';
import { Button } from '../../../design-system';
import { H4, H5, SubHeading } from '../../../design-system/Headings/Headings';
import Spinner from '../../../design-system/Spinner/spinner';
import { ReloadIcon } from '../../../icons/Ui/ReloadIcon';
import { StoreProviderValue, useStore } from '../../../store/storeContext';
import Card from '../../Card/Card';
import NotificationBar from '../../Notification/NotificationBar';
import Hosting from '../HostingSlug/HostingSlug';
import UnpublishPortalAction from '../unpublishPortalAction/unpublishPortalAction';
import { PublishedPortalProviderValue, usePublishedPortal } from './PublishedPortalContext';

const PublishedPortal: FunctionComponent = () => {
  const {
    publishedPortalState,
    uiState,
    loadingState,
    fetchPublishPortalData,
  } = usePublishedPortal() as PublishedPortalProviderValue;
  const { storeState } = useStore() as StoreProviderValue;

  const [isSlugEditable, setIsSlugEditable] = useState(false);
  const { slug } = publishedPortalState;

  const renderPublishedPortalView = () => {
    return (
      <div className="p-8 pt-0 fade-in-animation">
        <NotificationBar />
        <H4 className="mt-10 mb-3">Publishing</H4>
        <SubHeading text="Manage where your portal and API versions are published, and keep track of your API's publishing history"></SubHeading>
        {slug && (
          <Card>
            <Hosting
              showEditControls={false}
              isSlugEditable={isSlugEditable}
              setIsSlugEditable={setIsSlugEditable}
            />
          </Card>
        )}
        <Card className="mt-10">
          <div className="flex justify-between">
            <H5>API Versions</H5>
            <span className="flex items-center">
              <Button
                text=""
                fill="transparent"
                icon={loadingState ? <Spinner size="s" /> : <ReloadIcon fill="#24313E" />}
                className="ml-1"
                onClick={() => {
                  fetchPublishPortalData(true);
                }}
              />
            </span>
          </div>

          <table className="w-full table-basic-layout">
            <thead>
              <tr>
                <th>Version</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {storeState.settings?.apiEntities.map((apiEntity) => {
                const isPublished = publishedPortalState.status[apiEntity.id];

                const publishStatus = isPublished ? 'Published' : 'Unpublished';
                return (
                  <tr key={apiEntity.version}>
                    <td>{apiEntity.version}</td>
                    <td>{publishStatus}</td>
                    <td>{isPublished && <UnpublishPortalAction apiEntity={apiEntity} />}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      </div>
    );
  };

  const renderErrorView = () => {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p className="text-base font-bold text-red ">
          {uiState.message
            ? `${uiState.statusCode} - ${uiState.message}`
            : 'Oops! Something went wrong.'}
        </p>
      </div>
    );
  };

  const renderLoadingView = () => {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner size="xl" />
      </div>
    );
  };

  return (
    <Fragment>
      {uiState.isLoading
        ? renderLoadingView()
        : uiState.isError
        ? renderErrorView()
        : renderPublishedPortalView()}
    </Fragment>
  );
};

export default PublishedPortal;
