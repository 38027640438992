import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export const CloseIcon = (props: SVGIconProps) => (
    <SVGIcon width="14" height="14" viewBox="0 0 12 15" {...props} fill="none">
        <g transform="translate(6.93 -4.384) rotate(45)">
            <line
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeWidth="1.8px"
                y2="16"
                transform="translate(8 0)"
            />
            <line
                stroke={props.fill || DEFAULT_SVG_COLOR}
                strokeWidth="1.8px"
                x2="16"
                transform="translate(0 8)"
            />
        </g>
    </SVGIcon>
);

export const CloseIconFilled = (props: SVGIconProps) => (
    <SVGIcon width="22" height="22" viewBox="0 0 22 22" {...props} fill="none">
        <g transform="translate(-723 -252)">
            <rect
                fill={props.fill || DEFAULT_SVG_COLOR}
                width="22"
                height="22"
                rx="11"
                transform="translate(723 252)"
            />
            <g transform="translate(733.941 255.512) rotate(45)">
                <line
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8px"
                    y2="10.507"
                    transform="translate(5.254 0)"
                />
                <line
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8px"
                    x2="10.507"
                    transform="translate(0 5.254)"
                />
            </g>
        </g>
    </SVGIcon>
);
