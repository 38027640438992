import React, { FunctionComponent, useState, useContext } from 'react';
import { CustomContentContext } from './CustomContentContext';
import CustomSectionInput from './DropdownOptions/CustomSectionInput';
import './CustomContent.scss';
import { AddIcon } from '../../icons/Ui/AddIcon';
import { Button } from '../../design-system';
import { CustomContentContextValue, ToCItemError } from './CustomContentTypes';
import { getDefaultErrorMessage } from './CustomContentConstant';

const AddSection: FunctionComponent<{ path: string[] }> = ({ path }) => {
    const [showInput, setShowInput] = useState(false);
    const [errorMsg, setErrorMsg] = useState<ToCItemError>(getDefaultErrorMessage());
    const { addSection } = useContext(CustomContentContext) as CustomContentContextValue;

    const _handleCustomInputUpdate = (data?: { name: string; slug: string }) => {
        if (data) {
            addSection(path, data.name, data.slug)
                .then(() => {
                    setShowInput(false);
                    setErrorMsg(getDefaultErrorMessage());
                })
                .catch((e) => {
                    setErrorMsg(e);
                });
        } else {
            setErrorMsg(getDefaultErrorMessage());
            setShowInput(false);
        }
    };

    return (
        <div>
            <Button
                text=""
                size="sm"
                fill="default"
                className="custom-content-add-section"
                icon={<AddIcon width="10px" height="10px" />}
                onClick={(e) => setShowInput(true)}
            />

            {showInput && (
                <div className="absolute top-0 right-0 z-10 block h-32 mt-8 custom-content-input-box">
                    <CustomSectionInput
                        className="drop-shadow"
                        title="Add Section"
                        errorMsg={errorMsg}
                        setErrorMsg={setErrorMsg}
                        close={_handleCustomInputUpdate}
                    ></CustomSectionInput>
                </div>
            )}
        </div>
    );
};

export default AddSection;
