import { ApiSpecExportStateDef, ExportTemplate } from './ApiSpecExportInitialState';

import { utilityFunctions } from '../../../utilities/functions';

export const ON_EXPORT_ENABLED_CHANGE = 'ON_ALLOW_EXPORT_CHANGE';
export const ON_EXPORT_TEMPLATE_CHANGE = 'ON_EXPORT_TEMPLATE_CHANGE';
export const UPDATE_API_SPEC_EXPORT_STATE = 'UPDATE_API_SPEC_EXPORT_STATE';

export const apiSpecExportReducer = (
    state: ApiSpecExportStateDef,
    action: any
): ApiSpecExportStateDef => {
    const { checkApiSpecExport } = utilityFunctions;

    switch (action.type) {
        case UPDATE_API_SPEC_EXPORT_STATE:
            Object.keys(state.exportFormats).map((el: string) => {
                state.exportFormats[el] = utilityFunctions.mergeAndOverrideArrays(
                    state.exportFormats[el],
                    action.payload.exportFormats[el],
                    'exportFormat'
                );
            });
            return {
                ...state,
                enabled: action.payload.enabled,
            };

        case ON_EXPORT_ENABLED_CHANGE:
            return {
                ...state,
                enabled: action.payload,
            };

        case ON_EXPORT_TEMPLATE_CHANGE: {
            const updatedExportFormat = state.exportFormats[action.payload.formatValue].map(
                (t: ExportTemplate) => {
                    if (t.exportFormat === action.payload.templateValue) {
                        return {
                            ...t,
                            enabled: action.payload.templateState,
                        };
                    }
                    return t;
                }
            );

            const apiSpecExport = {
                ...state.exportFormats,
                [action.payload.formatValue]: updatedExportFormat,
            };
            const checkApiSpecExist = checkApiSpecExport(apiSpecExport);

            return {
                ...state,
                exportFormats: apiSpecExport,
                enabled: !checkApiSpecExist,
            };
            break;
        }

        default:
            return state;
    }
};
