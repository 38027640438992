/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState, useReducer } from 'react';
import { ContentDef } from '../HomePageIntialState';
import { Dropdown, Button } from '../../../../design-system';
import { DropdownItemDef } from '../../../../design-system/DropDown/DropDown';
import { HomePageProviderValue, useHomePage } from '../HomePageContext';
import { ADD_CONTENT_BOX, UPDATED_CONTENT_BOX, DELETE_CONTENT_BOX } from '../HomePageReducer';
import './content-section.scss';
import ThemeEditorCard from '../ThemeEditorCard/ThemeEditorCard';
import { EditIcon } from '../../../../icons/Ui/EditIcon';

interface ContentBoxProps {
    content: ContentDef;
    index: number;
    deleteContentBox: (index: number) => void;
    updateContentBox: (updatedState: ContentDef, index: number) => void;
}

const ContentBox: FunctionComponent<ContentBoxProps> = (props) => {
    const { content, index, deleteContentBox, updateContentBox } = props;

    const [toggleThemeEditorCard, setToggleThemeEditorCard] = useState(false);

    const onSave = (updatedState: ContentDef) => {
        updateContentBox(updatedState, index);
        setToggleThemeEditorCard(!toggleThemeEditorCard);
    };

    const onEdit = () => {
        setToggleThemeEditorCard(!toggleThemeEditorCard);
    };

    const dropdownItems: DropdownItemDef[] = [
        {
            name: 'Edit',
            onClick: onEdit,
        },
        {
            name: 'Delete',
            onClick: () => deleteContentBox(index),
        },
    ];

    return (
        <>
            {!toggleThemeEditorCard && (
                <div className="relative p-5 mx-3 my-6 border border-transparent border-solid content-box rounded-12 hover:bg-white hover:border-goose-600">
                    <Dropdown
                        className="absolute top-0 right-0 edit-icon"
                        fill="default"
                        icon={<EditIcon />}
                        name="Edit"
                        dropdownItems={dropdownItems}
                    />
                    <div className="flex flex-col">
                        <h1 className="mb-2  font-medium leading-relaxed text-18 text-charcoal-900">
                            {content.title}
                        </h1>
                        <p className="leading-relaxed text-14 text-charcoal-800">{content.body}</p>
                    </div>
                </div>
            )}
            {toggleThemeEditorCard && (
                <ThemeEditorCard
                    className="content-box"
                    initialState={content}
                    onSave={onSave}
                    onCancel={() => setToggleThemeEditorCard(!toggleThemeEditorCard)}
                />
            )}
        </>
    );
};

const ContentSection: FunctionComponent = () => {
    const { homePageState, homePageDispatch } = useHomePage() as HomePageProviderValue;

    const addContentBox = () => {
        homePageDispatch({
            type: ADD_CONTENT_BOX,
            payload: {},
        });
    };

    const updateContentBox = (updatedCard: ContentDef, index: number) => {
        homePageDispatch({
            type: UPDATED_CONTENT_BOX,
            payload: {
                updatedCard,
                index,
            },
        });
    };

    const deleteContentBox = (index: number) => {
        homePageDispatch({
            type: DELETE_CONTENT_BOX,
            payload: {
                index,
            },
        });
    };

    return (
        <div className="flex flex-wrap border-0 border-b border-solid content-section-wrapper border-goose-600">
            {homePageState.cards.map((content, index) => (
                <ContentBox
                    key={index}
                    index={index}
                    content={content}
                    deleteContentBox={deleteContentBox}
                    updateContentBox={updateContentBox}
                />
            ))}
            {homePageState.cards.length < 4 && (
                <div className="flex items-center justify-center mx-3 my-6 border border-solid bg-snow-500 add-section-wrapper content-box rounded-12 hover:bg-white border-goose-600">
                    <Button
                        text="+ NEW SECTION"
                        className="block mx-auto add-content-button"
                        fill="transparent"
                        onClick={addContentBox}
                    />
                </div>
            )}
        </div>
    );
};

export default ContentSection;
