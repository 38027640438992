import * as React from 'react';
import { DEFAULT_SVG_COLOR, SVGIcon, SVGIconProps } from '../SvgIcon';

export let TrashIcon = (props: SVGIconProps) => (
    <SVGIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g transform="translate(-113 -489)">
            <rect
                width="24"
                height="24"
                transform="translate(113 489)"
                fill="rgba(255,255,255,0)"
            />
            <path
                d="M5,1.5,6.5,0h3L11,1.5h3V3H2V1.5Zm-2.25,3h10.5L12.5,15h-9ZM5.643,6v7.5H7.25V6ZM8.75,6v7.5h1.607V6Z"
                transform="translate(117 494)"
                fill={props.fill || DEFAULT_SVG_COLOR}
            />
        </g>
    </SVGIcon>
);
