import React, { FunctionComponent, useState } from 'react';
import { TemplateConstantDef } from './PlatformConstants';
import { usePlatforms, PlatformsProviderValue } from './PlatformsContext';
import SdkSettings from './SdkSettings/SdkSettings';
import { FieldBox, TextField } from '../../../design-system';
import PublishedPackages from '../PublishedPackages/PublishedPackages';
import { FormValidatorDef } from '../../../types/form-validator';

interface PlatformTemplateProps {
    template: TemplateConstantDef;
}

const PlatformTemplate: FunctionComponent<PlatformTemplateProps> = (props) => {
    const { template } = props;
    const {
        platformsState: { sdkPlatforms },
        onPlatformInputChange,
    } = usePlatforms() as PlatformsProviderValue;
    const { platformEnabled, githubLink } = sdkPlatforms[template.value];

    const [formValidator, setFormValidator] = useState<FormValidatorDef>({
        isSubmitted: false,
        errors: {},
    });

    const form = { state: formValidator, setState: setFormValidator };

    return (
        <form className={`px-6 pt-6 ${!platformEnabled && 'opacity-50 pointer-events-none'}`}>
            <SdkSettings templateValue={template.value} />

            <FieldBox label="SDK Source Code Repository Link" className="justify-between mt-6">
                <TextField
                    disabled={!platformEnabled}
                    placeholder="Link to repository page on GitHub, BitBucket, etc"
                    name="githubLink"
                    value={githubLink || ''}
                    onChange={(e) => onPlatformInputChange(e, template.value)}
                    validation={{
                        key: template.name,
                        github: true,
                    }}
                    form={form}
                />
            </FieldBox>

            <PublishedPackages
                templateValue={template.value}
                packageDeployment={template.packageDeployment}
            />
        </form>
    );
};

export default PlatformTemplate;
