import React, { FunctionComponent, useState, useRef } from 'react';
import './color-selector.scss';
import useOutsideClick from '../../hooks/useOutsideClick';

/***
 * ColorSelector Element
 * @param {string} color css hex color example: #FFDDFF
 * @param {string[]} suggestedColors array of css hex color example: [#FFDDFF]
 * @param {(color: string, isValid: boolean)=> void} onChange emit event on color change
 ***/

type ColorSelector = {
    color: string;
    suggestedColors?: string[];
    onChange: (color: string) => void;
};

const ColorSelector: FunctionComponent<ColorSelector> = ({ suggestedColors, color, onChange }) => {
    const ref = useRef(null);
    // const [toggleColorSelector, setToggleColorSelector] = useState(false);

    const isSuggestedColorSelected = () => {
        return suggestedColors?.includes(color);
    };

    const setSuggestedColor = (text: string): void => {
        // setToggleColorSelector(false);
        onChange(text);
    };

    useOutsideClick(ref, () => {
        // setToggleColorSelector(false);
    });

    const colorInputRef = useRef<any>(null);

    const _toggleColorSelector = () => {
        if (colorInputRef && colorInputRef.current) {
            colorInputRef.current.click();
        }
    };

    return (
        <div className="flex color-selector-wrapper" ref={ref}>
            {!isSuggestedColorSelected() && (
                <div className={'color-item ' + (color === color ? 'active' : '')}>
                    <button
                        onClick={() => setSuggestedColor(color)}
                        className="absolute color-selector-button"
                        style={{
                            backgroundColor: color,
                        }}
                    ></button>
                </div>
            )}

            {suggestedColors?.map((currentColor) => (
                <div
                    key={`c_${currentColor}`}
                    className={'color-item ' + (currentColor === color ? 'active' : '')}
                >
                    <button
                        onClick={() => setSuggestedColor(currentColor)}
                        className="absolute color-selector-button"
                        style={{
                            backgroundColor: currentColor,
                        }}
                    ></button>
                </div>
            ))}

            <div className="relative color-item">
                <button
                    onClick={() => _toggleColorSelector()}
                    className="absolute color-selector-button open-button"
                ></button>

                <input
                    type="color"
                    className="absolute color-selected-input opacity-0"
                    ref={colorInputRef}
                    onChange={(ev): void => onChange(ev.target.value)}
                />

                {/* {toggleColorSelector && (
                    <input type="color" ref = {colorInputRef}/>
                    // <EuiColorPicker
                    //     onChange={(text: string, output: EuiColorPickerOutput): void =>
                    //         onChange(text, output.isValid)
                    //     }
                    //     color={color}
                    //     display="inline"
                    // />
                )} */}
            </div>
        </div>
    );
};

export default ColorSelector;
