import { ApiDocsHeaderStateDef } from './ApiDocsHeaderInitialState';
export const UPDATE_STATE = 'UPDATE_STATE';
export const ADD_NAV_LINK = 'ADD_NAV_LINK';
export const REMOVE_NAV_LINK = 'REMOVE_NAV_LINK';
export const ON_NAV_LINK_DRAG_END = 'NAV_LINK_ON_DRAG_END';
export const ON_NAV_LINK_INPUT_CHANGE = 'ON_NAV_LINK_INPUT_CHANGE';
export const ON_INPUT_CHANGE = 'ON_INPUT_CHANGE';

export const apiDocsHeaderReducer = (state: ApiDocsHeaderStateDef, action: any) => {
    switch (action.type) {
        case UPDATE_STATE:
            return {
                ...action.payload,
            };
            break;
        case ON_INPUT_CHANGE:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
            break;
        case ADD_NAV_LINK:
            return {
                ...state,
                navigationLinks: [...state.navigationLinks, action.payload],
            };
            break;

        case REMOVE_NAV_LINK: {
            return {
                ...state,
                navigationLinks: [
                    ...state.navigationLinks.slice(0, action.payload.index),
                    ...state.navigationLinks.slice(action.payload.index + 1),
                ],
            };
            break;
        }

        case ON_NAV_LINK_DRAG_END:
            return {
                ...state,
                navigationLinks: action.payload,
            };
            break;

        case ON_NAV_LINK_INPUT_CHANGE: {
            return {
                ...state,
                navigationLinks: [
                    ...state.navigationLinks.slice(0, action.payload.index),
                    {
                        ...state.navigationLinks[action.payload.index],
                        [action.payload.name]: action.payload.value,
                    },
                    ...state.navigationLinks.slice(action.payload.index + 1),
                ],
            };
            break;
        }
        default:
            return state;
            break;
    }
};
