import React, { FunctionComponent, ChangeEvent } from 'react';
import './radio-button.scss';

interface RadioButtonProps {
    label?: string;
    defaultChecked?: boolean;
    className?: string;
    id?: string;
    value: string;
    name: string;
    checked?: boolean;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    /** Callback when input is focused */
    onFocus?(): void;
    /** Callback when focus is removed */
    onBlur?(): void;
}

const RadioButton: FunctionComponent<RadioButtonProps> = (props) => {
    const {
        className,
        value,
        disabled,
        defaultChecked,
        id,
        label,
        name,
        checked,
        onChange,
    } = props;

    return (
        <div>
            <label
                className={`radio-button-wrapper flex items-center mb-2 ${className}`}
                htmlFor={id}
            >
                <input
                    checked={defaultChecked}
                    id={id}
                    type="radio"
                    className="radio-button-input"
                    disabled={disabled}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e)}
                />
                <span className="radio-button">
                    <span className="icon"></span>
                </span>
                <span className="label">
                    {label} {JSON.stringify(checked)}
                </span>
            </label>
        </div>
    );
};

export default RadioButton;
