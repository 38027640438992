import React, { FunctionComponent, useState, useRef } from 'react';
import { H4, H5, SubHeading } from '../../../design-system/Headings/Headings';
import Spinner from '../../../design-system/Spinner/spinner';
import Card from '../../Card/Card';
import NotificationBar from '../../Notification/NotificationBar';
import { Editor } from '@toast-ui/react-editor';
import Tooltip from '../../../design-system/Tooltip/Tooltip';
import { ToggleSwitch } from '../../../design-system';
import SaveAction from '../../SaveAction/SaveAction';
import ConfirmUnsavedChanges from '../../ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import { AIChatbotContextValue, useAIChatbot } from './AIChatbotContext';
import useConfirmUnsavedChanges from '../../../hooks/useConfirmUnsavedChanges';
import { aiChatbotStoreKey } from './AIChatbotConstant';
import { PreReleaseTag } from './PreReleaseTag/PreReleaseTag';

export const aiChatbotTitle = 'API Copilot';

const AIChatbot: FunctionComponent = () => {
    const [showModal, setShowModal] = useState(false);
    const [discard, setDiscard] = useState(false);

    const {
        uiState,
        aiChatbotState,
        updateToggleState,
        updateWelcomeMessageState,
        saveData,
    } = useAIChatbot() as AIChatbotContextValue;

    const editorRef = useRef<Editor | null>(null);

    const _handleOnChange = () => {
        if (editorRef.current) {
            updateWelcomeMessageState(editorRef.current.getInstance().getMarkdown());
        }
    };

    const saveChanges = () => {
        setShowModal(false);
        saveData();
    };

    const discardChanges = () => {
        setShowModal(false);
        setDiscard(true);
    };

    useConfirmUnsavedChanges(
        discard,
        aiChatbotState,
        'apiGroup',
        () => {
            setShowModal(true);
        },
        aiChatbotStoreKey
    );

    if (uiState.isError) return <ErrorView />;
    if (uiState.isLoading) return <LoadingView />;

    return (
        <div className="p-8 pt-0 fade-in-animation">
            <NotificationBar />
            <SaveAction
                isSaving={uiState.isSaving}
                disabled={uiState.isSaving}
                onClick={saveChanges}
            />
            <ConfirmUnsavedChanges
                show={showModal}
                closeModal={setShowModal}
                discardChanges={discardChanges}
                saveChanges={saveChanges}
            />
            <div className="flex mt-10 mb-3">
                <H4>{aiChatbotTitle}</H4>
                <PreReleaseTag title="BETA" />
                <Tooltip
                    message={`Toggle ${aiChatbotTitle}’s visibility on the published portal.`}
                    position="right"
                >
                    <ToggleSwitch
                        onChange={(e) => updateToggleState(e.target.checked)}
                        checked={aiChatbotState.isAiEnabled}
                    />
                </Tooltip>
            </div>
            <SubHeading
                text={`Empower your Developer experience with our hallucination-free ${aiChatbotTitle} – delivering reliable and accurate integration code every time.`}
            ></SubHeading>

            <Card className="mt-10">
                <div>
                    <H5>Welcome message</H5>
                    <SubHeading
                        text={`Create the first message to help ${aiChatbotTitle} users start chatting easily.`}
                    ></SubHeading>
                </div>

                <Editor
                    initialValue={aiChatbotState.welcomeMessage}
                    placeholder={
                        'Here you can add your welcome message. Add inline-code elements in an unordered list. API Copilot will then recognize these as your suggested prompts.'
                    }
                    previewStyle="vertical"
                    height="400px"
                    initialEditType="markdown"
                    useCommandShortcut={true}
                    ref={editorRef}
                    onChange={_handleOnChange}
                    toolbarItems={[
                        ['heading', 'bold', 'italic', 'strike'],
                        ['hr', 'quote'],
                        ['ul', 'ol', 'task', 'indent', 'outdent'],
                        ['link'],
                        ['code', 'codeblock'],
                    ]}
                />
            </Card>
        </div>
    );
};

const ErrorView = () => {
    return (
        <div className="flex items-center justify-center w-full h-full">
            <p className="text-base font-bold text-red ">{'Oops! Something went wrong.'}</p>
        </div>
    );
};

const LoadingView = () => {
    return (
        <div className="flex items-center justify-center w-full h-full">
            <Spinner size="xl" />
        </div>
    );
};

export default AIChatbot;
