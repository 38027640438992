import React, {
    createContext,
    FunctionComponent,
    useReducer,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react';
import {
    TypographyElementIdentifierType,
    TypographyElementProperty,
    TypographyType,
} from './Typography/TypographySection/TypographySectionConstant';
import { TypographySectionKeyType } from './Typography/TypographyConstant';
import {
    ApiDocsBrandingContextValue,
    ApiDocsBrandingProviderProps,
    ApiDocsBrandingStateDef,
    UPDATE_STATE,
    CHANGE_DEFAULT_FONTS,
    CHANGE_ADVANCED_FONT,
    CHANGE_COLOR,
    CHANGE_LOGO,
    CHANGE_FAVICON,
    UPDATE_LOGO_URI,
    UPDATE_FAVICON_URI,
    CLEAR_LOGO_FAVICON_FILES,
    REMOVE_LOGO,
    REMOVE_FAVICON,
    RESET_ADVANCED_FONT,
} from './BrandingTypes';
import { brandingReducer } from './BrandingReducer';
import { brandingInitialState } from './BrandingInitialState';
import { ColorsType } from './Colors/ColorsContant';
import { FileType } from './Logo/LogoConstant';
import { getApiDocsBranding } from '../../../api-client/ApiDocsBrandingService';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { BrandingStoreKey } from './BrandingConstant';
import { FormValidatorDef } from '../../../types/form-validator';
import { UiStateDef } from '../../../types/ui-state';

export const BrandingContext = createContext<ApiDocsBrandingContextValue>(brandingInitialState);

const ApiDocsBrandingProvider: FunctionComponent<ApiDocsBrandingProviderProps> = ({ children }) => {
    const [apiDocsBrandingState, apiDocsBrandingDispatch] = useReducer(
        brandingReducer,
        brandingInitialState.apiDocsBrandingState
    );
    const [uiState, setUiState] = useState(brandingInitialState.uiState);
    const { updateStoreApiGroup, storeState } = useStore() as StoreProviderValue;

    const [formValidator, setFormValidator] = useState<FormValidatorDef>({
        isSubmitted: false,
        errors: {},
    });

    useEffect(() => {
        const cacheData = storeState.apiGroup.apiDocsBranding;
        if (cacheData) {
            setUiState({
                ...uiState,
                isLoading: false,
            });
            return updateState(cacheData);
        }

        const promise = getApiDocsBranding();
        promise
            .then((resp: ApiDocsBrandingStateDef) => {
                setUiState({
                    ...uiState,
                    isLoading: false,
                });

                if (!resp.logo) {
                    resp.logo = {
                        uri: null,
                        file: null,
                        delete: false,
                    };
                }

                if (!resp.favicon) {
                    resp.favicon = {
                        uri: null,
                        file: null,
                        delete: false,
                    };
                }

                updateState(resp);
                updateStoreApiGroup(resp, BrandingStoreKey);
            })
            .catch((e: Response) => {
                setUiState({
                    isLoading: false,
                    isError: true,
                    statusCode: e.status,
                    message: e.statusText,
                });
            });
    }, []);

    const updateState = (updatedState: ApiDocsBrandingStateDef): void => {
        apiDocsBrandingDispatch({
            type: UPDATE_STATE,
            payload: updatedState,
        });
    };

    const changeDefaultFont = useCallback(
        (sectionKey: TypographySectionKeyType, font: string) => {
            apiDocsBrandingDispatch({
                type: CHANGE_DEFAULT_FONTS,
                payload: {
                    sectionKey,
                    font,
                },
            });
        },
        [apiDocsBrandingDispatch]
    );

    const updateUiState = (newState: UiStateDef) => {
        setUiState(newState);
    };

    const restAdvancedFont = useCallback(
        (typogrophy: TypographyType, sectionKey: TypographySectionKeyType) => {
            apiDocsBrandingDispatch({
                type: RESET_ADVANCED_FONT,
                payload: {
                    typogrophy: typogrophy,
                    sectionKey: sectionKey,
                },
            });
        },
        [apiDocsBrandingDispatch]
    );

    const changeAdvancedFont = useCallback(
        (
            identifier: TypographyElementIdentifierType,
            property: TypographyElementProperty,
            value: any
        ) => {
            apiDocsBrandingDispatch({
                type: CHANGE_ADVANCED_FONT,
                payload: {
                    identifier,
                    property,
                    value,
                },
            });
        },
        [apiDocsBrandingDispatch]
    );

    const changeColor = useCallback(
        (color: string, section: keyof ColorsType) => {
            apiDocsBrandingDispatch({
                type: CHANGE_COLOR,
                payload: {
                    color,
                    section,
                },
            });
        },
        [apiDocsBrandingDispatch]
    );

    const changeLogo = useCallback(
        (file: FileType) => {
            apiDocsBrandingDispatch({
                type: CHANGE_LOGO,
                payload: {
                    file,
                },
            });
        },
        [apiDocsBrandingDispatch]
    );

    const changeFavicon = useCallback(
        (file: FileType) => {
            apiDocsBrandingDispatch({
                type: CHANGE_FAVICON,
                payload: {
                    file,
                },
            });
        },
        [apiDocsBrandingDispatch]
    );

    const updateLogoUri = useCallback(
        (uri: string) => {
            apiDocsBrandingDispatch({
                type: UPDATE_LOGO_URI,
                payload: {
                    uri,
                },
            });
        },
        [apiDocsBrandingDispatch]
    );

    const updateFaviconUri = useCallback(
        (uri: string) => {
            apiDocsBrandingDispatch({
                type: UPDATE_FAVICON_URI,
                payload: {
                    uri,
                },
            });
        },
        [apiDocsBrandingDispatch]
    );

    const clearLogoFaviconFiles = useCallback(() => {
        apiDocsBrandingDispatch({
            type: CLEAR_LOGO_FAVICON_FILES,
        });
    }, [apiDocsBrandingDispatch]);

    const removeLogo = useCallback(() => {
        apiDocsBrandingDispatch({
            type: REMOVE_LOGO,
        });
    }, [apiDocsBrandingDispatch]);

    const removeFavicon = useCallback(() => {
        apiDocsBrandingDispatch({
            type: REMOVE_FAVICON,
        });
    }, [apiDocsBrandingDispatch]);

    const value: ApiDocsBrandingContextValue = {
        apiDocsBrandingState,
        uiState,
        form: { state: formValidator, setState: setFormValidator },
        updateUiState,
        changeDefaultFont,
        changeAdvancedFont,
        restAdvancedFont,
        changeColor,
        changeLogo,
        changeFavicon,
        updateLogoUri,
        updateFaviconUri,
        clearLogoFaviconFiles,
        removeLogo,
        removeFavicon,
    };

    return <BrandingContext.Provider value={value}>{children}</BrandingContext.Provider>;
};

const useApiDocsBranding = () => useContext(BrandingContext);

export { ApiDocsBrandingProvider, useApiDocsBranding };
