import React, { FunctionComponent, useEffect } from 'react';
import { ApiDocsBrandingProvider } from '../../components/api-docs/Branding/BrandingContext';
import Branding from '../../components/api-docs/Branding/Branding';
import { useStore, StoreProviderValue } from '../../store/storeContext';
import { BrandingItemKeyForSidenav, SideNavActiveItemStoreKey } from '../ViewConstant';

const BrandingView: FunctionComponent = () => {
    const { updateStore } = useStore() as StoreProviderValue;

    useEffect(() => {
        updateStore(BrandingItemKeyForSidenav, SideNavActiveItemStoreKey);
    }, []);

    return (
        <div className="w-full h-full">
            <ApiDocsBrandingProvider>
                <Branding />
            </ApiDocsBrandingProvider>
        </div>
    );
};

export default BrandingView;
