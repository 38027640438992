import { getHeaders, fetcher } from './ApiClient';
import { ApiSpecExportStateDef } from '../components/api-docs/ApiSpecExport/ApiSpecExportInitialState';

const getApiSpecExport = (apiEntityId: string): Promise<ApiSpecExportStateDef> => {
    const uri = `api-entities/${apiEntityId}/draft-portal-settings/api-spec-exports`;

    return fetcher<ApiSpecExportStateDef>(uri, 'GET', getHeaders());
};

const updateApiSpecExport = (apiEntityId: string, body: ApiSpecExportStateDef) => {
    const uri = `api-entities/${apiEntityId}/draft-portal-settings/api-spec-exports`;

    return fetcher<ApiSpecExportStateDef>(uri, 'PUT', getHeaders(), body);
};

export { getApiSpecExport, updateApiSpecExport };
