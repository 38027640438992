/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { FunctionComponent, useState, ReactChild, useRef, ReactNode } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';
import Button from '../Button/Button';
import { EllipsisIcon } from '../../icons/Ui/EllipsisIcon';
import './drop-down-style.scss';
import DropdownSubMenu from './DropDownSubMenu';
import cx from 'classnames';

export interface DropdownItemDef {
    icon?: ReactChild;
    data?: any;
    hr?: 'above' | 'below' | null;
    disabled?: boolean;
    name: string;
    dropdownItems?: DropdownItemDef[];
    onClick: (data?: any) => void;
}

interface DropdownProps {
    icon?: ReactNode;
    size?: 'sm';
    fill?: 'transparent' | 'default' | 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link';
    className?: string;
    name: string;
    dropdownItems: DropdownItemDef[];
}

/***
 * Dropdown Element
 * @param {ReactNode} icon Icon as react node
 * @param {ReactNode} size "sm" (not need to define size for default button)
 * @param {DropdownItemDef[]} dropdownItems list of dropdown items
 * @param {string} className CSS class applies on dropdown wrapper (optional)
 ***/
const Dropdown: FunctionComponent<DropdownProps> = (props) => {
    const ref = useRef(null);

    const { icon, dropdownItems, className, size, fill } = props;
    const [toggleDropdown, setToggleDropDown] = useState(false);
    const actionIcon = icon ? icon : <EllipsisIcon />;

    useOutsideClick(ref, () => {
        setToggleDropDown(false);
    });

    return (
        <div
            className={cx('dropdown-wrapper', className, size, toggleDropdown ? 'open' : 'close')}
            ref={ref}
        >
            <Button
                text=""
                size={size}
                fill={fill}
                icon={actionIcon}
                onClick={() => setToggleDropDown(!toggleDropdown)}
                className="dropdown-button"
            />

            {toggleDropdown && (
                <ul className="dropdown-menu">
                    {dropdownItems.map(
                        ({ name, data, icon, dropdownItems, hr, disabled, onClick }) => (
                            <div key={name}>
                                {hr === 'above' && <hr />}
                                {(!dropdownItems || dropdownItems.length === 0) && (
                                    <li
                                        key={name}
                                        className="dropdown-item"
                                        onClick={() => {
                                            setToggleDropDown(!toggleDropdown);
                                            onClick(data);
                                        }}
                                    >
                                        <a>
                                            {icon && <div className="mr-2 icon">{icon}</div>}
                                            <div className="item-text">{name}</div>
                                        </a>
                                    </li>
                                )}

                                {dropdownItems && dropdownItems.length > 0 && (
                                    <DropdownSubMenu
                                        name={name}
                                        icon={icon}
                                        disabled={disabled}
                                        dropdownItems={dropdownItems}
                                        hr={hr}
                                        onClick={onClick}
                                    ></DropdownSubMenu>
                                )}
                                {hr === 'below' && <hr />}
                            </div>
                        )
                    )}
                </ul>
            )}
        </div>
    );
};

export default Dropdown;
