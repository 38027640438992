import React, { FunctionComponent, Fragment, useState } from 'react';
import Jumbotron from './Jumbotron/Jumbotron';
import ContentSection from './ContentSection/ContentSection';
import Footer from './Footer/Footer';
import { HomePageProviderValue, useHomePage } from './HomePageContext';
import { useStore, StoreProviderValue } from '../../../store/storeContext';
import { useHeader, HeaderProviderValue } from '../../Header/HeaderContext';
import useUpdateEffect from '../../../hooks/UseUpdateEffect';
import {
    useNotification,
    NotificationProviderValue,
} from '../../Notification/NotificationBarContext';
import {
    updateHomePage,
    updateHomePageCoverImage,
    updateHomepageLogo,
    removeHomepageLogo,
} from '../../../api-client/HomePageService';
import { HomePageStoreKey } from './HomePageConstant';
import ConfirmUnsavedChanges from '../../ConfirmUnsavedChanges/ConfirmUnsavedChanges';
import useConfirmUnsavedChanges from '../../../hooks/useConfirmUnsavedChanges';
import HomePageHeader from './HomePageHeader/HomePageHeader';
import NotificationBar from '../../Notification/NotificationBar';
import SaveAction from '../../SaveAction/SaveAction';
import { utilityFunctions } from '../../../utilities/functions';
import PlatformsIcon from './PlatformsIcon/PlatformsIcon';
import Spinner from '../../../design-system/Spinner/spinner';

const HomePage: FunctionComponent = () => {
    const { addNotification } = useNotification() as NotificationProviderValue;
    const { updateStoreApiGroup, updateStore } = useStore() as StoreProviderValue;
    const { headerState, resetHeaderState } = useHeader() as HeaderProviderValue;
    const {
        homePageState,
        uiState,
        coverImageState,
        updateUiState,
        clearLogoFile,
        updateCoverImage,
    } = useHomePage() as HomePageProviderValue;
    const [showModal, setShowModal] = useState(false);
    const [discard, setDiscard] = useState(false);

    useUpdateEffect(() => {
        if (headerState.isSaveClicked) {
            saveData();
            resetHeaderState();
        }
    }, [headerState]);

    const saveData = () => {
        updateUiState(utilityFunctions.getSavingUiState());
        const promiseArray: Promise<any>[] = [updateHomePage(homePageState)];

        const logo = homePageState.logo ? homePageState.logo.file : null;

        if (logo && logo.length > 0) {
            const logoFormData = new FormData();
            logoFormData.append('logo', logo[0]);
            promiseArray.push(updateHomepageLogo(logoFormData));
        }

        if (homePageState.logo && homePageState.logo.delete) {
            promiseArray.push(removeHomepageLogo());
        }

        if (coverImageState.file) {
            const coverImageFormData = new FormData();
            coverImageFormData.append('ImageFile', coverImageState.file);
            promiseArray.push(updateHomePageCoverImage(coverImageFormData));
        }

        Promise.all(promiseArray)
            .then((resp) => {
                addNotification({
                    show: true,
                    type: 'success',
                    message: 'Home Page settings saved successfully.',
                });

                clearLogoFile();
                updateStoreApiGroup(
                    {
                        ...homePageState,
                        coverImage: { ...homePageState.coverImage, uri: coverImageState.fileUri },
                    },
                    'homePage'
                );
                updateStore(coverImageState.fileUri, 'coverImage');

                updateCoverImage({
                    fileUri: coverImageState.fileUri,
                    file: null,
                });
            })
            .catch((e) =>
                addNotification({
                    show: true,
                    type: 'error',
                    message: 'Failed to save Home Page settings.',
                })
            )
            .finally(() => {
                updateUiState(utilityFunctions.getDefaultUiState());
            });
    };

    useConfirmUnsavedChanges(
        discard,
        homePageState,
        'apiGroup',
        () => {
            setShowModal(true);
        },
        HomePageStoreKey,
        undefined,
        [coverImageState]
    );

    const saveChanges = () => {
        setShowModal(false);
        saveData();
    };

    const discardChanges = () => {
        setShowModal(false);
        setDiscard(true);
    };

    const renderHomePage = () => {
        return (
            <>
                <NotificationBar />

                <SaveAction
                    disabled={uiState.isLoading}
                    isSaving={uiState.isSaving!}
                    onClick={saveChanges}
                />

                <ConfirmUnsavedChanges
                    show={showModal}
                    closeModal={setShowModal}
                    discardChanges={discardChanges}
                    saveChanges={saveChanges}
                />
                {/* {JSON.stringify(homePageState)} */}
                <div className="flex flex-col items-center w-full h-full fade-in-animation">
                    <HomePageHeader />
                    <div className="mx-auto my-0 xl:w-3/4 lg:w-11/12">
                        <Jumbotron />

                        <PlatformsIcon />

                        <ContentSection />

                        <Footer />
                    </div>
                </div>
            </>
        );
    };

    const renderErrorView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <p className="text-base font-bold text-red ">
                    {uiState.message
                        ? `${uiState.statusCode} - ${uiState.message}`
                        : 'Oops! Something went wrong.'}
                </p>
            </div>
        );
    };

    const renderLoadingView = () => {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <Spinner size="xl" />
            </div>
        );
    };

    return (
        <Fragment>
            {uiState.isLoading
                ? renderLoadingView()
                : uiState.isError
                ? renderErrorView()
                : renderHomePage()}
        </Fragment>
    );
};

export default HomePage;
