export type ExportFormatValueDef =
    | 'openApiSpec'
    | 'raml'
    | 'apiBlueprint'
    | 'insomnia'
    | 'wadl'
    | 'wsdl'
    | 'apimatic'
    | 'postman';

export type ExportTemplateValueDef =
    | 'OpenApi31Json'
    | 'OpenApi31Yaml'
    | 'OpenApi3Json'
    | 'OpenApi3Yaml'
    | 'Swagger20'
    | 'SwaggerYaml'
    | 'Swagger10'
    | 'RAML10'
    | 'RAML'
    | 'APIBluePrint'
    | 'Insomnia'
    | 'InsomniaYaml'
    | 'WADL2009'
    | 'WSDL'
    | 'APIMATIC'
    | 'Postman20'
    | 'Postman10';

export interface ExportTemplate {
    exportFormat: ExportTemplateValueDef;
    enabled: boolean;
}

export type ExportFormat = {
    [format in ExportFormatValueDef]: ExportTemplate[];
};

export interface ApiSpecExportStateDef {
    enabled: boolean;
    exportFormats: ExportFormat;
}

export const apiSpecExportInitialState: ApiSpecExportStateDef = {
    enabled: true,
    exportFormats: {
        openApiSpec: [
            {
                exportFormat: 'OpenApi31Json',
                enabled: true,
            },
            {
                exportFormat: 'OpenApi31Yaml',
                enabled: true,
            },
            {
                exportFormat: 'OpenApi3Json',
                enabled: true,
            },
            {
                exportFormat: 'OpenApi3Yaml',
                enabled: true,
            },
            {
                exportFormat: 'Swagger20',
                enabled: true,
            },
            {
                exportFormat: 'SwaggerYaml',
                enabled: true,
            },
            {
                exportFormat: 'Swagger10',
                enabled: true,
            },
        ],
        raml: [
            {
                exportFormat: 'RAML10',
                enabled: true,
            },
            {
                exportFormat: 'RAML',
                enabled: true,
            },
        ],
        apiBlueprint: [
            {
                exportFormat: 'APIBluePrint',
                enabled: true,
            },
        ],
        insomnia: [
            {
                exportFormat: 'Insomnia',
                enabled: true,
            },
            {
                exportFormat: 'InsomniaYaml',
                enabled: true,
            },
        ],
        wadl: [
            {
                exportFormat: 'WADL2009',
                enabled: true,
            },
        ],
        wsdl: [
            {
                exportFormat: 'WSDL',
                enabled: true,
            },
        ],
        apimatic: [
            {
                exportFormat: 'APIMATIC',
                enabled: true,
            },
        ],
        postman: [
            {
                exportFormat: 'Postman20',
                enabled: true,
            },
            {
                exportFormat: 'Postman10',
                enabled: true,
            },
        ],
    },
};
