import React, { FunctionComponent, useState } from 'react';
import { PlatformConstantDef } from './PlatformConstants';
import { usePlatforms, PlatformsProviderValue } from './PlatformsContext';
import Card from '../../Card/Card';
import { Button, ToggleSwitch } from '../../../design-system';
import PlatformTemplate from './PlatformTemplate';
import { ChevronIcon } from '../../../icons/Ui/ChevronIcon';
import Tooltip from '../../../design-system/Tooltip/Tooltip';

interface PlatformSettingsProps {
    index: number;
    platformConst: PlatformConstantDef;
    showToggle: boolean;
}

const PlatformSettings: FunctionComponent<PlatformSettingsProps> = (props) => {
    const {
        platformConst: { name, templates },
        showToggle,
    } = props;
    const [collapsed, setCollapsed] = useState(false);
    const {
        platformsState: { sdkPlatforms },
        onPlatformInputChange,
    } = usePlatforms() as PlatformsProviderValue;

    const isAlphaRelease = (lang: string) => {
        return ['go'].includes(lang.toLocaleLowerCase());
    }

    return (
        // <EuiDraggable index={index} draggableId={`${name}-${index}`} customDragHandle={true}>
        //     {(provided) => (
        <Card key={name} className="flex flex-col pt-0 pl-0 pr-0 mb-5">
            <div className="relative flex items-center justify-between w-full h-16 p-6 border-t-0 border-b border-l-0 border-r-0 border-solid header text-16 border-goose-600">
                {/* <div
                            className="absolute top-0 left-0 invisible hidden"
                            {...provided.dragHandleProps}
                        >
                            <DragIcon />
                        </div> */}
                <div className="flex">
                    <div className="flex items-center content-end">
                    <h3 className={'font-medium ' + (isAlphaRelease(name) ? 'mr-2' : 'mr-4')}>
                            {name}
                        </h3>
                        {isAlphaRelease(name) && (
                            <div className="mr-4 pre-release-tag" style={{ height: '14px' }}>
                                ALPHA RELEASE
                            </div>
                        )}
                    </div>

                    {(showToggle || !sdkPlatforms[templates[0].value].platformEnabled) && (
                        <Tooltip
                            message={`Toggle ${name} SDK documentation in the portal.`}
                            position="right"
                        >
                            <ToggleSwitch
                                name="platformEnabled"
                                checked={sdkPlatforms[templates[0].value].platformEnabled}
                                onChange={(e) => onPlatformInputChange(e, templates[0].value)}
                            />
                        </Tooltip>
                    )}
                </div>
                <Button
                    text=""
                    icon={<ChevronIcon className={collapsed ? 'rotate-180' : ''} />}
                    onClick={() => setCollapsed(!collapsed)}
                ></Button>
            </div>
            {!collapsed && <PlatformTemplate template={templates[0]} />}
        </Card>
        //     )}
        // </EuiDraggable>
    );
};

export default PlatformSettings;
