import * as React from 'react';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export let FilmIcon = (props: SVGIconProps) => (
    <SVGIcon viewBox="0 0 15 12" width="15" height="12" {...props}>
        <path
            d="M0,3.5A1.5,1.5,0,0,1,1.5,2h12A1.5,1.5,0,0,1,15,3.5v9A1.5,1.5,0,0,1,13.5,14H1.5A1.5,1.5,0,0,1,0,12.5Zm4.5,0v9h6v-9Zm-3,.75v1.5H3V4.25Zm0,3v1.5H3V7.25Zm0,3v1.5H3v-1.5Zm10.5-6v1.5h1.5V4.25Zm0,3v1.5h1.5V7.25Zm0,3v1.5h1.5v-1.5Z"
            transform="translate(0 -2)"
        />
    </SVGIcon>
);
